import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelLibraryService {


  constructor(private http: HttpClient) {

  }
  
  getDisciplineInformation(): Observable<any> {
    const query = environment.ECHO_Api_URL + 'api/discipline';
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  
  getModels(): Observable<any> {
    const query = environment.BAMS_Api_URL + 'api/models';
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  
  getModelsByProjectPk(projectPk: number):Observable<any> {;
    const query = `${environment.BAMS_Api_URL}api/models/project/${projectPk}`;
    return this.http
      .get(query)
      .pipe(map((data: any) => data.Value));
  }
  
  saveData(pData:any):Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = environment.BAMS_Api_URL + 'api/models';
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }
}