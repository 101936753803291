import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { MetaboliteXModel } from 'src/app/shared/models/echo/metabolite';
import { ProjectXMolecule } from 'src/app/shared/models/project-x-molecule';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaboliteBamsApiService extends HttpApiService{

  override apiUrl: string = environment.BAMS_Api_URL;

  getMetabolitesByProjectAndMolecule(projectXMolecule: ProjectXMolecule): Observable<MetaboliteXModel[]> {
    const body = JSON.stringify(projectXMolecule);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/Metabolite/GetMetabolitesByProjectAndMolecule`;
    return this
      .put(endpoint, body,  headers )
      .pipe(map((data: any) => data.Value));
  }
}
