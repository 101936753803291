import { Component, OnInit, ViewChild } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Datafield } from 'src/app/shared/models/echo/data-field';
import { CropList } from 'src/app/shared/models/crop-list';
import { CropListService } from './crop-list.service';
import { GridLogicService } from 'src/app/shared/components/grid/grid.logic.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { take } from 'rxjs';
import Swal from 'sweetalert2';
import { RoleApiService } from 'src/app/shared/services/role.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';


@Component({
  selector: 'app-crop-list',
  templateUrl: './crop-list.component.html',
  styleUrls: ['./crop-list.component.css']
})

export class CropListComponent implements OnInit {
  afterSearch: boolean = false;
  loading: boolean = false;
  geography: string[] = [];
  purpose: string[] = [];
  compartment: string[] = [];
  selectedGeography: string = '';
  selected!: DropdownChangeEvent;
  selectedPurpose: string = ""
  selectedCompartment: string = "";
  hiddenPurpose: boolean = false;
  hiddenCompartment: boolean = false;
  applicationCropListComponent = this;
  datafields: Datafield[] = [];
  datafieldValues: DatafieldValue[] = [];

  @ViewChild('grid') grid!: GridComponent;
  columnDefs: any = [];
  rowData: CropList[] = [];
  selectedRow: CropList | null | undefined;
  scenario: DatafieldValue[]|undefined  = [];
  rowHeight: number = 50;
  useDeleteAction: boolean = true;
  currentUserCanEdit: boolean = true;
  constructor(private datafieldValueService: DatafieldValueApiService,
              private cropListService: CropListService,
              private gridService: GridLogicService,
              private userService: UserLogicService) { }

  async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.geography = Constants.CROP_LIST_FILTER_GEOGRAPHY;
    this.selectedGeography = Constants.CROP_GEOGRAPHIES.GLOBAL;
    this.geographyOnInit(this.selectedGeography);
    this.onGridReady();
  }

  onGridReady(): void {
    this.configureGrid();
  }

  fillingCropLists(pCropList: CropList[]): any {
    if (pCropList) {
      pCropList.forEach((crop: CropList) => {
        crop.ScenariosNames = [];
        crop.CropListXScenarios.forEach((cropListXScenarios) => {
          let value: any = this.datafieldValues.find((x: any) => x.datafieldValuePk == cropListXScenarios.Scenarios);
          crop.ScenariosNames.push(value);
        });
      });
      return pCropList;
    }
    return [];
  }

  configureGrid() {
    if (this.selectedGeography == Constants.CROP_LIST_FILTER.USA) {
      let datapk: any = this.datafields.find((df: any) => df.value.toLowerCase() === Constants.DATA_VALUES_NAMES.scenario.toLowerCase())?.datafieldPk;
      this.scenario=this.datafieldValues.filter((x: any) => x.datafieldPk == datapk);
    
      this.rowData = this.fillingCropLists(this.rowData);
      this.columnDefs = [
        {
          headerName: 'Crop Name',
          field: 'CropName',
          minWidth: 200,
          type: 'default',
          filter: true,
          editable: this.currentUserCanEdit,
        },
        {
          headerName: 'Scenario',
          field: 'ScenariosNames',
          minWidth: 320,
          type: 'select',
          cellRenderer: 'listBoxRenderer',
          cellRendererParams: {
            options: this.scenario,
            optionValue: 'datafieldValuePk',
            optionLabel: 'value',
            columnThatModifies: 'ScenariosNames',
            checkbox: true,
            filter: false,
            multiple: true,
            editable: this.currentUserCanEdit,
            validateModel: false,
            compartment: 'ScenariosNames'
          },
          filter: 'agSetColumnFilter',
        }
      ];
    }
    else {
      this.columnDefs = [
        {
          headerName: 'CropListPk',
          field: 'CropListPk',
          minWidth: 100,
          hide: true,
          type: 'default',
          editable: this.currentUserCanEdit,
          filter: false,
        },
        {
          headerName: 'Crop Name',
          field: 'CropName',
          minWidth: 100,
          type: 'default',
          editable: this.currentUserCanEdit,
          filter: true,
        }
      ];
    }
  }

  geographySelectionChange({ value }: DropdownChangeEvent) {
    this.useDeleteAction = false;
    this.geographyOnInit(value);
  }

  geographyOnInit(value: string) {
    this.selectedGeography = value == undefined ? Constants.CROP_GEOGRAPHIES.GLOBAL : value;
    this.setLoadingState(true);
    if (this.selectedGeography == Constants.CROP_LIST_FILTER.USA) {
      this.useDeleteAction = false;
      this.rowHeight = 100;
      this.datafieldValueService.getDatafieldData(Constants.THIS_APPLICATION).subscribe((datafieldData: Datafield[]) => {
        this.datafieldValueService.getDatafieldValueInformation().subscribe((datafieldValueData: DatafieldValue[]) => {
          this.datafieldValues = datafieldValueData;
          this.datafields = datafieldData;
          this.hiddenCompartment = true;
          this.hiddenPurpose = true;
          this.selectedCompartment = "";
          this.selectedPurpose = "";
          this.getData();
        });
      });
    }
    else if (this.selectedGeography == Constants.CROP_LIST_FILTER.GLOBAL) {
      this.useDeleteAction = true;
      this.hiddenCompartment = true;
      this.hiddenPurpose = false;
      this.selectedCompartment = "";
      this.selectedPurpose = Constants.CROP_LIST_FILTER.GAP_CROP;
      this.purpose = Constants.CROP_LIST_FILTER_PURPOSE_GLOBAL;
      this.getData();
    }
    else if (this.selectedGeography == Constants.CROP_LIST_FILTER.EUROPE_UNION) {
      this.useDeleteAction = false;
      this.hiddenCompartment = false;
      this.hiddenPurpose = false;
      this.compartment = Constants.CROP_LIST_FILTER_COMPARTMENT;
      this.purpose = Constants.CROP_LIST_FILTER_PURPOSE_EU_SURFACE_WATER;
      this.selectedCompartment = Constants.CROP_LIST_FILTER.SURFACE_WATER;
      this.selectedPurpose = Constants.CROP_LIST_FILTER.DRIFT_INTERCEPTION;
      this.getData();
    }
    else if (this.selectedGeography == Constants.CROP_LIST_FILTER.UK) {
      this.useDeleteAction = false;
      this.hiddenCompartment = false;
      this.hiddenPurpose = false;
      this.compartment = Constants.CROP_LIST_FILTER_COMPARTMENT;
      this.purpose = Constants.CROP_LIST_FILTER_PURPOSE_UK;
      this.selectedCompartment = Constants.CROP_LIST_FILTER.SURFACE_WATER;
      this.selectedPurpose = Constants.CROP_LIST_FILTER.SPRAY;
      this.getData();
    }
  }

  compartmentSelectionChange({ value }: DropdownChangeEvent) {
    this.selectedCompartment = value;
    if(this.selectedCompartment==Constants.CROP_LIST_FILTER.SURFACE_WATER && this.selectedGeography == Constants.CROP_LIST_FILTER.EUROPE_UNION ){
      this.purpose= Constants.CROP_LIST_FILTER_PURPOSE_EU_SURFACE_WATER;
      this.selectedPurpose = Constants.CROP_LIST_FILTER.DRIFT_INTERCEPTION;
    };
    if(this.selectedCompartment== Constants.CROP_LIST_FILTER.GROUND_WATER && this.selectedGeography == Constants.CROP_LIST_FILTER.EUROPE_UNION ){
      this.purpose= Constants.CROP_LIST_FILTER_PURPOSE_EU;
      this.selectedPurpose = Constants.CROP_LIST_FILTER.INTERCEPTION;
    };

    
    this.setLoadingState(true);
    this.getData();
  }

  purposeSelectionChange({ value }: DropdownChangeEvent) {
    this.selectedPurpose = value;
    this.setLoadingState(true);
    this.getData();
  }

  getData() {
    this.rowData = [];
    this.cropListService.getCropList(this.selectedGeography, this.selectedCompartment, this.selectedPurpose).subscribe((cropList: CropList[]) => {
      this.rowData = cropList;
      this.setLoadingState(false);
    });
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  saveData(dataTransaction: any[]) {
    dataTransaction.forEach((element: any) => {
      if (this.selectedGeography == Constants.CROP_LIST_FILTER.USA) {
        element.row.Geography = this.selectedGeography;
        element.row.Compartment = '';
        element.row.Purpose = '';
      }
      else if (this.selectedGeography != Constants.CROP_LIST_FILTER.GLOBAL) {
        element.row.Geography = this.selectedGeography;
        element.row.Compartment = this.selectedCompartment;
        element.row.Purpose = this.selectedPurpose;
      }
      else {
        element.row.Geography = this.selectedGeography;
        element.row.Compartment = '';
        element.row.Purpose = this.selectedPurpose;
      }
    });
    this.cropListService.saveData(dataTransaction).pipe(take(1)).subscribe({
      next: () => {
        Swal.fire({
          text: Constants.MESSAGE_SAVED_SUCCESSFULLY,
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Ok',
        });
        this.getData();
      },
      error: (err) => {
        console.warn(err);
      }
    });
  }


  addRow(): void {
    let row: any = {};
    if (this.selectedGeography == Constants.CROP_LIST_FILTER.USA) {
      row = {
        CropListPk: -1,
        CropName: '',
        ScenariosNames: [],
        Scenarios: []
      };
    }
    else {
      row = {
        CropListPk: -1,
        CropName: ''
      };
    }
    if (this.rowData.length === 0) {
      let tmpDataset: any[] = [];
      tmpDataset.push(row);
      this.rowData = tmpDataset;
    }
    else {
      this.rowData.unshift(row);
    }

    this.gridService.addTransaction([row]);
  }

  onSelectionChanged(event: any) {
    this.selectedRow = event as CropList;
    let tempData = this.rowData.filter((element) => element.CropListPk);
  }

  ScenariosListBoxSelectionChanged(params: any) {
    let oldValue: any = params.data.ScenariosNames;
    params.data.Scenarios = params.selectedValues;
    this.grid.CreateTransaction(params.id, params.id, oldValue, params.data);
  }


}
