import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Accordion, AccordionTabCloseEvent } from 'primeng/accordion';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { firstValueFrom, take } from 'rxjs';
import { UdlControlledVocabulary } from 'src/app/shared/models/udl-controlled-vocabulary.model';
import { EsaUdlOverlapToolService } from 'src/app/shared/services/esa-udl-overlap-tool.service';

@Component({
  selector: 'app-esa-setup-models-to-be-run',
  templateUrl: './esa-setup-models-to-be-run.component.html',
  styleUrls: ['./esa-setup-models-to-be-run.component.css']
})
export class ESASetupModelsToBeRunComponent {

  @ViewChild('accordion') accordion!: Accordion;

  @Input() modelsLoading: boolean = false;

  @Output() areSetupModelsValidEvent = new EventEmitter<boolean>();

  activeIndex: number | null = null;

  modelHeader: string = "EPA UDL Overlap";
  chemicalName: string = '';
  isChemicalNameValid: boolean = false;

  selectedSpeciesHabitat?: UdlControlledVocabulary;
  speciesHabitatOptions: UdlControlledVocabulary[] = [];
  isSpeciesHabitatValid: boolean = false;

  includeDrift: string = '';
  isIncludeDriftValid: boolean = true;

  regionsToIncludePlaceholder: string = 'Select Regions to Include';
  regionsToIncludeOptions: UdlControlledVocabulary[] = [];
  selectedRegionsToInclude?: UdlControlledVocabulary[] = [];
  isRegionsToIncludeValid: boolean = false;
  selectAllRegions: boolean = false;

  statesToIncludeLabel: string = 'States to Include*';
  statesToIncludePlaceholder: string = 'Select States to Include';
  statesToIncludeOptions: UdlControlledVocabulary[] = [];
  selectedStatesToInclude?: UdlControlledVocabulary[] = [];
  isStatesToIncludeValid: boolean = false;
  isStatesToIncludeRequired: boolean = true;
  selectAllStates: boolean = false;

  bufferDistances: string = '';
  isBufferDistancesValid: boolean = false;

  onOffFieldConsiderationsPlaceholder: string = 'Select On/Off Field Considerations';
  onOffFieldConsiderationsOptions: UdlControlledVocabulary[] = [];
  selectedOnOffFieldConsiderations?: UdlControlledVocabulary[] = [];
  isOnOffFieldConsiderationsValid: boolean = false;
  selectAllOnOffFieldConsiderations: boolean = false;

  adjustForPCT: string = '';
  isAdjustForPCTValid: boolean = true;

  constructor(private esaUdlOverlapToolService: EsaUdlOverlapToolService) {
  }

  async ngOnInit(): Promise<void> {
    this.includeDrift = 'Yes';
    this.adjustForPCT = 'No';

    await this.getControlledVocabularyItems();
    this.initializeControlsUdl();
  }

  onClose(event: AccordionTabCloseEvent) {
    this.activeIndex = null;
  }

  onOpen({ index }: AccordionTabCloseEvent) {
    this.activeIndex = index;
  }

  private async getControlledVocabularyItems() {
    this.speciesHabitatOptions = await this.getCVItemsByKey("SpeciesHabitat");
    this.regionsToIncludeOptions = await this.getCVItemsByKey("Regions");
    this.statesToIncludeOptions = await this.getCVItemsByKey("States");
    this.onOffFieldConsiderationsOptions = await this.getCVItemsByKey("OnOffFieldConsiderations");
  }

  private initializeControlsUdl() {
    this.selectedSpeciesHabitat = this.speciesHabitatOptions?.find(x => x.long_value?.toLowerCase() == "range");
    this.isSpeciesHabitatValid = this.selectedSpeciesHabitat != null;

    this.selectedRegionsToInclude = this.regionsToIncludeOptions.filter(x => x.short_value?.toLowerCase() == "conus");
    this.isRegionsToIncludeValid = this.selectedRegionsToInclude?.length != 0;

    this.setupStatesToInclude();
  }

  private async getCVItemsByKey(key: string): Promise<UdlControlledVocabulary[]> {
    let resp: UdlControlledVocabulary[] = await firstValueFrom(this.esaUdlOverlapToolService.getControlledVocabularyItemsByKey(key));
    return resp;
  }

  getOptionsByModel(modelHeader: string, controlledVocabulary: UdlControlledVocabulary[]) {
    const optionsForModel: UdlControlledVocabulary[] = [];

    controlledVocabulary.forEach(CVItem => {
      if (CVItem.metadata) {
        const jsonObject = JSON.parse(CVItem.metadata);
        if (jsonObject.versions && Array.isArray(jsonObject.versions)) {
          if (jsonObject.versions.includes(modelHeader))
            optionsForModel.push(CVItem);
        }
      }
    });

    return optionsForModel;
  }


  async chemicalNameChanged(value: string) {
    this.isChemicalNameValid = value.length > 0;
    this.chemicalName = value;
  }

  speciesHabitatSelectionChanged({ value }: DropdownChangeEvent) {
    this.isSpeciesHabitatValid = value != null;
    this.selectedSpeciesHabitat = value;
  }

  includeDriftSelectionChanged({ value }: DropdownChangeEvent) {
    this.isIncludeDriftValid = value != null;
    this.includeDrift = value;
  }

  regionsToIncludeSelectionChanged(event: any) {
    this.selectedRegionsToInclude = event.value;
    this.isRegionsToIncludeValid = this.selectedRegionsToInclude?.length != 0;
    this.selectAllRegions = this.selectedRegionsToInclude?.length == this.regionsToIncludeOptions.length;
    this.setupStatesToInclude();
  }

  regionsToIncludeSelectAllChanged(event: any) {
    this.selectAllRegions = event.checked;
    this.selectedRegionsToInclude = this.selectAllRegions ? this.regionsToIncludeOptions : [];
    this.isRegionsToIncludeValid = this.selectedRegionsToInclude?.length != 0;
    this.setupStatesToInclude();
  }

  statesToIncludeSelectionChanged(event: any) {
    this.selectedStatesToInclude = event.value;
    this.isStatesToIncludeValid = this.selectedStatesToInclude?.length != 0;
    this.selectAllStates = this.selectedStatesToInclude?.length == this.statesToIncludeOptions.length;
  }

  statesToIncludeSelectAllChanged(event: any) {
    this.selectAllStates= event.checked;
    this.selectedStatesToInclude = this.selectAllStates ? this.statesToIncludeOptions : [];
    this.isStatesToIncludeValid = this.selectedStatesToInclude?.length != 0;
  }

  async bufferDistancesChanged(value: string) {
    this.isBufferDistancesValid = value.length > 0;
    this.bufferDistances = value;
  }

  onOffFieldConsiderationsChanged(event: any) {
    this.selectedOnOffFieldConsiderations = event.value;
    this.isOnOffFieldConsiderationsValid = this.selectedOnOffFieldConsiderations?.length != 0;
    this.selectAllOnOffFieldConsiderations = this.selectedOnOffFieldConsiderations?.length == this.onOffFieldConsiderationsOptions.length;
  }

  onOffFieldConsiderationsSelectAllChanged(event: any) {
    this.selectAllOnOffFieldConsiderations= event.checked;
    this.selectedOnOffFieldConsiderations = this.selectAllOnOffFieldConsiderations ? this.onOffFieldConsiderationsOptions : [];
    this.isOnOffFieldConsiderationsValid = this.selectedOnOffFieldConsiderations?.length != 0;
  }

  adjustForPCTSelectionChanged({ value }: DropdownChangeEvent) {
    this.isAdjustForPCTValid = value != null;
    this.adjustForPCT = value;
  }

  setupStatesToInclude() {
    if (this.selectedRegionsToInclude?.some(x => x.short_value?.toLowerCase() == "conus")) {
      if (this.selectedStatesToInclude?.length == 0) {
        this.selectedStatesToInclude = this.statesToIncludeOptions;
        this.isStatesToIncludeValid = this.selectedStatesToInclude != null;
        this.isStatesToIncludeRequired = true;
        this.selectAllStates = true;
        this.statesToIncludeLabel = 'States to Include*';
      }
    }
    else {
      this.selectedStatesToInclude = [];
      this.isStatesToIncludeRequired = false;
      this.isStatesToIncludeValid = false;
      this.selectAllStates = false;
      this.statesToIncludeLabel = 'States to Include';
      this.statesToIncludePlaceholder = '';
    }
  }
}
