import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { HttpApiService } from './http.api.service';
import { environment } from 'src/environments/environment';
import { ModelLibrary } from '../models/model-library';


@Injectable({
  providedIn: 'root',
})

export class ModelLibraryApiService extends HttpApiService {
  override apiUrl: string = environment.BAMS_Api_URL;

  getModels(): Observable<ModelLibrary[]> {
    const endpoint = 'api/models';
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getModelsByProjectPk(projectPk: number):Observable<ModelLibrary[]> {;
    const endpoint = `api/models/project/${projectPk}`;
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }

  saveData(pData: any):Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = 'api/models';
    return this.put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }
}
