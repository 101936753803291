import { Injectable } from "@angular/core";
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
})

export class EraRunRiskAssessmentBeesColDef {

    public getColumnsDefinition() {
        return [
            {
                headerName: 'Active Ingredient',
                editable: false,
                pinned: 'left',
                field: 'activeIngredient',
                width: 160,
                minWidth: 160,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Endpoints Dataset',
                editable: false,
                pinned: 'left',
                field: 'endpointsDataset',
                width: 180,
                minWidth: 180,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Application Scheme',
                editable: false,
                pinned: 'left',
                field: 'applicationScheme',
                width: 220,
                minWidth: 220,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Application Rate (lbs a.i./acre)',
                editable: false,
                field: 'applicationRate',
                width: 135,
                minWidth: 135,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Units of App Rate',
                editable: false,
                field: 'unitsOfAppRate',
                width: 115,
                minWidth: 115,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Application method',
                editable: false,
                field: 'applicationMethod',
                width: 115,
                minWidth: 115,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Log Kow',
                editable: false,
                field: 'logKow',
                width: 95,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (!params.data.logKow) {
                        return { 'background-color': '#f0f0f0' };
                    }
                    return null;
                }
            },
            {
                headerName: 'Koc',
                editable: false,
                field: 'koc',
                width: 95,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (!params.data.koc) {
                        return { 'background-color': '#f0f0f0' };
                    }
                    return null;
                }
            },
            {
                headerName: 'Mass of tree vegetation (kg-wet weight)',
                editable: false,
                field: 'massTreeVegetation',
                width: 200,
                minWidth: 200,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (!params.data.massTreeVegetation) {
                        return { 'background-color': '#f0f0f0' };
                    }
                    return null;
                }
            },
            {
                headerName: 'Are empirical residue data available?',
                editable: false,
                field: 'areEmpiricalResidue',
                width: 155,
                minWidth: 155,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Empirical residue in pollen/bread (mg a.i./kg) ',
                editable: false,
                field: 'empiricalResidueInPollenBread',
                width: 175,
                minWidth: 175,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (!params.data.empiricalResidueInPollenBread) {
                        return { 'background-color': '#f0f0f0' };
                    }
                    return null;
                }
            },
            {
                headerName: 'Empirical residue in nectar (mg a.i./kg)',
                editable: false,
                field: 'empiricalResidueInNectar',
                width: 150,
                minWidth: 150,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (!params.data.empiricalResidueInNectar) {
                        return { 'background-color': '#f0f0f0' };
                    }
                    return null;
                }
            },
            {
                headerName: 'Empirical residue in jelly (mg a.i./kg)',
                editable: false,
                field: 'empiricalResidueInJelly',
                width: 150,
                minWidth: 150,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (!params.data.empiricalResidueInJelly) {
                        return { 'background-color': '#f0f0f0' };
                    }
                    return null;
                }
            },
            {
                headerName: 'Toxicity type',
                editable: false,
                field: 'toxicityType',
                width: 145,
                minWidth: 145,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Value',
                editable: false,
                field: 'toxicityValue',
                width: 90,
                minWidth: 90,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Exposure type',
                editable: false,
                field: 'exposureType',
                width: 155,
                minWidth: 155,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'RQ ',
                editable: false,
                field: 'rq',
                width: 80,
                minWidth: 80,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Listed / Non-listed',
                editable: false,
                field: 'listedNoListed',
                width: 120,
                minWidth: 120,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'LOC',
                editable: false,
                field: 'loc',
                width: 75,
                minWidth: 75,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Result',
                editable: false,
                pinned: 'right',
                field: 'result',
                width: 100,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (params.value === Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed) {
                        return { color: 'green' };
                    }
                    else {
                        return { color: 'red' };
                    }
                }
            },
        ]
    }

    public setStyle(params: any) {

    }
}