<div class="pt-1 pr-5 pb-5 pl-5">
    <div class="col-12 w-12">
        <app-wizard class="surface-200" [items]="tabsToDisplay" [message]="message" [loading]="loadingWizard" [activeItem]="activeItem" [isSetupValid]="true" (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
    </div>
    
    <div class="p-3 col-12 w-12" *ngIf="afterSearch">
        <ng-container *ngIf="activeItem.index== 0">
            <app-human-health-risk-assessment-substance-properties *ngIf="isOpex" [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="HHRAEndpointsLogicService" [menuService]="menuService" compartment="Substance Properties" [compartmentList]="compartmentList" [selectedProject]="selectedProject"></app-human-health-risk-assessment-substance-properties>
            <app-tox-expo-inputs *ngIf="isExpoTox"  [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="HHRAEndpointsLogicService" [menuService]="menuService" compartment="Substance Properties" [compartmentList]="compartmentList" [selectedProject]="selectedProject" ></app-tox-expo-inputs>
        </ng-container>
    </div>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>
