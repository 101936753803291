import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, lastValueFrom, map, take } from "rxjs";
import { ApplicationSchemeBees } from "src/app/shared/models/bees/bees";
import { BeesForInputs } from "src/app/shared/models/bees/bees-for-Inputs";
import { Project } from "src/app/shared/models/project";
import { HttpApiService } from "src/app/shared/services/http.api.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
 export class EraGapBeesBamsApiService extends HttpApiService {
    override apiUrl: string= environment.BAMS_Api_URL;
    private eraApiPrefix: string = 'api/gap/';

    SaveTransaction(dataTransaction: any): Observable<boolean> {
        const body = JSON.stringify(dataTransaction);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `${this.eraApiPrefix}SaveBeesApplicationScheme`;
        return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
      }

      getApplicationSchemesByProjectAndCompartment(pProjectPk: number, pCompartmentPk: number): Observable<ApplicationSchemeBees[]> {
        return this
          .get(`${this.eraApiPrefix}bees/applicationSchemesByProjectAndCompartment/${pProjectPk}/${pCompartmentPk}`)
          .pipe(map((data: any) => data.Value));
      }
   
      runBees(dataTransaction: BeesForInputs[]): Observable<any> {
        const body = JSON.stringify(dataTransaction);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `api/runBeeRex`;
        return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
      }

      DownloadBeesOutputFilesByProject(pProject: Project): Observable<string> {
        const body = JSON.stringify(pProject);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/downloadBeesOutputFilesByProject`;
        return this.put(query, body, headers)
                    .pipe(map((data: any) => data.Value));
      }

      save(pData: any): Observable<any> {
        const body = JSON.stringify(pData);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `api/dataSet`;
        return this
          .post(endpoint, body, headers).
          pipe(map((data: any) => data.Value));
      }
 } 
 