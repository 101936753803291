import { Component, DestroyRef, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DisciplineService } from 'src/app/administrator/filters/discipline/discipline.service';
import { CompoundPkAndName } from 'src/app/shared/models/compound';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { EndpointGroupedFieldValues, EndpointParameter, EndpointParameterCompound, EndpointParameterDetail, EndpointParameterFormationFraction, EndpointParameterMap } from 'src/app/shared/models/endpoint-parameter';
import { Project } from 'src/app/shared/models/project';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { UsersLogicService } from 'src/app/shared/services/users.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { EEAEndpointsByCompartmentLogicService } from '../../eea-endpoints-by-compartment/eea-endpoints-by-compartment.logic.service';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { MetaboliteApiService } from 'src/app/shared/services/echo/metabolite.api.service';
import { take } from 'rxjs';
import { EndpointsParameterConfiguration } from './eea-endpoints-parameters.config';
import { HHRAConstants } from 'src/app/human-health-risk-assessments/hhra-constants';
import { DatasetXMetabolites } from 'src/app/shared/models/echo/dataset_x_metabolites';

@Component({
  selector: 'app-eea-endpoints-parameters',
  templateUrl: './eea-endpoints-parameters.component.html',
  styleUrls: ['./eea-endpoints-parameters.component.css']
})
export class EeaEndpointsParametersComponent implements OnInit {
  @Input() endpointParameterCompound!: EndpointParameterCompound;
  @Input() selectedModels!: string[];
  @Input() compartment!: string;
  @Input() dataset!: DataSet;
  @Input() coreDefaultValues?: EndpointParameterDetail[];
  @Input() precursorOptions!: CompoundPkAndName[];
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;


  public parametersMap: EndpointParameterMap[];
  public endpointParameters: EndpointParameter[];
  public endpointsGroups: string[];
  public endpointsSubGroups: { group: string, subGroup: string }[];
  public fractionSoilRowData: EndpointParameterFormationFraction[];
  public fractionSurfaceWaterRowData: EndpointParameterFormationFraction[];
  public fractionSedimentRowData: EndpointParameterFormationFraction[];
  public isLoading: boolean;
  public excludeMetaboliteFromAI: boolean | undefined;

  disciplines: Discipline[] = [];
  saveStructure: any[] = [];
  mainDiscipline = Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE;
  dataToSave: any = [];
  updatedValues: any[] = [];
  saveRef?: string;
  destroyRef = inject(DestroyRef);
  totalparameters: number = 0;
  missingEndpoints?: EndpointGroupedFieldValues[];
  regexExponentialNumbers = Constants.REGEX_EXPONENTIAL_NUMBERS;
  pelmoLetterSchemeVisible:boolean = false;

  constructor(private readonly disciplineService: DisciplineService,
    private readonly dataSetService: DataSetApiService,
    private readonly usersService: UsersLogicService,
    private readonly endpointByCompartmentLogicService: EEAEndpointsByCompartmentLogicService,
    private readonly activeIngredientService: ActiveIngredientApiService,
    private readonly metaboliteService: MetaboliteApiService) {
    this.endpointParameters = [];
    this.endpointsGroups = [];
    this.parametersMap = [];
    this.endpointsSubGroups = [];
    this.fractionSedimentRowData = [];
    this.fractionSoilRowData = [];
    this.fractionSurfaceWaterRowData = [];
    this.isLoading = false;
    this.excludeMetaboliteFromAI = undefined;
  }

  ngOnInit(): void {
    this.initSubscribes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['endpointParameterCompound'] || changes['dataset']) {
      this.excludeMetaboliteFromAI = undefined;
      this.resetUiData();
      this.resetFormationFractionData();
      this.assingUiData();
      this.getSavedEndpoints();
      this.totalparameters = this.endpointParameters.length;
    }
    else if (changes['selectedModels']) {
      if (this.totalparameters == this.endpointParameters.length) { this.MapEndpointValue(); }
      this.assingUiData();
    }
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.prepareDataToSave(true);
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      this.prepareDataToSave(true);
    });
    this.endpointByCompartmentLogicService.excludeMetabolite$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.excludeMetaboliteFromAI = value;
      if(this.excludeMetaboliteFromAI !== undefined){
        this.prepareDataToSave(false, true);
      }
    });
  }

  private assingUiData(): void {
    if (this.endpointParameterCompound.compoundPk != undefined) {
      this.FillParametersMap();
      this.FillUiParameters();
      this.FillGroups();
      this.FillSubGroups();
    }
  }

  private FillParametersMap(): void {
    const models = EndpointsParameterConfiguration.MODELS_BY_COMPARTMENT_ENDPOINTS.filter(c => c.compartment == this.compartment)[0].models;
    models.forEach((model: any) => {
      const modelName: string = model.name;
      if (this.selectedModels.includes(modelName))
        model.endpoints.forEach((parameter: EndpointParameter) => {
          const previousValue = this.parametersMap.find(parameterMap => parameterMap.data.tableField == parameter.tableField)?.data.value;
          if ((parameter.dataValueName.toLocaleLowerCase() == Constants.DATA_VALUES_NAMES.airDiffusionCoefficient || parameter.dataValueName.toLocaleLowerCase() == Constants.DATA_VALUES_NAMES.foliarHalfLife)  && modelName == Constants.MODELS.PWC)
            parameter.configuration.defaultValue = undefined;
          this.parametersMap.push({
            model: modelName,
            data: {
              tableField: parameter.tableField,
              dataValueName: parameter.dataValueName,
              configuration: parameter.configuration,
              value: previousValue ? previousValue : parameter.configuration.defaultValue,
              modified: false,
              model: modelName
            }
          });
        });
    });
    this.parametersMap = this.setCoreDefaultValues(this.parametersMap);
  }

  private setCoreDefaultValues(endpointParametersMap: EndpointParameterMap[]): EndpointParameterMap[] {
    endpointParametersMap.forEach(parameterMap => {
      if (parameterMap.data.configuration.isCore) {
        const { compoundPk, compoundType } = this.endpointParameterCompound;
        const coreValue = this.coreDefaultValues!.find(defaultValues => defaultValues.dataValueName == parameterMap.data.dataValueName && defaultValues.type == compoundType && defaultValues.pk == compoundPk)?.value;
        if (parameterMap.data.value == undefined)
          parameterMap.data.value = coreValue;
      }
    });
    return endpointParametersMap;
  }

  private FillUiParameters(): void {
    const endpointParameters: EndpointParameter[] = [];
    this.parametersMap.forEach(parameterMap => {
      if (this.selectedModels.some(model => model === parameterMap.model))
        if (!endpointParameters.some(parameter => parameter.tableField === parameterMap.data.tableField)) {
          if (!(parameterMap.data.configuration.showOnlyForMetabolite && this.endpointParameterCompound.compoundType == Constants.SUBSTANCE_TYPES.ACTIVE))
            endpointParameters.push({
              configuration: parameterMap.data.configuration,
              dataValueName: parameterMap.data.dataValueName,
              tableField: parameterMap.data.tableField,
              value: parameterMap.data.configuration.controlType == Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER || parameterMap.data.configuration.controlType == Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER_INSERT ? this.setExponentialNumber(parameterMap.data.value) : parameterMap.data.value,
              modified: false,
              model: parameterMap.model
            });
        }
    });
    this.endpointParameters = this.filterEndpointParameters(endpointParameters);
    this.endpointParameters.sort((a, b) => a.configuration.order - b.configuration.order);
  }

  private MapEndpointValue(): void {
    this.parametersMap.forEach(parameterMap => {
      const parameter = this.endpointParameters.find(parameter => parameter.tableField == parameterMap.data.tableField);
      parameterMap.data.value = parameter?.value;
      parameterMap.data.modified = parameter ? parameter.modified : false;
    });
  }

  public onBlurControl(tableField: string): void {
    const parameters = this.endpointParameters;
    parameters.forEach(parameter => {
      if (parameter.tableField == tableField) {
        if (parameter.configuration.range) {
          parameter.value = Utils.CheckValueInRanges(parameter.configuration.range, parameter.value);
          this.endpointParameters.find(parameter => parameter.tableField === tableField) == parameter;
        }

        this.getDT90Value(parameter, parameters);
        this.getK1Value(parameter, parameters);
        parameter.modified = true;

        if (parameter.configuration.controlType == Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER_INSERT)
          parameter.value = this.regexExponentialNumbers.test(parameter.value) ? this.setExponentialNumber(parameter.value) : '';

        if (parameter.configuration.controlType == Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER)
          parameter.value = this.setExponentialNumber(parameter.value);
      }
    });
    this.endpointParameters = parameters;
    this.MapEndpointValue();
    this.prepareDataToSave(false);
  }

  public onDDLChange(tableField:string):void{
    this.endpointParameters.forEach(parameter => {
      if (parameter.tableField == tableField)
        this.setDisabledValuesByKineticModel(parameter);
    })
  }

  setDisabledValuesByKineticModel(parameter: EndpointParameter)
  {
    if (parameter.dataValueName == Constants.DATA_VALUES_NAMES.kineticModel)
    {
      this.disableSFONotApplicableParameters(parameter)
      this.disableDFOPNotApplicableParameters(parameter)
      this.disableHSNotApplicableParameters(parameter)
      this.disableFOMCNotApplicableParameters(parameter)
    }
  }

  disableSFONotApplicableParameters(parameter: EndpointParameter)
  {
    if (parameter.value == Constants.KINETIC_MODELS.SFO)
      {
        this.disableField(Constants.DATA_VALUES_NAMES.k2);
        this.disableField(Constants.DATA_VALUES_NAMES.alpha)
        this.disableField(Constants.DATA_VALUES_NAMES.beta);
        this.disableField(Constants.DATA_VALUES_NAMES.tb);
        this.disableField(Constants.DATA_VALUES_NAMES.g);
      }
  }

  disableDFOPNotApplicableParameters(parameter: EndpointParameter)
  {
    if (parameter.value == Constants.KINETIC_MODELS.DFOP)
      {
        this.enableField(Constants.DATA_VALUES_NAMES.k2);
        this.disableField(Constants.DATA_VALUES_NAMES.alpha)
        this.disableField(Constants.DATA_VALUES_NAMES.beta);
        this.disableField(Constants.DATA_VALUES_NAMES.tb);
        this.enableField(Constants.DATA_VALUES_NAMES.g);
      }
  }

  disableHSNotApplicableParameters(parameter: EndpointParameter)
  {
    if (parameter.value == Constants.KINETIC_MODELS.HS)
      {
        this.enableField(Constants.DATA_VALUES_NAMES.k2);
        this.disableField(Constants.DATA_VALUES_NAMES.alpha)
        this.disableField(Constants.DATA_VALUES_NAMES.beta);
        this.enableField(Constants.DATA_VALUES_NAMES.tb);
        this.disableField(Constants.DATA_VALUES_NAMES.g);
      }
  }

  disableFOMCNotApplicableParameters(parameter: EndpointParameter)
  {
    if (parameter.value == Constants.KINETIC_MODELS.FOMC)
      {
        this.disableField(Constants.DATA_VALUES_NAMES.k1);
        this.disableField(Constants.DATA_VALUES_NAMES.k2);
        this.enableField(Constants.DATA_VALUES_NAMES.alpha)
        this.enableField(Constants.DATA_VALUES_NAMES.beta);
        this.disableField(Constants.DATA_VALUES_NAMES.tb);
        this.disableField(Constants.DATA_VALUES_NAMES.g);
      }
  }

  disableField(field:string)
  {
    var fieldToDisable = this.endpointParameters.find(x => x.dataValueName == field);
    if (fieldToDisable)
    {
      fieldToDisable.value = undefined;
      fieldToDisable.configuration.disabled = true;
    }
  }

  enableField(field:string)
  {
    var fieldToDisable = this.endpointParameters.find(x => x.dataValueName == field);
    if (fieldToDisable)
    {
      fieldToDisable.configuration.disabled = false;
    }
  }

  public onKeyUp(tableField: string, value: number | string | undefined): void {
    value = Number(value);
    if (!isNaN(value)) {
      const parameter = this.endpointParameters.find(parameter => parameter.tableField === tableField)!;
      const numAsString = value.toString();
      const parts = numAsString.split('.');
      const qtyDecimals = parameter ? parameter.configuration.qtyDecimals ?? 0 : 0;

      if (parts.length === 2 && parts[1].length > qtyDecimals) {
        parameter.value = value.toFixed(qtyDecimals);
        this.endpointParameters.find(parameter => parameter.tableField === tableField) == parameter;
      }

    }
  }

  private FillGroups(): void {
    const groups: string[] = [];
    this.endpointParameters.forEach(parameter => {
      if (!groups.some(group => group == parameter.configuration.group))
        groups.push(parameter.configuration.group);
    });
    this.endpointsGroups = groups;
  }

  private FillSubGroups(): void {
    const subGroups: { group: string, subGroup: string }[] = [];
    this.endpointParameters.forEach(parameter => {
      if (!subGroups.some(subGroup => subGroup.group == parameter.configuration.group && subGroup.subGroup == parameter.configuration.subGroup))
        subGroups.push({ group: parameter.configuration.group, subGroup: parameter.configuration.subGroup });
    });
    this.endpointsSubGroups = subGroups;
  }

  private setExponentialNumber(pValue: number | undefined | string): string | number {
    if (!pValue || Number(pValue))
      return Utils.convertNumberToExponential(pValue);
    else
      return pValue;
  }

  private findMissingDefaultEndpoints(savedList: any[], defaultList: EndpointGroupedFieldValues[]): EndpointGroupedFieldValues[] {
    const missingEndpoints: EndpointGroupedFieldValues[] = [];
    for (const defaultItem of defaultList) {
      const foundItem = savedList.find(item => item.key === defaultItem.key);
      if (!foundItem) {
        missingEndpoints.push(defaultItem);
      }
    }
    return missingEndpoints;
  }
  private async getSavedEndpoints(): Promise<void> {
    this.dataToSave.length = 0;
    this.saveStructure.length = 0;
    this.isLoading = true;
    this.dataSetService.getCoreEndpointsByDataSets([this.dataset]).subscribe(async (data: any) => {
      const { compoundType, compoundPk } = this.endpointParameterCompound;
      let metabolitePk: number | undefined;
      if (compoundType == Constants.SUBSTANCE_TYPES.METABOLITE)
        metabolitePk = compoundPk;
      data[0].endpoints = data[0].endpoints.filter((d: any) => d.endpointTypePk == this.compartment && d.substanceType == compoundType && d.metabolitePk == metabolitePk);
      let savedEndpoint = data[0].endpoints[0]?.groupedEndpointFieldValues;
      this.updateMetaboliteExclusionValue(data[0].endpoints[0]?.datasetXMetabolites, metabolitePk);
      if (savedEndpoint == null) {
        this.missingEndpoints = this.createGroupedDefaultValues();
        if (this.dataset.source == HHRAConstants.HUMAN_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT) {
          this.missingEndpoints = await this.fillHHRADefaultValues();
        }
      }
      else {
        this.missingEndpoints = this.findMissingDefaultEndpoints(data[0].endpoints[0].groupedEndpointFieldValues, this.createGroupedDefaultValues());
      }
      if (data[0].endpoints.length == 0 || this.missingEndpoints.length > 0) {
        this.saveFirstEndpoint();
      }
      else {
        this.disciplineService.getDisciplineInformation().subscribe((discipline: any) => {
          this.disciplines = discipline;
          const endpointsList: Endpoint[] = [];
          if (data[0]) {
            endpointsList.push(...data[0].endpoints);
          }
          this.fillSaveStructure(endpointsList);
          this.isLoading = false;
        });
      }
    });
  }

  private getSavedFormationFraction(): void {
    if(this.saveStructure[0]?.precursors)
    this.saveStructure[0].precursors.forEach((parameter: EndpointParameterFormationFraction) => {
      parameter.compoundPk = parameter.metabolitePk ? parameter.metabolitePk : parameter.moleculePk;
      switch (parameter.precursorType) {
        case Constants.PRECURSOR_TYPES.SOIL:
          this.fractionSoilRowData.push(parameter);
          break;
        case Constants.PRECURSOR_TYPES.SURFACE_WATER:
          this.fractionSurfaceWaterRowData.push(parameter);
          break;
        case Constants.PRECURSOR_TYPES.SEDIMENT:
          this.fractionSedimentRowData.push(parameter);
          break;
      }
    });
  }

  private updateMetaboliteExclusionValue(datasetXMetabolites: DatasetXMetabolites, metabolitePK: number | undefined): void {
    if (metabolitePK && datasetXMetabolites) {
      if(datasetXMetabolites.excludeMetabolite && datasetXMetabolites.metabolitePk == metabolitePK){
        this.excludeMetaboliteFromAI = datasetXMetabolites.excludeMetabolite;
        this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(this.excludeMetaboliteFromAI);
      } else {
        this.excludeMetaboliteFromAI = false;
        this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(this.excludeMetaboliteFromAI);
      }
    } else {
      this.excludeMetaboliteFromAI = false;
      this.endpointByCompartmentLogicService.excludeMetaboliteFromAI(this.excludeMetaboliteFromAI);
    }
  }

  private createGroupedDefaultValues(): EndpointGroupedFieldValues[] {
    const groupedFieldValues: EndpointGroupedFieldValues[] = [];
    this.endpointParameters.forEach(parameter => {
      const { value, dataValueName, configuration } = parameter;
      const { controlType } = configuration;
      switch (controlType) {
        case Constants.CONTROL_TYPES.RADIO_BUTTON:
          groupedFieldValues.push(this.setRadioButtonValueGrouped(value, dataValueName));
          break;
        case Constants.CONTROL_TYPES.TEXT:
          const textValue: string | undefined = value;
          if (textValue != undefined)
            groupedFieldValues.push({ endpointPk: -1, key: dataValueName, value: undefined, textValue: textValue });
          break;
        default:
          if (value != undefined)
            groupedFieldValues.push({ endpointPk: -1, key: dataValueName, value: value });
          break;
      }
    });
    return groupedFieldValues;
  }

  private async fillHHRADefaultValues(): Promise<EndpointGroupedFieldValues[]> {
    let molecularWeight = 0;
    if (this.endpointParameterCompound.compoundType == Constants.SUBSTANCE_TYPES.ACTIVE) {
      molecularWeight = await this.getActiveIngredientData();
    } else if (this.endpointParameterCompound.compoundType == Constants.SUBSTANCE_TYPES.METABOLITE) {
      molecularWeight = await this.getMetaboliteData();
    }
    const groupedFieldValues: EndpointGroupedFieldValues[]=[];
    const molecularWeightField = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.molecularWeight, value: molecularWeight };
    const foliarDT50Field = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.foliarHalfLife, value: 30 };
    const dislodgableFoliarResidueField = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.initialDislodgableFoliarResidue, value: 3 };
    const inhalationAbsorptionField = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.inhalationAbsorptionActiveSubstance, value: 100 };
    const oralAbsorptionField = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.oralAbsorptionActiveSubstance, value: 100 };
    groupedFieldValues.push(molecularWeightField,foliarDT50Field,dislodgableFoliarResidueField,inhalationAbsorptionField,oralAbsorptionField);
    this.validateDermalAbsorptionField(groupedFieldValues);

    return groupedFieldValues;
  }
  validateDermalAbsorptionField(groupedFieldValues:EndpointGroupedFieldValues[])
  {
    let valueConcentrate = 0;
    let valueDilution = 0;
    if(Constants.FORMULATION_TYPES.ORGANIC_BASED.includes(this.selectedProject?.formulationType?.substring(0,2).trim()!)){
      valueConcentrate = Constants.FORMULATION_TYPES.ORGANIC_BASED_VALUES.CONCENTRATE;
      valueDilution = Constants.FORMULATION_TYPES.ORGANIC_BASED_VALUES.DILUTION;
    };
    if(Constants.FORMULATION_TYPES.WATER_BASED.includes(this.selectedProject?.formulationType?.substring(0,2).trim()!)){
      valueConcentrate = Constants.FORMULATION_TYPES.WATER_BASED_VALUES.CONCENTRATE;
      valueDilution = Constants.FORMULATION_TYPES.WATER_BASED_VALUES.DILUTION;
    };
    if(Constants.FORMULATION_TYPES.SOLID.includes(this.selectedProject?.formulationType?.substring(0,2).trim()!)){
      valueConcentrate = Constants.FORMULATION_TYPES.SOLID_VALUES.CONCENTRATE;
      valueDilution = Constants.FORMULATION_TYPES.SOLID_VALUES.DILUTION;
    };
    const dilution = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.dermalAbsorptionInUseDilution, value: valueDilution };
    const concentrate = { endpointPk: -1, key: Constants.DATA_VALUES_NAMES.dermalAbsorptionProduct, value: valueConcentrate };
    groupedFieldValues.push(dilution,concentrate);
  }

  private async getActiveIngredientData(): Promise<number> {
    try {
      const result: any = await this.activeIngredientService.getMoleculeByMoleculePks([this.endpointParameterCompound.compoundPk]).pipe(take(1)).toPromise();
      return result[0].molecularWeight || 0;
    } catch (err) {
      return 0;
    }
  }

  private async getMetaboliteData(): Promise<number> {
    try {
      const result: any = await this.metaboliteService.getMetaboliteWeightByPk(this.endpointParameterCompound.compoundPk).pipe(take(1)).toPromise();
      return result.molecularWeight || 0;
    } catch (err) {
      return 0;
    }
  }

  private setRadioButtonValueGrouped(value: any, dataValueName: string): EndpointGroupedFieldValues {
    let textValue: string | undefined;
    const radioButton = value;
    textValue = radioButton!.value;
    return { endpointPk: -1, key: dataValueName, value: undefined, textValue: textValue };
  }

  private saveFirstEndpoint(): void {
    let itemToSave: any = {};
    itemToSave.endpointTypePk = this.compartment;
    itemToSave.substanceType = this.endpointParameterCompound.compoundType;
    itemToSave.activeIngredientName = this.dataset.activeIngredient;
    itemToSave.dataSetPk = this.dataset.dataSetPk;
    itemToSave.moleculePk = this.dataset.moleculePk;
    itemToSave.substanceName = this.endpointParameterCompound.compoundName;
    itemToSave.groupedEndpointFieldValues = this.missingEndpoints;

    if (itemToSave.substanceType == Constants.SUBSTANCE_TYPES.METABOLITE)
      itemToSave.metabolitePk = this.endpointParameterCompound.compoundPk;

    let saveStructure: any = [];
    saveStructure.push(itemToSave);

    saveStructure = saveStructure.map((data: any) => {
      return {
        transactionType: 2,
        row: data
      }
    });

    this.addAuthor();
    this.addLastModifiedDate();

    this.save(saveStructure, true);
  }

  private fillSaveStructure(pData: any): void {
    this.saveStructure = pData;
    this.filterDataSetByDicipline();
    this.saveStructure = this.processGroupedEndpoints();
    this.assingParametersValues();
    this.getSavedFormationFraction();
  }

  private filterDataSetByDicipline(): void {
    this.saveStructure = this.saveStructure.filter((x: any) =>
      this.saveStructure.filter((x: any) => x.disciplinePk === this.disciplines.filter(x => x.name == this.mainDiscipline).map(x => x.disciplinePk)[0])
    );
  }

  private processGroupedEndpoints() {
    this.saveStructure.forEach((row: any) => {
      if (row.groupedEndpointFieldValues.length > 0)
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpoint) => {
          if (row.groupedEndpointFieldValues.find((x: any) => x.key.toLowerCase() == endpoint.name))
            row[endpoint.field] = row.groupedEndpointFieldValues.filter(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )[0].value;
        });
    });
    return this.saveStructure;
  }

  private assingParametersValues() {
    if (this.saveStructure[0].groupedEndpointFieldValues) {
      const groupedFieldValues: EndpointGroupedFieldValues[] = this.saveStructure[0].groupedEndpointFieldValues;

      this.endpointParameters.forEach(parameter => {
        const valueToAssign = groupedFieldValues.find(field => field.key == parameter.dataValueName)?.value;
        const textValueToAssing = groupedFieldValues.find(field => field.key == parameter.dataValueName)?.textValue;

        if (parameter.configuration.controlType == Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER || parameter.configuration.controlType == Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER_INSERT) {
          if (valueToAssign != undefined)
            parameter.value = this.setExponentialNumber(valueToAssign);
          else
            parameter.value = undefined;
        }
        else
          parameter.value = valueToAssign;


        if (parameter.configuration.controlType == Constants.CONTROL_TYPES.RADIO_BUTTON) {
          if (textValueToAssing)
            parameter.value = Constants.CANOPY_PROCESS_OPTIONS.find(c => c.value == textValueToAssing);
          else {
            parameter.value = Constants.CANOPY_PROCESS_OPTIONS[0];
            parameter.modified = true;
          }
        }

        if (parameter.configuration.controlType == Constants.CONTROL_TYPES.TEXT || parameter.configuration.controlType == Constants.CONTROL_TYPES.DROPDOWN) {
          if (textValueToAssing)
            parameter.value = textValueToAssing;
        }
        this.setDisabledValuesByKineticModel(parameter);
      });
    }
  }

  private fillSaveObject(): void {
    this.dataToSave = this.saveStructure;
    this.setGroupedModifiedFieldValues();
  }

  private prepareDataToSave(isTabChanged: boolean, validateMetabolite: boolean = false) {
    if (this.saveStructure.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    this.fillSaveObject();
    this.getModifiedFormationFraction();
    if(validateMetabolite){
      this.validateMetaboliteExclusion();
    }
    this.dataToSave = this.dataToSave.map((data: any) => {
      return {
        transactionType: 1,
        row: data
      }
    });

    this.addAuthor();
    this.addLastModifiedDate();
    this.updateDataToSave();
    if (isTabChanged) {
      this.isLoading = true;
      this.save(this.dataToSave);
    }
  }

  private validateMetaboliteExclusion() {
    if (this.saveStructure?.length > 0){
      this.dataToSave[0].datasetXMetabolites = { datasetPk: this.dataset.dataSetPk, metabolitePk: this.endpointParameterCompound.compoundPk, excludeMetabolite: this.excludeMetaboliteFromAI, childLevel: null};
    }
  }

  private setGroupedModifiedFieldValues(): void {
    this.endpointParameters.forEach(parameter => {
      let { value, dataValueName, modified, configuration } = parameter;
      const endpoint = this.saveStructure[0].groupedEndpointFieldValues.find((s: EndpointGroupedFieldValues) => s.key == dataValueName);
      const endpointPk: number = endpoint ? endpoint.endpointPk : -1;
      let textValue: string | undefined;

      if (configuration.controlType == Constants.CONTROL_TYPES.RADIO_BUTTON) {
        const radioButton = value as any;
        value = undefined;
        textValue = radioButton!.value;
      }

      if (configuration.controlType == Constants.CONTROL_TYPES.TEXT || configuration.controlType == Constants.CONTROL_TYPES.DROPDOWN) {
        textValue = value;
        value = undefined;
      }

      if (modified) {
        const groupedEndpointFieldValues = this.dataToSave[0].groupedEndpointFieldValues.find((g: EndpointGroupedFieldValues) => g.key == dataValueName);
        if (groupedEndpointFieldValues) {
          groupedEndpointFieldValues!.value = value;
          groupedEndpointFieldValues!.textValue = textValue;
        }
        else
          this.dataToSave[0].groupedEndpointFieldValues.push({ endpointPk: endpointPk, key: dataValueName, value: value, textValue: textValue });
      }
    });
  }

  private addAuthor(): void {
    this.dataToSave.forEach((transaction: any) => {
      if (transaction['row'].status === Constants.CONST_APPROVED)
        transaction['row'].reviewedBy = this.usersService.getProfile();
      else
        transaction['row'].modifiedBy = this.usersService.getActiveUserID();
      transaction['row'].dataSource = Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE;
    });
  }


  private addLastModifiedDate(): void {
    this.dataToSave.forEach((transaction: any) => {
      transaction['row'].lastModifiedDate = Utils.getDateFormatToSaveData();
    });
  }

  private save(data: any, firstSave: boolean = false): void {
    this.endpointByCompartmentLogicService.saveData(data).then(success => {
      if (success) {
        this.menuService.setSuccess(true);
        this.subTabMenuService.setSuccess(true);
        if (firstSave)
          this.getSavedEndpoints();
        this.resetFormationFractionData();
      }
      else {
        this.menuService.setSuccess(false);
        this.subTabMenuService.setSuccess(false);
      }
    });
  }

  private getModifiedFormationFraction(): void {
    if(this.dataToSave[0].precursors)
      this.dataToSave[0].precursors.length = 0;
    this.assingFormationFractioToDataToSave(this.fractionSoilRowData);
    this.assingFormationFractioToDataToSave(this.fractionSedimentRowData);
    this.assingFormationFractioToDataToSave(this.fractionSurfaceWaterRowData);
  }

  private assingFormationFractioToDataToSave(formationFractionToUpdate: EndpointParameterFormationFraction[]): void {
    formationFractionToUpdate.forEach(precursor => {
      this.dataToSave[0].precursors.push(precursor);
    });
  }

  public onFormationFractionRowModified(precursors: EndpointParameterFormationFraction[]): void {
    const precursorType = precursors[0].precursorType;

    if (precursors[0].emptyRow)
      precursors.length = 0;

    switch (precursorType) {
      case Constants.PRECURSOR_TYPES.SOIL:
        this.fractionSoilRowData = precursors;
        break;
      case Constants.PRECURSOR_TYPES.SURFACE_WATER:
        this.fractionSurfaceWaterRowData = precursors;
        break;
      case Constants.PRECURSOR_TYPES.SEDIMENT:
        this.fractionSedimentRowData = precursors;
        break;
    }

    this.prepareDataToSave(false);
  }

  private updateDataToSave(): void {
    this.endpointByCompartmentLogicService.updateDataToSave(this.dataToSave);
  }

  private resetUiData(): void {
    this.parametersMap.length = 0;
    this.endpointParameters.length = 0;
  }

  private resetFormationFractionData(): void {
    this.fractionSoilRowData.length = 0;
    this.fractionSedimentRowData.length = 0;
    this.fractionSurfaceWaterRowData.length = 0;
  }

  showFormationFractions(): boolean {
    return this.endpointParameterCompound.compoundType == Constants.SUBSTANCE_TYPES.METABOLITE &&
      this.endpointParameterCompound.compoundPk !== this.dataset.compoundPk  &&
      (JSON.stringify(this.selectedModels) !== JSON.stringify(new Array(Constants.MODELS.STEP_1_2))) &&
      !this.isPwcModelSelected() &&
      (this.selectedModels.length > 0) &&
      (this.selectedProject?.geography !== Constants.CROP_GEOGRAPHIES.UK);
  }

  private getDT90Value(parameter: EndpointParameter, parameters: EndpointParameter[]): void {
    if (parameter.dataValueName == Constants.DATA_VALUES_NAMES.k1) {
      const dt90 = parameters.find(parameter => parameter.dataValueName == Constants.DATA_VALUES_NAMES.dt90);
      if (dt90) {
        dt90.value = Utils.getDegT90Value(parameter.value);
        dt90.modified = true;
      }
    }
  }

  private getK1Value(parameter: EndpointParameter, parameters: EndpointParameter[]): void {
    if (parameter.dataValueName == Constants.DATA_VALUES_NAMES.halfLifeInSoil) {
      const k1 = parameters.find(parameter => parameter.dataValueName == Constants.DATA_VALUES_NAMES.k1);
      if (k1) {
        k1.value = Utils.getk1Value(Number(parameter.value));
        k1.modified = true;
        this.getDT90Value(k1, parameters);
      }
    }
  }

  private isPwcModelSelected(): boolean {
    let isPwcModelSelected: boolean = false;
    this.selectedModels.forEach(model => {
      if (model == Constants.MODELS.PWC)
        isPwcModelSelected = true;
    });
    return isPwcModelSelected;
  }

  isDisable(endpointData: EndpointParameter) {
    let disabled: boolean = endpointData.configuration.disabled;

    if (endpointData.configuration.usedInModels) {
      endpointData.configuration.usedInModels.forEach((model: string) => {
        if (this.selectedModels.includes(model)) {
          disabled = false;
        }
      });
    }

    return disabled;
  }

  showInfo() {
    return !this.isLoading && (this.excludeMetaboliteFromAI === undefined || this.excludeMetaboliteFromAI === false);
  }

  filterEndpointParameters(endpointParameter:EndpointParameter[]): EndpointParameter[]{
    let validation = this.endpointParameterCompound.compoundType == Constants.SUBSTANCE_TYPES.METABOLITE &&
    this.endpointParameterCompound.compoundPk === this.dataset.compoundPk;
    return validation ? endpointParameter.filter(x => x.tableField != Constants.FIELD_NAMES.PELMO_LETTER) : endpointParameter;
  }
  openPelmoLetterSchemeDialog() {
    this.pelmoLetterSchemeVisible = true;
  }
  closePelmoLetterSchemeDialog() {
    this.pelmoLetterSchemeVisible = false;

  }
}
