import { Component, inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { EeaGapSurfacewaterPwcScenariosLogicService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap-surfacewater-pwc/eea-gap-surfacewater-pwc-scenarios/eea-gap-surfacewater-pwc-scenarios.logic.service';

@Component({
  selector: 'app-view-button',
  templateUrl: './view-pwc-scenarios-button.component.html',
  styleUrls: ['./view-pwc-scenarios-button.component.css']
})
export class ViewPwcScenariosButtonRenderer implements ICellRendererAngularComp {

  private logicService = inject(EeaGapSurfacewaterPwcScenariosLogicService);

  public disabled:  boolean = false;
  public params:    any;
  public parent:    any;
  public scenarios: string[] =[];

  agInit(params: any): void {
    this.params = params;
    this.disabled = this.params.disabled;
    this.parent = params.context ? params.context : params.instance;
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  public onClick(): void {
    this.parent.onClickButton({
      rowNode: this.params.node,
      data : this.params.data,
      field: this.params.field
    });
  }

  public onMouseEnter(): void {
    if( !this.params ) return;

    const selectedScenarios: number[] = this.params.data?.cropXRegionPks;

    if ( selectedScenarios )
      this.scenarios = this.logicService.cropXRegion.filter(CxR => selectedScenarios.includes(CxR.crop_x_region_pk)).map(CxR => CxR.fullName);
  }
}
