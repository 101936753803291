import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomTabMenuItem } from '../models/custom-tab-menu-item';

@Injectable({
  providedIn: 'root'
})
export abstract class TabMenuLogicService {

  constructor() { }

  abstract items: CustomTabMenuItem[];

  public saveRef: string = '';
  public nextIndex?: number;
  abstract activeItemSubject: BehaviorSubject<CustomTabMenuItem>;
  abstract readonly activeItem: Observable<CustomTabMenuItem>;

  abstract activeIndexChangedSubject: BehaviorSubject<boolean>;
  abstract readonly activeIndexChanged: Observable<boolean>;

  abstract successSubject: BehaviorSubject<boolean>;
  abstract readonly success: Observable<boolean>;

  setActiveItem(activeItem: CustomTabMenuItem)
  {
    this.activeItemSubject.next(activeItem);
  }

  resetActiveItem()
  {
    this.activeItemSubject.next({} as CustomTabMenuItem);
  }

  setActiveIndexChanged(changed: boolean, nextIndex?: number): boolean {
    if(nextIndex == this.activeItemSubject.value?.index) return false;

    this.nextIndex = nextIndex;
    this.activeIndexChangedSubject.next(changed);
    return true;
  }

  setSuccess(success: boolean): void {
    this.successSubject.next(success);
  }
}
