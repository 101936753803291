import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { List } from '../models/list';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListManagerApiService {
  constructor(private http: HttpClient) {}

  getList(pListLevel1: string, pListLevel2: string, pListLevel3: string, pMappingValueText: string, pTags: string[]): Observable<List[]> {
    const body = JSON.stringify(pTags);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ListManagerCoreApiURL}api/ListManager/GetFlatList/${pListLevel1}/${pListLevel2}/${pListLevel3}/${pMappingValueText}`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data));
  }

  getGrowthStageMatchByCrop(pListLevel1: string, pListLevel2: string, pListLevel3: string, pMappingValueText: string, pCrop: string, pTags: string[]): Observable<List[]> {
    const body = JSON.stringify(pTags);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ListManagerCoreApiURL}api/ListManager/GetGrowthStageMatchByCrop/${pListLevel1}/${pListLevel2}/${pListLevel3}/${pMappingValueText}/${pCrop}`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data));
  }
}
