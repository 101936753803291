<div class="content" style="margin-left:36px; margin-top: 12px; width:1500px;">

    <app-discipline [componentThatImplementsGrid]='applicationMethodMatchingComponent'></app-discipline>

    <div class="grid-container" *ngIf="afterSearch" [ngStyle]="{'display': afterSearch == true? 'block': 'none' }" style="margin-bottom: -11px;">
        <app-application-method-matching-grid [parentComponent]='applicationMethodMatchingComponent'></app-application-method-matching-grid>
    </div>

    <app-loading *ngIf="loading"></app-loading>

    <div kendoWindowContainer></div>
</div>