<div class="pt-1 pr-5 pb-5 pl-5">
    <div class="col-12 w-12">
        <app-wizard class="surface-200" [items]="tabsToDisplay" [message]="message" [loading]="wizardLoading" [activeItem]="activeItem"  [isSetupValid]="true" (activeIndexChangeEmitter)="onActiveIndexChange($event)" [isSetupValid]="isApplicationSchemeValid"></app-wizard>
    </div>

    <div class="col-12 w-12" >
        <ng-container *ngIf="activeItem.index == 0">
            <app-era-gap-terrestrial [isProjectOwnershipValid]="isProjectOwnershipValid" [subTabMenuService]="ERAGapLogicService" [menuService]="menuService" [selectedProject]="selectedProject" (isValid)="getIsApplicationSchemeValid($event)"></app-era-gap-terrestrial>
        </ng-container>
    </div>
    <div class="col-12 w-12" >
        <ng-container *ngIf="activeItem.index == 1">
           <app-era-gap-bees [isProjectOwnershipValid]="isProjectOwnershipValid" [subTabMenuService]="ERAGapLogicService" [menuService]="menuService" [selectedProject]="selectedProject" (isValid)="getIsApplicationSchemeValid($event)"></app-era-gap-bees>
        </ng-container>
    </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>