<p-menubar>
    <ng-template pTemplate="start">
        <a [routerLink]="['/','Home']">
            <img src="assets/img/LOGO_BaMS.png" alt="homepage" style="width: 15%;">
        </a>
    </ng-template>
    <ng-template pTemplate="end">
        <div class="mb-2" style="display: flex;">
            <div class="userLoged">
                <span class="head">
                    Welcome!
                </span>
                <br />
                <span>
                    {{userService.profile.displayName}}
                </span>
                <br />
                <div style="display: inline-flex;">
                    <p class="roleLabel">ROLE:</p>
                    <p-dropdown [options]="userService.userRoles" [(ngModel)]="userService.selectedRole" optionLabel="role" [showClear]="false" [style]="{'height': '25px','align-items': 'center','margin-top': '2px','font-size': '14px'}" (onChange)="userService.roleSelectionChange($event)"></p-dropdown>
                </div>
            </div>
            <div style="margin: 5px 0px 5px 5px;">
                <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle" [style]="{'background-color':'#0069be', 'color': '#ffffff', 'margin-top': '5px'}"></p-avatar>
            </div>
        </div>
    </ng-template>
</p-menubar>