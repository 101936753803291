import { EraEcotoxImportPopupApiService } from './era-ecotox-import-popup.api.service';
import { Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { EcotoxEndpointFlatValues } from './era-ecotox-import-popup.interface';

@Injectable({
  providedIn: 'root'
})
export class EraEcotoxImportPopupLogicService {

  constructor(private readonly apiService: EraEcotoxImportPopupApiService) { }

  public async getEcotoxEndpointFlatValues(datasetToImportPk: number, geography: string): Promise<any> {
    const source$ = this.apiService.getEcotoxEndpointFlatValues(datasetToImportPk, geography).pipe(take(1));
    return await lastValueFrom(source$);
  }

}
