import { Injectable } from "@angular/core";
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
  })
  
  export class EraRunRiskAssessmentTerrestrialColDef { 

    public getColumnsDefinition(){
        return [
            {
                headerName: 'Active Ingredient',
                editable: false,
                pinned: 'left',
                field: 'activeIngredient',
                width: 160,
                minWidth: 160,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Endpoints Dataset',
                editable: false,
                pinned: 'left',
                field: 'endpointsDataset',
                width: 180,
                minWidth: 180,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },             
            {
                headerName: 'Application Scheme',
                editable: false,
                pinned: 'left',
                field: 'applicationScheme',
                width: 220,
                minWidth: 220,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Use',
                editable: false,
                pinned: 'left',
                field: 'use',
                width: 220,
                minWidth: 220,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Number of Applications',
                editable: false,                
                field: 'numberApplication',
                width: 120,
                minWidth: 120,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Application Interval',
                editable: false,
                field: 'applicationInterval',
                width: 115,
                minWidth: 115,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Application Rate (lbs a.i./acre)',
                editable: false,
                field: 'applicationRate',
                width: 135,
                minWidth: 135,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Half-life (d)',
                editable: false,                
                field: 'halflife',
                width: 95,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Sub-taxon',
                editable: false,                
                field: 'subTaxon',
                width: 200,
                minWidth: 200,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Avian or Mammalian Class',
                editable: false,                
                field: 'avianOrMammalianClass',
                width: 160,
                minWidth: 160,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Body Weight',
                editable: false,                
                field: 'bodyWeight',
                width: 95,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Endpoint ',
                editable: false,
                field: 'endpoint',
                width: 130,
                minWidth: 130,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Endpoint value',
                editable: false,
                field: 'endpointValue',
                width: 95,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Unit',
                editable: false,                
                field: 'unit',
                width: 110,
                minWidth: 110,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Dietary item',
                editable: false,                
                field: 'dietaryItem',
                width: 140,
                minWidth: 140,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Dose Based EEC (mg/kg bw)/ Dietary EEC (mg/kg diet)',
                editable: false,
                field: 'eecValue',
                width: 190,
                minWidth: 190,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'RQ',
                editable: false,
                field: 'rq',
                width: 95,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
              },
            {
                headerName: 'Listed / Non-listed',
                editable: false,
                field: 'listedNoListed',
                width: 120,
                minWidth: 120,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'LOC',
                editable: false,                
                field: 'loc',
                width: 75,
                minWidth: 75,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
            },
            {
                headerName: 'Result',
                editable: false,
                pinned: 'right',
                field: 'result',
                width: 100,
                minWidth: 95,
                suppressSizeToFit: true,
                filter: 'agSetColumnFilter',
                type: 'default',
                cellStyle: (params: any) => {
                    if (params.value === Constants.RUN_RISK_ASSESSMENT_RESULT_VALUE.Passed) {
                        return {color: 'green'};
                    }
                    else {
                      return {color: 'red'};
                    }
                  }
            },               
        ]
    }
  }