<app-selected-project [module]="module"></app-selected-project>
<div class="isProjectOwnershipValid">
    <p [hidden]="isProjectOwnershipValid">You cannot modify or run someone's project</p>
</div>
<div class="pt-1 pr-5 pb-5 pl-5">
    <app-wizard class="surface-200" [items]="ERAService.items" [message]="message" [loading]="loading" 
        [activeItem]="activeItem" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus" [isSetupValid]="isSetupValid" 
        (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
    <div class="shadow-8 surface-0 p-3">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-era-setup [menuService]="ERAService" [modelsCollection]="modelsCollection" [disciplinePk]="disciplinePk" [columnDef]="columnDef" (isSetupValidEvent)="validateSetup($event)"></app-era-setup>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 1">
            <app-era-endpoints [isProjectOwnershipValid]="isProjectOwnershipValid"  [selectedProject]="selectedProject" [menuService]="ERAService" (isValid)="getIsValidEraEndpoints($event)"></app-era-endpoints>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 2">
            <app-era-gap [isProjectOwnershipValid]="isProjectOwnershipValid"  [selectedProject]="selectedProject" [menuService]="ERAService" (isValid)="getIsValidApplicationScheme($event)"></app-era-gap>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 3">
            <app-era-pec-ecc [isProjectOwnershipValid]="isProjectOwnershipValid"  [selectedProject]="selectedProject" [menuService]="ERAService"></app-era-pec-ecc>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 4">
            <app-era-run-risk-assessment [isProjectOwnershipValid]="isProjectOwnershipValid"  [selectedProject]="selectedProject" [menuService]="ERAService"></app-era-run-risk-assessment>
        </ng-container>
    </div>
</div>
