import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EaaOutputProjectPwcResultSummaryColdefService {

  constructor() { }
  private columnDefs = [
    {
      headerName: '',
      instance: this,
      children: [
        {
          headerName: 'Run ID',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'RunID',
          width: 90,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: '',
      instance: this,
      children: [
        {
          headerName: 'Application Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'ApplicationScheme',
          width: 300,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Endpoints Data Set',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'EndPointsDataSet',
          width: 250,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Region',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Region',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Crop',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Crop',
          width: 170,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Compound',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Compound',
          width: 200,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Simulation Type',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'SimulationType',
          width: 120,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: '1-day \n Avg',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'OneDayAvg',
          width: 110,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: '4-day \n Avg',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'FourDayAvg',
          width: 110,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: '21-day \n Avg',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'TwentyOneAvg',
          width: 110,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: '60-day \n Avg',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'SixtyDayAvg',
          width: 110,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: '365-day \n Avg',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'OneYearAvg',
          width: 110,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },  
        {
          headerName: 'Entire Simulation \n Mean',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'SimulationAvg',
          width: 140,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        
      ],
    },
  ];

  getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
