<div>
    <app-grid #grid [columnDefs]="columsDef" [rowData]="rowData" [autoAdjustColumns]="true"
        [useAddAction]="isProjectOwnershipValid" [useModels]="true" [compartment]="compartment"
        [selectedProject]="selectedProject" [useDeleteAction]="true" [useServerExcelExportAction]="true" [rowHeight]="rowHeight" [isMasterDetail]="true"
        [detailColumnDefs]="detailsColumsDef" [useDetailAddButton]="false" [detailAutoRowHeigth]="true"
        [isProjectOwnershipValid]="isProjectOwnershipValid"
        (listBoxSelectionChangeEvent)="geographiesListBoxSelectionChanged($event)"
        (onClickButtonEvent)="onClickViewButton($event)"
        (calendarSelectionChangeEvent)="onCalendarSelectionChanged($event)"
        (dropDownSelectionChangeEvent)="onDropDownSelectionChanged($event)"
        (onModelOptionsChangedEvent)="onModelOptionsChanged($event)" [rowMultiSelectWithClick]="true"
        [rowSelection]="'multiple'" (onFirstDataRenderedEvent)="onFirstDataRendered($event)"
        (buttonExpandParams)="onButtonExpandClick($event)" (deleteEvent)="deleteRow($event)"
        (addEvent)="newRowAdded($event)" (onBlurInputText)="onBlurInputText($event)"
        [suppressRowClickSelection]="true" [paginationPageSize]="25" [pagination]="false"
        (onSelectedRowEvent)="onSelectedRow($event)"
        (onRowSelectedEmit)="onSelectionChanged($event)" (serverExcelExportEvent)="serverExcelExport()"
      ></app-grid>
    <app-eea-application-dialog #appDialog [isProjectOwnershipValid]="isProjectOwnershipValid"
        [selectedProject]="selectedProject" [isInverseModeling]="isInverseModeling" [compartment]="compartment"
        [rowData]="applicationDatesByCrop" [appScheme]="applicationScheme"
        (onClickSaveButtonEvent)="onSaveApplicationDates($event)">
    </app-eea-application-dialog>
</div>
