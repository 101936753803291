import { Component } from '@angular/core';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Datafield } from 'src/app/shared/models/echo/data-field';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { GeographyMatching } from 'src/app/shared/models/geography-matching';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { GeographyMatchingService } from './geography-matching.service';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { ModelLibraryService } from '../model-library/model-library.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-geography-matching',
  templateUrl: './geography-matching.component.html',
  styleUrls: ['./geography-matching.component.css']
})
export class GeographyMatchingComponent {
  afterSearch: boolean = false;
  allModels: ModelLibrary[] = [];
  datafields: Datafield[] = [];
  datafieldValues: DatafieldValue[] = [];
  disciplinePk: number = 0;
  geographies: Catalog[] = [];
  geographyMatchingComponent = this;
  geographyMatchings: GeographyMatching[] = [];
  loading: boolean = false;
  models: ModelLibrary[] = [];

  constructor(
    private datafieldValueService: DatafieldValueApiService,
    private geographyMatchingService: GeographyMatchingService,
    private geographyService: GeographyApiService,
    private modelLibraryService: ModelLibraryService,
  ) {}

  ngOnInit(): void {
    this.getDatafields();
  }

  getDatafields(): void {
    this.datafieldValueService.getDatafieldData(Constants.THIS_APPLICATION).subscribe((datafieldData: Datafield[]) => {
      this.datafieldValueService.getDatafieldValueInformation().subscribe((datafieldValueData: DatafieldValue[]) => {
        this.datafieldValues = datafieldValueData;
        this.datafields = datafieldData;
        this.getGeographies();
      });
    });
  }

  getGeographies(){
    this.geographyService.getRegionCountryCatalog().subscribe((geographies: Catalog[]) => {
      this.geographies = Utils.sortObject(geographies, 'name');
      this.getData();
    });
  }

  getData() {
    this.setLoadingState(true);
    this.modelLibraryService.getModels().subscribe((models: ModelLibrary[]) => {
      this.allModels = models;
      this.filterDataByDiscipline(this.disciplinePk);
    });
  }

  filterDataByDiscipline(pDisciplinePk: number) {
    this.setLoadingState(true);
    this.disciplinePk = pDisciplinePk;
    this.models = this.allModels.filter((x) => x.disciplineXModel && x.disciplineXModel.includes(pDisciplinePk));
    if (this.disciplinePk)
      this.geographyMatchingService.getGeographyMatchings(pDisciplinePk).subscribe((geographyMatchings: GeographyMatching[]) => {
          this.geographyMatchings = geographyMatchings;
          this.setLoadingState(false);
        });
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }
}
