<div class="status-container grid" style="margin-bottom: -40px;">
    <div class="col-8"></div>
    <div class="col-2" style="align-items: end; padding-top: 13px; padding-left: 80px;">
        <span>Project Status: &nbsp; <strong>{{ statusName }}</strong></span>&nbsp;
        <p-badge pTooltip="{{selectedProject?.description}}" tooltipPosition="top"
            styleClass="{{badgeStyle}}"></p-badge>
    </div>
    <div class="col-2" style="align-items: end;">
        <app-button iconPos="right" [outlined]="true" severity="secondary"
            [icon]="isStoping ? 'pi pi-spin pi-spinner' : 'pi pi-stop-circle'" *ngIf="showStopProjectButton()"
            (onClickEvent)="onStopProjectClick()"></app-button>
        &nbsp;
        <app-button label="Summary" iconPos="right" [outlined]="true" severity="secondary"
            [icon]="isSummaryDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" *ngIf="showDownloadButton()"
            (onClickEvent)="onDownloadOutputFilesClick(false)"></app-button>
        &nbsp;
        <app-button label="All files" iconPos="right" [outlined]="true" severity="secondary"
            [icon]="isAllFilesDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" *ngIf="showDownloadButton()"
            (onClickEvent)="onDownloadOutputFilesClick(true)"></app-button>
    </div>
    <p-dialog header="Select the output data files to download" [(visible)]="visible" [modal]="true"
        [style]="{ width: '29vw'}" [draggable]="true" [resizable]="true">
        <app-grid #grid [columnDefs]="columnDefs" [rowData]="rowData" [rowSelection]="'multiple'" [style]="gridStyle"
            [paginationPageSize]="paginationPageSize">
        </app-grid>
        <ng-template pTemplate="footer">
            <p-button label="Download File(s)" iconPos="right" icon="pi pi-download"
                (click)="onDownloadSelectedRowsClick()" pAutoFocus [autofocus]="true"></p-button>
        </ng-template>
    </p-dialog>
</div>