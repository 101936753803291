<ng-container *ngIf="!floating && !useTitle">
    <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" *ngIf="label">{{label}}</label>
    <input pInputText [type]="type" [class]="class"
        [ngClass]="!isValid && isRequired ? 'ng-invalid ng-dirty' : 'ng-valid'" [placeholder]="placeholder" [id]="id"
        [style]="style" [(ngModel)]="model" [disabled]="disabled" (ngModelChange)="modelChanged($event)" />
</ng-container>

<span *ngIf="floating" class="p-float-label">
    <input class="w-12" [ngClass]="!isValid && isRequired ? 'ng-invalid ng-dirty' : 'ng-valid'" pInputText [id]="id"
        [(ngModel)]="model" [disabled]="disabled" (ngModelChange)="modelChanged($event)" />
    <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" [htmlFor]="id">{{label}}</label>
</span>

<div *ngIf="useTitle" class="flex flex-column gap-2">
    <div class="label-with-icon">
        <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" [htmlFor]="id">{{ label }}</label>
        <img *ngIf="useIcon" src="assets/img/infoIcon.png" (click)="showInfoPopup()"
            style="width: 16px; height: 16px; margin-left: 8px; cursor: pointer;">
    </div>
    <input class="w-12" [ngClass]="!isValid && isRequired ? 'ng-invalid ng-dirty' : 'ng-valid'" pInputText [id]="id"
        [(ngModel)]="model" [disabled]="disabled" (ngModelChange)="modelChanged($event)" />
</div>