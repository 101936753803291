<div class="grid">
    <div class="col-12 flex justify-content-center">
        <p-messages></p-messages>
        <app-tab-menu [items]="items" [selectedProject]="selectedProject"
        [selectedProjectStatus]="selectedProjectStatus" [activeItem]="activeItem" [isSetupValid]="isSetupValid"
        (onActiveIndexChangeEvent)="onActiveIndexChange($event)"></app-tab-menu>
    
    </div>

</div>
<app-block-ui [blocked]="loading"></app-block-ui>