<!-- 
  <sumary>
  Description: Crop List Component HTML
  Author: Karina Villalobos S.
  Creation date: 13/Dic/2023  
  </sumary>
  <history>
     <update> H00 - BAMS3-50
              Description: Creation.
     </update>
  </history>
   -->
<div class="content" style="margin-left:36px; margin-top: 12px; width:1500px;">
    <div class="content" style="display: inline-flex;">
        <div class="col">
            <div class="row">
                <span class="filter-label">Geography:</span>
            </div>
            <div class="row">
                <div>
                    <app-dropdown styleClass="ddlFilterSelection" panelStyleClass="panel-filter-dropdown" 
                     [options]="geography" [model]="selectedGeography" 
                     (onChangeEvent)="geographySelectionChange($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="col" style="width: 20px;"> 
        </div>
        <div class="col" [hidden]="hiddenCompartment">
            <div class="row">
                <span class="filter-label">Compartment:</span>
            </div>
            <div class="row">
                <div>
                    <app-dropdown styleClass="ddlFilterSelection" panelStyleClass="panel-filter-dropdown" 
                     [options]="compartment" [model]="selectedCompartment"
                          (onChangeEvent)="compartmentSelectionChange($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="col" style="width: 20px;"> 
        </div>
        <div class="col" [hidden]="hiddenPurpose">
            <div class="row">
                <span class="filter-label">Purpose:</span>
            </div>
            <div class="row">
                <div>
                    <app-dropdown styleClass="ddlFilterSelection" panelStyleClass="panel-filter-dropdown" 
                     [options]="purpose " [model]="selectedPurpose" 
                        (onChangeEvent)="purposeSelectionChange($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="grid-container" *ngIf="afterSearch" [ngStyle]="{'display': afterSearch == true? 'block': 'none' }" style="margin-bottom: -11px;">
        <app-grid #grid [rowData]="rowData" [rowHeight]="rowHeight" rowSelection="single"
        (selectionChangedEvent)="onSelectionChanged($event)" [columnDefs]="columnDefs" (addEvent)="addRow()"
        (saveEvent)="saveData($event)" [useSaveAction]="this.currentUserCanEdit" [useAddAction]="this.currentUserCanEdit" [useDeleteAction]="this.useDeleteAction && this.currentUserCanEdit" (refreshEvent)="getData()"(listBoxSelectionChangeEvent)="ScenariosListBoxSelectionChanged($event)" (deleteEvent)="saveData($event)"></app-grid>
    </div>  
    <app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading> 
</div>