export abstract class OPEXConstants {

  public static GENERAL = {
    componentQC: 'HHRA',
    ProjectWarningAlertText: 'There is no a selected project.',
    dataSetsWarningAlertText: 'There are no datasets for this project.',
    noRunsWarningAlertText: 'There are no runs to be process by the QC',
    missingData: 'Missing Data'
  }
    
  public static OPEX_FIELD_NAMES = {
    PRODUCT_NAME: 'activeIngredient',
    FORMULATION_TYPE: 'formulationType',
    OPEX_FORMULATION_TYPE: 'OPEXFormulationType',
    ACTIVE_INGREDIENT: 'substancePropertiesDataSet.substanceName',
    CALCULATOR_GROUP: 'substancePropertiesDataSet.calculatorGroup',
    ENDPOINTS_DATASET: 'substancePropertiesDataSet.description',
    DERMAL_ABSORPTION_OF_PRODUCT: 'substancePropertiesDataSet.endpoints.DermalAbsorptionOfProduct',
    DERMAL_ABSORTION_OF_IN_USE_DILUTION: 'substancePropertiesDataSet.endpoints.DermalAbsorptionOfInUseDilution',
    AOEL: 'substancePropertiesDataSet.endpoints.AcceptableOperatorExposureLevel',
    DISLODGEABLE_FOLIAR_RESIDUE: 'substancePropertiesDataSet.endpoints.InitialDislodgeableFoliarResidue',
    FOLIAR_DT50: 'substancePropertiesDataSet.endpoints.FoliarDT50',
    ORAL_ABSORPTION: 'substancePropertiesDataSet.endpoints.OralAbsorptionOfActiveSubstance',
    INHALATION_ABSORPTION: 'substancePropertiesDataSet.endpoints.InhalationAbsorptionofActiveSubstance',
    APPLICATION_SCHEME: 'humanHealthApplicationScheme.name',
    CROP_TYPE: 'humanHealthApplicationScheme.cropType',
    ACTIVITY: 'humanHealthApplicationScheme.activity',
    INDOOR_OR_OUTDOOR_APPLICATION: 'humanHealthApplicationScheme.indoorOutdoorApplication',
    BUFFER_STRIP: 'humanHealthApplicationScheme.bufferStrip',
    DRIFT_REDUCTION: 'humanHealthApplicationScheme.driftReduction',
    NUMBER_OF_APPLICATIONS: 'humanHealthApplicationScheme.numberOfApplications',
    APPLICATION_INTERVAL: 'humanHealthApplicationScheme.applicationInterval',
    APPLICATION_METHOD: 'humanHealthApplicationScheme.applicationMethod',
    APPLICATION_EQUIPMENT: 'humanHealthApplicationScheme.applicationEquipment',
    APPLICATION_RATE: 'applicationRate',
    PPE_SCHEME: 'personalProtectionEquipmentScheme.name',
  }

  public static ENDPOINTS_FIELD_NAMES = {
    ACTIVE_INGREDIENT: 'substanceName',
    CALCULATOR_GROUP: 'calculatorGroup',
    ENDPOINTS_DATASET: 'description',
    DERMAL_ABSORPTION_OF_PRODUCT: 'DermalAbsorptionOfProduct',
    DERMAL_ABSORTION_OF_IN_USE_DILUTION: 'DermalAbsorptionOfInUseDilution',
    AOEL: 'AcceptableOperatorExposureLevel',
    AAOEL: 'AcuteAcceptableOperatorExposureLevel',
    DISLODGEABLE_FOLIAR_RESIDUE: 'InitialDislodgeableFoliarResidue',
    FOLIAR_DT50: 'FoliarDT50',
    ORAL_ABSORPTION: 'OralAbsorptionOfActiveSubstance',
    INHALATION_ABSORPTION: 'InhalationAbsorptionofActiveSubstance',
  };

  public static HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES = {
    APPLICATION_SCHEME: 'name',
    CROP_TYPE: 'cropType',
    ACTIVITY: 'activity',
    INDOOR_OR_OUTDOOR_APPLICATION: 'indoorOutdoorApplication',
    NUMBER_OF_APPLICATIONS: 'numberOfApplications',
    APPLICATION_INTERVAL: 'applicationInterval',
    MAX_APPLICATION_RATE_OF_PRODUCT: 'maxProductApplicationRate',
    RATE: 'rate',
    MIN: 'applicationWaterVolumeMin',
    MAX: 'applicationWaterVolumeMax',
    APPLICATION_METHOD: 'applicationMethod',
    APPLICATION_EQUIPMENT: 'applicationEquipment',
    APPLICATION_CLOSED_CAB: 'applicationClosedCab',
    BUFFER_STRIP: 'bufferStrip',
    SEASON: 'season',
    DRIFT_REDUCTION: 'driftReduction'
  };

  public static PPE_SCHEME_FIELD_NAMES = {
    PPE_SCHEME: 'name',
    MIXING_AND_LOADING_GLOVES: 'mixingLoadingGloves',
    MIXING_AND_LOADING_CLOTHING: 'mixingLoadingClothing',
    HEAD_AND_RESPIRATORY_PPE: 'mixingLoadingHeadRespiratory',
    WATER_SOLUBLE_BAG: 'mixingLoadingWaterSolubleBag',
    APPLICATION_GLOVES: 'applicationGloves',
    APPLICATION_CLOTHES: 'applicationClothing',
    APPLICATION_HEAD_RESPIRATORY: 'applicationHeadRespiratory'
  };

  public static PPE_GRANULES_SCHEME_FIELD_NAMES = {
    PPE_SCHEME: 'name',
    MIXING_AND_LOADING_GLOVES: 'mixingLoadingGloves',
    MIXING_LOADING_BODY: 'mixingLoadingBody',
    MIXING_LOADING_RPE: 'mixingLoadingRPE',
    APPLICATION_GLOVES: 'applicationGloves',
    APPLICATION_BODY: 'applicationBody',
    APPLICATION_RPE: 'applicationRPE'
  };

  public static OPEX_INPUTS_COLUMN_HEADER_NAMES = {
    SUBSTANCE_NAME: 'Active Ingredient',
    CALCULATOR_GROUP: 'Calculator Group',
    DESCRIPTION: 'Endpoints Dataset',
    NAME: 'Application Scheme',
    ACTIVE_INGREDIENT: 'Product name',
    FORMULATION_TYPE: 'Formulation type',
    OPEX_FORMULATION_TYPE: 'OPEX Formulation type',
    CROP_TYPE: 'Crop type',
    ACTIVITY: 'Activity',
    INDOOR_OR_OUTDOOR_APPLICATION: 'Indoor or Outdoor application',
    BUFFER_STRIP: 'Buffer strip (m)',
    DRIFT_REDUCTION: 'Drift reduction (%)',
    NUMBER_OF_APPLICATION: 'Number of applications',
    APPLICATION_INTERVAL: 'Application interval (d)',
    APPLICATION_RATE: 'Application rate (L or Kg a.s./ha)',
    PPE_SCHEME: 'PPE Scheme',
    DERMAL_ABSORPTION_OF_PRODUCT: 'Dermal absorption of product (%)',
    DERMAL_ASORPTION: 'Dermal absorption of \n in-use dilution (%)',
    AOEL: 'AOEL (mg/kg bw/day)',
    AAOEL: 'AAOEL (mg/kg bw/day)',
    DISLODGEABLE_FOLIAR_RESIDUE: 'Dislodgeable Foliar Residue \n (µg/cm² of foliage/kg a.s. applied/ha)',
    FOLIAR: 'Foliar DT50 (d)',
    ORAL_ABSORPTION: 'Oral absorption (%)',
    INHALATION_ABSORPTION: 'Inhalation absorption (%)',
    PERCENTAGE_AOEL: '%(A)AOEL',
    MIXING_LOADING_WATER_SOLUBLE_BAG: 'Water soluble bag',
  };

  public static readonly MODELS = {
    SWASH: 'FOCUS Step 3 (Swash)',
    STEP_1_2: 'FOCUS Step 1 and 2',
    SWAN: 'FOCUS Step 4 (Swan)',
    UK: 'UK Tier 1',
    PWC: 'PWC_SW',
    MACRO_GW: 'MACRO_GW',
    PEARL: 'PEARL',
    PELMO: 'PELMO',
    EU_SOIL: 'EU Soil',
    GERMAN_SOIL: 'Germany Soil',
    EU_OPEX: "EU - OPEX",
    US_OHE: "US - Occupation Handler Exposure",
    US_OREX: "US - Occupational Re-entry Exposure",
    NEU_SOIL: 'NEU Soil',
    US_RE: "US - Residencial Exposure",
    AGD_AQUAMIX: "AGD_AquaMix",
    AQUATICS_US: "Aquatics_US",
    BEE_REX: "Bee-REX",
    T_REX: "T-Rex",
  };

  public static ENDPOINTS_BY_MODEL = {
    [OPEXConstants.MODELS.EU_OPEX]: [
      OPEXConstants.ENDPOINTS_FIELD_NAMES.ACTIVE_INGREDIENT,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.ENDPOINTS_DATASET,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.CALCULATOR_GROUP,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.FOLIAR_DT50,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.DISLODGEABLE_FOLIAR_RESIDUE,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.DERMAL_ABSORPTION_OF_PRODUCT,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.DERMAL_ABSORTION_OF_IN_USE_DILUTION,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.AOEL,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.ORAL_ABSORPTION,
      OPEXConstants.ENDPOINTS_FIELD_NAMES.INHALATION_ABSORPTION,
    ]
  };

  public static APPSCHEME_BY_MODEL = {
    [OPEXConstants.MODELS.EU_OPEX]: [
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_SCHEME,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.CROP_TYPE,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.ACTIVITY,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.INDOOR_OR_OUTDOOR_APPLICATION,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.NUMBER_OF_APPLICATIONS,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_INTERVAL,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.MAX_APPLICATION_RATE_OF_PRODUCT,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.RATE,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.MIN,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.MAX,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_METHOD,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_EQUIPMENT,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_CLOSED_CAB,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.BUFFER_STRIP,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.SEASON,
      OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.DRIFT_REDUCTION
    ]
  };

  public static PPE_BY_MODEL = {
    [OPEXConstants.MODELS.EU_OPEX]: [
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.PPE_SCHEME,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.MIXING_AND_LOADING_GLOVES,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.MIXING_AND_LOADING_CLOTHING,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.HEAD_AND_RESPIRATORY_PPE,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.WATER_SOLUBLE_BAG,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.APPLICATION_GLOVES,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.APPLICATION_CLOTHES,
      OPEXConstants.PPE_SCHEME_FIELD_NAMES.APPLICATION_HEAD_RESPIRATORY
    ]
  };

  public static PPE_GRANULES_MODEL = {
    [OPEXConstants.MODELS.EU_OPEX]: [
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.PPE_SCHEME,
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.MIXING_AND_LOADING_GLOVES,
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.MIXING_LOADING_BODY,
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.MIXING_LOADING_RPE,
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.APPLICATION_GLOVES,
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.APPLICATION_BODY,
      OPEXConstants.PPE_GRANULES_SCHEME_FIELD_NAMES.APPLICATION_RPE
    ]
  };

  public static REQUIRED_COLUMNS_BY_MODEL = {
    [OPEXConstants.MODELS.EU_OPEX]: [
      OPEXConstants.OPEX_FIELD_NAMES.ACTIVE_INGREDIENT,
      OPEXConstants.OPEX_FIELD_NAMES.ACTIVITY,
      OPEXConstants.OPEX_FIELD_NAMES.APPLICATION_INTERVAL,
      OPEXConstants.OPEX_FIELD_NAMES.APPLICATION_RATE,
      OPEXConstants.OPEX_FIELD_NAMES.APPLICATION_SCHEME,
      OPEXConstants.OPEX_FIELD_NAMES.NUMBER_OF_APPLICATIONS,
      OPEXConstants.OPEX_FIELD_NAMES.BUFFER_STRIP,
      OPEXConstants.OPEX_FIELD_NAMES.CALCULATOR_GROUP,
      OPEXConstants.OPEX_FIELD_NAMES.CROP_TYPE,
      OPEXConstants.OPEX_FIELD_NAMES.DERMAL_ABSORPTION_OF_PRODUCT,
      OPEXConstants.OPEX_FIELD_NAMES.DERMAL_ABSORTION_OF_IN_USE_DILUTION,
      OPEXConstants.OPEX_FIELD_NAMES.DISLODGEABLE_FOLIAR_RESIDUE,
      OPEXConstants.OPEX_FIELD_NAMES.DRIFT_REDUCTION,
      OPEXConstants.OPEX_FIELD_NAMES.ENDPOINTS_DATASET,
      OPEXConstants.OPEX_FIELD_NAMES.FOLIAR_DT50,
      OPEXConstants.OPEX_FIELD_NAMES.FORMULATION_TYPE,
      OPEXConstants.OPEX_FIELD_NAMES.INDOOR_OR_OUTDOOR_APPLICATION,
      OPEXConstants.OPEX_FIELD_NAMES.INHALATION_ABSORPTION,
      OPEXConstants.OPEX_FIELD_NAMES.OPEX_FORMULATION_TYPE,
      OPEXConstants.OPEX_FIELD_NAMES.ORAL_ABSORPTION,
      OPEXConstants.OPEX_FIELD_NAMES.PPE_SCHEME,
      OPEXConstants.OPEX_FIELD_NAMES.AOEL
    ]
  };

  public static HEADER_NAMES_BY_FIELD = {
    [OPEXConstants.OPEX_FIELD_NAMES.ACTIVE_INGREDIENT]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.ACTIVE_INGREDIENT,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.CALCULATOR_GROUP]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.CALCULATOR_GROUP,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.ENDPOINTS_DATASET]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.DESCRIPTION,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_SCHEME]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.NAME,
    [OPEXConstants.OPEX_FIELD_NAMES.PRODUCT_NAME]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.ACTIVE_INGREDIENT,
    [OPEXConstants.OPEX_FIELD_NAMES.FORMULATION_TYPE]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.FORMULATION_TYPE,
    [OPEXConstants.OPEX_FIELD_NAMES.OPEX_FORMULATION_TYPE]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.OPEX_FORMULATION_TYPE,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.CROP_TYPE]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.CROP_TYPE,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.ACTIVITY]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.ACTIVITY,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.INDOOR_OR_OUTDOOR_APPLICATION]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.INDOOR_OR_OUTDOOR_APPLICATION,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.BUFFER_STRIP]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.BUFFER_STRIP,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.DRIFT_REDUCTION]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.DRIFT_REDUCTION,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.NUMBER_OF_APPLICATIONS]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.NUMBER_OF_APPLICATION,
    [OPEXConstants.HUMAN_HEALTH_APPLICATION_SCHEME_FIELD_NAMES.APPLICATION_INTERVAL]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.APPLICATION_INTERVAL,
    [OPEXConstants.OPEX_FIELD_NAMES.APPLICATION_RATE]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.APPLICATION_RATE,
    [OPEXConstants.PPE_SCHEME_FIELD_NAMES.PPE_SCHEME]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.PPE_SCHEME,
    [OPEXConstants.PPE_SCHEME_FIELD_NAMES.WATER_SOLUBLE_BAG]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.MIXING_LOADING_WATER_SOLUBLE_BAG,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.DERMAL_ABSORPTION_OF_PRODUCT]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.DERMAL_ABSORPTION_OF_PRODUCT,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.DERMAL_ABSORTION_OF_IN_USE_DILUTION]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.DERMAL_ASORPTION,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.AOEL]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.AOEL,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.DISLODGEABLE_FOLIAR_RESIDUE]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.DISLODGEABLE_FOLIAR_RESIDUE,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.FOLIAR_DT50]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.FOLIAR,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.ORAL_ABSORPTION]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.ORAL_ABSORPTION,
    [OPEXConstants.ENDPOINTS_FIELD_NAMES.INHALATION_ABSORPTION]: OPEXConstants.OPEX_INPUTS_COLUMN_HEADER_NAMES.INHALATION_ABSORPTION
  };
}