import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutoCompleteCompleteEvent, AutoCompleteDropdownClickEvent, AutoCompleteOnSelectEvent, AutoCompleteUnselectEvent } from 'primeng/autocomplete';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteComponent implements OnInit,OnChanges {

  @Input() optionLabel: string = "";
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() model: any;
  @Input() suggestions: any[] = [];
  @Input() dropdown: boolean = false;
  @Input() forceSelection: boolean = false;
  @Input() showClear: boolean = false;
  @Input() hardDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isValid: boolean = true;

  @Output() queryFilterEvent = new EventEmitter<AutoCompleteCompleteEvent>();
  @Output() selectionChangedEvent = new EventEmitter<AutoCompleteOnSelectEvent>();
  @Output() onClearEvent = new EventEmitter<any>();
  @Output() onDropdownClickEvent = new EventEmitter<AutoCompleteDropdownClickEvent>();
  
  disabled: boolean = false;

  ngOnInit(): void {
    if(this.label && this.isRequired) this.label = `${this.label}*`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['hardDisabled'])
      this.disabled = this.hardDisabled;
  }

  queryFilter(event: AutoCompleteCompleteEvent)
  {
    this.queryFilterEvent.emit(event);
  }

  onSelectionChanged(event: AutoCompleteOnSelectEvent){
    this.selectionChangedEvent.emit(event);
  }

  onClear(){
    this.onClearEvent.emit(this.model);
  }

  onDropdownClick(event: AutoCompleteDropdownClickEvent){
    this.onDropdownClickEvent.emit(event);
  }
}
