import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { InverseModelingEndpointsLogicService } from './inverse-modeling-endpoints.logic.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-inverse-modeling-endpoints',
  templateUrl: './inverse-modeling-endpoints.component.html',
  styleUrls: ['./inverse-modeling-endpoints.component.css']
})

export class InverseModelingEndpointsComponent implements OnInit {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() isValid = new EventEmitter<boolean>();

  public isEraEcotoxDatasetValid: boolean = true;
  activeItem!: any;
  items: any[] = []
  destroyRef = inject(DestroyRef)
  afterSearch: boolean = false;
  loading: boolean = false;
  nextIndex?: number;
  message!: Message;
  taxonGroups: any = Constants.TAXON_GROUPS;


  constructor(public InverseModelingEndpointsLogicService: InverseModelingEndpointsLogicService){}

  ngOnInit(): void {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.menuService.setSuccess(true));
    this.InverseModelingEndpointsLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if(!this.InverseModelingEndpointsLogicService.activeItemSubject.value.index) this.InverseModelingEndpointsLogicService.setSuccess(true)
    });
    this.InverseModelingEndpointsLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activeItem: any) => this.activeItem = activeItem);
    this.InverseModelingEndpointsLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe( (success: any)  => {
      this.loading = false;
      if(success) {
        const nextItem = this.InverseModelingEndpointsLogicService.items.find(i => i.index == this.nextIndex);
        if(nextItem)
          this.InverseModelingEndpointsLogicService.setActiveItem(nextItem);
      }
    });
  }

  onActiveIndexChange(index: number){
    this.nextIndex = index;
    this.InverseModelingEndpointsLogicService.setActiveIndexChanged(true, index);
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  public getIsEraEcotoxDatasetValid(isValid: boolean): void {
    this.isEraEcotoxDatasetValid = isValid;
    this.isValid.emit(isValid);
  }
}
