import { Component } from '@angular/core';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonAdminRenderer {
  params: any;
  parent: any;
  isFractionGrid: boolean = false;
  editable: boolean = true;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.editable = this.params.editable ?? this.editable;
    this.parent = params.context.componentParent;
    this.isFractionGrid = this.parent.isFractionGrid ? this.parent.isFractionGrid : false;
  }

  isDeletable(): boolean {
    return this.params.data.deletable;
  }
  
  refresh(): boolean {
    return false;
  }

  
  onDeleteClick(): void {
    if (this.parent.isFractionGrid !== undefined && this.parent.isFractionGrid) {
      this.parent.setNode(this.params.node.id);
    }
    this.parent.showDialog(Constants.DeleteDialogElements, 'delete');
  }
}
