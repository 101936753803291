import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Catalog } from '../../models/echo/catalog';
import { Observable, map } from 'rxjs';
import { Molecule, MoleculePkAndName } from '../../models/echo/molecule';
import { HttpApiService } from '../http.api.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveIngredientApiService extends HttpApiService {
  override apiUrl: string = environment.ECHO_Api_URL;

  private _moleculesPkAndNames: MoleculePkAndName[] = [];

  getSubstancesCatalog(): Observable<Catalog[]> {
    return this.get(`api/toxicology/GetAllSubstanceCatalog`).pipe(
      map((data: any) => data.Value)
    );
  }
  getActiveIngredients(): Observable<Molecule[]> {
    return this.get(`api/molecule_bams`)
      .pipe(map((data: any) => data.Value));
  }
  getAllActiveIngredients(): Observable<Molecule[]> {
    return this.get(`api/molecule/false`)
      .pipe(map((data: any) => data.Value));
  }


  getMoleculeByMoleculePks(moleculePks: any): Observable<Molecule[]> {
    const body = JSON.stringify(moleculePks);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/molecule_bams/moleculePks`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

  getBamsMoleculePkAndNameByPks(moleculePks: number[]): Observable<MoleculePkAndName[]> {
    const body = JSON.stringify(moleculePks);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/getBamsMoleculePkAndNameByPks`;
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data));
  }

  getAllMoleculesPkAndName(): Observable<MoleculePkAndName[]> {
    return this.get(`api/getAllMoleculesPkAndName`)
      .pipe(map((data: any) => data.Value));
  }

}
