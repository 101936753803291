
<div class="container m-6">
    <div class="row row-filters">
        <div class="col-3">
            <app-region [componentThatImplementsGrid]='componentThatImplementsGrid'></app-region>
            </div>
</div>
</div>
<div class="content" class="m-6">


    <div class="grid shadow-8 surface-0 p-3 mt-6 div-grid" *ngIf="afterSearch" [ngStyle]="{'display': afterSearch == true? 'block': 'none' }">
        <div class="col-12 p-3 mt-3">
        <app-triggers-ecotox-grid  [style]="style" [dataComponent]='componentThatImplementsGrid'></app-triggers-ecotox-grid>
        </div>
    </div>
    <app-loading *ngIf="loading"></app-loading>

    <div kendoWindowContainer></div>
</div>