import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root',
})

export class HHEASetupColDef {

  public getColumnsDefinition(dietary: ModelLibrary[], nonDietary: ModelLibrary[], isProjectOwnershipValid: boolean): ColDef[] {
    return [
      {
        headerName: 'Compound',
        field: 'metaboliteName',
        width: 250,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Dietary',
        field: Constants.COMPARTMENTS.DIETARY.toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: (params: any) =>  {
          return{
            options: dietary,
            optionValue: 'modelPk',
            optionLabel: 'nameVersion',
            checkbox: true,
            filter: false,
            multiple: true,
            disabled: !isProjectOwnershipValid || params.data.disabled,
            validateModel: true,
            compartment: Constants.COMPARTMENTS.DIETARY.toLowerCase(),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Non-Dietary',
        field: Constants.COMPARTMENTS.NONDIETARY.toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: nonDietary,
            optionValue: 'modelPk',
            optionLabel: 'nameVersion',
            checkbox: true,
            filter: false,
            multiple: true,
            disabled: !isProjectOwnershipValid || params.data.disabled,
            validateModel: true,
            compartment: Constants.COMPARTMENTS.NONDIETARY.toLowerCase(),
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      }
    ];
  }
}
