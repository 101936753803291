import { Component } from '@angular/core';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-endpoint-dataset-ddl-renderer',
  templateUrl: '../../../renderers/dropdown-list/dropdown-list.component.html',
  styleUrls: ['../../../renderers/dropdown-list/dropdown-list.component.css']
})
export class EndpointDatasetDdlRendererComponent extends DropdownListRenderer {
  constructor(orderByPipe: OrderByPipe) {
      super(orderByPipe)
  }

  override agInit(params: any): void {
    super.agInit(params);
    this.addSelectedOptionIfNotInOptions(params);
  }

  addSelectedOptionIfNotInOptions(params:any)
  {
    if (params.value && params.columnThatModifies == 'compoundPk' && !this.options.find((x:any) => x.compoundPk == params.value))
    {
      var compound = params.data.substanceType == Constants.SUBSTANCE_TYPES.ACTIVE ? params.allMolecules.find((x:any) => x.moleculePk == params.value).moleculeName:  
                                                             params.allMetabolites.find((x:any) => x.metabolitePk == params.value).metaboliteName      
      this.options.push({ compoundName: compound, compoundPk: params.value, compoundType: params.data.substanceType })
      this.optionsToRemove?.push(params.value);         
    }
  }
}
