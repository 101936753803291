import { Component, effect, EventEmitter, inject, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { Accordion, AccordionTabCloseEvent } from 'primeng/accordion';
import { ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { Project } from 'src/app/shared/models/project';
import { ModelToBeRun } from 'src/app/shared/models/run/model-to-be-run';
import { SetupLogicService } from 'src/app/shared/services/setup.logic.service';
import { Constants } from 'src/app/shared/utils/constants';


@Component({
  selector: 'app-era-setup-models-to-be-run',
  templateUrl: './era-setup-models-to-be-run.component.html',
  styleUrls: ['./era-setup-models-to-be-run.component.css']
})
export class ERASetupModelsToBeRunComponent implements OnChanges, OnDestroy {

  @ViewChild('accordion') accordion!: Accordion;

  @Input() modelsToBeRun: ModelToBeRun[] = [];
  @Input() columnsDefinition: ColDef[] = [];
  @Input() selectedProject?: Project;
  @Input() modelsLoading!:boolean;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() modelsCollection: ModelLibraryCollection[] = [];

  @Output() areSetupModelsValidEvent = new EventEmitter<any>();

  gridStyle: string = 'width: auto; height: 600px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  rowHeight: number = 180;
  selected = false;
  activeIndex: number | null = null;
  isValid: boolean = true;

  public showModels:boolean = false;

  private setupLogicService = inject(SetupLogicService);

  selectedGeographyEffect = effect(() => {
    this.showModels = this.setupLogicService.selectedGeographyKey() !== undefined;
  });

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['modelsToBeRun'] ){
      this.activeIndex = null;
      this.validateSelectedModels();
    }
  }

  onClose(event: AccordionTabCloseEvent) {
    this.activeIndex = null;
  }

  onOpen({ index, originalEvent }: AccordionTabCloseEvent) {
    if (!this.modelsToBeRun[index].selected) {
      originalEvent.stopPropagation();
      const tab = this.accordion.tabs.find(t => t.findTabIndex() == index);
      if (tab) tab.selected = false;
      this.activeIndex = null;
      return;
    }
    this.activeIndex = index;
  }

  modelsListBoxSelectionChanged({ data, selectedValues, compartment }: any) {
    if (!this.selectedProject) return;
    data[compartment] = selectedValues;
    this.validateSelectedModels();
  }


  validateSelectedModels() {
    let validSetupAquaticModel = false;
    let validSetupPlantModel = false;
    this.isValid = false;
    this.modelsToBeRun.map(model => {
      model.rowData.map((row: any) => {
        this.modelsCollection.map(model => {
          if (row[model.name]?.length > 0) this.isValid = true;
          if (row[Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase()]?.length > 0) validSetupAquaticModel = true;
          if (row[Constants.COMPARTMENTS.PLANT.toLowerCase()]?.length > 0) validSetupPlantModel = true;
        });
      });
    });

    this.areSetupModelsValidEvent.emit({isValidSetup: this.isValid, validSetupAquaticModel: validSetupAquaticModel, validSetupPlantModel: validSetupPlantModel });
  }

  ngOnDestroy(): void {
    this.selectedGeographyEffect.destroy();
  }

}

