import { Component, inject, computed, Output, EventEmitter, Input, OnInit } from '@angular/core';

import { EeaGapSurfacewaterPwcScenariosLogicService } from './eea-gap-surfacewater-pwc-scenarios.logic.service';
import { ListboxChangeEvent, ListboxClickEvent } from 'primeng/listbox';
import { CropXRegionUI, SelectedScenariosByRow } from 'src/app/shared/models/pwc/scenarios.interface';
import { ApplicationScheme } from 'src/app/shared/models/application-scheme';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'eea-gap-surfacewater-pwc-scenarios',
  templateUrl: './eea-gap-surfacewater-pwc-scenarios.component.html',
  styleUrls: ['./eea-gap-surfacewater-pwc-scenarios.component.css']
})
export class EeaGapSurfacewaterPwcScenariosComponent implements OnInit {

  @Input() row?: ApplicationScheme;
  @Input({required: true}) isProjectOwner! : boolean;
  @Output() onSelectScenarios = new EventEmitter<SelectedScenariosByRow>;

  public visible = computed( ()=> this.logicService.visible() );
  public listboxHeight:      number = 425;
  public crops:              string[] = [];
  public regions:            CropXRegionUI[] = [];
  public selectedCrop?:      string;
  public selectedRegions?:   number[] = [];
  public selectedScenarios:  CropXRegionUI[] = [];
  public regionAllSelected:  boolean = false;
  public regionCheckLabel:   'Select All' | 'Unselect All' = 'Select All';

  private logicService = inject(EeaGapSurfacewaterPwcScenariosLogicService);

  public ngOnInit(): void {
    if( !this.row ) return;
    this.selectedRegions = this.row.cropXRegionPks;
    this.selectedScenarios = this.logicService.cropXRegion.filter(c => this.selectedRegions?.includes(c.crop_x_region_pk));
    this.orderScenarios();
  }

  public onShow(): void {
    this.crops = this.logicService.crops;
  }

  public onClose(): void {
    const selectedScenariosIds: SelectedScenariosByRow = {
      rowId: this.logicService.currentRowId()!,
      selectedScenariosIds: this.selectedScenarios.map(s => s.crop_x_region_pk)
    }

    this.onSelectScenarios.emit( selectedScenariosIds );

    this.logicService.visible.set( false );
    this.logicService.currentRowId.set( undefined );
    this.selectedCrop = undefined;
    this.selectedRegions = [];
    this.selectedScenarios = [];
    this.row = undefined;
  }

  public onCropChange(event: ListboxChangeEvent): void {
    const {value: crop} = event;
    this.regions = this.logicService.getRegionsByCrop(crop);
    this.setRegionsAllSelected(crop);
  }

  public onClickRegion(event: ListboxClickEvent): void {
    const option: CropXRegionUI = event.option;
    if(this.selectedScenarios.some(s => s.crop_x_region_pk === option.crop_x_region_pk))
      this.selectedScenarios = this.selectedScenarios.filter(s => s.crop_x_region_pk !== option.crop_x_region_pk);
    else
      this.selectedScenarios.push(option);

    this.orderScenarios();
  }

  public removeScenario(crop_x_region_pk: number): void {
    this.selectedScenarios = this.selectedScenarios.filter(s => s.crop_x_region_pk !== crop_x_region_pk);
    this.selectedRegions = this.selectedRegions?.filter(r => r !== crop_x_region_pk);
  }

  public onRegionAllChange(event: CheckboxChangeEvent): void {
    if (event.checked) {
      this.regions?.forEach(r => {
        if (!this.selectedScenarios.some(s => s.crop_x_region_pk === r.crop_x_region_pk)) {
          this.selectedScenarios.push(r);
          this.selectedRegions?.push(r.crop_x_region_pk);
          this.setRegionsAllSelected(r.crop);
        }
      });
    } else {
      this.selectedScenarios = this.selectedScenarios.filter(s => !this.regions?.some(r => r.crop_x_region_pk === s.crop_x_region_pk));
      this.selectedRegions = this.selectedScenarios.map(s => s.crop_x_region_pk);

      if(this.selectedCrop)
        this.setRegionsAllSelected(this.selectedCrop);
    }

    this.orderScenarios();

    if(this.selectedRegions)
      this.selectedRegions = [...this.selectedRegions];
  }

  private setRegionsAllSelected(crop: string): void {
    const selectedRegionsLength = this.selectedScenarios.filter(s => s.crop === crop).length;
    this.regionAllSelected = this.regions.length === selectedRegionsLength;

    if( this.regionAllSelected )
      this.regionCheckLabel = 'Unselect All';
    else
      this.regionCheckLabel = 'Select All';
  }

  private orderScenarios(): void {
    this.selectedScenarios = [...this.selectedScenarios.sort((a, b) => {
      if (a.fullName < b.fullName) {
        return -1;
    }
    if (a.fullName > b.fullName) {
        return 1;
    }
    return 0;
    })];
  }

}
