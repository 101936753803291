import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  @Input() icon: string = "";
  @Input() style: string = "";
  @Input() label: string = "";
  @Input() severity: string = "";
  @Input() badge: string = "";
  @Input() badgeClass: string = "";
  @Input() iconPos: any = "";
  @Input() size: 'small'| 'large' | undefined;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false; 
  @Input() raised: boolean = false;
  @Input() rounded: boolean = false;
  @Input() text: boolean = false;
  @Input() outlined: boolean = false;
  @Input() plain: boolean = false;



  @Output() onClickEvent = new EventEmitter<MouseEvent>();
  @Output() onFocusEvent = new EventEmitter<FocusEvent>();
  @Output() onBlurEvent = new EventEmitter<FocusEvent>();

  onClick(event: MouseEvent){
    this.onClickEvent.emit(event);
  }

  onFocus(event: FocusEvent)
  {
    this.onFocusEvent.emit(event);
  }

  onBlur(event: FocusEvent)
  {
    this.onBlurEvent.emit(event);
  }
}
