import { Component } from '@angular/core';

@Component({
  selector: 'app-listbox',
  templateUrl: './listbox.component.html',
  styleUrls: ['./listbox.component.css']
})
export class ListboxAdminRenderer {
  params: any;
  parent: any;
  selectedValue: any;
  options: any;
  optionValue: any;
  optionLabel: any;
  checkbox: boolean = true;
  filter: boolean = true;
  multiple: boolean = true;
  showToggleAll: boolean = true;
  listStyle: object = {'height':'120px'};
  style: object = {'width':'28rem'};
  editable: boolean = true;

  constructor() {}

  refresh(): boolean {
    return false;
  }


  agInit(params: any): void {
    this.params = params;
    this.editable = this.params.editable ?? this.editable;
    this.parent = params.context.componentParent;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel;
    this.checkbox = params.checkbox != undefined ? params.checkbox : this.checkbox;
    this.showToggleAll = params.showToggleAll != undefined ? params.showToggleAll : this.showToggleAll;
    this.filter = params.filter != undefined ? params.filter : this.filter;
    this.multiple = params.multiple != undefined ? params.multiple : this.multiple;
    this.listStyle = params.listStyle != undefined ? params.listStyle : this.listStyle;
    this.style = params.style != undefined ? params.style : this.style;
    this.selectedValue = this.setDefaultValue(params);
    this.options = params.options;
    this.defaultValueProcess()
  }
  
  defaultValueProcess()
  {
    if (this.params.data.newRow === true)
    {
       var index = this.defaultValueIndex()
       if (index !== -1)
       {
         this.selectedValue = [];
         this.selectedValue.push(this.options[index])
         this.onChange();
       }
    }
  }
  
  defaultValueIndex()
  {
    return this.options.findIndex((x: any) => {
      if (x.isDefault && x.isDefault === true) {
        return true;
      }
      return false;
    });
  }
  
  setDefaultValue(params: any): any {
    if (params.value !== undefined)      
      return params.options.filter((x: any) => params.value.includes(x[params.isPlainArray ? params.optionValue: params.optionLabel] ));
    return params.value;
  }
  
  onChange() {
    this.parent.setSelectionValues(
      this.selectedValue,
      this.params.columnThatModifies,
      this.params.data,
      this.params.node.id
    );
  }
 
}
