import { Component } from '@angular/core';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-eea-endpoints-formation-fraction-ddl-renderer',
  templateUrl: '../../../../../shared/renderers/dropdown-list/dropdown-list.component.html',
  styleUrls: ['../../../../../shared/renderers/dropdown-list/dropdown-list.component.css']
})
export class EeaEndpointsFormationFractionDdlRendererComponent extends DropdownListRenderer {
  constructor(orderByPipe: OrderByPipe) {
    super(orderByPipe)
  }

  override agInit(params: any): void {
    super.agInit(params);
    this.addSelectedOptionIfNotInOptions(params);
  }

  addSelectedOptionIfNotInOptions(params:any)
  {
    if (params.value && params.optionValue == 'compoundPk' && !this.options.find((x:any) => x.compoundPk == params.value))
    {
      this.options.push({ compoundName: params.data.substanceName, compoundPk: params.value, compoundType: Constants.SUBSTANCE_TYPES.METABOLITE })
      this.optionsToRemove?.push(params.value);         
    }
  }
}
