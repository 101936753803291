import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomDatafieldGroup } from '../models/custom-datafield-group';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomDatafieldGroupApiService {
  constructor(private http: HttpClient) {}

  Get(query: string) {
    return this.http.get(environment.ECHO_Api_URL + query, {});
  }
  
  GetCustomDatafieldGroupByDisciplineName(
    pDiscipline: string
  ): Observable<CustomDatafieldGroup[]> {
    return this.Get(
      `api/customDatafieldGroupByDisciplineName/${pDiscipline}`
    ).pipe(map((data: any) => data.Value));
  }
  
  GetAllDatafieldValuesByDiscipline( pDiscipline: number): Observable<any> {
      return this.Get(
        `api/customDatafieldGroup/GetAllDatafieldValuesByDiscipline/${pDiscipline}`
      ).pipe(map((data: any) => data.Value));
    }
}
