import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import Swal from 'sweetalert2';
import { ModelLibraryService } from './model-library.service';
import { Constants } from 'src/app/shared/utils/constants';
import { AdminGridComponent } from '../../admin-grid/admin-grid.component';
import { SelectionValues } from 'src/app/shared/models/selectionValues';
import { RoleApiService } from 'src/app/shared/services/role.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-model-library-grid',
  templateUrl: './../../admin-grid/admin-grid.component.html',
})

export class ModelLibraryGridComponent extends AdminGridComponent implements OnInit {    
  tierList:DatafieldValue[] = [];
  purposesList:DatafieldValue[] = [];
  softwareshellList:DatafieldValue[] = [];
  compartmentList:DatafieldValue[] = [];
  exposureTypeList:DatafieldValue[] = [];
  geographyList:DatafieldValue[] = [];
  statusList:DatafieldValue[] = [];
  @Input() dataComponent: any;

  dataToSave: any;
  invalidColumns: string[] = [];
  requiredColumns = [
    {
      field: 'name',
      headerName: 'Model Name',
    },
  ];

  constructor(
    private modelLibraryService: ModelLibraryService,
    private userService: UserLogicService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.rowHeight = 50;
    this.context = { componentParent: this };
    this.batchUpload = true;
  }

  override onGridReady(params: { api: { showLoadingOverlay: () => void; }; columnApi: any; }): void {
    params.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.rowData = this.dataComponent.models;
    this.configureGrid();
  }

  setGeographyStringValues()
  {
    this.rowData.forEach((row: { geographyXModelNames: any[]; geographyXModel: any[]; }) => {
      row.geographyXModelNames = [];
      row.geographyXModel.forEach(element => {
        if (element.regionPk)
          row.geographyXModelNames.push(this.dataComponent.geographies.find((x: { key: any; source: string; }) => x.key == element.regionPk && x.source == "Region")?.name)
        else
          row.geographyXModelNames.push(this.dataComponent.geographies.find((x: { key: any; source: string; }) => x.key == element.countryPk && x.source == "Country")?.name)
      });
    });
  }
   
  configureGrid(): void {   
    this.dataComponent.setLoadingState(false);
    this.setGeographyStringValues();
    this.getStringsForFilteringPurposes();
    this.rowSelection = 'single';
    this.columnDefs = [
      {
        headerName: 'Model Purpose',
        field: 'purposeName',
        minWidth: 250,
        type: 'select',
        filter: 'agSetColumnFilter',
        cellRenderer: 'dropdownlistRenderer',
        editable: false,
        cellRendererParams: {
          options: this.dataComponent.datafieldValues.filter((x: { datafieldPk: any; }) => x.datafieldPk == this.dataComponent.datafields.filter((df: { value: string; }) => df.value == "Model Purpose")[0].datafieldPk),
          optionValue: 'value',
          optionLabel:'value',
          columnThatModifies:'purposePk',      
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },
      {
        headerName: 'Model Name',
        field: 'name',
        minWidth: 200,
        type: 'default',
        filter: true,
        editable: this.currentUserCanEdit,
      },
      {
        headerName: 'Model Version',
        field: 'version',
        minWidth: 200,
        type: 'default',
        filter: true,
        editable: this.currentUserCanEdit,
      },
      {
        headerName: 'Release Date',
        field: 'releaseDate',
        minWidth: 250,
        type: 'select',
        filter: true,
        editable: false,
        cellRenderer: 'calendarRenderer',
        cellRendererParams: {                 
          columnThatModifies:'releaseDate',
          editable: this.currentUserCanEdit,  
        },        
      },
      {
        headerName: 'Software Shell',    
        field: 'softwareShellName',
        minWidth: 250,
        filter: 'agSetColumnFilter',        
        type: 'select',              
        cellRenderer: 'dropdownlistRenderer',
        editable: false,
        cellRendererParams: {
          options: this.dataComponent.datafieldValues.filter((x: { datafieldPk: any; }) => x.datafieldPk == this.dataComponent.datafields.filter((df: { value: string; }) => df.value == "Software Shell")[0].datafieldPk),
          optionValue: 'value',
          optionLabel:'value',
          columnThatModifies:'softwareShellPk',        
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },
      {
        headerName: 'Applicable Compartment',
        field: 'applicableCompartmentNames',
        filter: 'agSetColumnFilter',        
        minWidth: 250,
        type: 'select',
        editable: false,
        cellRenderer: 'multiselectionRenderer',
        cellRendererParams: {
          options: this.dataComponent.datafieldValues.filter((x: { datafieldPk: any; }) => x.datafieldPk == this.dataComponent.datafields.filter((df: { value: string; }) => df.value == "Compartment")[0].datafieldPk),
          optionValue: 'value',
          optionLabel:'value',
          columnThatModifies:'compartmentXModel',         
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },  
      {
        headerName: 'Exposure type',
        field: 'exposureTypeNames',
        filter: 'agSetColumnFilter',        
        minWidth: 250,
        type: 'select',
        editable: false,
        cellRenderer: 'multiselectionRenderer',
        cellRendererParams: {
          options: this.dataComponent.datafieldValues.filter((x: { datafieldPk: any; }) => x.datafieldPk == this.dataComponent.datafields.filter((df: { value: string; }) => df.value == "Exposure Type")[0].datafieldPk),
          optionValue: 'value',
          optionLabel:'value',
          columnThatModifies:'exposureTypeXModel',           
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },  
      {
        headerName: 'Applicable Tier',
        field: 'tierNames',
        minWidth: 250,
        type: 'select',
        filter: 'agSetColumnFilter',
        editable: false,
        cellRenderer: 'multiselectionRenderer',
        cellRendererParams: {
          options: this.dataComponent.datafieldValues.filter((x: { datafieldPk: any; }) => x.datafieldPk == this.dataComponent.datafields.filter((df: { value: string; }) => df.value == "Tier")[0].datafieldPk),
          optionValue: 'value',
          optionLabel:'value',
          columnThatModifies:'tierXModel',              
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },  
      {
        headerName: 'Applicable Geography',
        field: 'geographyXModelNames',
        minWidth: 250,
        type: 'select',
        filter: 'agSetColumnFilter',
        editable: false,
        cellRenderer: 'multiselectionRenderer',
        cellRendererParams: {
          options: this.dataComponent.geographies, 
          optionValue: 'name',
          optionLabel:'name',
          columnThatModifies:'geographyXModel',          
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },    
      {
        headerName: 'Status',
        field: 'statusNames',
        minWidth: 250,
        type: 'select',
        editable: false,
        filter: 'agSetColumnFilter',
        cellRenderer: 'dropdownlistRenderer',
        cellRendererParams: {
          options: this.dataComponent.datafieldValues.filter((x: { datafieldPk: any; }) => x.datafieldPk == this.dataComponent.datafields.filter((df: { value: string; }) => df.value == "Model Status")[0].datafieldPk),
          optionValue: 'value',
          optionLabel:'value',
          columnThatModifies:'statusPk',                   
          suggest: true,
          editable: this.currentUserCanEdit,
        },
      },                                                      
      {
        colId: 'action',
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        type: 'rightAligned',
        cellRendererParams: {
          editable: this.currentUserCanEdit,
        }
      },
    ];

  }
  
  getStringsForFilteringPurposes() {
    this.rowData.forEach((row : any) => {
      row.purposeName = this.dataComponent.datafieldValues.filter((x: any) => x.datafieldValuePk == row.purposePk).map((x: any) => x.value)[0];      
      row.softwareShellName = this.dataComponent.datafieldValues.filter((x: any) => x.datafieldValuePk == row.softwareShellPk).map((x: any) => x.value)[0];      
      row.applicableCompartmentNames = this.dataComponent.datafieldValues.filter((x: any) => row.compartmentXModel.includes(x.datafieldValuePk)).map((x: any) => x.value);
      row.exposureTypeNames = this.dataComponent.datafieldValues.filter((x: any) => row.exposureTypeXModel.includes(x.datafieldValuePk)).map((x: any) => x.value);
      row.tierNames = this.dataComponent.datafieldValues.filter((x: any) => row.tierXModel.includes(x.datafieldValuePk)).map((x: any) => x.value);
      row.statusNames = this.dataComponent.datafieldValues.filter((x: any) => x.datafieldValuePk == row.statusPk).map((x: any) => x.value)[0]; 
    });
  }
    
  setDataIDsForSavingPurposes(pData: any)
  {
    this.setPurposeIDS(pData);
    this.setSoftwareShellIDS(pData);
    this.setApplicableCompartmentIDS(pData);
    this.setExposureTypesIDS(pData);
    this.setTierIDS(pData);
    this.setStatusIDS(pData);
  }
     
  setPurposeIDS(pData: any){
    pData.forEach((rowTransaction: any, transactionIndex: any) => {
      if (typeof rowTransaction.row.purposePk === "string")        
          pData[transactionIndex].row.purposePk = this.dataComponent.datafieldValues.filter((x: any) => x.value === rowTransaction.row.purposePk).map((x: any) => x.datafieldValuePk)[0];  
    });
  }
   
  setSoftwareShellIDS(pData: any){
    pData.forEach((rowTransaction: any, transactionIndex: any) => {
      if (typeof rowTransaction.row.softwareShellPk === "string")        
          pData[transactionIndex].row.softwareShellPk = this.dataComponent.datafieldValues.filter((x: any) => x.value === rowTransaction.row.softwareShellPk).map((x: any) => x.datafieldValuePk)[0];  
    });
  }
  
  setApplicableCompartmentIDS(pData: any){
    pData.forEach((rowTransaction: any, transactionIndex: any) => {
      if (rowTransaction.row.compartmentXModel?.find((x: any) => typeof x === "string"))
        rowTransaction.row.compartmentXModel.forEach((element: any, elementIndex: any) => {
          element = this.dataComponent.datafieldValues.filter((x: any) => x.value === element).map((x: any) => x.datafieldValuePk)[0];
          pData[transactionIndex].row.compartmentXModel[elementIndex] = element;
      });
    });
  }  
   
  setExposureTypesIDS(pData: any){
    pData.forEach((rowTransaction: any, transactionIndex: any) => {      
      if (rowTransaction.row.exposureTypeXModel?.find((x: any) => typeof x === "string"))
        rowTransaction.row.exposureTypeXModel.forEach((element: any, elementIndex: any) => {
          element = this.dataComponent.datafieldValues.filter((x: any) => x.value === element).map((x: any) => x.datafieldValuePk)[0];
          pData[transactionIndex].row.exposureTypeXModel[elementIndex] = element;
      });
    });
  }
      
  setTierIDS(pData: any){
    pData.forEach((rowTransaction: any, transactionIndex: any) => {
      if (rowTransaction.row.tierXModel?.find((x: any) => typeof x === "string"))
        rowTransaction.row.tierXModel.forEach((element: any, elementIndex: any) => {
          element = this.dataComponent.datafieldValues.filter((x: any) => x.value === element).map((x: any) => x.datafieldValuePk)[0];
          pData[transactionIndex].row.tierXModel[elementIndex] = element;
      });
    });
  }  
  
  setStatusIDS(pData: any){
    pData.forEach((rowTransaction: any, transactionIndex: any) => {
      if (typeof rowTransaction.row.statusPk === "string")        
          pData[transactionIndex].row.statusPk = this.dataComponent.datafieldValues.filter((x: any) => x.value === rowTransaction.row.statusPk).map((x: any) => x.datafieldValuePk)[0];  
    });
  }
    
  override saveData(pData: any): Observable<any> {
    this.dataToSave = pData;    
    if (this.dataRequiredColumnsValidation()) {
      this.setDataIDsForSavingPurposes(pData);
      this.dataComponent.setLoadingState(true);
      var discipline = this.dataComponent.selectedDiscipline;
      pData.forEach(function (region: any, index: any) {
        pData[index].row.disciplineXModel = [];
        pData[index].row.disciplineXModel.push(discipline)
      });
      this.modelLibraryService.saveData(pData).subscribe(
        (res: any) => {
        this.afterSaveSuccess();
        this.getData();
        },
        (error) => {
          this.afterSaveError(error);
          this.getData();
        });
      return pData;
    } 
    else{
      this.isSaving = false;
      Swal.fire({
        title: Constants.requiredColumnsElement.title,
        text: Constants.requiredColumnsElement.content + this.invalidColumns.join(', '),
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Ok',
        icon: 'warning',
      });
      return pData;
    }
  }
  
  dataRequiredColumnsValidation(): boolean {
    let isValid = true;
    this.invalidColumns = [];
    this.dataToSave.forEach((transaction: any) => {
      this.requiredColumns.forEach((column) => {
        if (!transaction.row[column.field]){
          isValid = false;
          if (!this.invalidColumns.includes(column.headerName)) {
            this.invalidColumns.push(column.headerName);
          }
        }
      });
    });
    return isValid;
  }
  
  override getData(): void {
    this.dataComponent.getData();
  }
  
  public setSelectionValues(selectionObject: SelectionValues): void {
    const oldvalue = selectionObject.row[selectionObject.field];    
    if (selectionObject.sourceValues?.length > 0 && selectionObject.sourceValues[0].source)
    {
      this.setValuesForGeography(selectionObject)      
    }
    else
      selectionObject.row[selectionObject.field] = selectionObject.value;
    this.CreateTransaction(selectionObject.row.id, selectionObject.id, oldvalue, selectionObject.row);
  }
  
  setValuesForGeography(selectionObject:SelectionValues)
  {
    var selectedGeographies = selectionObject.sourceValues.filter(x => selectionObject.value.includes(x.name));
    if (selectedGeographies.length > 0)
    {
      selectionObject.row.geographyXModel = [];
      selectedGeographies.forEach(element => {
        if (element.source == "Country")
          selectionObject.row.geographyXModel.push({countryPk:element.key})
        else
          selectionObject.row.geographyXModel.push({regionPk:element.key})
      });
    }
  }

}
