import { ChangeDetectorRef, Component, DestroyRef, OnDestroy, OnInit, effect, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InverseModelingEEALogicService } from './inverse-modeling-environmental-exposure-assesment.logic.service';
import { Message } from 'primeng/api';
import { Project } from 'src/app/shared/models/project';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Environmental_Assessments, UserLogicService } from 'src/app/shared/services/user.logic.service';
import { ModelLibrary, ModelLibraryCollection } from 'src/app/shared/models/model-library';
import { firstValueFrom, take } from 'rxjs';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { ModelLibraryApiService } from 'src/app/shared/services/model-library.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { InverseModelingModelsColDef } from '../inverse-modeling-setup/inverse-modeling-models-to-be-run-coldef';
import { SetupLogicService } from 'src/app/shared/services/setup.logic.service';

@Component({
  selector: 'app-inverse-modeling-environmental-exposure-assesment',
  templateUrl: './inverse-modeling-environmental-exposure-assesment.component.html',
  styleUrls: ['./inverse-modeling-environmental-exposure-assesment.component.css']
})
export class InverseModelingEnvironmentalExposureAssesmentComponent implements OnInit, OnDestroy {

  activeItem!: any;
  message!: Message;
  loading: boolean = false;
  nextIndex?: number;
  destroyRef = inject(DestroyRef)
  selectedProject?: Project;
  isSetupValid: boolean = false;
  isInverseModule = true;
  isProjectOwnershipValid: boolean = true;
  environmentalAssessment: string = Constants.ENVIRONMENTAL_ASSESSMENTS.INVERSE_MODELING_ENVIRONMENTAL_EXPOSURE_ASSESSMENT;
  compartments: string[] = [Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase().replace(" ", ""), Constants.COMPARTMENTS.AQUATIC.toLowerCase().replace(" ", "")];
  columnDef: any[] = [];
  disciplinePk: number = 0;
  modelsCollection: ModelLibraryCollection[] = [];
  compartmentList: any[] = [];
  disciplineAguaticTerrestrial: number = 0;
  module = Environmental_Assessments.IMEEA;

  private setupLogicService = inject(SetupLogicService);

  constructor(public inverseModelingEEALogicService: InverseModelingEEALogicService,
    private selectedProjectApiService: SelectedProjectApiService,
    private userLogicService: UserLogicService,
    private orderByPipe: OrderByPipe,
    private modelLibraryApiService: ModelLibraryApiService,
    private inverseModelingModelsColDef: InverseModelingModelsColDef,
    private disciplineApiService: DisciplineApiService,
    private dataSetService: DataSetApiService,
    private cdr: ChangeDetectorRef) { }

  selectedGeographyEffect = effect(() => {
    if(this.setupLogicService.selectedGeographyKey())
      this.initColumnDef();
  });

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.initCompartemtList();
    await this.getDiscipline();
    await this.getDisciplineAquaticTerretrial();
    await this.initModels();
    this.initSubscribes();
  }

  async initCompartemtList(): Promise<void> {
    await firstValueFrom(this.dataSetService.getCompartments(Constants.CONST_COMPARTMENT).pipe(take(1))).then(((compartment: any) => {
      this.compartmentList = compartment;

    }));
  }

  async getDiscipline(): Promise<void> {
    await firstValueFrom(this.disciplineApiService.getDisciplineByName(Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE).pipe(take(1))).then((discipline: Discipline) => {
      this.disciplinePk = discipline?.disciplinePk ?? -1;
    });
  }

  async getDisciplineAquaticTerretrial(): Promise<void> {
    await firstValueFrom(this.disciplineApiService.getDisciplineInformation().pipe(take(1))).then((disciplines) => {
      this.disciplineAguaticTerrestrial = disciplines.find(x => x.name === Constants.DISCIPLINE_IDENTITIES.ECOTOXICOLOGY)?.disciplinePk ?? -1;
    });
  }



  async initModels() {
    const localCollection: ModelLibraryCollection[] = this.compartments.map(compartment => { return { name: compartment, library: [] }});
    let models: ModelLibrary[] = [];
    let modelsAguaticTerrestrial: ModelLibrary[] = [];

    const swModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.replace(" ", "").toLowerCase());
    const aquaticModels = localCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.AQUATIC.toLowerCase());

    const aquatic = this.compartmentList?.filter((x: { compartment: string; }) => x.compartment === Constants.COMPARTMENTS.SURFACE_WATER);
    const soil = this.compartmentList?.filter((x: { compartment: string; }) => x.compartment === Constants.COMPARTMENTS.SOIL);
    const plant = this.compartmentList?.filter((x: { compartment: string; }) => x.compartment === Constants.ECOTOXICOLOGY_COMPARMENTS.PLANT);
    const terrestrial = [soil[0]?.endpointCompartmentPk, plant[0]?.endpointCompartmentPk];

    await firstValueFrom(this.modelLibraryApiService.getModels()).then((library: ModelLibrary[]) => {
      modelsAguaticTerrestrial = library.filter(f => f.compartmentXModel?.length && f.compartmentXModel?.length >= 0 && f.disciplineXModel?.some(d => d == this.disciplineAguaticTerrestrial));
      models = library.filter(f => f.compartmentXModel?.length && f.compartmentXModel?.length >= 0 && f.disciplineXModel?.some(d => d == this.disciplinePk));
      if (swModels) swModels.library = this.orderByPipe.transform(models.filter(m => m.compartmentXModel?.includes(this.compartmentList?.find(x => x.compartment.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.toLowerCase())?.endpointCompartmentPk)), 'nameVersion');
      if (aquaticModels) aquaticModels.library = this.orderByPipe.transform(modelsAguaticTerrestrial.filter(m => m.compartmentXModel?.includes(aquatic[0]?.endpointCompartmentPk)), 'nameVersion');
    });
    this.modelsCollection = localCollection;
  }

  initColumnDef() {
    const selectedGeography = this.setupLogicService.selectedGeographyKey();
    const swModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase())?.library.filter(m => m.geographyXModel?.some(g => this.setupLogicService.isSelectedGeography(g, selectedGeography)));;
    const aquaticModels = this.modelsCollection.find(x => x.name.toLowerCase() === Constants.COMPARTMENTS.AQUATIC.toLowerCase())?.library.filter(m => m.geographyXModel?.some(g => this.setupLogicService.isSelectedGeography(g, selectedGeography)));;
    this.columnDef = this.inverseModelingModelsColDef.getColumnsDefinition(swModels ?? [], aquaticModels ?? [], this.isProjectOwnershipValid);
  }

  initSubscribes() {
    this.selectedProjectApiService.selectedProject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((project?: Project) => {
      this.selectedProject = project;
      this.isProjectOwnershipValid = this.selectedProject ? this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy) : true;
      this.onActiveIndexChange(0);
    });

    this.inverseModelingEEALogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => this.activeItem = activeItem);

    this.inverseModelingEEALogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loading = false;
      if (success) {
        const nextItem = this.inverseModelingEEALogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem)
          this.inverseModelingEEALogicService.setActiveItem(nextItem);
      }
    });
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.inverseModelingEEALogicService.setActiveIndexChanged(true, index);
    if (index == Constants.TAB_INDEXIMEEA.RUN_ERA) this.loading = false;
  }

  validateSetup(event: boolean) {
    this.isSetupValid = event;
    this.cdr.detectChanges();
  }

  public getIsValidInverseModelingEndpoints(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.selectedGeographyEffect.destroy();
  }

}
