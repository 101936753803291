<div class="content" style="display: inline-flex;">
    <div class="col">
        <div class="row">
            <span class="filter-label">Discipline:</span>
        </div>
        <div class="row">
            <div>
                <p-dropdown styleClass="discipline-filter-dropdown" styleClass="ddlFilterSelection" panelStyleClass="panel-filter-dropdown" placeholder="Environmetal Fate" [disabled]="disciplines.length == 0" [options]="disciplines" [(ngModel)]="selectedDiscipline" optionLabel="name"
                    optionValue="disciplinePk" [virtualScroll]="true" [virtualScrollItemSize]="30" (onChange)="disciplineSelectionChange()">
                </p-dropdown>
            </div>
        </div>
    </div>
</div>