import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';
import { ChildLevel } from './eea-endpoints-parameters-pwc.inteface';

@Injectable({
  providedIn: 'root'
})
export class EeaEndpointsParametersPwcApiService extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  getChildLevelByDataset(datasetpk: number): Observable<ChildLevel[]> {
    let endpoint = `api/getChildLevelByDataset?datasetPk=${datasetpk}`;
    return this.get(endpoint)
                .pipe(
                  map((data: any) => data.Value)
                );
  }

}
