import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { User } from '../models/user.model';
import { Profile } from '../models/profile';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { RoleApiService } from './role.api.service';
import { Constants } from '../utils/constants';
import { Router } from '@angular/router';

export enum Environmental_Assessments {
  ERA = 'ERA',
  HHRA = 'HHRA',
  EEA = 'EEA',
  IMEEA= 'IMEEA'
}

@Injectable({
  providedIn: 'root'
})
export class UserLogicService {

  profile: Profile = {};
  userRoles: Role[] = [];
  loading: boolean = true;
  private readonly _destroying$ = new Subject<void>();
  private selectedRoleSubject = new BehaviorSubject<Role | undefined>(undefined);
  selectedRole$ = this.selectedRoleSubject.asObservable();
  selectedRole?: Role;
  tokenUserRoles: string[] | undefined = [];
  

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private rolesApiService: RoleApiService,
              private http: HttpClient,
              private router: Router) {
  }

  async initProfile()
  {
    this.loading = true;
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (!this.authService.instance.getActiveAccount() && this.authService.instance.getAllAccounts().length > 0) {
          this.authService.instance.setActiveAccount(result.account);
        }
      },
      error: (error) => console.log(error)
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(async () => {
        await this.getProfile();
        this.checkAndSetActiveAccount();
        this.setUserRoles();
        this.loading = false;
      });
  }

  setUserRoles(): void {
    try {
      if(this.currentUserHasRole(Constants.APP_ROLES.ADMIN)) {
        this.userRoles.push(Constants.USER_ROLES.ADMIN);
      }
      if(this.currentUserHasRole(Constants.APP_ROLES.EDITOR)) {
        this.userRoles.push(Constants.USER_ROLES.EDITOR);
      }
      if(this.currentUserHasRole(Constants.APP_ROLES.READER)) {
        this.userRoles.push(Constants.USER_ROLES.READER);
      }      
      this.userRoles.push(Constants.USER_ROLES.LOGOUT); //Default
      this.selectedRole = this.userRoles.find((x) => x.bitmask == 1)
        ? this.userRoles.filter((x) => x.bitmask == 1)[0]
        : this.userRoles.find((c) => c.bitmask == 2)
        ? this.userRoles.filter((x) => x.bitmask == 2)[0]
        : Constants.USER_ROLES.READER;
      this.rolesApiService.userRolesBitmask = this.userRoles.find(
        (x) => x.bitmask == 1
      )
        ? 1
        : this.userRoles.find((c) => c.bitmask == 2)
        ? 2
        : Constants.USER_ROLES.READER.bitmask;
      this.selectedRoleSubject.next(this.selectedRole);
      this.rolesApiService.systemRoles = this.userRoles;
      this.rolesApiService.userRolesBitmask = this.selectedRole?.bitmask;
    }
    catch (e) {
      console.error(e);
    }
  }

  async getProfile() {
    try {
      const profile: Profile = await firstValueFrom(this.http.get('https://graph.microsoft.com/v1.0/me'));
      this.profile = profile;
    } catch (e) {
      console.error(e);
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    this.tokenUserRoles = activeAccount?.idTokenClaims?.roles;

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.tokenUserRoles = accounts[0]?.idTokenClaims?.roles;
      this.authService.instance.setActiveAccount(accounts[0]);      
    }
  }
  
  GetAll() : Observable<User[]>
	{
    const query = environment.BAMS_Api_URL + 'api/users';
    return this.http.get(query)
      .pipe(map((data: any) => data.value));
  }

  GetEchoUsers(): Observable<any[]>
  {
    const query = environment.ECHO_Api_URL + "api/users";
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));
  }

  GetByID(id: number) : Observable<User>
	{
    const query = environment.BAMS_Api_URL + 'api/user/' + id;
    return this.http.get(query)
    .pipe(map((response: any) => response.value as User));
  }

  GetByNickName(nickName: string) : Observable<any>
	{
    return this.http.get("https://graph.microsoft.com/v1.0/users?$filter=mailNickname eq '" + nickName + "'");
  }
  
  roleSelectionChange(event: DropdownChangeEvent): void {
    if(this.selectedRole?.role === Constants.ROLE_LOGOUT)
    {
      this.logout();
      return;
    }
    this.rolesApiService.userRolesBitmask = this.selectedRole!.bitmask;
    this.selectedRoleSubject.next(this.selectedRole);
  }
  
  logout(): void {
    this.authService.logout();
    localStorage.clear();
  }

  verifyProjectOwnership(createdBy?: string, module?: Environmental_Assessments): boolean {
    const ownership = (this.profile.displayName === createdBy && this.currentUserCanRunModels(this.selectedRole!.role))
    || this.currentUserIsModuleEditor(module);
    return ownership;
  }

  async currentUserCanEditAdmin(): Promise<boolean> {
    return new Promise((resolve) => {
      this.selectedRole$.pipe(
        filter((role) => !!role),
        take(1)
      ).subscribe((role) => {
        const isAdmin = this.userRoles.map((r: Role) => r.role).includes(Constants.ROLE_ADMIN);
        resolve(isAdmin && (this.currentUserCanRunModels(role?.role)));
      });
    });
  }
  
  currentUserCanRunModels(role?: string, module?: Environmental_Assessments): boolean {
    const commonRoles = [Constants.ROLE_ADMIN, Constants.ROLE_EDITOR];
    
    if (module === Environmental_Assessments.ERA) {
      return [...commonRoles, Constants.ROLE_ERA_EDITOR].includes(role!);
    }
  
    if (module === Environmental_Assessments.HHRA) {
      return [...commonRoles, Constants.ROLE_HHRA_EDITOR].includes(role!);
    }
  
    return commonRoles.includes(role!);
  }

  currentUserCanRunModelsAsync(module?: Environmental_Assessments): Promise<boolean> {
    return new Promise((resolve) => {
      this.selectedRole$.pipe(
        filter((role) => !!role),
        take(1)
      ).subscribe((role) => {
        resolve((this.currentUserCanRunModels(role?.role, module)));
      });
    });
  }
  
  currentUserIsModuleEditor(module?: Environmental_Assessments): boolean {
    const moduleRoleMap: Record<Environmental_Assessments, string> = {
      [Environmental_Assessments.ERA]: Constants.ROLE_ERA_EDITOR,
      [Environmental_Assessments.HHRA]: Constants.ROLE_HHRA_EDITOR,
      [Environmental_Assessments.EEA]: 'NONE',
      [Environmental_Assessments.IMEEA]: 'NONE',
    };

    const moduleRole = module ? moduleRoleMap[module] : undefined;
    return this.selectedRole?.role === moduleRole;
}

  currentUserHasRole(role: string) {
    return this.tokenUserRoles?.includes(role);
  }
}
