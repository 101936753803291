import { Component, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements ICellRendererAngularComp {
  public params: any;
  public columnThatModifies: any;
  public parent: any;
  public selectedValue!: boolean;
  public field!: string;
  public disabled: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.columnThatModifies = params.columnThatModifies;  
    this.parent = params.context;
    this.selectedValue = params.value;
    this.field = params.colDef.field;
    this.disabled = params.disabled;
  }

  public onChange(event:any) {
    this.parent.setSelectionValues({
      field: this.columnThatModifies,
      id: this.params.node.id,
      row: this.params.data,
      value: event.currentTarget.checked
    });

    this.parent.onCheckboxSelectionChanged({
      field: this.columnThatModifies,
      id: this.params.node.id,
      row: this.params.data,
      value: event.currentTarget.checked
    });  
  }
   refresh(params: ICellRendererParams): boolean {
      return false;
  }
}