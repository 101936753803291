import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { EraEcotoxImportPopUpColDefService } from './era-ecotox-import-popup.coldef';
import { EraEcotoxImportPopupLogicService } from './era-ecotox-import-popup.logic.service';
import { EcotoxEndpointFlatValues, EraEcotoxImportPopupParameters, EraEcotoxImportPopupTabs } from './era-ecotox-import-popup.interface';
import { Project } from 'src/app/shared/models/project';
import { TabViewChangeEvent } from 'primeng/tabview';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { IRowNode } from 'ag-grid-community';
import { ImportDataSet } from 'src/app/shared/models/echo/import-data-set';

@Component({
  selector: 'app-era-ecotox-import-popup',
  templateUrl: './era-ecotox-import-popup.component.html',
  styleUrls: ['./era-ecotox-import-popup.component.css']
})
export class EraEcotoxImportPopupComponent implements OnChanges, OnInit {

  @Input() public parameters?: EraEcotoxImportPopupParameters;
  @Input() public selectedProject?: Project;

  @Output() public endpointsToImport = new EventEmitter<ImportDataSet>();

  @ViewChild('grid') grid!: GridComponent;

  public visible: boolean = false;
  public columnDefs: any = [];
  public rowData: EcotoxEndpointFlatValues[] = [];
  public tabs: EraEcotoxImportPopupTabs[] = [];
  public activeTabIndex: number = 0;
  public selectedTaxonGroup?: string;

  private allEndpoints: EcotoxEndpointFlatValues[] = [];

  constructor(
    private readonly columnDefsService: EraEcotoxImportPopUpColDefService,
    private readonly logicService: EraEcotoxImportPopupLogicService
  ) { }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.rowData.length = 0;
    this.tabs.length = 0;
    if (changes['parameters'] && this.parameters?.datasetToImport.originDataSetPk != undefined) {
      this.visible = true;
      const {datasetToImport,geography, taxonGroup} = this.parameters;
      this.allEndpoints = await this.logicService.getEcotoxEndpointFlatValues(datasetToImport.originDataSetPk,geography);
      this.getTabs();
      this.activeTabIndex = this.tabs.find(t => t.label == taxonGroup)?.index || 0;
      this.selectedTaxonGroup = this.tabs.find(t => t.index == this.activeTabIndex)!.label;
      this.rowData = this.allEndpoints.filter(row => this.selectedProject?.projectXCompoundXModel?.some(m => m.MetabolitePk == row.metabolitePk && row.taxonGroup == this.tabs.find(t => t.index == this.activeTabIndex)?.label));
    }
  }

  public ngOnInit(): void {
    this.setColumnDef();
  }

  private setColumnDef(): void{
    this.columnDefs = this.columnDefsService.getColumnsDefinition();
  }

  public onImport(): void {
    this.setSelectedValues();
    this.refreshSelectedValuesTimeout();
    const selectedRows = this.allEndpoints.filter(e => e.selected);
    const endpointsToImport: number[] =[];

    selectedRows.forEach(r =>{
      endpointsToImport.push(r.endpointPk);
    });

    this.parameters!.datasetToImport.endpointsToImport = endpointsToImport;

    this.endpointsToImport.emit(this.parameters?.datasetToImport);

    this.visible = false;
  }

  public onChangeTab(): void {
    this.rowData = this.allEndpoints.filter(row => this.selectedProject?.projectXCompoundXModel?.some(m => m.MetabolitePk == row.metabolitePk && row.taxonGroup == this.tabs.find(t => t.index == this.activeTabIndex)?.label));
    this.setSelectedValues();
    this.refreshSelectedValuesTimeout();
    this.selectedTaxonGroup = this.tabs.find(t => t.index == this.activeTabIndex)!.label;
  }

  private getTabs(): void {
    this.allEndpoints.forEach(e => {
      if(!this.tabs.some(t => t.label == e.taxonGroup))
        this.tabs.push({index: this.tabs.length, label: e.taxonGroup});
    });
  }

  private refreshSelectedValues(): void {
    const selected: IRowNode[] = [];
    const deselected: IRowNode[] = [];
    this.grid.gridApi.forEachNode((node: any) => {
      if(node.data?.selected)
        selected.push(node);
       });
    this.grid.gridApi.setNodesSelected({ nodes: selected, newValue: true });
    this.grid.gridApi.setNodesSelected({ nodes: deselected, newValue: false });
  }

  private setSelectedValues(): void {

    this.allEndpoints.forEach(e => {
      if(e.taxonGroup == this.selectedTaxonGroup)
        e.selected = false;
    });

    this.grid.gridApi.forEachNode((node: any) => {
      if(node.selected)
        this.allEndpoints.find(e => e.endpointPk == node.data.endpointPk)!.selected = true;
    });
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

}
