import { Injectable, signal } from '@angular/core';
import { Catalog } from '../models/echo/catalog';
import { Geography } from '../models/echo/geography';

@Injectable({
  providedIn: 'root'
})
export class SetupLogicService {

  public selectedGeographyKey = signal<Catalog | undefined>(undefined);

  public isSelectedGeography (geography: Geography, selectedGeography: Catalog | undefined): boolean {
    return (geography.countryPk == selectedGeography?.key && selectedGeography?.source === 'Country') ||
           (geography.regionPk == selectedGeography?.key && selectedGeography?.source === 'Region');
  };

}
