import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdvancedFilterService } from 'src/app/shared/services/advanced-filter.service';
import { BehaviorSubject, map } from 'rxjs';
import { StopButtonComponent } from '../custom-component/stop-button/stop-button.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent {
  stopEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  visible: boolean = true;
  statusList: any[] = [];
  rowHeight: number = 45;
  pagination: boolean = true;
  columnDefs: any;
  rowData: any[] | null | undefined;
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi: any;
  gridColumnApi: any;

  constructor(
    private http: HttpClient
  ) {
    this.GetColumnDef();
    this.loadList();
  }

  private GetColumnDef(): void {
    this.columnDefs = [
      {
        headerName: 'PK',
        wrapHeaderText: true,
        editable: false,
        resizable: true,
        sortable: true,
        field: 'projectPk',
        width: 80,
        filter: true,
        pinned: 'left'

      },
      {
        headerName: 'Project Name',
        wrapHeaderText: true,
        editable: false,
        resizable: true,
        sortable: true,
        field: 'name',
        width: 320,
        filter: true,
        pinned: 'left',
        wrapText: true
      },
      {
        headerName: 'Created by',
        editable: false,
        wrapHeaderText: true,
        resizable: true,
        sortable: true,
        field: 'createdBy',
        width: 200,
        filter: true,
      },
      {
        headerName: 'Started date',
        editable: false,
        resizable: true,
        sortable: true,
        field: 'startedDate',
        width: 180,
        filter: true,
      },
      {
        headerName: 'Model',
        editable: false,
        resizable: true,
        sortable: true,
        field: 'modelList',
        width: 500,
        filter: false,
        wrapText: true
      },
      {
        headerName: 'Runs',
        editable: false,
        resizable: true,
        sortable: true,
        field: 'amountRuns',
        width: 80,
        filter: true,
      },
      {
        headerName: '',
        width: 150,
        cellRenderer: StopButtonComponent
      },
    ];
  }

  StopClick(event: any) {
    console.log(event);
  }

  private loadList(): void {
    const body = JSON.stringify([1]);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = environment.BAMS_Api_URL + "api/GetProjectsByStatus";
    this.http.post(query, body, { headers }).pipe(map((data: any) => data.Value)).subscribe((result: any) => {
      this.rowData = result;
    });
  }
}