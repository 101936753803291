
//AgGrid Imports
import { AgGridModule } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from '../shared/utils/primeng.module';
import { SharedModule } from 'primeng/api';
import { ProjectListComponent } from './project-list/project-list.component';
import { StopButtonComponent } from './custom-component/stop-button/stop-button.component';

@NgModule({
  declarations: [
    ProjectListComponent,
    StopButtonComponent,
  ],
  imports: [
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PrimengModule,
    SharedModule
  ],
  providers:[
    
  ],
  exports:[
    ProjectListComponent
  ]
})
export class MiscModule { }
