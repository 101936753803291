<!--
Description: HTML corresponding to the main content
Author: Leonardo Hernandez N
Creation date: 21/Jul/2022 
<history>
   <update> H00 - BAMS-37
            Description: Creation.
   </update>  
</history>
-->
<div style="width: 100%;">
    <div style="text-align: center; margin-top: 25px;">
        <div class="row justify-content-center">
            <div class="col" class="div">
                <span class="fontStyle fontColor" style="font-weight: bold;">Welcome to BaMS</span>
            </div>
        </div>
        <div class="row justify-content-center" class="div">
            <div class="col">
                <span class="fontStyle" style="color: #867874; font-weight: bold;">(Batch Modeling System)</span>
            </div>
        </div>
        <div class="row justify-content-center" class="div">
            <div class="col" style="margin: 0px auto 10px auto; width: 70%;">
                <span class="fontStyle" style="font-size: 20px;">BaMS is a tool that assists team(s) with exposure and risk assessment modeling with specific inputs that are provided to support actives, metabolites, and formulations for registration purposes.</span>
            </div>
        </div>
        <div class="row justify-content-center line">
        </div>
    </div>
    <div class="row justify-content-center" style="margin: 40px 0px 20px 0px;">
        <div style="display: flex;">
            <div style="margin: 4px auto 0px auto; width: 245px;">
                <img alt="Restrictive for Discovery" src="assets/img/banner_echo1.png" width="245">
            </div>
        </div>
    </div>
</div>