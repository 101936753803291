<div class="centerInGrid">
  <p-overlayPanel #op>
    <ng-template pTemplate="content">
        <ul class="two-column-list">
          <li *ngFor="let scenario of scenarios">{{scenario}}</li>
        </ul>
    </ng-template>
  </p-overlayPanel>

  <app-button
    label="Select"
    [disabled]="disabled"
    [outlined]="false"
    [severity]="'warning'"
    (mouseenter)="op.toggle($event); onMouseEnter()"
    (mouseleave)="op.hide()"
    (onClickEvent)="onClick()"
  ></app-button>
</div>
