    <p-multiSelect #genericMultiSelect 
                    [disabled]="editable == false" 
                    [options]="options" 
                    appendTo="body" 
                    (onChange)="onChange($event)" 
                    [(ngModel)]="selectedValues" 
                    [optionLabel]="optionLabel"
                    [optionValue]="optionValue" 
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="26"
                    [maxSelectedLabels]="7">
    </p-multiSelect>