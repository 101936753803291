import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'app-date-cell-renderer',
  template: `{{ formattedDate }}`
})
export class DateTimeCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public formattedDate: string | undefined;

  agInit(params: any): void {
    this.params = params;
    this.formattedDate = moment(params.value).format('DD-MMM-YYYY');
  }

  refresh(params: any): boolean {
    this.params = params;
    this.formattedDate = moment(params.value).format('DD-MMM-YYYY');
    return true;
  }
}