import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

//AgGrid License
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('CompanyName=SHI International Corp._on_behalf_of_Pioneer Hi-Bred Intl Inc. (US, Johnston, IA 50131),LicensedGroup=DSI,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=25,LicensedProductionInstancesCount=0,AssetReference=AG-036752,SupportServicesEnd=2_September_2024_[v2]_MTcyNTIzMTYwMDAwMA==3211b93f03e1e90c9f013132ac4acca8');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
