import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent {

  @Input() options: any[] = [];
  @Input() model: string[] = [];
  @Input() value: any;
  @Input() name: string = '';
  @Input() inputId: any;
  @Input() binary: boolean = true;  
  @Input() label?: string;
  @Input() labelStyle?: string;

  @Output() onSelectionChangedEvent = new EventEmitter<string[]>();

  onSelectionChanged()
  {
    this.onSelectionChangedEvent.emit(this.model);
  }
}
