import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TaxonMatching } from 'src/app/shared/models/echo/taxon-matching';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxonEndpointsMatchingService {
  constructor(private http: HttpClient) {
  }

  getTaxonEndpointsMatching(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = environment.ECHO_Api_URL + 'api/getTaxonEndpointsMatching';
    return this.http.put(query, body, { headers }).pipe(map((data: any) => data.Value));
  }
  
  saveData(pData: any):Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = environment.ECHO_Api_URL + 'api/taxonEndpointsMatching';
    return this.http.put(query, body, { headers }).pipe(map((data: any) => data.Value));
  }

  getTaxonFromEndPoints( pEndpointType : string,  pRegion :string,  pEndpoint :string ): Observable<any[]> {
    const query = `${environment.ECHO_Api_URL}api/GetTaxonFromEndpointsMatching/${pEndpoint}/${pRegion}/${pEndpointType}`;
    return this.http.get(query).pipe(map((data: any) => data));
  }

  GetAllTaxonValueFromEndPointsByGeography(pRegion?: string): Observable<TaxonMatching[]> {
    const query = `${environment.ECHO_Api_URL}api/GetTaxonFromEndpointsMatching/${pRegion}`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
}
