import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.css']
})
export class ExternalComponent {
  externalPageURL: string = '';
  @ViewChild('qcModal') modal!: any;
  
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(() => {
      this.route.params.subscribe((params: Params) => {
        this.externalPageURL = this.getExternalURI(params);
        if (params['Application'])
          this.externalPageURL = `${this.externalPageURL}/${params['Application']}`;
      });
    });
  }
  
  getExternalURI(params: Params): string {
    if (params['ECHOModule'])
      return `${environment.ECHOURI}${params['ECHOModule']}`;
    return `${environment.GAPURI}${params['GAPModule']}`;
  }
}
