import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef, DomLayoutType, GridApi, GridReadyEvent } from 'ag-grid-community';
import { EndpointParameterFormationFraction } from 'src/app/shared/models/endpoint-parameter';
import { DeleteButtonRenderer } from 'src/app/shared/renderers/delete-button/delete-button.component';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { CompoundPkAndName } from 'src/app/shared/models/compound';
import { Constants } from 'src/app/shared/utils/constants';
import { EEAEndpointsFormationFractionColDef } from './eea-endpoints-formation-fraction-coldef';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-eea-endpoints-formation-fraction',
  templateUrl: './eea-endpoints-formation-fraction.component.html',
  styleUrls: ['./eea-endpoints-formation-fraction.component.css']
})
export class EEAEndpointsFormationFractionComponent {

  @Input() rowData!: EndpointParameterFormationFraction[];
  @Input() columnDef!: ColDef[];
  @Input() precursorType!: string;
  @Input() precursorOptions!: CompoundPkAndName[];
  @Input() leyend!: string;
  @Input() isProjectOwnershipValid: boolean = false;


  @Output() modifiedRowData = new EventEmitter<EndpointParameterFormationFraction[]>();

  public context: EEAEndpointsFormationFractionComponent;
  public domLayout: DomLayoutType = 'autoHeight';
  public components?: {
    deleteButtonRenderer: typeof DeleteButtonRenderer,
    dropdownlistRenderer: typeof DropdownListRenderer,
    inputNumberRenderer: typeof InputNumberRenderer
  };

  private gridApi!: GridApi;

  constructor(private readonly columnDefService: EEAEndpointsFormationFractionColDef) {
    this.context = this;
  }

  public onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
  }

  public ngOnInit(): void {
    this.setColumnDef();
    this.initializeComponents();
  }

  private initializeComponents(): void {
    this.components = {
      deleteButtonRenderer: DeleteButtonRenderer,
      dropdownlistRenderer: DropdownListRenderer,
      inputNumberRenderer: InputNumberRenderer
    };
  }

  private setColumnDef(): void{
    this.columnDef = this.columnDefService.getColumnsDefinition(this.precursorOptions,this.isProjectOwnershipValid);
  }

  public onAdd(): void {
    const newItems = [this.createNewFormationFraction()];
    this.gridApi.applyTransaction({
      add: newItems,
      addIndex: 0
    });
  }

  private createNewFormationFraction(): EndpointParameterFormationFraction {
    const newRow = {
      deletable : true,
      endpointPk: -1,
      endpointPrecursorPk: undefined,
      formationFraction: undefined,
      precursor: undefined,
      precursorType: this.precursorType,
      substanceName: undefined,
      metabolitePk: undefined,
      moleculePk: undefined,
      compoundPk: undefined
    };
    return newRow;
  }

  public dropDownSelectionChanged(modifiedData: any): void{
    this.updateSelectedPrecursor(modifiedData);
    this.modifiedRowData.emit(this.getRowData());
  }

  public onInputNumberBlur(params: any): void {
    this.updateFormationFractionValue(params);
    this.modifiedRowData.emit(this.getRowData());
  }

  public showDialog(element: any, action: string, params? : any): void {
    Swal.fire({
      title: element.title,
      text: element.content,
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (action === 'delete') {
          this.removeRow(params);
          const result = this.getRowData();
          if(result.length == 0)
            result.push({precursorType: this.precursorType, emptyRow: true});
          this.modifiedRowData.emit(result);
        }
      }
    });
  }

  private removeRow(rowParams: any): void{
    const itemsToDelete: EndpointParameterFormationFraction[] = [];
    const rowId: number | undefined = rowParams.node.id;
    itemsToDelete.push(this.getRowById(rowId));
    this.gridApi.applyTransaction({ remove: itemsToDelete })!;
  }

  private getRowById(rowId: number | undefined): EndpointParameterFormationFraction{
    let data: EndpointParameterFormationFraction = {} as EndpointParameterFormationFraction;
    this.gridApi.forEachNodeAfterFilterAndSort(function (rowNode) {
      if(rowNode.id == rowId){
        data = rowNode.data;
      }
    });
    return data;
  }

  private updateSelectedPrecursor(modifiedData: any) {
    const rowId: number | undefined = modifiedData.id;
    const eventCompoundPk: number = modifiedData.value;
    const selectedOption = this.precursorOptions.find(option => option.compoundPk == eventCompoundPk)!;
    const {compoundName, compoundPk, compoundType} = selectedOption;
    const moleculePk: number | undefined = compoundType == Constants.SUBSTANCE_TYPES.ACTIVE ? compoundPk :undefined;
    const metabolitePk: number | undefined = compoundType == Constants.SUBSTANCE_TYPES.METABOLITE ? eventCompoundPk : undefined;
    const data = this.getRowById(rowId);

    data.metabolitePk = metabolitePk;
    data.moleculePk = moleculePk;
    data.substanceName = compoundName;
    data.compoundPk = metabolitePk ? metabolitePk : moleculePk;
    this.gridApi.applyTransaction({ update: [data] })!;
  }

  private updateFormationFractionValue(rowParams: any): void{
    const rowId: number | undefined = rowParams.node.id;
    const data = this.getRowById(rowId);
    data.formationFraction = rowParams.data.value;
    this.gridApi.applyTransaction({ update: [data] })!;
  }

  private getRowData(): EndpointParameterFormationFraction[] {
    const rowData: EndpointParameterFormationFraction[]= [];
    this.gridApi.forEachNode(function (node: any) {
      rowData.push(node.data);
    });
    return rowData;
  }
}
