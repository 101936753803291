import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { firstValueFrom, take } from 'rxjs';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Project } from 'src/app/shared/models/project';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ListboxChangeEvent } from 'primeng/listbox';
import { Constants } from 'src/app/shared/utils/constants';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-esa-setup',
  templateUrl: './esa-setup.component.html',
  styleUrls: ['./esa-setup.component.css']
})
export class ESASetupComponent implements OnInit {

  @Input() menuService!: TabMenuLogicService;
  @Input() activeItem?: CustomTabMenuItem;
  @Input() disciplinePk: number = 0;

  @Output() isSetupValidEvent = new EventEmitter<boolean>();

  currentUserName: string = "";
  selectedProject?: Project;
  projects: Project[] = [];
  selectedOutputs: any = [];
  gapOuputs = Constants.GAP_OUTPUTS;
  modelsLoading: boolean = false;
  setupLoading: boolean = false;
  validSetup: boolean = false;
  validSetupModel: boolean = false;
  destroyRef = inject(DestroyRef)
  isProjectOwnershipValid: boolean = false;

  constructor(private selectedProjectAPIService: SelectedProjectApiService,
    private userLogicService: UserLogicService) {
  }

  ngOnInit(): void {
    this.setupLoading = true;
    this.getCreatedBy();
    this.initSubscribes();
  }

  initSubscribes() {
    this.selectedProjectAPIService.projects.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(projects => this.projects = projects);

    this.selectedProjectAPIService.selectedProject.pipe<Project | undefined>(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: async (project?: Project) => {
        await this.getProjectsXCompoundXModels(project);
      }
    });

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((changed: boolean) => {
      if (changed) this.SaveSelectedProject()
    });
  }

  initNewProject() {
    if (this.selectedProject) return;
    this.selectedProjectAPIService.initNewProject(this.currentUserName);
  }

  getCreatedBy() {
    this.currentUserName = this.userLogicService.profile.displayName ?? "";
  }

  async SaveSelectedProject() {
    let validatedName = await this.validateName();
    if (!this.selectedProject || !validatedName) {
      this.menuService.setSuccess(true);
      return;
    }

    this.selectedProject.projectXCompoundXModel = [];

    if (this.selectedProject.projectXCompoundXModel.length <= 0) {
      this.menuService.setSuccess(true);
      return;
    }
    let savedProjectPk = -1;
    this.selectedProjectAPIService.saveProject().pipe(take(1)).subscribe({
      next: (projectPk: number) => {
        savedProjectPk = projectPk;
        this.selectedProjectAPIService.saveProjectXCompoundXModels(savedProjectPk, this.disciplinePk).pipe(take(1)).subscribe({
          next: () => {
            if (!this.projects.map(p => p.projectPk).includes(savedProjectPk)) {
              if (!this.selectedProject) return;
              this.selectedProject.projectPk = savedProjectPk;
              this.selectedProjectAPIService.insertProject(this.selectedProject);
            }
            this.menuService.setSuccess(true);
          },
          error: (err) => {
            console.warn(err);
            this.menuService.setSuccess(true);
          }
        });
      },
      error: (err) => {
        console.warn(err);
        this.menuService.setSuccess(true);
      }
    });
  }

  async validateName() {
    let isValid = true;
    if (!this.selectedProject?.name) isValid = false;
    await firstValueFrom(this.selectedProjectAPIService.projects).then(projects => {
      if (projects.find(p => p.name === this.selectedProject?.name && p.projectPk !== this.selectedProject?.projectPk)) {
        isValid = false;
        this.menuService.setSuccess(true);
        Swal.fire({
          title: `${this.selectedProject?.name}, project name already exists.`,
          text: 'Please use another name',
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Ok',
          icon: 'error',
        });
      }
    });
    this.validSetup = isValid;
    return isValid;
  }

  outputSelectionChanged({ value }: ListboxChangeEvent) {
    this.selectedOutputs = value;
    this.updateProjectOutputs();
  }

  updateProjectOutputs() {
    if (!this.selectedProject) return;
    this.selectedProject.useRateGHA = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.RateGHA) ? true : false;
    this.selectedProject.useRateLBACRE = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.RateLBAcre) ? true : false;
    this.selectedProject.useBBCH = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.BBCH) ? true : false;
    this.selectedProject.useApplicationWindow = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.ApplicationWindow) ? true : false;
    this.selectedProject.useNumberOfApplications = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.NumberOfApplications) ? true : false;
    this.selectedProject.useIntervalBetweenApplications = this.selectedOutputs.find((x: any) => x.label === Constants.GAP_OUTPUTS_LABELS.IntervalBetweenApplications) ? true : false;
  }

  getProjectOutputs(project: Project) {
    this.selectedOutputs = [];
    if (!project) return;
    if (project.useRateGHA) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.RateGHA));
    if (project.useRateLBACRE) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.RateLBAcre));
    if (project.useBBCH) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.BBCH));
    if (project.useApplicationWindow) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.ApplicationWindow));
    if (project.useNumberOfApplications) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.NumberOfApplications));
    if (project.useIntervalBetweenApplications) this.selectedOutputs.push(this.gapOuputs.find(x => x.label === Constants.GAP_OUTPUTS_LABELS.IntervalBetweenApplications));
  }

  async getProjectsXCompoundXModels(project?: Project): Promise<void> {
    if (!project) {
      this.selectedProject = project;
      this.setupLoading = false;
      return;
    }

    await firstValueFrom(this.selectedProjectAPIService.getProjectXCompoundXModels(project.projectPk, this.disciplinePk).pipe(take(1))).then(models => {
      project.projectXCompoundXModel = models;
    });

    this.getProjectOutputs(project);
    this.selectedProject = project;
    this.isProjectOwnershipValid = this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy);
    this.setupLoading = false;
  }

  validateSetupInfoInProject(event: boolean) {
    this.validSetup = event;
  }
}

