import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectPelmoResultSummaryColdef {
  constructor() { }

  public GetMaxAmountOfGaps(SummaryResult: any[]): number {
    SummaryResult.sort((a, b) => b.Gapsummary.length - a.Gapsummary.length);
    return SummaryResult.length === 0 ? 0 : SummaryResult[0].Gapsummary.length;
  }
  getConcentrationValue(params: any): any {
    const ingredientName = params.column.getColDef().headerName;
    const ingredient = params.data.ingredients.find(
      (ingr: any) => ingr.SubstanceName === ingredientName
    );
    return ingredient ? ingredient.concentration : null;
  }

  getAplicationRate(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.ApplicationRate : null;
  }

  getBBCH(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.BBCH : null;
  }

  getInterception(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.Interception : null;
  }
  getDosage(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.Dosage : null;
  }

  public children = {
    headerName: '',
    editable: false,
    wrapText: false,
    resizable: true,
    sortable: true,
    filterable: true,
    valueGetter: this.getConcentrationValue.bind(this),
    width: 220,
    filter: 'agSetColumnFilter',
  };

  public EntireColumAplication = {
    headerName: '',
    instance: this,
    children: [
      {
        headerName: 'Application Rate \n (g/ha) of a.i.',
        editable: false,
        wrapText: false,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getAplicationRate.bind(this),
        width: 151,
        filter: 'agSetColumnFilter',
        name: '',
      },
      {
        headerName: 'BBCH',
        editable: false,
        wrapText: false,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getBBCH.bind(this),
        width: 100,
        filter: 'agSetColumnFilter',
        name: '',
      },
      {
        headerName: 'Interception',
        editable: false,
        wrapText: false,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getInterception.bind(this),
        width: 130,
        filter: 'agSetColumnFilter',
        name: '',
      },
      {
        headerName: 'Dosage',
        editable: false,
        wrapText: false,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getDosage.bind(this),
        width: 600,
        minWidth: 60,
        type: 'select',
        filter: 'agSetColumnFilter',
        instance: this,
        name: '',
      },
    ],
  };
  private columnDefs = [
    {
      headerName: 'General',
      instance: this,
      children: [
        {
          headerName: 'Run ID',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'RunID',
          width: 100,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Formulation',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Formulation',
          width: 110,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Summary',
      instance: this,
      children: [
        {
          headerName: 'Crop',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Crop',
          width: 100,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Scheme',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Active Substance',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'ActiveSubstance',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Pathway',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Pathway',
          width: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Location',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Location',
          width: 120,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Number Of \n Metabolites',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'NumberOfMetabolites',
          width: 110,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
      ],
    },
  ];

  public getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
