import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectStep1n2ScenarioColdef } from './eea-output-project-step1n2-scenario.coldef';
import { EEAOutputProjectStep1n2ScenarioApiService } from './eea-output-project-step1n2-scenario.api.service';

@Component({
  selector: 'app-eea-output-project-step1n2-scenario',
  templateUrl: './eea-output-project-step1n2-scenario.component.html',
  styleUrls: ['./eea-output-project-step1n2-scenario.component.css'],
})
export class EEAOutputProjectStep1n2ScenarioComponent implements OnChanges {
  constructor(
    private scenarioDataCalculationService: EEAOutputProjectStep1n2ScenarioApiService,
    private step1n2ScenarioDataCalculationColDef: EEAOutputProjectStep1n2ScenarioColdef
  ) {}

  @Input() selectedProject?: Project;
  rowData: any;
  columDef: any;
  loading: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedProject'].currentValue.projectPk)
      this.getScenarioDataUseInCalculation(changes['selectedProject'].currentValue.projectPk)
  }
  
  ngOnInit(): void {
    this.columDef = this.step1n2ScenarioDataCalculationColDef.getColumnsDefinition();
  }
  
  getScenarioDataUseInCalculation(pProjectPk: number) {
    if (pProjectPk != 0) {
    this.loading = true;
    this.scenarioDataCalculationService.getScenarioDataUseInCalculation(pProjectPk).subscribe((result: any) => {
      this.rowData = result.Value;
      this.loading = false;
    });
    }
  }
}