import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { Constants } from 'src/app/shared/utils/constants';
import { RunApiService } from 'src/app/shared/services/run.api.service';

@Component({
  selector: 'app-eea-output-project-step1n2',
  templateUrl: './eea-output-project-step1n2.component.html',
  styleUrls: ['./eea-output-project-step1n2.component.css']
})
export class EEAOutputStep1n2Component implements OnInit {
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() selectedProjectRunsSaved?: boolean;
  @Input() selectedModel?:string;
  disabledInputs: boolean = true;
  disabledInputsSubscription: Subscription | undefined;  
  disabledSummary = true;
  activeIndex = -1;
  tabs: any[] = [];

  constructor(public runApiService: RunApiService) { }

  ngOnInit(): void {
    this.disabledSummary = !(this.selectedProject?.status == 2);
    if (this.selectedProject) {
      try {
        this.disabledInputsSubscription = this.runApiService.runsHaveBeenSaved(this.selectedProject.projectPk).subscribe((value: boolean) => {
          this.disabledInputs = !value;
        });
      } catch (error) {
        console.error(error);
      }
    }

    this.tabs = [
      {
        name: 'Inputs Used in Calculation'
      },
      {
        name: 'Scenario data used in Calculation'
      },
      {
        name: 'Results of the Calculation'
      },
      {
        name: 'Details of the Results'
      },
      {
        name: 'Summary of the Results'
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.disabledInputsSubscription) {
      this.disabledInputsSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes[Constants.SELECTED_PROJECT_STATUS]) {
      this.disabledSummary = !(this.selectedProjectStatus == 2);
    }
    if (changes[Constants.SELECTED_PROJECT_RUNS_SAVED]) {
      this.disabledInputs = !this.selectedProjectRunsSaved;
    }
  }
}