import { Component, OnInit } from '@angular/core';
import { UserLogicService } from './shared/services/user.logic.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  title = 'BaMSUIWorkflow';

  constructor(public userLogicService: UserLogicService){

  }
  
  ngOnInit(): void {
    this.userLogicService.initProfile();
  }
}
