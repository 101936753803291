import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EndpointBatchUploadComponent } from './endpoint-batch-upload/endpoint-batch-upload.component';
import { DisciplineComponent } from './filters/discipline/discipline.component';
import { EndpointUploadTemplateComponent } from './endpoint-upload-template/endpoint-upload-template.component';
import { ExternalComponent } from './external/external.component';
import { RegionComponent } from './filters/region/region.component';
import { TaxonComponent } from './filters/taxon/taxon.component';
import { TriggersEcotoxComponent } from './regulatory-models/triggers-ecotox/triggers-ecotox.component';
import { AdminGridComponent } from './admin-grid/admin-grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { ModelLibraryComponent } from './regulatory-models/model-library/model-library.component';
import { FormsModule } from '@angular/forms';
import { CalendarAdminRenderer } from './renderers/calendar/calendar.component';
import { DeleteButtonAdminRenderer } from './renderers/delete-button/delete-button.component';
import { DropDownListAdminRenderer } from './renderers/drop-down-list/drop-down-list.component';
import { ListboxAdminRenderer } from './renderers/listbox/listbox.component';
import { MultiSelectAdminRenderer } from './renderers/multi-select/multi-select.component';
import { SafePipe } from './pipes/safe.pipe';
import { ApplicationMethodMatchingComponent } from './regulatory-models/application-method-matching/application-method-matching.component';
import { ApplicationMethodMatchingGridComponent } from './regulatory-models/application-method-matching/application-method-matching-grid.component';
import { SharedModule } from '../shared/shared.module';
import { CropMatchingComponent } from './regulatory-models/crop-matching/crop-matching.component';
import { CropMatchingGridComponent } from './regulatory-models/crop-matching/crop-matching-grid.component';
import { CropListMatchingGridComponent } from './regulatory-models/crop-list-matching/crop-matching-list-grid.component';
import { GeographyMatchingComponent } from './regulatory-models/geography-matching/geography-matching.component';
import { GeographyMatchingGridComponent } from './regulatory-models/geography-matching/geography-matching-grid.component';
import { ModelLibraryGridComponent } from './regulatory-models/model-library/model-library-grid.component';
import { TaxonEndpointsMatchingComponent } from './regulatory-models/taxon-endpoints-matching/taxon-endpoints-matching.component';
import { TaxonEndpointsMatchingGridComponent } from './regulatory-models/taxon-endpoints-matching/taxon-endpoints-matching-grid.component';
import { TriggersEcotoxGridComponent } from './regulatory-models/triggers-ecotox/triggers-ecotox-grid.component';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { CropListComponent } from './regulatory-models/crop-list/crop-list.component';
import { CropListMatchingComponent } from './regulatory-models/crop-list-matching/crop-list-matching.component';

@NgModule({
  declarations: [
    EndpointBatchUploadComponent,
    DisciplineComponent,
    EndpointUploadTemplateComponent,
    ExternalComponent,
    ModelLibraryComponent,
    RegionComponent,
    TaxonComponent,
    TriggersEcotoxComponent,
    AdminGridComponent,
    CalendarAdminRenderer,
    DeleteButtonAdminRenderer,
    DropDownListAdminRenderer,
    ListboxAdminRenderer,
    MultiSelectAdminRenderer,
    SafePipe,
    ApplicationMethodMatchingComponent,
    ApplicationMethodMatchingGridComponent,
    CropMatchingComponent,
    CropMatchingGridComponent,
    GeographyMatchingComponent,
    GeographyMatchingGridComponent,
    ModelLibraryGridComponent,
    TaxonEndpointsMatchingComponent,
    TaxonEndpointsMatchingGridComponent,
    TriggersEcotoxGridComponent,
    CropListComponent,
    CropListMatchingComponent,
    CropListMatchingGridComponent

  ],
  imports: [
    FormsModule,
    CommonModule,
    SplitButtonModule,
    DialogModule,
    FileUploadModule,
    DropdownModule,
    AgGridModule,
    SharedModule,
    CalendarModule,
    ButtonModule,
    ListboxModule,
    MultiSelectModule
  ]
})
export class AdministratorModule { }
