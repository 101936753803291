    <div class="container m-6">
        <div class="row row-filters">
            <div class="col-4">
                <app-region [componentThatImplementsGrid]='componentThatImplementsGrid'></app-region>
                </div>
                <div class="col-6" style="margin-left: -9%;" >
                <app-taxon  #taxonFilterComponent [componentThatImplementsGrid]='componentThatImplementsGrid'></app-taxon>
                </div>
            </div>
    </div>
<div class="content" class="m-6">

   <div class="row">
    <div class="grid shadow-8 surface-0 p-3 mt-6 div-grid" *ngIf="afterSearch" [ngStyle]="{'display': afterSearch == true? 'block': 'none' }" style="margin-bottom: -11px;">
        <app-taxon-endpoints-matching-grid [dataComponent]='componentThatImplementsGrid'></app-taxon-endpoints-matching-grid>
    </div>
    <app-loading *ngIf="loading"></app-loading>
   </div>
</div>