import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Message } from 'primeng/api';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { ERAGapLogicService } from './era-gap.logic.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { EnviromentalRiskAssessmentService } from '../../environmental-risk-assessment/environmental-risk-assessment.service';

@Component({
  selector: 'app-era-gap',
  templateUrl: './era-gap.component.html',
  styleUrls: ['./era-gap.component.css']
})

export class ERAGapComponent implements OnInit, OnDestroy {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() isValid = new EventEmitter<boolean>();

  activeItem!: any;
  items: CustomTabMenuItem[] = []
  destroyRef = inject(DestroyRef)
  afterSearch: boolean = false;
  loading: boolean = false;
  wizardLoading: boolean = false;
  nextIndex?: number;
  message!: Message;
  isSetupValid: boolean = false;
  tabsToDisplay: CustomTabMenuItem[] = [];
  public isApplicationSchemeValid: boolean = true;

  constructor(public ERAGapLogicService: ERAGapLogicService,
              private ERALogicService: EnviromentalRiskAssessmentService){}

  ngOnInit(): void {
    let isInit = true;
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => { this.menuService.setSuccess(true) });

    this.ERAGapLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => { this.ERAGapLogicService.setSuccess(true) });

    this.ERALogicService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
      this.tabsToDisplay = this.ERAGapLogicService.items.filter(item => usedModels.map(model => model.toLocaleLowerCase()).includes(item.model ? item.model.toLocaleLowerCase() : ''));

      if(this.tabsToDisplay.length === 1) {
        isInit = false;
        this.ERAGapLogicService.setActiveItem(this.tabsToDisplay[0]);
      }
    });

    this.ERAGapLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activeItem: CustomTabMenuItem) => this.activeItem = isInit ? {} : activeItem);
    
    this.ERAGapLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((success: boolean)  => {
      this.wizardLoading = false;
      if(success) {
        const nextItem = this.ERAGapLogicService.items.find((i: CustomTabMenuItem) => i.index == this.nextIndex);
        if(nextItem  && !isInit)
          this.ERAGapLogicService.setActiveItem(nextItem);
      }
      isInit = false;
    });
  }

   
  ngOnDestroy() {
    this.ERAGapLogicService.resetActiveItem();
  }
  
  onActiveIndexChange(index: number)
  {
    this.nextIndex = index;
    this.ERAGapLogicService.setActiveIndexChanged(true, index);
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  public getIsApplicationSchemeValid(isValid: boolean): void {
    this.isApplicationSchemeValid = isValid;
    this.isValid.emit(isValid);
  }
}
