import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdvancedFiltersGridColdef {
  constructor() { }

  private columnDefs = [
    {
      headerName: 'Project Name',
      wrapHeaderText: true,
      editable: false,
      resizable: true,
      sortable: true,
      field: 'nameWithPk',
      width: 220,
      filter: true,
      pinned: 'left'

    },
    {
      headerName: 'Created by',
      editable: false,
      wrapHeaderText: true,
      resizable: true,
      sortable: true,
      field: 'createdBy',
      width: 115,
      filter: true,
    },
    {
      headerName: 'Creation date',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'createdDate',
      width: 130,
      filter: true,
    },
    {
      headerName: 'Formulation',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'formulationName',
      width: 180,
      filter: true,
    },
    {
      headerName: 'Model',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'modelNames',
      width: 180,
      filter: true,
      tooltipField: "model",
      valueFormatter: (params:any) => {
        if (Array.isArray(params.value)) {
          return params.value.join(', ');
        }
        return params.value;
      }
    },
    {
      headerName: 'Parent',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'parentName',
      width: 180,
      filter: true,
      tooltipField: "parentName",
    },
    {
      headerName: 'Metabolite',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'metaboliteName',
      width: 180,
      filter: true,
      tooltipField: "metaboliteName",
    },
    {
      headerName: 'Geography',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'geographyName',
      width: 130,
      filter: true,
    },
    {
      headerName: 'Crop (Core GAP)',
      editable: false,
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'cropName',
      width: 150,
      filter: true,
      tooltipField:'cropName',
      valueFormatter: (params:any) => {
        if (Array.isArray(params.value)) {
          return params.value.join(', ');
        }
        return params.value;
      }
    },
    {
      headerName: 'Archived',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'archive',
      width: 100,
      filter: true,
    },
    {
      headerName: 'Status',
      editable: false,
      resizable: true,
      sortable: true,
      field: 'status',
      width: 110,
      filter: true,
    },
  ];

  getColumnsDefinition(): any[] {
    return [...this.columnDefs];
  }
}
