import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RunInputsUsedInCalculation } from '../../models/out-put-project/run-inputs-used-in-calculation';
import { ukDataInterface } from '../../models/out-put-project/uk-inputs-use-in-calculation-interface';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectInputsLogicService {

  constructor(private http: HttpClient) { }

  transformUkRunData(results: RunInputsUsedInCalculation[]): ukDataInterface[] {
    let ukData: ukDataInterface[] = [];
    const DateFormatOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    results.forEach(element => {
      var newUkInput: ukDataInterface = {
        runId: element.runId,
        applicationScheme: element.applicationSchemeName,
        model: element.model,
        crop: element.crop ?? '',
        cropDrift: element.cropDrift === null ? this.getCropDrift(element, results, this.getActiveIngredient(element.runId ?? "", results)) : element.cropDrift,
        cropSpray: element.cropSpray,
        applicationIntervalDays: element.applicationsNumber === null ? results.find(x => x.runId === element.runId && x.applicationsNumber !== null)?.applicationsNumber ?? "" : element.applicationsNumber?.toString() ?? '',
        rate: element.rate === null ? this.getRate(element, results, this.getActiveIngredient(element.runId ?? "", results)) : element.rate?.toString() ?? '',
        applicationMethod: element.applicationMethod,
        cropCoverage: element.cropCovarage,
        cropInterception: element.cropInterceptionPercentage === null ? this.getCropInterception(element, results, this.getActiveIngredient(element.runId ?? "", results)) : element.cropInterceptionPercentage?.toString() ?? '',
        endpointsDataSetName: element.endpointsDataSetName ?? '',
        molecularWeight: element.molecularWeight?.toString() ?? '',
        substanceName: element.substanceName,
        kfocLKg: element.kfoc?.toString() ?? '',
        applicationNumber: element.applicationsNumber === null ? results.find(x => x.runId === element.runId && x.applicationsNumber !== null)?.applicationsNumber ?? "" : element.applicationsNumber?.toString() ?? '',
        kfomLKg: element.kfom?.toString() ?? '',
        halflifeinsoild: element.halfLifeInSoil?.toString() ?? '',
        halflifeinwater: element.halfLifeInWater?.toString() ?? '',
        activeIngredient: element.activeIngredient !== null ? element.activeIngredient : this.getActiveIngredient(element.runId ?? "", results),
        halflifeinsediment: element.halfLifeInSediment?.toString() ?? '',
        maxiumoccurrenceinsediment: element.maxiumOccurrenceInSediment?.toString() ?? '',
        maxiumoccurrenceinwater: element.maxiumOccurrenceInWater?.toString() ?? '',
        maximumOccurrenceInSoil: element.maximumOccurrenceInSoil?.toString() ?? '',
        bbchEarliest: element.BBCHEarliest === null ? this.getBBCH(element, results, this.getActiveIngredient(element.runId ?? "", results)) : element.BBCHEarliest?.toString() ?? '',
        applicationDate: element.applicationDate
          ? new Date(element.applicationDate)
            .toLocaleDateString('en-US', DateFormatOptions)
            .toString()
          : '',
      };
      ukData.push(newUkInput);
    });
    return ukData;
  }

  getBBCH(element: RunInputsUsedInCalculation, results: RunInputsUsedInCalculation[], activeIngredient: string): string {
    var result = results.find(x => x.runId === element.runId && x.BBCHEarliest !== null && activeIngredient === x.activeIngredient)?.BBCHEarliest ?? "";
    return result;
  }

  getActiveIngredient(runId: string, results: RunInputsUsedInCalculation[]): string {
    if (runId.length === 0 || results.length === 0) {
      return "";
    }
    const result = results.find(x => x.runId === runId && x.activeIngredient !== null);
    if (result) {
      return result.activeIngredient || "";
    }
    return "";
  }

  getRate(element: RunInputsUsedInCalculation, results: RunInputsUsedInCalculation[], activeIngredient: string): string {
    var result = results.find(x => x.runId === element.runId && x.rate !== null && activeIngredient === x.activeIngredient)?.rate?.toString() ?? "";
    return result;
  }

  GetApplicationMethod(element: RunInputsUsedInCalculation, results: RunInputsUsedInCalculation[], activeIngredient: string) {
    var result = results.find(x => x.runId === element.runId && x.applicationMethod !== null && activeIngredient === x.activeIngredient)?.applicationMethod ?? "";
    return result;
  }

  getCropDrift(element: RunInputsUsedInCalculation, results: RunInputsUsedInCalculation[], activeIngredient: string): string {
    var result = results.find(x => x.runId === element.runId && x.cropDrift !== null && activeIngredient === x.activeIngredient)?.cropDrift ?? "";
    return result;
  }

  getCropInterception(element: RunInputsUsedInCalculation, results: RunInputsUsedInCalculation[], activeIngredient: string): string {
    var result = results.find(x => x.runId === element.runId && x.cropInterceptionPercentage !== null && activeIngredient === x.activeIngredient)?.cropInterceptionPercentage?.toString() ?? "";
    return result;
  }
}