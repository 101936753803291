import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.css']
})
export class InputTextareaComponent {

  
  @Input() model?: string = '';
  @Input() style: string = '';
  @Input() rows: number = 2;
  @Input() cols: number = 30;
  @Input() autoResize: boolean = true;
  @Input() disabled: boolean = false;
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() floating: boolean = false;
  @Input() useTitle: boolean = false;

  @Output() onChangeEvent= new EventEmitter<string>();

  modelChanged(value: string){
    this.onChangeEvent.emit(value);
  }
}
