import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { SelectedProjectApiService } from './selected-project.api.service';
import { Project } from '../../models/project';
import { firstValueFrom, take } from 'rxjs';
import { OrderByPipe } from '../../pipes/order-by.pipe';
import { Environmental_Assessments, UserLogicService } from '../../services/user.logic.service';
import { ModelLibraryApiService } from '../../services/model-library.api.service';
import { ModelLibrary } from '../../models/model-library';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormulationApiService } from '../../services/echo/formulation.api.service';
import { Formulation } from '../../models/formulation';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { EEAGapLogicService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap.logic.service';
import { Catalog } from '../../models/echo/catalog';
import { Constants } from '../../utils/constants';

@Component({
  selector: 'app-selected-project',
  templateUrl: './selected-project.component.html',
  styleUrls: ['./selected-project.component.css']
})
export class SelectedProjectComponent implements OnInit {

  @Input() isEEA:boolean = false;
  @Input() module?: Environmental_Assessments;
  destroyRef = inject(DestroyRef)

  advancedFiltersVisibles: boolean = false;
  myProjectsFilter: boolean = true;
  projects: Project[] = [];
  userFilteredProjects: Project[] = [];
  searchFilteredProjects: Project[] = [];
  models: ModelLibrary[] = [];
  loading: boolean = false;
  selectedProject?: Project;
  createdBy: string = '';
  placeholder: string = ' ';
  geographies: Catalog[] = [];
  currentUserCanRunModels: boolean = true;
  constructor(public selectedProjectApiService: SelectedProjectApiService,
    public userLogicService: UserLogicService,
    private orderByPipe: OrderByPipe,
    private modelLibraryApiService: ModelLibraryApiService,
    private formulationAPIService: FormulationApiService,
    private gapLogicSWService: EEAGapLogicService) {
  }

  async ngOnInit(): Promise<void> {
    this.selectedProjectApiService.module = this.module;
    this.currentUserCanRunModels = await this.userLogicService.currentUserCanRunModelsAsync(this.module);
    this.loading = true;
    this.placeholder = 'Loading...'
    this.getMatchingFACTSOPEX();
    this.createdBy = this.userLogicService.profile.displayName ?? "";

    this.selectedProjectApiService.projects
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((projects: Project[]) => {
        this.projects = projects.filter(x => x.active === true);
        this.userFilterProjects(true);
      });

    this.selectedProjectApiService.selectedProject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((project?: Project) => {
        this.selectedProject = project;
      });


    await this.selectedProjectApiService.getProjects('NA');
    this.getModels();
    this.loading = false;

    this.placeholder = ' ';
    this.iniValues();
  }

  iniValues() {
    let promises = [
      this.gapLogicSWService.getGeographies(),
    ]
    Promise.all(promises)
      .then(async (results) => {
        this.geographies = results[0];
      })
  }

  initNewProject() {
    this.selectedProjectApiService.initNewProject(this.createdBy);
  }

  getMatchingFACTSOPEX() {
    this.selectedProjectApiService.getMatchingFACTSOPEX().pipe(take(1)).subscribe((data) => {
      this.selectedProjectApiService.matchingFACTSOPEX = data;
    });
  }
  queryFilterProjects({ query }: AutoCompleteCompleteEvent) {
    this.searchFilteredProjects = this.userFilteredProjects.filter((project: Project) => project.name?.toLowerCase().indexOf(query.toLowerCase()) == 0);
  }

  async onSelectionChanged(project: Project) {
    await this.setFormulationToSelectedProject(project);
    this.setGeographyToSelectedProject(project);
    this.selectedProjectApiService.setSelectedProject(project);
  }

  setGeographyToSelectedProject(project: Project) {
    const geographiesToFilter = [Constants.CROP_GEOGRAPHIES.EUROPE_UNION, Constants.CROP_GEOGRAPHIES.UK, Constants.CROP_GEOGRAPHIES.USA];
    project.geography = this.geographies.filter((x: Catalog) => geographiesToFilter.includes(x.name ?? ''))?.find((x: Catalog) => this.selectedProject?.countryPk === x.key || this.selectedProject?.regionPk === x.key)?.name;
    if (project?.geographyName && project.geography == null) {
      project.regionPk = project.regionPk ?? undefined;
      project.countryPk = project.countryPk ?? undefined;
      project.geography = this.geographies.find(x => x.name == project?.geographyName)?.name ?? "";
    }
  }

  async userFilterProjects(state: boolean): Promise<void> {
    this.myProjectsFilter = state;
    this.userFilteredProjects = this.myProjectsFilter
      ? this.orderByPipe.transform([...this.projects.filter((p: Project) => p.createdBy === this.createdBy)], 'name')
      : this.orderByPipe.transform([...this.projects], 'name');

    this.searchFilteredProjects = [...this.userFilteredProjects];
  }

  getModels() {
    this.modelLibraryApiService.getModels().pipe(take(1)).subscribe((models) => {
      this.models = models;
      this.setProjectsXModels()
      this.loading = false;
    });
  }

  async setFormulationToSelectedProject(project: Project) {
    await firstValueFrom(this.formulationAPIService.getFormulationByPk(project.formulationPk).pipe(take(1))).then((formulation: Formulation) => {
      project.formulation = formulation.tradeName;
    });
  }

  private setProjectsXModels() {
    this.projects.forEach(project => {
      project?.projectXCompoundXModel?.forEach(x => {
        const matchingModel = this.models.find(model => model.modelPk === x.ModelPk);
        if (matchingModel) {
          x.ModelName = matchingModel.name;
          x.CompartmentPk = matchingModel.compartmentXModel && matchingModel.compartmentXModel.length > 0 ? matchingModel.compartmentXModel![0] : undefined
        }
      });
    });
  }

  showAdvancedFiltersDialog() {
    this.advancedFiltersVisibles = !this.advancedFiltersVisibles
  }

  handleAdvancedFilterRow(project: any) {
    this.advancedFiltersVisibles = !this.advancedFiltersVisibles;
    this.onSelectionChanged(project)
  }
}


