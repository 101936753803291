import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectSwashSwanInputsColdef } from './eea-output-project-swash-swan-inputs.coldef';
import { EEAOutputProjectInputsApiService } from 'src/app/shared/services/eea-output-project-inputs.api.service';

@Component({
  selector: 'app-eea-output-project-swan-run-inputs-use-in-calculation',
  templateUrl: './eea-output-project-swan-run-inputs-use-in-calculation.component.html',
  styleUrls: ['./eea-output-project-swan-run-inputs-use-in-calculation.component.css']
})
export class EeaOutputProjectSwanRunInputsUseInCalculationComponent implements OnInit, OnChanges {

  @Input() selectedProject?: Project;
  @Input() selectedModel?:string;
  columDef: any;
  loading: boolean = true;
  rowData: any;
  constructor(private swanSwashInputUsedInCalculationsColDef: EEAOutputProjectSwashSwanInputsColdef,
    private cdRef: ChangeDetectorRef,
    private readonly inputsUsedInCalculationsService: EEAOutputProjectInputsApiService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.columDef = this.swanSwashInputUsedInCalculationsColDef.getColumnsDefinition();
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          this.rowData = data.filter(item=> item.model == this.selectedModel);
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
    this.loading = false;
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          this.rowData = data.filter(item=> item.model == this.selectedModel);
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
  }
}