export class AdminGridTransaction {
    constructor() {}
    static readonly update: number = 1;
    static readonly insert: number = 2;
    static readonly delete: number = 3;

    private rowUniqueID: string = '';
    private transactionType: number = 0;
    private row: any;
    
    public setRowUniqueID(pIdRowUniqueID: string) {
      this.rowUniqueID = pIdRowUniqueID;
    }
    public getRowUniqueID() {
      return this.rowUniqueID;
    }
    
    public setTransactionType(pTransactionType: number) {
      this.transactionType = pTransactionType;
    }
    public getTransactionType() {
      return this.transactionType;
    }
    
    public setRow(pRow: any) {
      this.row = pRow;
    }
    public getRow() {
      return this.row;
    }
  }
  