<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel class="mb-2" [disabled]="disabledInputs" header="Inputs used in calculation">
        <app-eea-output-project-tier1uk-run-inputs [selectedModel]="selectedModel" *ngIf="!disabledInputs"
            [selectedProject]="selectedProject"></app-eea-output-project-tier1uk-run-inputs>
    </p-tabPanel>
    <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="PECsw and PECsed (Distance)">
        <app-eea-output-project-tier1uk-pec-distance *ngIf="!disabledSummary"
            [selectedProject]="selectedProject"></app-eea-output-project-tier1uk-pec-distance>
    </p-tabPanel>
    <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="PECsw and PECsed (Time)">
        <app-eea-output-project-tier1uk-pec-time *ngIf="!disabledSummary"
            [selectedProject]="selectedProject"></app-eea-output-project-tier1uk-pec-time>
    </p-tabPanel>
    <p-tabPanel class="mb-2" [disabled]="disabledSummary" header="Drainflow and Stream">
        <app-eea-output-project-tier1uk-drainflow-and-stream *ngIf="!disabledSummary"
            [selectedProject]="selectedProject"></app-eea-output-project-tier1uk-drainflow-and-stream>
    </p-tabPanel>
</p-tabView>