
<div class="border-bottom-1 font-bold mb-2">
    <label>Models to be run*</label>
</div>
<p-accordion #accordion [multiple]="false" [activeIndex]="activeIndex" (onClose)="onClose($event)" (onOpen)="onOpen($event)">
    <p-accordionTab [selected]="true">
        <ng-template pTemplate="icon">
            <app-button [text]="true" [plain]="true" [size]="'small'" [icon]="'pi pi-angle-down'" [iconPos]="'right'" [disabled]=true></app-button>
        </ng-template>
        <!-- Header -->
        <ng-template class="flex" pTemplate="header">
            <div class="ml-2 flex align-items-center text-color">
                <span class="vertical-align-middle">{{modelHeader}}</span>
            </div>
            <div class="flex align-items-end">
                <app-button [text]="true" [disabled]="true" [plain]="true" [icon]="'pi pi-check-square'" [iconPos]="'right'"></app-button>
            </div>
        </ng-template>

        <!-- Content -->
        <ng-template pTemplate="content">
          <div class="formgrid grid" >
            <div class="col-3 font-bold mr-4">
                <app-input-text [label]="'Chemical'" [useTitle]="true" [model]="chemicalName" [isRequired]="true" [isValid]="isChemicalNameValid" (onChangeEvent)="chemicalNameChanged($event)"></app-input-text>
            </div>
            <div class="col-3 font-bold mr-4">
              <app-dropdown [label]="'Species Habitat'" [options]="getOptionsByModel(modelHeader, speciesHabitatOptions)" optionLabel="long_value" [model]="selectedSpeciesHabitat"
              [isRequired]="true" [showClear]="true" [placeholder]="'Select a Species Habitat'" [isValid]="isSpeciesHabitatValid"
              (onChangeEvent)="speciesHabitatSelectionChanged($event)"></app-dropdown>
            </div>
            <div class="col-2 font-bold mr-4">
              <app-dropdown [label]="'Include Drift'" [isRequired]="true" [model]="includeDrift" [options]="['Yes', 'No']" [showClear]="true" [isValid]="isIncludeDriftValid"
                [placeholder]="'Select'" (onChangeEvent)="includeDriftSelectionChanged($event)"></app-dropdown>
            </div>
            <div class="col-3 font-bold">
              <label [ngClass]="!isRegionsToIncludeValid ? 'lbl-invalid' : 'lbl-valid'">Regions to Include*</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isRegionsToIncludeValid ? 'dd-invalid' : 'dd-valid'"
                  [options]="getOptionsByModel(modelHeader, regionsToIncludeOptions)"
                  appendTo="body"
                  [placeholder]="regionsToIncludePlaceholder"
                  (onChange)="regionsToIncludeSelectionChanged($event)"
                  (onSelectAllChange)="regionsToIncludeSelectAllChanged($event)"
                  [selectAll]="selectAllRegions"
                  [(ngModel)]="selectedRegionsToInclude"
                  [optionLabel]="'short_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="26"></p-multiSelect>
              </div>
            </div>
          </div>

          <div class="formgrid grid mt-6 mb-6">
            <div class="col-3 font-bold mr-4">
              <label [ngClass]="!isStatesToIncludeValid && isStatesToIncludeRequired ? 'lbl-invalid' : 'lbl-valid'">{{statesToIncludeLabel}}</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isStatesToIncludeValid && isStatesToIncludeRequired ? 'dd-invalid' : 'dd-valid'"
                  [disabled]="!isStatesToIncludeRequired"
                  [options]="getOptionsByModel(modelHeader, statesToIncludeOptions)"
                  appendTo="body"
                  [placeholder]="statesToIncludePlaceholder"
                  (onChange)="statesToIncludeSelectionChanged($event)"
                  (onSelectAllChange)="statesToIncludeSelectAllChanged($event)"
                  [selectAll]="selectAllStates"
                  [(ngModel)]="selectedStatesToInclude"
                  [optionLabel]="'long_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="26"></p-multiSelect>
              </div>
            </div>
            <div class="col-3 font-bold mr-4">
              <app-input-text [useTitle]="true" [label]="'Buffer Distances'" [model]="bufferDistances" [isRequired]="true" [isValid]="isBufferDistancesValid" (onChangeEvent)="bufferDistancesChanged($event)"></app-input-text>
            </div>
            <div class="col-3 font-bold mr-4">
              <label [ngClass]="!isOnOffFieldConsiderationsValid ? 'lbl-invalid' : 'lbl-valid'">On/Off Field Considerations*</label>
              <div>
                <p-multiSelect
                  class="w-12"
                  [ngClass]="!isOnOffFieldConsiderationsValid ? 'dd-invalid' : 'dd-valid'"
                  [options]="getOptionsByModel(modelHeader, onOffFieldConsiderationsOptions)"
                  appendTo="body"
                  [placeholder]="onOffFieldConsiderationsPlaceholder"
                  (onChange)="onOffFieldConsiderationsChanged($event)"
                  (onSelectAllChange)="onOffFieldConsiderationsSelectAllChanged($event)"
                  [selectAll]="selectAllOnOffFieldConsiderations"
                  [(ngModel)]="selectedOnOffFieldConsiderations"
                  [optionLabel]="'short_value'"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="35"></p-multiSelect>
              </div>
            </div>
            <div class="col-2 font-bold">
              <app-dropdown [label]="'Adjust for PCT'" [isRequired]="true" [model]="adjustForPCT" [options]="['Yes', 'No']" [showClear]="true" [isValid]="isAdjustForPCTValid"
                [placeholder]="'Select'" (onChangeEvent)="adjustForPCTSelectionChanged($event)"></app-dropdown>
            </div>
          </div>
          <div class="formgrid grid mt-6 mb-6">
            <div class="col-3 font-bold">
              <label>Crop Use</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" id="cropUseFileUpload">
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download Crop Use"></p-button>
            </div>
            <div class="col-3 font-bold">
              <label>PCT Values</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" id="pctValuesFileUpload">
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download PCT"></p-button>
            </div>
            <div class="col-3 font-bold">
              <label>On/Off Considerations</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" id="onAndOffConsiderationsFileUpload">
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download On/Off"></p-button>
            </div>
            <div class="col-3 font-bold">
              <label>Obligate/Indirect Relationship</label>
              <div class="file-upload-container mt-2 mb-2">
                <input type="file" id="obligateAndIndirectRelationshipFileUpload">
              </div>
              <p-button styleClass="standard-button" class="p-button-outlined" label="Download Relationships"></p-button>
            </div>
          </div>
          <div class="formgrid grid mt-6 mb-6 clear-button">
            <div class="col-12">
              <p-button [disabled]="false" styleClass="standard-button" class="p-button-outlined mr-6" label="Clear Model Inputs"></p-button>
            </div>
          </div>

        </ng-template>
    </p-accordionTab>
</p-accordion>
<app-loading class="flex align-items-center justify-content-center" *ngIf="modelsLoading"></app-loading>
