import { Injectable } from '@angular/core';
import { HttpApiService } from '../http.api.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { TaxonMatching } from '../../models/echo/taxon-matching';

@Injectable({
  providedIn: 'root'
})
export class TaxonEndpointMatchingApiService extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  GetAllTaxonValueFromEndPointsByGeographyAndTaxonGroup(region: string, taxonGroup: string): Observable<TaxonMatching[]> {
    const endpoint = `api/GetTaxonFromEndpointsMatching/${region}/${taxonGroup}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }
}
