//AgGrid Imports
import { AgGridModule } from 'ag-grid-angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { EnvironmentalRiskAssessmentComponent } from './environmental-risk-assessment/environmental-risk-assessment.component';
import { OrderByPipe } from '../shared/pipes/order-by.pipe';
import { ERAEndpointsComponent } from './era-endpoints/era-endpoints/era-endpoints.component';
import { ERASetupModelsToBeRunComponent } from './era-setup-models-to-be-run/era-setup-models-to-be-run.component';
import { ERASetupComponent } from './era-setup/era-setup.component';
import { ERASetupInputsComponent } from './era-setup-inputs/era-setup-inputs.component';
import { ERAEcotoxEndpointsComponent } from './era-endpoints/era-ecotox-endpoints/era-ecotox-endpoints.component';
import { ERARunRiskAssessmentComponent } from './era-run-risk-assessment/era-run-risk-assessment.component';
import { ERAAquaticRunRiskAssessmentGridComponent } from './era-run-risk-assessment/era-aquatic-run-risk-assessment-grid/era-aquatic-run-risk-assessment-grid.component';
import { ERAPecEccComponent } from './era-pec-ecc/era-pec-ecc/era-pec-ecc.component';
import { ERAPecEccAquaticComponent } from './era-pec-ecc/era-pec-ecc-aquatic/era-pec-ecc-aquatic.component';
import{ERACopyProjectDialogComponent} from './shared/era-copy-project-dialog/era-copy-project-dialog.component';
import { ERAGapComponent } from './era-gap/era-gap/era-gap.component';
import { ERAGapTerrestrialComponent } from './era-gap/era-gap-terrestrial/era-gap-terrestrial.component'
import { ERAEcotoxDatasetsComponent } from './era-endpoints/era-ecotox-datasets/era-ecotox-datasets.component';
import { EraRunRiskAssessmentTerrestrialComponent } from './era-run-risk-assessment/era-run-risk-assessment-terrestrial/era-run-risk-assessment-terrestrial.component';
import { EraGapBeesComponent } from './era-gap/era-gap-bees/era-gap-bees.component';
import { EraRunRiskAssessmentBeesComponent } from './era-run-risk-assessment/era-run-risk-assessment-bees/era-run-risk-assessment-bees.component';

@NgModule({
  declarations: [
    EnvironmentalRiskAssessmentComponent,
    ERASetupModelsToBeRunComponent,
    ERAEndpointsComponent,
    ERASetupModelsToBeRunComponent,
    ERASetupComponent,
    ERASetupInputsComponent,
    ERAEcotoxEndpointsComponent,
    ERAEcotoxDatasetsComponent,
    ERARunRiskAssessmentComponent,
    ERAAquaticRunRiskAssessmentGridComponent,
    ERAPecEccComponent,
    ERAPecEccAquaticComponent,
    ERACopyProjectDialogComponent,
    ERAGapComponent,
    ERAGapTerrestrialComponent,
    EraRunRiskAssessmentTerrestrialComponent,
    EraGapBeesComponent,
    EraRunRiskAssessmentBeesComponent
  ],
  imports: [
    AgGridModule,
    FormsModule,
    CommonModule,
    SharedModule
  ],
  providers: [
    OrderByPipe
  ],
  exports:[
    ERAEcotoxDatasetsComponent,
    ERAEcotoxEndpointsComponent,
    ERARunRiskAssessmentComponent,
    ERAGapTerrestrialComponent
  ]
})
export class EnvironmentalRiskAssessmentModule { }
