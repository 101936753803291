<div class="card grid" *ngIf="!activeItem?.disabled && !setupLoading">
    <div class="mt-2 ml-2 col-12">
        <app-era-setup-inputs [isProjectOwnershipValid]="isProjectOwnershipValid" [selectedProject]="selectedProject"
            [filteredFormulations]="filteredFormulations" [geographies]="geographies"
            [currentUserName]="currentUserName"
            (onformulationSelectionChangeEvent)="onformulationSelectionChanged($event)"
            (onQueryFilterChangeEvent)="onFormulationFilterChanged($event)"
            (isSetupValidEvent)="validateSetupInfoInProject($event)"></app-era-setup-inputs>
    </div>
    <div class="mt-5 ml-2 col-8">
        <app-era-setup-models-to-be-run [isProjectOwnershipValid]="isProjectOwnershipValid"
            [modelsLoading]="modelsLoading" [selectedProject]="selectedProject" [modelsToBeRun]="modelsToBeRun"
            [columnsDefinition]="columnDef" [modelsCollection]="modelsCollection"
            (areSetupModelsValidEvent)="validateSetupModelInProject($event)"></app-era-setup-models-to-be-run>
    </div>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="setupLoading"></app-loading>