import { Component, Input, OnInit } from '@angular/core';
import { Observable, take } from 'rxjs';
import { SelectionValues } from 'src/app/shared/models/selectionValues';
import { TriggersEcotoxService } from './triggers-ecotox.service';
import { Constants } from 'src/app/shared/utils/constants';
import { AdminGridComponent } from '../../admin-grid/admin-grid.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { RoleApiService } from 'src/app/shared/services/role.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-triggers-ecotox-grid',
  templateUrl: '../../admin-grid/admin-grid.component.html',
})
export class TriggersEcotoxGridComponent
  extends AdminGridComponent
  implements OnInit
{
  @Input() dataComponent: any;

  dataToSave: any;
  invalidColumns: string[] = [];
  pecTypesList: any;
  eecTypesList: any;

  constructor(private triggersEcotoxService: TriggersEcotoxService,
    private userService: UserLogicService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.rowHeight = 50;
    this.context = { componentParent: this };
    this.batchUpload = false;
    this.pecTypesList = this.dataComponent.pecTypesList;
    this.eecTypesList = this.dataComponent.eecTypesList;

  }

  override onGridReady(params: any): void {
    params.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.rowData = this.dataComponent.allTaxonTriggers;
    this.rowData.map((row: any) => {
      row.deletable = true;
    });
    this.configureGrid();
  }

  configureGrid(): void {
    this.dataComponent.setLoadingState(false);
    this.rowSelection = 'single';
    this.setColumnDefinition();
  }
  setColumnDefinition() {
    if (
      this.dataComponent.selectedRegion ==
      Constants.GEOGRAPHYS.USA
    ) {
      this.columnDefs = [
        {
          headerName: 'Taxon/Exposure duration',
          field: 'description',
          minWidth: 600,
          type: 'default',
          filter: true,
          editable: false,
          cellRenderer: InputTextRenderer,
          cellRendererParams: {
            disabled: !this.currentUserCanEdit,
          },
        },
        {
          headerName: 'Risk Quotient (RQ)',
          headerClass: 'group-identity',
          width: 220,
          maxWidth: 220,
          children: [
            {
              headerName: 'Non-Listed',
              field: 'riskQuotientNonListed',
              width: 120,
              maxWidth: 120,
              editable: false,
              cellRenderer: InputNumberRenderer,
              cellRendererParams: {
                columnThatModifies: 'riskQuotientNonListed',
                disabled: !this.currentUserCanEdit,
              },
              type: 'default',
              filter: true,
            },
            {
              headerName: 'Listed',
              field: 'riskQuotientListed',
              editable: false,
              cellRenderer: InputNumberRenderer,
              cellRendererParams: {
                columnThatModifies: 'riskQuotientListed',
                disabled: !this.currentUserCanEdit,
              },
              width: 100,
              maxWidth: 100,
              type: 'default',
              filter: true,
            },
          ],
        },
        {
          headerName: 'EEC Type',
          field: 'eecTypePk',
          hide: false,
          width: 250,
          suppressSizeToFit: true,
          columnGroupShow: 'close',
          type: 'select',
          editable: false,
          cellRenderer: DropdownListRenderer,
          cellRendererParams: {
            instance: this,
            options: this.eecTypesList,
            columnThatModifies: 'eecTypePk',
            optionLabel: 'name',
            optionValue: 'key',
            sort: 'asc',
            disabled: !this.currentUserCanEdit,
          },
          filter: 'agSetColumnFilter' ,
          filterValueGetter: (params: any) => {
            const eecTypePk = this.eecTypesList.find((eecs:any) => eecs.key == params.data.eecTypePk);
            return eecTypePk ? eecTypePk.name : params.data.eecTypePk;
          },
          instance: this,
          validationType: 'string',
        },
        {
          headerName: 'Taxon Group',
          field: 'taxonGroup',
          hide: false,
          width: 250,
          suppressSizeToFit: true,
          type: 'select',
          editable: false,
          filter: 'agSetColumnFilter',
          cellRenderer: DropdownListRenderer,
          cellRendererParams: {
            instance: this,
            options: ['Arthropods','Aquatic','Bees','Terrestrial Vertebrate'],
            columnThatModifies: 'taxonGroup',
            sort: 'asc',
            filterable: false,
            disabled: !this.currentUserCanEdit,
          },
          instance: this,
          validationType: 'string',
        },
        {
          colId: 'action',
          cellRenderer: 'deleteButtonRenderer',
          cellRendererParams: {
            editable: this.currentUserCanEdit,
          },
          type: 'centerAligned',
          width: 125,
          minWidth: 125,
          maxWidth: 125,
          editable: false,
        },
      ];
    } else if (
      this.dataComponent.selectedRegion ==
      Constants.GEOGRAPHYS.EUROPE_UNION
    ) {
      this.columnDefs = [
        {
          headerName: 'Indicator species/Category',
          field: 'description',
          cellRenderer: InputTextRenderer,
          cellRendererParams: {
            disabled: !this.currentUserCanEdit,
          },
          minWidth: 450,
          width: 450,
          type: 'default',
          filter: true,
          editable:false,
        },
        {
          headerName: 'AF',
          field: 'assessmentFactor',
          maxWidth: 80,
          minWidth: 80,
          editable:false,
          cellRenderer: InputNumberRenderer,
          cellRendererParams: {
            columnThatModifies: 'assessmentFactor',
            disabled: !this.currentUserCanEdit,
          },
          suppressSizeToFit: true,
          filter: 'agSetColumnFilter',
          type: 'default',
          suppressToolPanel: true,
          suppressFiltersToolPanel: true,
          cellStyle: (params: any) => {
            return params.data.automatic ? { color: '#aaaaaa' } : '';
          },
        },

        {
          headerName: 'Trigger, passes If...',
          headerClass: 'group-identity',
          children: [
            {
              headerName: 'Type',
              field: 'triggerType',
              hide: false,
              width: 110,
              suppressSizeToFit: true,
              type: 'select',
              editable:false,
              filter: 'agSetColumnFilter',
              cellRenderer: DropdownListRenderer,
              cellRendererParams: {
                instance: this,
                options: this.dataComponent.triggerTypesList,
                columnThatModifies: 'triggerType',
                optionLabel: 'name',
                optionValue: 'name',
                sort: 'asc',
                filterable: false,
                disabled: !this.currentUserCanEdit,
              },
              instance: this,
              validationType: 'string',
            },
            {
              headerName: 'Operator',
              field: 'triggerOperator',
              hide: false,
              width: 115,
              suppressSizeToFit: true,
              type: 'select',
              editable: false,
              filter: 'agSetColumnFilter',
              cellRenderer: DropdownListRenderer,
              cellRendererParams: {
                instance: this,
                options: ['<', '>', '=', '≤', '≥'],
                columnThatModifies: 'triggerOperator',
                sort: 'asc',
                filterable: false,
                disabled: !this.currentUserCanEdit,
              },
              instance: this,
              validationType: 'string',
            },
            {
              headerName: 'Value',
              field: 'triggerValue',
              maxWidth: 120,
              minWidth: 100,
              suppressSizeToFit: true,
              editable:false,
              cellRenderer: InputNumberRenderer,
              cellRendererParams: {
                columnThatModifies: 'triggerValue',
                disabled: !this.currentUserCanEdit,
              },
              filter: 'agSetColumnFilter',
              type: 'default',
              suppressToolPanel: true,
              suppressFiltersToolPanel: true,
            },
            {
              headerName: 'Tier',
              field: 'triggerTier',
              hide: false,
              maxWidth: 120,
              suppressSizeToFit: true,
              type: 'select',
              editable:false,
              filter: 'agSetColumnFilter',
              cellRenderer: DropdownListRenderer,
              cellRendererParams: {
                instance: this,
                options: this.dataComponent.triggerTiersList,
                optionLabel: 'name',
                optionValue: 'name',
                columnThatModifies: 'triggerTier',
                sort: 'asc',
                filterable: false,
                disabled: !this.currentUserCanEdit,
              },
              instance: this,
              validationType: 'string',
            },
          ],
        },

        {
          headerName: 'PEC Type',
          field: 'pecTypePk',
          hide: false,
          maxWidth: 170,
          suppressSizeToFit: true,
          columnGroupShow: 'close',
          type: 'select',
          editable:false,
          cellRenderer: DropdownListRenderer,
          cellRendererParams: {
            instance: this,
            options: this.pecTypesList,
            columnThatModifies: 'pecTypePk',
            optionLabel: 'name',
            optionValue: 'key',
            sort: 'asc',
            disabled: !this.currentUserCanEdit,
          },
          instance: this,
          validationType: 'string',
          filterable:false,
          filter: 'agSetColumnFilter' ,
          filterValueGetter: (params: any) => {
            const pecTypePk = this.pecTypesList.find((pecs:any) => pecs.key == params.data.pecTypePk);
            return pecTypePk ? pecTypePk.name : params.data.pecTypePk;
          }
        },
        {
          headerName: 'Taxon Group',
          field: 'taxonGroup',
          hide: false,
          width: 250,
          suppressSizeToFit: true,
          type: 'select',
          editable:false,
          filter: 'agSetColumnFilter',
          cellRenderer: DropdownListRenderer,
          cellRendererParams: {
            instance: this,
            options: ['Arthropods','Aquatic','Bees','Terrestrial Vertebrate'],
            columnThatModifies: 'taxonGroup',
            sort: 'asc',
            filterable: false,
            disabled: !this.currentUserCanEdit,
          },
          instance: this,
          validationType: 'string',
        },
        {
          colId: 'action',
          cellRenderer: 'deleteButtonRenderer',
          cellRendererParams: {
            editable: this.currentUserCanEdit,
          },
          type: 'rightAligned',
          width: 125,
          minWidth: 125,
          maxWidth: 125,
          editable: false,
        },
      ];
    }

    this.gridApi.redrawRows();
  }

  override saveData(pData: any): Observable<any> {
    try {
      this.dataToSave = pData;
      this.dataComponent.setLoadingState(true);
      let geography = this.dataComponent.selectedRegion;
      pData.map((transaction: any) => {
        transaction.row.geography = geography;
      });

      this.triggersEcotoxService
        .saveData(pData)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.afterSaveSuccess();
            this.dataComponent.filterDataByGeography(geography);
            this.isSaving = false;
          },
          error: (e) => {
            this.afterSaveError(e);
            this.dataComponent.filterDataByGeography(geography);
            this.isSaving = false;
          },
        });

      return pData;
    } catch (e) {
      return pData;
    }
  }

  override getData(): void {
    this.dataComponent.getData();
  }

  public setChangeValues(newData:any): void {
    let oldValue = newData.oldValue;
    this.CreateTransaction(
      newData.row.id,
      newData.id,
      oldValue,
      newData.row);
  }

  public dropDownSelectionChanged(newData: any): void {
    this.setSelectionValues(newData);
  }

  public setSelectionValues(selectionObject: SelectionValues): void {
    const oldvalue = selectionObject.row[selectionObject.field];

    selectionObject.row[selectionObject.field] = selectionObject.value;
    this.CreateTransaction(
      selectionObject.row.id,
      selectionObject.id,
      oldvalue,
      selectionObject.row
    );
  }
}
