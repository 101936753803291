import { Constants } from "src/app/shared/utils/constants";

export abstract class EndpointsParameterConfiguration {

  public static readonly ENDPOINT_FIELDS_CONFIGURATION = {
    MOLECULAR_WEIGHT: {
      dataType: 'decimal',
      qtyDecimals: 3,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'General',
      subGroup: '',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(g/mol)',
      order: 0,
      usedInModels: undefined,
      displayName: undefined
    },
    SATURATED_VAPOUR_PRESSURE: {
      dataType: 'decimal',
      qtyDecimals: 10,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER_INSERT,
      group: 'General',
      subGroup: '',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(Pa)',
      order: 1,
      usedInModels: undefined,
      displayName: undefined
    },
    SOLUBILITY_IN_WATER: {
      dataType: 'decimal',
      qtyDecimals: 10,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER_INSERT,
      group: 'General',
      subGroup: '',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(mg/L)',
      order: 2,
      usedInModels: undefined,
      displayName: undefined
    },
    HENRYS_CONSTANT: {
      dataType: 'decimal',
      qtyDecimals: 20,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER_INSERT,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 3,
      usedInModels: undefined,
      displayName: undefined
    },
    AIR_DIFFUSION_COEFFICIENT: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: 0.43,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: true,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(m²/d)',
      order: 4,
      usedInModels: [Constants.MODELS.PWC],
      displayName: undefined
    },
    HEAT_OF_HENRY: {
      dataType: 'decimal',
      qtyDecimals: 0,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(J/mol)',
      order: 5,
      usedInModels: [Constants.MODELS.PWC],
      displayName: undefined
    },
    WATER_DIFFUSION_COEFFICIENT: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: 0.000043,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: true,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(m²/d)',
      order: 6,
      usedInModels: undefined,
      displayName: undefined
    },
    QUALITATIVE_MEASSURE: {
      dataType: 'text',
      qtyDecimals: 0,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.TEXT,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 7,
      usedInModels: undefined,
      displayName: undefined
    },
    KOM: {
      dataType: 'decimal',
      qtyDecimals: 18,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Sorption',
      subGroup: 'Soil-equilibrium',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(L/Kg)',
      order: 8,
      usedInModels: undefined,
      displayName: undefined
    },
    K_FOC: {
      dataType: 'decimal',
      qtyDecimals: 18,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Sorption',
      subGroup: 'Soil-equilibrium',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 9,
      usedInModels: undefined,
      displayName: undefined
    },
    DESORPTION_RATE_KD: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Sorption',
      subGroup: 'Soil non-equilibium',
      isCore: false,
      range: '0-0.5',
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 10,
      usedInModels: undefined,
      displayName: undefined
    },
    FRACTION_OF_NON_EQ_SITES_FNE: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Sorption',
      subGroup: 'Soil non-equilibium',
      isCore: true,
      range: '0-1',
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(f_ne)',
      order: 11,
      usedInModels: undefined,
      displayName: undefined
    },
    EXPONENT: {
      dataType: 'decimal',
      qtyDecimals: 3,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Sorption',
      subGroup: 'Soil-equilibrium',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(-)',
      order: 12,
      usedInModels: undefined,
      displayName: undefined
    },
    HALFLIFE_IN_SOIL: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 13,
      usedInModels: undefined,
      displayName: undefined
    },
    MEASURED_HALF_LIFE_SOIL: {
      dataType: 'decimal',
      qtyDecimals: 2,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic',
      isCore: true,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(°C)',
      order: 14,
      usedInModels: undefined,
      displayName: undefined
    },
    KINETIC_MODEL: {
      dataType: 'text',
      qtyDecimals: 0,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.DROPDOWN,
      group: 'Transformation',
      subGroup: 'Soil-aerobic',
      isCore: false,
      range: undefined,
      disabled: false,
      options: ['SFO', 'DFOP', 'HS', 'FOMC'],
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 15,
      usedInModels: undefined,
      displayName: undefined
    },
    NON_NORMALISED_DT50_SOIL: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 16,
      usedInModels: undefined,
      displayName: undefined
    },
    HALFLIFE_IN_WATER: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Surface water',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 17,
      usedInModels: undefined,
      displayName: undefined
    },
    MEASURED_HALF_LIFE_WATER: {
      dataType: 'decimal',
      qtyDecimals: 2,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Transformation',
      subGroup: 'Surface water',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(°C)',
      order: 18,
      usedInModels: undefined,
      displayName: undefined
    },
    AQUEOUS_PHOTOLYSIS_HALFLIFE: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Surface water',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(°C)',
      order: 19,
      usedInModels: undefined,
      displayName: undefined
    },
    PHOTOLYSIS_REFERENCE_LATITUDE: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Surface water',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(ºN)',
      order: 20,
      usedInModels: undefined,
      displayName: undefined
    },
    HYDROLYSIS_HALFLIFE: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Surface water',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 21,
      usedInModels: undefined,
      displayName: undefined
    },
    HALFLIFE_IN_SEDIMENT: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Sediment',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 22,
      usedInModels: undefined,
      displayName: undefined
    },
    MEASURED_HALF_LIFE_SEDIMENT: {
      dataType: 'decimal',
      qtyDecimals: 2,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Transformation',
      subGroup: 'Sediment',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(°C)',
      order: 23,
      usedInModels: undefined,
      displayName: undefined
    },
    HALFLIFE_IN_SEDIMENT_WATER_SYSTEM: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Sediment/Water',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 24,
      usedInModels: undefined,
      displayName: undefined
    },
    ALPHA: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic (FOMC)',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 25,
      usedInModels: undefined,
      displayName: undefined
    },
    BETA: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic (FOMC)',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 26,
      usedInModels: undefined,
      displayName: undefined
    },
    G: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic (DFOP)',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 27,
      usedInModels: undefined,
      displayName: undefined
    },
    K1: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic (DFOP/HS)',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d-1)',
      order: 28,
      usedInModels: undefined,
      displayName: undefined
    },
    K2: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic (DFOP/HS)',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d-1)',
      order: 29,
      usedInModels: undefined,
      displayName: undefined
    },
    TB: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic (HS)',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: undefined,
      order: 30,
      usedInModels: undefined,
      displayName: undefined
    },
    FOLIAR_WASH_OFF_FACTOR: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: 50,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Crop processes',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: true,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(m-1)',
      order: 31,
      usedInModels: undefined,
      displayName: undefined
    },
    CANOPY_PROCESS_OPTION: {
      dataType: 'text',
      qtyDecimals: 0,
      defaultValue: Constants.CANOPY_PROCESS_OPTIONS[0],
      controlType: Constants.CONTROL_TYPES.RADIO_BUTTON,
      group: 'Crop processes',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: true,
      options: Constants.CANOPY_PROCESS_OPTIONS,
      unit: undefined,
      order: 32,
      usedInModels: undefined,
      displayName: undefined
    },
    COEFFICIENT_FOR_UPTAKE_BY_PLANT: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Crop processes',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(TSCF) (-)',
      order: 33,
      usedInModels: undefined,
      displayName: undefined
    },
    FOLIAR_HALFLIFE: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: 10,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Crop processes',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: true,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 34,
      usedInModels: [Constants.MODELS.PWC],
      displayName: undefined
    },
    MAXIMUM_OCCURRENCE_IN_SEDIMENT: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: 100,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Maximum occurrence',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 35,
      usedInModels: undefined,
      displayName: undefined
    },
    MAXIMUM_OCCURRENCE_IN_SOIL: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: 100,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Maximum occurrence',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 36,
      usedInModels: undefined,
      displayName: undefined
    },
    MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: 100,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Maximum occurrence',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 37,
      usedInModels: undefined,
      displayName: undefined
    },
    MAXIMUM_OCCURRENCE_IN_WATER: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: 100,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Maximum occurrence',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 38,
      usedInModels: undefined,
      displayName: undefined
    },
    PELMO_LETTER: {
      dataType: 'text',
      qtyDecimals: 0,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.DROPDOWN,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'D1', 'D2'],
      showOnlyForMetabolite: true,
      unit: undefined,
      order: 39,
      usedInModels: undefined,
      displayName: 'PELMO Letter'
    },
    DT50_FOLIAR: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 40,
      usedInModels: undefined,
      displayName: undefined
    },
    INITIAL_DISLODGEABLE_FOLIAR_RESIDUE: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'General',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(µg/cm² of foliage/kg a.s. applied/ha)',
      order: 41,
      usedInModels: undefined,
      displayName: undefined
    },
    ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Toxicology',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(mg/kg bw/day)',
      order: 42,
      usedInModels: undefined,
      displayName: 'Acceptable Operator Exposure Level'
    },
    ACUTE_ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Toxicology',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(mg/kg bw/day)',
      order: 43,
      usedInModels: undefined,
      displayName: 'Acute Acceptable Operator Exposure Level'
    },
    DERMAL_ABSORPTION_PRODUCT: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Toxicology',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 44,
      usedInModels: undefined,
      displayName: undefined
    },
    DERMAL_ABSORPTION_USE_DILUTION: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Toxicology',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 45,
      usedInModels: undefined,
      displayName: undefined
    },
    ORAL_ABSORPTION_ACTIVE_SUBSTANCE: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Toxicology',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 46,
      usedInModels: undefined,
      displayName: undefined
    },
    INHALATION_ABSORPTION_ACTIVE_SUBSTANCE: {
      dataType: 'decimal',
      qtyDecimals: 4,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.NUMBER,
      group: 'Toxicology',
      subGroup: '',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(%)',
      order: 47,
      usedInModels: undefined,
      displayName: undefined
    },
    DT90: {
      dataType: 'decimal',
      qtyDecimals: 6,
      defaultValue: undefined,
      controlType: Constants.CONTROL_TYPES.EXPONENTIAL_NUMBER,
      group: 'Transformation',
      subGroup: 'Soil-aerobic',
      isCore: false,
      range: undefined,
      disabled: false,
      options: undefined,
      showOnlyForMetabolite: false,
      unit: '(d)',
      order: 40,
      usedInModels: undefined,
      displayName: undefined
    },
  }

  public static readonly ENDPOINTS_STEP1N2 = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.solubilityInWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SOLUBILITY_IN_WATER },
    { tableField: Constants.FIELD_NAMES.KOM, dataValueName: Constants.DATA_VALUES_NAMES.kom, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.KOM },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_WATER },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeSedimentWaterSystem, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SEDIMENT_WATER_SYSTEM },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceWaterSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_WATER_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_SOIL },
  ];

  public static readonly ENDPOINTS_TIER1UK = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_WATER },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_WATER },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_SOIL }
  ];

  public static readonly ENDPOINTS_SWASH = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.solubilityInWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SOLUBILITY_IN_WATER },
    { tableField: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE, dataValueName: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SATURATED_VAPOUR_PRESSURE },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.KOM, dataValueName: Constants.DATA_VALUES_NAMES.kom, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.KOM },
    { tableField: Constants.FIELD_NAMES.EXPONENT, dataValueName: Constants.DATA_VALUES_NAMES.exponent, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.EXPONENT },
    { tableField: Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT, dataValueName: Constants.DATA_VALUES_NAMES.coefficientForUptakePlant, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.COEFFICIENT_FOR_UPTAKE_BY_PLANT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_WATER },
    { tableField: Constants.FIELD_NAMES.FOLIAR_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.foliarHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_HALFLIFE },
    { tableField: Constants.FIELD_NAMES.FOLIAR_WASH_OFF_FACTOR, dataValueName: Constants.DATA_VALUES_NAMES.foliarWashOfFactor, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_WASH_OFF_FACTOR },
    { tableField: Constants.FIELD_NAMES.CANOPY_PROCESS_OPTION, dataValueName: Constants.DATA_VALUES_NAMES.canopyProcessOption, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.CANOPY_PROCESS_OPTION },
    { tableField: Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT, dataValueName: Constants.DATA_VALUES_NAMES.airDiffusionCoefficient, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.AIR_DIFFUSION_COEFFICIENT },
    { tableField: Constants.FIELD_NAMES.WATER_DIFFUSION_COEFFICIENT, dataValueName: Constants.DATA_VALUES_NAMES.waterDiffusionCoefficient, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.WATER_DIFFUSION_COEFFICIENT },
  ];

  public static readonly ENDPOINTS_PWC = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.solubilityInWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SOLUBILITY_IN_WATER },
    { tableField: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE, dataValueName: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SATURATED_VAPOUR_PRESSURE },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.measuredHalfLifeSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MEASURED_HALF_LIFE_SOIL },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.MEASURED_HALF_LIFE_SEDIMENT, dataValueName: Constants.DATA_VALUES_NAMES.measuredHalfLifeSediment, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MEASURED_HALF_LIFE_SEDIMENT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_WATER },
    { tableField: Constants.FIELD_NAMES.MEASURED_HALF_LIFE_WATER, dataValueName: Constants.DATA_VALUES_NAMES.measuredHalfLifeWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MEASURED_HALF_LIFE_WATER },
    { tableField: Constants.FIELD_NAMES.AQUEOUS_PHOTOLYSIS_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.aqueousPhotolysisHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.AQUEOUS_PHOTOLYSIS_HALFLIFE },
    { tableField: Constants.FIELD_NAMES.PHOTOLYSIS_REFERENCE_LATITUDE, dataValueName: Constants.DATA_VALUES_NAMES.photolysisReferenceLatitude, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.PHOTOLYSIS_REFERENCE_LATITUDE },
    { tableField: Constants.FIELD_NAMES.HYDROLYSIS_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.hydrolysisHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HYDROLYSIS_HALFLIFE },
    { tableField: Constants.FIELD_NAMES.FOLIAR_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.foliarHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_HALFLIFE },
    { tableField: Constants.FIELD_NAMES.HENRYS_CONSTANT, dataValueName: Constants.DATA_VALUES_NAMES.henrysConstant, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HENRYS_CONSTANT },
    { tableField: Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT, dataValueName: Constants.DATA_VALUES_NAMES.airDiffusionCoefficient, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.AIR_DIFFUSION_COEFFICIENT },
    { tableField: Constants.FIELD_NAMES.HEAT_OF_HENRY, dataValueName: Constants.DATA_VALUES_NAMES.heatOfHenry, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HEAT_OF_HENRY },
  ];

  public static readonly ENDPOINTS_MACRO = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.solubilityInWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SOLUBILITY_IN_WATER },
    { tableField: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE, dataValueName: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SATURATED_VAPOUR_PRESSURE },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.EXPONENT, dataValueName: Constants.DATA_VALUES_NAMES.exponent, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.EXPONENT },
    { tableField: Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT, dataValueName: Constants.DATA_VALUES_NAMES.coefficientForUptakePlant, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.COEFFICIENT_FOR_UPTAKE_BY_PLANT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.FOLIAR_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.foliarHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_HALFLIFE },
    { tableField: Constants.FIELD_NAMES.FOLIAR_WASH_OFF_FACTOR, dataValueName: Constants.DATA_VALUES_NAMES.foliarWashOfFactor, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_WASH_OFF_FACTOR }
  ];

  public static readonly ENDPOINTS_PELMO = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.solubilityInWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SOLUBILITY_IN_WATER },
    { tableField: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE, dataValueName: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SATURATED_VAPOUR_PRESSURE },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.EXPONENT, dataValueName: Constants.DATA_VALUES_NAMES.exponent, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.EXPONENT },
    { tableField: Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT, dataValueName: Constants.DATA_VALUES_NAMES.coefficientForUptakePlant, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.COEFFICIENT_FOR_UPTAKE_BY_PLANT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE, dataValueName: Constants.DATA_VALUES_NAMES.fne, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FRACTION_OF_NON_EQ_SITES_FNE },
    { tableField: Constants.FIELD_NAMES.DESORPTION_RATE_KD, dataValueName: Constants.DATA_VALUES_NAMES.kdes, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.DESORPTION_RATE_KD },
    { tableField: Constants.FIELD_NAMES.PELMO_LETTER, dataValueName: Constants.DATA_VALUES_NAMES.pelmoLetter, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.PELMO_LETTER },
  ];

  public static readonly ENDPOINTS_PEARL = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.SOLUBILITY_IN_WATER, dataValueName: Constants.DATA_VALUES_NAMES.solubilityInWater, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SOLUBILITY_IN_WATER },
    { tableField: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE, dataValueName: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SATURATED_VAPOUR_PRESSURE },
    { tableField: Constants.FIELD_NAMES.K_FOC, dataValueName: Constants.DATA_VALUES_NAMES.Kfoc, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K_FOC },
    { tableField: Constants.FIELD_NAMES.EXPONENT, dataValueName: Constants.DATA_VALUES_NAMES.exponent, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.EXPONENT },
    { tableField: Constants.FIELD_NAMES.COEFFICIENT_FOR_UPTAKE_BY_PLANT, dataValueName: Constants.DATA_VALUES_NAMES.coefficientForUptakePlant, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.COEFFICIENT_FOR_UPTAKE_BY_PLANT },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.FOLIAR_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.foliarHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_HALFLIFE },
    { tableField: Constants.FIELD_NAMES.FOLIAR_WASH_OFF_FACTOR, dataValueName: Constants.DATA_VALUES_NAMES.foliarWashOfFactor, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FOLIAR_WASH_OFF_FACTOR },
    { tableField: Constants.FIELD_NAMES.CANOPY_PROCESS_OPTION, dataValueName: Constants.DATA_VALUES_NAMES.canopyProcessOption, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.CANOPY_PROCESS_OPTION },
    { tableField: Constants.FIELD_NAMES.AIR_DIFFUSION_COEFFICIENT, dataValueName: Constants.DATA_VALUES_NAMES.airDiffusionCoefficient, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.AIR_DIFFUSION_COEFFICIENT },
    { tableField: Constants.FIELD_NAMES.WATER_DIFFUSION_COEFFICIENT, dataValueName: Constants.DATA_VALUES_NAMES.waterDiffusionCoefficient, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.WATER_DIFFUSION_COEFFICIENT },
    { tableField: Constants.FIELD_NAMES.FRACTION_OF_NON_EQ_SITES_FNE, dataValueName: Constants.DATA_VALUES_NAMES.fne, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.FRACTION_OF_NON_EQ_SITES_FNE },
    { tableField: Constants.FIELD_NAMES.DESORPTION_RATE_KD, dataValueName: Constants.DATA_VALUES_NAMES.kdes, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.DESORPTION_RATE_KD },
  ];

  public static readonly ENDPOINTS_EU_SOIL = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.KINETIC_MODEL, dataValueName: Constants.DATA_VALUES_NAMES.kineticModel, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.KINETIC_MODEL },
    { tableField: Constants.FIELD_NAMES.HALFLIFE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.halfLifeInSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.HALFLIFE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_SOIL },
    { tableField: Constants.FIELD_NAMES.K1, dataValueName: Constants.DATA_VALUES_NAMES.k1, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K1 },
    { tableField: Constants.FIELD_NAMES.K2, dataValueName: Constants.DATA_VALUES_NAMES.k2, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.K2 },
    { tableField: Constants.FIELD_NAMES.TB, dataValueName: Constants.DATA_VALUES_NAMES.tb, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.TB },
    { tableField: Constants.FIELD_NAMES.G, dataValueName: Constants.DATA_VALUES_NAMES.g, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.G },
    { tableField: Constants.FIELD_NAMES.ALPHA, dataValueName: Constants.DATA_VALUES_NAMES.alpha, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.ALPHA },
    { tableField: Constants.FIELD_NAMES.BETA, dataValueName: Constants.DATA_VALUES_NAMES.beta, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.BETA },
    { tableField: Constants.FIELD_NAMES.DT90, dataValueName: Constants.DATA_VALUES_NAMES.dt90, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.DT90 },

  ];

  public static readonly ENDPOINTS_GERMAN_SOIL = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.NON_NORMALISED_DT50_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.nonNormalisedDt50Soil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.NON_NORMALISED_DT50_SOIL },
    { tableField: Constants.FIELD_NAMES.MAXIMUM_OCCURRENCE_IN_SOIL, dataValueName: Constants.DATA_VALUES_NAMES.maximumOccurrenceSoil, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MAXIMUM_OCCURRENCE_IN_SOIL },
  ];

  public static readonly ENDPOINTS_EU_OPEX = [
    { tableField: Constants.FIELD_NAMES.MOLECULAR_WEIGHT, dataValueName: Constants.DATA_VALUES_NAMES.molecularWeight, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.MOLECULAR_WEIGHT },
    { tableField: Constants.FIELD_NAMES.FOLIAR_HALFLIFE, dataValueName: Constants.DATA_VALUES_NAMES.foliarHalfLife, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.DT50_FOLIAR },
    { tableField: Constants.FIELD_NAMES.INITIAL_DISLODGEABLE_FOLIAR_RESIDUE, dataValueName: Constants.DATA_VALUES_NAMES.initialDislodgableFoliarResidue, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.INITIAL_DISLODGEABLE_FOLIAR_RESIDUE },
    { tableField: Constants.FIELD_NAMES.SATURATED_VAPOUR_PRESSURE, dataValueName: Constants.DATA_VALUES_NAMES.saturatedVapourPressure, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.SATURATED_VAPOUR_PRESSURE },
    { tableField: Constants.FIELD_NAMES.ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL, dataValueName: Constants.DATA_VALUES_NAMES.acceptableOperatorExposureLevel, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL },
    { tableField: Constants.FIELD_NAMES.ACUTE_ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL, dataValueName: Constants.DATA_VALUES_NAMES.acuteAcceptableOperatorExposureLevel, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.ACUTE_ACCEPTABLE_OPERATOR_EXPOSURE_LEVEL },
    { tableField: Constants.FIELD_NAMES.DERMAL_ABSORPTION_PRODUCT, dataValueName: Constants.DATA_VALUES_NAMES.dermalAbsorptionProduct, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.DERMAL_ABSORPTION_PRODUCT },
    { tableField: Constants.FIELD_NAMES.DERMAL_ABSORPTION_USE_DILUTION, dataValueName: Constants.DATA_VALUES_NAMES.dermalAbsorptionInUseDilution, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.DERMAL_ABSORPTION_USE_DILUTION },
    { tableField: Constants.FIELD_NAMES.ORAL_ABSORPTION_ACTIVE_SUBSTANCE, dataValueName: Constants.DATA_VALUES_NAMES.oralAbsorptionActiveSubstance, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.ORAL_ABSORPTION_ACTIVE_SUBSTANCE },
    { tableField: Constants.FIELD_NAMES.INHALATION_ABSORPTION_ACTIVE_SUBSTANCE, dataValueName: Constants.DATA_VALUES_NAMES.inhalationAbsorptionActiveSubstance, configuration: this.ENDPOINT_FIELDS_CONFIGURATION.INHALATION_ABSORPTION_ACTIVE_SUBSTANCE },

  ]

  public static readonly MODELS_BY_COMPARTMENT_ENDPOINTS = [
    {
      compartment: Constants.COMPARTMENTS.SURFACE_WATER,
      models: [
        { name: Constants.MODELS.STEP_1_2, endpoints: this.ENDPOINTS_STEP1N2 },
        { name: Constants.MODELS.UK, endpoints: this.ENDPOINTS_TIER1UK },
        { name: Constants.MODELS.SWASH, endpoints: this.ENDPOINTS_SWASH },
        { name: Constants.MODELS.PWC, endpoints: this.ENDPOINTS_PWC },
      ]
    },
    {
      compartment: Constants.COMPARTMENTS.GROUND_WATER,
      models: [
        { name: Constants.MODELS.MACRO_GW, endpoints: this.ENDPOINTS_MACRO },
        { name: Constants.MODELS.PELMO, endpoints: this.ENDPOINTS_PELMO },
        { name: Constants.MODELS.PEARL, endpoints: this.ENDPOINTS_PEARL },
      ]
    },
    {
      compartment: Constants.COMPARTMENTS.SOIL,
      models: [
        { name: Constants.MODELS.EU_SOIL, endpoints: this.ENDPOINTS_EU_SOIL },
        { name: Constants.MODELS.GERMAN_SOIL, endpoints: this.ENDPOINTS_GERMAN_SOIL },
      ]
    },
    {
      compartment: Constants.COMPARTMENTS.SUBSTANCE_PROPERTIES,
      models: [
        { name: Constants.MODELS.EU_OPEX, endpoints: this.ENDPOINTS_EU_OPEX },
      ]
    },
  ]
}