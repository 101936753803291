<div class="grid" *ngIf="!isLoading"  style="margin-bottom: -11px;">
    <p-accordion class="col-12">
        <p-accordionTab *ngIf="items[0].visible" header="{{items[0].label}}">
            <ng-template pTemplate="content">
                <app-era-aquatic-run-risk-assessment-grid [isInverseModule]="isInverseModule" [selectedProject]="selectedProject" [eraRunRiskAssessmentComponent]="eraRunRiskAssessmentComponent"></app-era-aquatic-run-risk-assessment-grid>
            </ng-template>
        </p-accordionTab>
        <p-accordionTab *ngIf="items[1].visible" header="{{items[1].label}}">
            <ng-template pTemplate="content">
                <app-era-run-risk-assessment-terrestrial [isInverseModule]="isInverseModule" [selectedProject]="selectedProject" [eraRunRiskAssessmentComponent]="eraRunRiskAssessmentComponent"></app-era-run-risk-assessment-terrestrial>
            </ng-template>
        </p-accordionTab>
        <p-accordionTab *ngIf="items[2].visible" header="{{items[2].label}}">
            <ng-template pTemplate="content">
                <app-era-run-risk-assessment-bees [isInverseModule]="isInverseModule" [selectedProject]="selectedProject" [eraRunRiskAssessmentComponent]="eraRunRiskAssessmentComponent"></app-era-run-risk-assessment-bees>
            </ng-template>
        </p-accordionTab>
    </p-accordion>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
