import { Injectable } from '@angular/core';
import { Catalog } from '../models/echo/catalog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Species, SpeciesAPIObject } from '../models/species';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpApiService } from './http.api.service';

@Injectable({
  providedIn: 'root'
})
export class SpeciesApiService extends HttpApiService {

  override apiUrl: string=environment.ECHO_Api_URL; 

  getAllSpeciesAPICall(): Observable<any> {
    const body = null;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = 'api/species';

    return this.put(endpoint, body, headers).pipe(
      map((data) => {
        const rawSpeciesObject: any = data;
        const speciesAPIObject: SpeciesAPIObject = rawSpeciesObject.Value;
		    return speciesAPIObject.speciesList;
      })
    );
  }


  GetSpeciesByType(pSpecieType: number): Observable<Species[]> {
    const endpoint = `api/species/GetSpeciesByType/${pSpecieType}`
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }
}
