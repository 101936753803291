<div class="grid" *ngIf="!isLoading">
  <div style="margin-left: 1789px;margin-bottom: -10px;">
    <app-action-buttons-panel [useAddAction]="isProjectOwnershipValid && showAdd"
      (onBtAddEvent)="newRowAdded()"></app-action-buttons-panel>
  </div>
  <div class="col-12 p-3 mt-3">
    <app-grid #grid [domLayout]="'autoHeight'" [columnDefs]="columnDefs" [rowMultiSelectWithClick]="true"
      [rowSelection]="'multiple'" [suppressRowClickSelection]="true" [style]="style" [rowData]="rowData"
      [useCustomNewRow]="true" (deleteEvent)="saveData($event, false)" (saveEvent)="saveData($event)"
      (refreshEvent)="getData()" (onSelectedRowEvent)="onSelectedRow($event)"
      (dropDownSelectionChangeEvent)="dropDownSelectionChangeEvent($event)" (onBlurInputText)="onBlurInputText($event)"
      (onRowSelectedEmit)="onSelectionChanged($event)" [animateRows]="true" [groupDisplayType]="'custom'">
    </app-grid>
  </div>
</div>
<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
