import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EEAOutputProjectStep1n2Inputs } from './eea-output-project-step1n2-inputs.coldef';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectInputsApiService } from 'src/app/shared/services/eea-output-project-inputs.api.service';
import { EEAOutputProjectInputsLogicService } from 'src/app/shared/services/out-put-project/eea-output-project-logic.service';

@Component({
  selector: 'app-eea-output-project-step1n2-run-inputs',
  templateUrl: './eea-output-project-step1n2-run-inputs.component.html',
  styleUrls: ['./eea-output-project-step1n2-run-inputs.component.css'],
})
export class EeaOutputProjectStep1n2RunInputsComponent
  implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?:string;
  columDef: any;
  loading: boolean = true;
  rowData: any;

  constructor(
    private readonly colDef: EEAOutputProjectStep1n2Inputs,
    private readonly cdRef: ChangeDetectorRef,
    private readonly inputsUsedInCalculationsService: EEAOutputProjectInputsApiService,
    private readonly eeaOutputProjectInputsLogicService: EEAOutputProjectInputsLogicService
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          this.rowData = data.filter(item=> item.model == this.selectedModel);
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.columDef = this.colDef.getColumnsDefinition();
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          this.rowData = data.filter(item=> item.model == this.selectedModel);
        };
        this.loading = false;
      });
      this.cdRef.detectChanges();
    }
  }
}