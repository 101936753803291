import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Constants } from '../../utils/constants';
import { Project } from '../../models/project';

@Component({
  selector: 'app-model-checkbox',
  templateUrl: './model-checkbox.component.html',
  styleUrls: ['./model-checkbox.component.css']
})
export class ModelCheckboxComponent {

  @Input() selectedProject?: Project;
  @Input() compartment: any;
  @Input() metabolitePk?: number;
  @Input() selectModels: boolean = true;
  options: string[] = [];
  selectedOptions: string[] = [];
  @Output() onModelOptionsChangedEvent = new EventEmitter<any>();
  @Input() isProjectOwnershipValid: boolean = false;

  constructor(){
  }

  ngOnInit(): void {
    this.getModelsByCompartment(this.selectedProject);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject']?.currentValue?.projectPk)
      this.getModelsByCompartment(changes['selectedProject'].currentValue);
    if(changes['metabolitePk'])
      this.getModelsByCompartment(this.selectedProject);
    this.onModelOptionsChangedEvent.emit(this.selectedOptions);
  }

  onModelOptionsChanged(event: string[]) {
    this.onModelOptionsChangedEvent.emit(event);
  }

  getModelsByCompartment(project?: Project): void {
    if( project != null){
      let selectedModels: (string | undefined)[] | undefined

      if(this.metabolitePk)
        selectedModels = project?.projectXCompoundXModel?.filter(model => model?.MetabolitePk === this.metabolitePk).map(model => model?.ModelName);
      else
        selectedModels = project?.projectXCompoundXModel?.map(model => model?.ModelName);

      let modelsByCompartment = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.find(x => x.compartment === this.compartment)?.models;
      this.options = modelsByCompartment?.filter(model => selectedModels?.includes(model)) ?? [];
      if(this.selectModels){
        this.selectedOptions = this.options;
      }
    }
  }
}
