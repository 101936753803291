import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';

@Injectable({
    providedIn: 'root'
})
export class HHRAProjectQcColdef {

    constructor() { }

    public getQcColumnsDefinition(): ColDef[] {
        return [
            {
                headerName: 'Active Ingredient',
                field: 'run.substancePropertiesDataSet.substanceName',
                width: 250,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
                pinned: "left",
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Application Scheme',
                field: 'run.humanHealthApplicationScheme.name',
                width: 200,
                filter: 'agSetColumnFilter',
                editable: false,
                resizable: true,
                sortable: true,
                pinned: "left",
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'PPE Scheme',
                field: 'run.personalProtectionEquipmentScheme.name',
                width: 190,
                filter: 'agSetColumnFilter',
                pinned: "left",
                editable: false,
                resizable: true,
                sortable: true,
                tooltipValueGetter: (params) => params.value
            },
        ]
    }
}