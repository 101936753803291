import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Constants } from '../../utils/constants';
import { AdvancedFilterService } from '../../services/advanced-filter.service';
import { AdvancedFiltersGridColdef } from './advanced-filters-grid-coldef';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RowClickedEvent } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { forkJoin, of } from 'rxjs';


@Component({
  selector: 'app-advanced-filters-dialog',
  templateUrl: './advanced-filters-dialog.component.html',
  styleUrls: ['./advanced-filters-dialog.component.css'],
})
export class AdvancedFiltersDialogComponent implements OnInit {
  @Output() rowSelected = new EventEmitter<any>();


  visible: boolean = true;
  form: FormGroup;

  projects: any[] = [];
  createdBy: any[] = [];
  formulations: any[] = [];
  parents: any[] = [];
  metabolites: any[] = [];
  models: any[] = [];
  geographies: any[] = [];
  crops: any[] = [];
  statuses: any[] = [];
  loading: boolean = true;
  defaultArchived = 3;

  rowHeight = 45;
  columnDefs: any;
  rowData: any[] | null | undefined;
  rowSelection: 'single' | 'multiple' = 'single';
  gridApi: any;
  gridColumnApi: any;
  context: any;
  searched: boolean = false;
  defaultColDef = {
    resizable: true,
  };
  isSearching: boolean = false;
  maxDate!: Date;
  minDate!: Date;
  creationDates?: (Date)[];
  constructor(
    private fb: FormBuilder,
    private advancedFilterService: AdvancedFilterService,
    private advancedFiltersGridColdef: AdvancedFiltersGridColdef,
    private datePipe: DatePipe
  ) {
    this.columnDefs = this.advancedFiltersGridColdef.getColumnsDefinition();
    this.form = this.fb.group({
      project: [[]],
      createdBy: [[]],
      creationDate: [[]],
      formulation: [[]],
      parent: [[]],
      metabolite: [[]],
      model: [[]],
      geography: [[]],
      crop: [[]],
      archive: [''],
      status: [[]],
    });
  }

  ngOnInit(): void {
    this.loadProjects();
    this.loadUsers();
    this.loadFormulations();
    this.loadParents();
    this.loadMetabolites();
    this.loadModels();
    this.loadCountries();
    this.loadRegions();
    this.loadCrops();
    this.loadStatuses();
    this.loadDates();
  }

  private loadStatuses(): void {
    this.statuses = Object.keys(Constants.PROJECT_STATUS).map((key, index) => ({
      label: key.replace("_", " "),
      value: index
    }));
  }

  private loadProjects(): void {
    this.advancedFilterService.getAllProjectOptions().pipe(
      tap(projects => {
        this.projects = projects.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(project => ({
            label: `${project.value} (${project.pk})`,
            value: project.pk
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadUsers(): void {
    this.advancedFilterService.getUsersOptions().pipe(
      tap(users => {
        this.createdBy = users.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(user => ({
            label: user.value,
            value: user.value
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadFormulations(): void {
    this.advancedFilterService.getFormulationsUsed().pipe(
      map((formulations: any[]) => formulations.map(formulation => formulation.pk)),
      switchMap(pks => this.advancedFilterService.getFormulationsOptions(pks)),
      tap(formulations => {
        this.formulations = formulations.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(formulation => ({
            label: formulation.value,
            value: formulation.pk
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadParents(): void {
    this.advancedFilterService.getParentsUsed().pipe(
      map((parents: any[]) => parents.map(parent => parent.pk)),
      switchMap(pks => this.advancedFilterService.getParentsOptions(pks)),
      tap(parents => {
        this.parents = parents.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(parent => ({
            label: parent.value,
            value: parent.pk
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadMetabolites(): void {
    this.advancedFilterService.getMetabolitesUsed().pipe(
      map((metabolites: any[]) => metabolites.map(metabolite => metabolite.pk)),
      switchMap(pks => this.advancedFilterService.getMetabolitesOptions(pks)),
      tap(metabolites => {
        this.metabolites = metabolites.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(metabolite => ({
            label: metabolite.value,
            value: metabolite.pk
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadModels(): void {
    this.advancedFilterService.getModelsOptions().pipe(
      tap(models => {
        this.models = models.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(model => ({
            label: model.value,
            value: model.pk
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }
  private loadRegions(): void {
    this.advancedFilterService.getRegionsUsed().pipe(
      map((regions: any[]) => regions.map(region => region.pk)),
      switchMap(pks => this.advancedFilterService.getRegionsOptions(pks)),
      tap(regions => {
        let result = regions.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(region => ({
            label: region.value,
            value: region.pk,
            source: region.source
          }));

        this.geographies = this.geographies.concat(result)
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadCountries(): void {
    this.advancedFilterService.getCountriesUsed().pipe(
      map((countries: any[]) => countries.map(country => country.pk)),
      switchMap(pks => this.advancedFilterService.getCountriesOptions(pks)),
      tap(countries => {
        let result = countries.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(country => ({
            label: country.value,
            value: country.pk,
            source: country.source
          }));

        this.geographies = this.geographies.concat(result)
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadCrops(): void {
    this.advancedFilterService.getCropsOptions().pipe(
      tap(crops => {
        this.crops = crops.sort((a: any, b: any) => a.value.localeCompare(b.value))
          .map(crop => ({
            label: crop.value,
            value: crop.pk
          }));
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  private loadDates(): void {
    this.advancedFilterService.getDatesUsed().pipe(
      tap(dates => {
        const currentYear = new Date().getFullYear();
        const defaultDate = new Date(currentYear, 0, 1);   
        this.minDate = new Date(dates[0].value??defaultDate);
        this.maxDate = new Date(dates[1].value??defaultDate);
      })
    ).subscribe({
      complete: () => this.checkAllDataLoaded()
    });
  }

  onSearch(): void {
    this.searched = false;
    this.isSearching = true;

    const geographiesArrays = this.geographies.reduce(
      (acc: any, item: any) => {
        if (Array.isArray(this.form.value.geography) && this.form.value.geography.includes(item.label)) {
          if (item.source === "Region") {
            acc.Regions.push(item.value);
          } else if (item.source === "Country") {
            acc.Countries.push(item.value);
          }
        }
        return acc;
      },
      { Countries: [], Regions: [] }
    );

    if (this.form.value.creationDate) {
      const selectedStartDate = this.form.value.creationDate[0];
      const selectedEndDate = this.form.value.creationDate[1];
    
      if (selectedStartDate && selectedEndDate) {
        const startDate = new Date(selectedStartDate);
        const endDate = new Date(selectedEndDate);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        this.creationDates = [startDate, endDate];
      } else {
        this.creationDates = [];
      }
    }
    const advancedFilter = {
      ...this.form.value,
      geography: geographiesArrays,
      archive: this.defaultArchived == 3? null : this.defaultArchived,
      creationDate: this.creationDates != null ? this.creationDates : []
    };

    this.advancedFilterService.advancedFilter(advancedFilter).subscribe(response => {


      const rowDataPromises = response.map(async (project: any) => {

        const formulation = this.formulations.find(formulation => formulation.value === project.formulationPk);
        let formulationName = "";
        if (formulation)
          formulationName = formulation?.label ?? "";

        var parentsNames: any[] = [];
        project.parentPk.forEach((parentPk: any) => {
          let parent = this.parents.find(parent => parent.value === parentPk);
          if (parent?.label)
            parentsNames.push(" " + parent?.label);
        });

        var metabolitesNames: any[] = [];
        project.metabolitePk.forEach((metabolitePk: any) => {
          let metabolite = this.metabolites.find(metabolite => metabolite.value === metabolitePk);
          if (metabolite?.label)
            metabolitesNames.push(" " + metabolite?.label);
        });

        let regionName = null;
        let countryName = null;

        if (project.regionPk) {
          const geographyRegion = this.geographies.find(geography => geography.value === project.regionPk && geography.source == "Region");
          regionName = geographyRegion?.label ?? ""
        }

        if (project.countryPk) {
          const geographyCountry = this.geographies.find(geography => geography.value === project.countryPk && geography.source == "Country");
          countryName = geographyCountry?.label ?? ""

        }
        return {
          projectPk: project.projectPk,
          formulationPk: project.formulationPk ?? "",
          name: project.name,
          nameWithPk:project.name + " (" + project.projectPk+")" ?? "",
          createdBy: project.createdBy ?? "",
          createdDate: this.datePipe.transform(project.createdDate, 'MMM d, yyyy'),
          archive: project.archive ? "Yes" : "No",
          status: this.formatStatuses(this.statuses[project.status].label) ?? "",
          model: project.model ?? "",
          formulationName: formulationName ?? "",
          cropName: project.cropCoreGapLabels ?? "",
          parentName: parentsNames ?? "",
          metaboliteName: metabolitesNames ?? "",
          modelNames: project.model??"",
          geographyName: (regionName ? regionName : countryName) ?? "",
          regionPk: project.regionPk ?? null,
          geographyPk: project.geographyPk ?? null,
        };
      });
      Promise.all(rowDataPromises).then(resolvedRowData => {
        this.rowData = resolvedRowData;
        this.searched = true;
        this.isSearching = false;

      }).catch(error => {
        console.error('Error processing row data:', error);
      });
    });
  }

  private checkAllDataLoaded(): void {
    const allDataArrays = [
      this.projects,
      this.createdBy,
      this.formulations,
      this.parents,
      this.metabolites,
      this.models,
      this.geographies,
      this.crops,
    ];

    if (allDataArrays.every(arr => arr.length > 0)) {
      this.loading = false;
    }
  }
  formatStatuses(status: string): string {
    return status
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }


  onRowClicked(event: RowClickedEvent<any, any>) {
    this.rowSelected.emit(event.data);
  }
  clearFilter() {
    this.form.value.creationDate = [];
  }
}
