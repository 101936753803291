import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CropInterceptionApiService {

  constructor(private http: HttpClient) { }

  getCropInterceptionStep1And2(): Observable<any>
  {		
    const query = environment.BAMS_Api_URL + "api/cropInterceptionStep1And2";    				
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));    
  }

  getCropInterception(): Observable<any>
  {		
    const query = environment.BAMS_Api_URL + "api/cropInterception";    				
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));    
  }  
}
