import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectTier1ukInputsColdef } from './eea-output-project-tier1uk-inputs.coldef';
import { EEAOutputProjectInputsApiService } from 'src/app/shared/services/eea-output-project-inputs.api.service';
import { EEAOutputProjectInputsLogicService } from 'src/app/shared/services/out-put-project/eea-output-project-logic.service';
import { EEAGapLogicService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap.logic.service';

@Component({
  selector: 'app-eea-output-project-tier1uk-run-inputs',
  templateUrl: './eea-output-project-tier1uk-run-inputs.component.html',
  styleUrls: ['./eea-output-project-tier1uk-run-inputs.component.css']
})
export class EeaOutputProjectTier1ukRunInputsComponent implements OnInit, OnChanges {

  @Input() selectedProject?: Project;
  @Input() selectedModel?: string;

  columDef: any;
  loading: boolean = true;
  rowData: any;
  constructor(private colDef: EEAOutputProjectTier1ukInputsColdef,
    private cdRef: ChangeDetectorRef,
    private readonly inputsUsedInCalculationsService: EEAOutputProjectInputsApiService,
    private readonly eeaOutputProjectInputsLogicService: EEAOutputProjectInputsLogicService,
    private readonly eeaGapLogicService: EEAGapLogicService) { }


  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.columDef = this.colDef.getColumnsDefinition();
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          let filteredData = data.filter(item=> item.model == this.selectedModel);
          this.rowData = await this.eeaOutputProjectInputsLogicService.transformUkRunData(filteredData);
          this.rowData.forEach((element: any) => {
            this.eeaGapLogicService.setCropCoverage(element);
          });
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async data => {
        if (data.length > 0) {
          let filteredData = data.filter(item=> item.model == this.selectedModel);

          this.rowData = await this.eeaOutputProjectInputsLogicService.transformUkRunData(filteredData);
          this.loading = false;
        };
      });
      this.cdRef.detectChanges();
    }
  }
}