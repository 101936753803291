import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { ExposureAssessmentOutputViewApiService } from '../../../eea-output/eea-output.api.service';
import { EEAOutputProjectStep1n2ResultSummaryColdef } from './eea-output-project-step1n2-result-summary.coldef';
import { EEAOutputProjectSummaryResultSurfaceWaterApiService } from '../../../../../shared/services/out-put-project/eea-output-project-step1n2-summary-result.api.service';

@Component({
  selector: 'app-eea-output-project-step1n2-result-summary',
  templateUrl: './eea-output-project-step1n2-result-summary.component.html',
  styleUrls: ['./eea-output-project-step1n2-result-summary.component.css']
})
export class EEAOutputProjectStep1n2ResultSummaryComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?:string;
  rowData: any;
  columDef: any;
  loading: boolean = false;

  constructor(private step1n2ResultSummaryColdef: EEAOutputProjectStep1n2ResultSummaryColdef,
              private summaryResultService: EEAOutputProjectSummaryResultSurfaceWaterApiService,
              private viewOutputService: ExposureAssessmentOutputViewApiService ) {
  }

  ngOnInit(): void {
    this.columDef = this.step1n2ResultSummaryColdef.getColumnsDefinition();
    if(this.selectedProject)
      this.getSummaryResult(this.selectedProject.projectPk);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.getSummaryResult(changes['selectedProject'].currentValue.projectPk);
    }
  }

  getSummaryResult(projectPk: number) {
    if (projectPk != 0) {
      this.loading = true;
      this.summaryResultService
        .getSummariesResultsSurfaceWaterByModel(projectPk,this.selectedModel??"")
        .pipe(take(1))
        .subscribe((result: any) => {
           this.rowData = result.Value;
           this.loading = false;
        });
    }
  }
}