import { DropdownListRenderer } from "src/app/shared/renderers/dropdown-list/dropdown-list.component";
import { Injectable } from "@angular/core";
import { InputTextRenderer } from "src/app/shared/renderers/input-text/input-text.component";
import { ListboxRenderer } from "src/app/shared/renderers/listbox/listbox.component";
import { Constants } from "src/app/shared/utils/constants";
import { EEAGapLogicService } from "../eea-gap.logic.service";
import { ViewPwcScenariosButtonRenderer } from "src/app/shared/renderers/view-pwc-scenarios-button/view-pwc-scenarios-button.component";
import { ViewButtonRenderer } from "src/app/shared/renderers/view-button/view-button.component";

@Injectable({
    providedIn: 'root'
})
export class EEAGapPWCSurfaceWaterColdef {

    constructor(private eEAGapLogicService: EEAGapLogicService) { }

    public getPWCColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: 'Application Scheme',
                headerComponent: 'infoGridHeaderComponentComponent',
                headerComponentParams: {
                    name: 'Application Scheme',
                    infoText: Constants.MESSAGES.APP_SCHEME_INFO,
                    popupWidth: 450
                },
                field: 'name',
                type: 'default',
                resizable: true,
                pinned: "left",
                filter: 'agSetColumnFilter',
                cellRenderer: 'inputTextRender',
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                    isRequired: true
                },
                editable: false,
                cellEditorParams: {
                    maxLength: 200,
                },
            },
            {
                headerName: 'Scenarios',
                type: 'default',
                width: 100,
                editable: false,
                resizable: false,
                cellRenderer: ViewPwcScenariosButtonRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        label: 'Select',
                        disabled: !params.data.name,
                        showOverlay: true,
                        field: 'scenarios'
                    };
                }
            },
            {
                headerName: 'Simulation Type',
                field: 'simulationTypes',
                minWidth: 320,
                type: 'select',
                editable: false,
                cellRenderer: ListboxRenderer,
                cellRendererParams: {
                    options: options.simulationTypes,
                    optionValue: 'typeName',
                    optionLabel: 'typeName',
                    checkbox: true,
                    disabled: false,
                    editable: isProjectOwnershipValid,
                    multiple: true,
                },
                filter: 'agSetColumnFilter',
            },
            {
                headerName: 'Use spraydrift buffers for TPEZ',
                editable: false,
                field: 'useSpraydriftBuffersForTpez',
                type: 'selected',
                resizable: true,
                width: 130,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'useSpraydriftBuffersForTpez',
                        disabled: !isProjectOwnershipValid || !this.eEAGapLogicService.disableSprayDriftBufferForTPEZ(params.data.simulationTypes),
                    };
                }
            },
            {
                headerName: 'Date Type',
                field: 'dateType',
                resizable: true,
                width: 140,
                wrapText: true,
                type: 'default',
                filter: 'agSetColumnFilter',
                editable: false,
                cellRenderer: 'dropdownlistRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        options: Constants.DATE_TYPES,
                        columnThatModifies: 'dateType',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Crop Events',
                field: 'cropEvent',
                type: 'select',
                width: 140,
                resizable: true,
                hide: false,
                filter: 'agSetColumnfilter',
                editable: false,
                cellRenderer: 'dropdownlistRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        options: options.cropEvents,
                        optionValue: 'id',
                        optionLabel: 'name',
                        columnThatModifies: 'cropEvent',
                        disabled: !isProjectOwnershipValid || this.eEAGapLogicService.disableCropEvent(params.data.dateType)
                    };
                }
            },
            {
                headerName: 'Specify Years',
                editable: false,
                field: 'specifyYear',
                type: 'selected',
                resizable: true,
                width: 90,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'specifyYear',
                        disabled: !isProjectOwnershipValid
                    };
                }
            },
            {
                headerName: 'Number of Applications',
                field: 'numberOfApplications',
                type: 'select',
                minWidth: 120,
                maxWidth: 120,
                wrapText: true,
                filter: 'agSetColumnfilter',
                cellRenderer: 'dropdownlistRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        options: options.numbersOfApplication,
                        optionValue: 'value',
                        optionLabel: 'value',
                        columnThatModifies: 'numberOfApplications',
                        disabled: !isProjectOwnershipValid,
                    };
                },
            },
            {
                headerName: 'Application',
                editable: isProjectOwnershipValid,
                cellRenderer: 'agGroupCellRenderer',
                resizable: true,
                type: 'default',
            },
            {
                headerName: 'Use an application window to account for application day uncertainty',
                editable: false,
                field: 'useApplicationWindow',
                type: 'selected',
                resizable: true,
                width: 240,
                wrapText: true,
                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'useApplicationWindow',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Windows (days) to Windows (days)',
                wrapHeaderText: true,
                width: 140,
                field: 'windowsDaysToWindowsDays',
                cellDataType: 'number',
                cellRenderer: InputTextRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
                filter: 'agSetColumnFilter'
            },
            {
                headerName: 'Steps (days) to Step(days)',
                width: 130,
                wrapHeaderText: true,
                field: 'stepDaysToStepDays',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                },
                editable: false,
            },
            {
                headerName: 'Adjust application dates if raining',
                editable: false,
                field: 'adjustApplicationDatesIfRaining',
                type: 'selected',
                resizable: true,
                width: 140,
                wrapText: true,

                cellRenderer: 'checkBoxRenderer',
                cellRendererParams: (params: any) => {
                    return {
                        instance: this,
                        columnThatModifies: 'adjustApplicationDatesIfRaining',
                        disabled: !isProjectOwnershipValid,
                    };
                }
            },
            {
                headerName: 'Intolerable Rain (cm)',
                width: 120,
                wrapHeaderText: true,
                field: 'intolerableRainCm',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Intolerable Rain Window (days)',
                width: 130,
                wrapHeaderText: true,
                field: 'intolerableRainWindowDays',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellDataType: 'number',
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Optimum application window (days)',
                width: 155,
                wrapHeaderText: true,
                field: 'optimumApplicationWindowDays',
                resizable: true,
                type: 'default',
                cellRenderer: InputTextRenderer,
                filter: 'agSetColumnFilter',
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Minimum days between applications',
                width: 155,
                wrapHeaderText: true,
                field: 'minimumDaysBetweenApplications',
                resizable: true,
                cellRenderer: InputTextRenderer,
                filter: 'agSetColumnFilter',
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid || !params.data?.adjustApplicationDatesIfRaining,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Optional Outputs',
                type: 'default',
                width: 100,
                editable: false,
                resizable: false,
                cellRenderer: ViewButtonRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        label: 'View',
                        disabled: !params.data.name,
                        field: 'optionalOutputs'
                    };
                }
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: 'deleteButtonRenderer',
                type: 'rightAligned',
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid
                }
            }
        ]
    }

    public static getPWCDetailsColumnsDefination(options: any, isProjectOwnershipValid: boolean): any[] {
        return [

            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Number',
                        field: 'application_number',
                        width: 110,
                        resizable: true,
                        minWidth: 110,
                        editable: false,
                    },
                ],
            },
            {
                headerName: 'Rate (kg/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        minWidth: 360,
                        width: 360,
                        type: 'leftAligned',
                        editable: isProjectOwnershipValid,
                    },
                ],
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Date',
                        field: 'application_date',
                        minWidth: 160,
                        width: 160,
                        maxWidth: 140,
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                        cellRenderer: 'calendarRender',
                        editable: (params: any) => isProjectOwnershipValid && params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE ? true : false,
                        cellRendererParams: (params: any) => {
                            return {
                                disabled: params?.data?.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE ? false : true,
                                columnThatModifies: 'application_date',
                                highlightDate: new Date(2001, 0, 1),
                                editable: isProjectOwnershipValid && params?.data?.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE ? true : false,

                            };
                        },
                        cellStyle: (params: any) => {
                            if (params?.data?.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                return null;
                            }
                            return { 'background-color': '#f0f0f0d7' };
                        },
                    },
                    {
                        headerName: 'Days',
                        field: 'days_since',
                        minWidth: 85,
                        hide: false,
                        wrapHeaderText: true,
                        maxWidth: 120,
                        editable: (params: any) => {
                            if (params.data.dateType === Constants.DATE_TYPE_VALUES.RELATIVE && isProjectOwnershipValid) {
                                return true;
                            }
                            return false;
                        },
                        cellStyle: (params: any) => {
                            if (params.data.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE) {
                                return { 'background-color': '#f0f0f0d7' };
                            }
                            return null;
                        },
                        valueFormatter: (params: any) => {
                            if (params.value == undefined && params.data.dateType === Constants.DATE_TYPE_VALUES.RELATIVE || params.value === 0 && params.data.dateType === Constants.DATE_TYPE_VALUES.RELATIVE) return '';

                            return params.value;
                        },
                        cellRendererParams: (params: any) => {
                            return {
                                disabled: params?.data?.dateType === Constants.DATE_TYPE_VALUES.ABSOLUTE ? true : false,
                                columnThatModifies: 'days_since',
                                editable: isProjectOwnershipValid,

                            };
                        },
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                    },
                    {
                        headerName: 'Application Method',
                        field: 'application_method',
                        type: 'select',
                        wrapHeaderText: true,
                        minWidth: 180,
                        maxWidth: 242,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: 'dropdownlistRenderer',
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.applicationMethods,
                                optionValue: 'id',
                                optionLabel: 'name',
                                columnThatModifies: 'application_method',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Depth (cm)',
                        field: 'depth',
                        minWidth: 90,
                        wrapHeaderText: true,
                        maxWidth: 110,
                        editable: (params: any) => {
                            if (params.data.application_method === Constants.PWCControlsValidations.AboveCrop || params.data.application_method === Constants.PWCControlsValidations.BelowCrop) {
                                return false;
                            }
                            return true;

                        },
                        hide: false,
                        type: 'C2',
                        filter: 'agSetColumnFilter',
                        valueFormatter: (params: any) => {
                            if (params.data.application_method === Constants.PWCControlsValidations.AboveCrop || params.data.application_method === Constants.PWCControlsValidations.BelowCrop && params.value === undefined) return 4.0;
                            if (params.data.application_method !== Constants.PWCControlsValidations.AboveCrop && params.value === undefined || params.value === 0 && params.data.application_method !== Constants.PWCControlsValidations.AboveCrop || params.data.application_method === Constants.PWCControlsValidations.BelowCrop && params.value === 0 || params.data.application_method === Constants.PWCControlsValidations.BelowCrop && params.value === undefined) return '';
                            return params.value;
                        },
                        cellStyle: (params: any) => {
                            if (params.data.application_method === Constants.PWCControlsValidations.BelowCrop || params.data.application_method === Constants.PWCControlsValidations.AboveCrop) {
                                return { 'background-color': '#f0f0f0d7' };
                            }
                            return null;

                        },
                    },
                    {
                        headerName: 'Split',
                        field: 't_band_split',
                        minWidth: 85,
                        wrapHeaderText: true,
                        editable: (params: any) =>
                            params.data.application_method === Constants.PWCControlsValidations.TBANDSPLIT ? true : false,
                        hide: false,
                        valueFormatter: (params: any) => {
                            if (params.value === undefined && params.data.application_method === Constants.PWCControlsValidations.TBANDSPLIT || params.value === 0) return '';
                            if (params.value === undefined && params.data.application_method !== Constants.PWCControlsValidations.TBANDSPLIT || params.value === 0 && params.data.application_method !== Constants.PWCControlsValidations.TBANDSPLIT) return 0.0;
                            return params.value;
                        },
                        cellStyle: (params: any) => {
                            if (params.data.application_method === Constants.PWCControlsValidations.TBANDSPLIT) {
                                return null;
                            }
                            return { 'background-color': '#f0f0f0d7' };
                        },
                        width: 170,
                        type: 'numericColumn',
                        filter: 'agSetColumnFilter',
                    },
                    {
                        headerName: 'Drift Type',
                        field: 'drift_type',
                        type: 'select',
                        wrapHeaderText: true,
                        minWidth: 210,
                        maxWidth: 242,
                        filter: 'agSetColumnfilter',
                        editable: isProjectOwnershipValid,
                        cellRenderer: 'dropdownlistRenderer',
                        cellRendererParams: (params: any) => {
                            return {

                                options: options.drifTypes,
                                optionValue: 'id',
                                optionLabel: 'name',
                                columnThatModifies: 'drift_type',
                                disabled: !isProjectOwnershipValid,
                            };
                        },
                    },
                    {
                        headerName: 'Drift Buffer (ft)',
                        editable: isProjectOwnershipValid,
                        field: 'drift',
                        minWidth: 100,
                        maxWidth: 150,
                        wrapHeaderText: true,
                        valueFormatter: (params: any) => {
                            if (params.value == undefined) return 0;

                            return params.value;
                        },
                        width: 170,
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                    },
                    {
                        headerName: 'Period',
                        editable: isProjectOwnershipValid,
                        field: 'period',
                        minWidth: 90,
                        maxWidth: 150,
                        wrapHeaderText: true,
                        valueFormatter: (params: any) => {
                            if (params.value == undefined) return 1;

                            return params.value;
                        },
                        width: 170,
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                    },
                    {
                        headerName: 'Lag',
                        editable: isProjectOwnershipValid,
                        field: 'lag',
                        minWidth: 90,
                        maxWidth: 150,
                        valueFormatter: (params: any) => {
                            if (params.value == undefined) return 0;

                            return params.value;
                        },
                        wrapHeaderText: true,
                        width: 170,
                        type: 'leftAligned',
                        filter: 'agSetColumnFilter',
                    },
                ],
            },
        ];
    }
}
