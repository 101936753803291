<p-button 
[icon]="icon" 
[styleClass]="style"
[disabled]="disabled"
[raised]="raised"
[rounded]="rounded"
[text]="text"
[outlined]="outlined"
[severity]="severity"
[plain]="plain"
[size]="size"
[label]="label"
[iconPos]="iconPos"
[loading]="loading"
(onClick)="onClick($event)" 
(onFocus)="onFocus($event)" 
(onBlur)="onBlur($event)">
</p-button>