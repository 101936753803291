import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectPearlResultSummaryColdef {
  constructor() {}

  public GetMaxAmountOfGaps(SummaryResult: any[]): number {
    SummaryResult.sort((a, b) => b.Gapsummary.length - a.Gapsummary.length);
    return SummaryResult.length===0?0: SummaryResult[0].Gapsummary.length;
  }
  getConcentrationValue(params: any): any {
    const ingredientName = params.column.getColDef().headerName;
    const ingredient = params.data.ingredients.find(
      (ingr: any) => ingr.SubstanceName === ingredientName
    );
    return ingredient ? ingredient.concentration : null;
  }

  getAplicationRate(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.ApplicationRate : null;
  }

  getBBCH(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.BBCH : null;
  }

  getInterception(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.Interception : null;
  }
  getDosage(params: any): any {
    const gapName = params.column.getColDef().name;
    const application = params.data.Gapsummary.find(
      (gap: any) => gap.headerName === gapName
    );
    return application ? application.Dosage : null;
  }

  public children = {
    headerName: '',
    editable: false,
    wrapText: true,
    resizable: true,
    sortable: true,
    filterable: true,
    valueGetter: this.getConcentrationValue.bind(this),
    width: 220 ,
    filter: 'agSetColumnFilter',
  };

  public EntireColumAplication = {
    headerName: '',
    instance: this,
    children: [
      {
        headerName: 'Application Rate ',
        editable: false,
        wrapText: true,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getAplicationRate.bind(this),
        width: 160,
        filter: 'agSetColumnFilter',
        name: '',
      },
      {
        headerName: 'BBCH',
        editable: false,
        wrapText: true,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getBBCH.bind(this),
        width: 90,
        filter: 'agSetColumnFilter',
        name: '',
      },
      {
        headerName: 'Interception \n (%)',
        editable: false,
        wrapText: true,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getInterception.bind(this),
        width: 130,
        filter: 'agSetColumnFilter',
        name: '',
      },
      {
        headerName: 'Dosage',
        editable: false,
        wrapText: true,
        resizable: true,
        sortable: true,
        filterable: true,
        valueGetter: this.getDosage.bind(this),
        minWidth: 110,
        type: 'select',
        filter: 'agSetColumnFilter',
        instance: this,
        name: '',
      },
    ],
  };
  private columnDefs = [
    {
      headerName: 'General',
      instance: this,
      headerClass: 'dark-header-group',
      children: [
        {
          headerName: 'Run ID',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'RunID',
          width: 120,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Formulation',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Formulation',
          width: 140,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Summary',
      instance: this,
      children: [
        {
          headerName: 'Crop',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Crop',
          width: 180,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Scheme',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Active Substance',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'ActiveSubstance',
          width: 180,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Pathway',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Pathway',
          width: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Location',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'Location',
          minWidth: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Number Of \n Metabolites',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'NumberOfMetabolites',
          width: 120,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
      ],
    },
  ];

  public getColumnsDefinition(): any {
    return this.columnDefs;
  }
}