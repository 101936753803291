import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectStep1n2ResultSummaryColdef {

  constructor() { }

  public getColumnsDefinition(): any {
    return [
      {
        headerName: "Run ID",
        field: "runId",
        width: 130,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        pinned: true,
      },
      {
        headerName: "Application Scheme",
        field: "ApplicationScheme",
        width: 180,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: "Crop",
        field: "cropName",
        width: 160,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: "Endpoints Data Set",
        field: "Dataset",
        width: 200,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: "Compound",
        field: "Compound",
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: "Scenario FOCUS",
        field: "ScenarioFocus",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: "Region",
        field: "Region",
        width: 130,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        pinned: true,
      },
      {
        headerName: "Period",
        field: "Period",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        pinned: true,
      },
      {
        headerName: "Max PECsw \n (µg/L)",
        field: "MaxPecSw",
        width: 140,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: "21 d - PECsw,twa \n (µg/L)",
        field: "PecSwTwa21Days",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: `Max PECsed \n (µg/kg)`,
        field: "MaxPecSed",
        width: 140,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      }
    ];
  }
}