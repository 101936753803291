<input
  #inputRef
  type="text"
  [disabled]="disabled"
  pInputText [(ngModel)]="value"
  (blur)="onBlur($event)"
  [ngClass]="isValid ? 'p-inputtext' : 'p-inputtext-invalid'"
  (input)="onInputChange($event)"
  (keypress)="restrictToNumbers($event)"
  (paste)="restrictOnPaste($event)"  
/>