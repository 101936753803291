import { Component } from '@angular/core';
import { DataSet, DataSetPkAndDescription } from '../../models/echo/data-set';
import { EndpointDatasetLogicService } from '../../components/endpoint-dataset/endpoint-dataset.logic.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ImportDataSet } from '../../models/echo/import-data-set';
import { UserLogicService } from '../../services/user.logic.service';
import { Constants } from '../../utils/constants';

@Component({
  selector: 'app-import-dataset',
  templateUrl: 'import-dataset.component.html',
  styleUrls: ['import-dataset.component.css']
})
export class ImportDatasetRenderer implements ICellRendererAngularComp {

  public disabled: boolean = true;
  public filterable: boolean = true;
  public optionLabel: string = '';
  public options: DataSet[] = [];
  public optionValue: string = '';
  public params: any;
  public parent: any;
  public placeholder: string = 'Select an option';
  public selectedValue: any;

  constructor(private readonly logicService: EndpointDatasetLogicService,
    private readonly userLogicService: UserLogicService,
  ){}

  public agInit(params: any): void {    
    this.params = params;
    this.parent = params.context;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel;
    this.disabled = params.disabled;
    this.options = this.logicService.datasetsToImport;
    this.filterImportDatasetsByMoleculePk(params);
  }

  public refresh(): boolean {
    return false;
  }

  public onChange(pValue: any): void {
    const rowIndex: number = this.params.node.id;
    const originDataSetPk: number = Number(pValue.value);
    const datasetToImport: ImportDataSet = this.createImportDataset(originDataSetPk, rowIndex);
    this.parent.onCopyDataset(datasetToImport);
  }

  private createImportDataset(originDataSetPk: number, rowIndex: number): ImportDataSet {
    return {
      destinationDataSetPk: this.params.data.dataSetPk ?? -1,
      originDataSetPk: originDataSetPk,
      description: this.params.data.description,
      moleculePk: this.params.data.moleculePk,
      metabolitePk: this.params.data.metabolitePk,
      substanceType: this.params.data.substanceType,
      projectPk: this.logicService.projectPk!,
      createdBy: this.userLogicService?.profile?.displayName,
      hasEndpoints: this.params?.data?.hasEndpoints,
      rowIndex: rowIndex
    }
  }

  filterImportDatasetsByMoleculePk(params:any)
  {
    if (params.optionValue == 'dataSetPk' && params.data.substanceType)
    {
      this.options = this.options.filter(x => params.data.substanceType == Constants.SUBSTANCE_TYPES.ACTIVE ? x.moleculePk == params.data.moleculePk: x.metabolitePk == params.data.metabolitePk);
    }
  }

}
