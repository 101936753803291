import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationDateApiService {

  constructor(private http: HttpClient) { }

  getApplicationDatesSurfaceWater(): Observable<any>
  {		
    const query = environment.BAMS_Api_URL + "api/applicationDatesSurfaceWater";
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));    
  }

  getApplicationDatesSurfaceWaterByCropListPkAndBBCH(pCropListPk: number, pBBCH: number): Observable<any>
  {		
    const query = environment.BAMS_Api_URL + `api/applicationDatesSurfaceWater/${pCropListPk}/${pBBCH}`;
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));    
  }

  getApplicationDatesGroundWater(): Observable<any>
  {		
    const query = environment.BAMS_Api_URL + "api/applicationDatesGroundWater";
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));    
  }

  getApplicationDatesGroundWaterByCropListPkAndBBCH(pCropListPk: number, pBBCH: number, cropInterceptionPk:number): Observable<any>
  {		
    const query = `${environment.BAMS_Api_URL}api/applicationDatesGroundWater/${cropInterceptionPk}/${pCropListPk}/${pBBCH}`;
    return this.http.get(query)
    .pipe(map((data: any) => data.Value));    
  }
}
