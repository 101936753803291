import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalProtectionEquipmentSchemeApiService extends HttpApiService {

  override apiUrl: string = environment.BAMS_Api_URL;

  private ppesApiPrefix: string = 'api/ppes/';
  savePersonalProtectionEquipmentScheme(dataTransaction: any): Observable<boolean> {
    const body = JSON.stringify(dataTransaction);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `${this.ppesApiPrefix}personaProtectionEquipmentScheme`;
    return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getPersonalProtectionEquipmentScheme(pProjectPk: number): Observable<any[]> {
    return this
      .get(`${this.ppesApiPrefix}personaProtectionEquipmentSchemesByProjectPk/${pProjectPk}`)
      .pipe(map((data: any) => data.Value));
  }
}
