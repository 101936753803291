import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HumanHealthRiskAssessmentComponent } from './human-health-risk-assessment/human-health-risk-assessment.component';
import { SharedModule } from "../shared/shared.module";
import { EnvironmentalExposureAssessmentModule } from "../environmental-exposure-assessment/environmental-exposure-assessment.module";
import { HumanHealthRiskAssessmentEndpointsComponent } from './human-health-risk-assessments-endpoints/human-health-risk-assessment-endpoints/human-health-risk-assessment-endpoints.component';
import { HumanHealthRiskAssessmentSubstancePropertiesComponent } from './human-health-risk-assessments-endpoints/human-health-risk-assessment-substance-properties/human-health-risk-assessment-substance-properties.component';
import { HHRAGapComponent } from './hhra-gap/hhra-gap.component';
import { HhraGapNonDietaryComponent } from './hhra-gap/hhra-gap-non-dietary/hhra-gap-non-dietary.component';
import { PersonalProtectionEquipmentSchemeComponent } from './personal-protection-equipment-scheme/personal-protection-equipment-scheme.component';
import { HHRARunRiskAssessmentComponent } from './hhra-run-risk-assessments/hhra-run-risk-assessment.component';
import { HhraRunRiskAssessmentOpexResultComponent } from './hhra-run-risk-assessments/hhra-run-risk-assessment-opex-result/hhra-run-risk-assessment-opex-result.component';
import { AgGridModule } from 'ag-grid-angular';
import { ToxExpoInputsComponent } from './human-health-risk-assessments-endpoints/tox-expo-inputs/tox-expo-inputs.component';
import { ToxExpoInputsEndpointsComponent } from './human-health-risk-assessments-endpoints/tox-expo-inputs-endpoints/tox-expo-inputs-endpoints.component';
import { HhraRunRiskAssessmentOccupationalHandlerExposureComponent } from './hhra-run-risk-assessments/hhra-run-risk-assessment-occupational-handler-exposure/hhra-run-risk-assessment-occupational-handler-exposure.component';
import { HhraRunRiskAssessmentGeneralButtonsComponent } from './hhra-run-risk-assessments/hhra-run-risk-assessment-general-buttons/hhra-run-risk-assessment-general-buttons.component';
import { HhraProjectQcComponent } from './shared/hhra-project-qc/hhra-project-qc.component';
import { HhraOpexParametersComponent } from './shared/hhra-opex-parameters/hhra-opex-parameters.component';


@NgModule({
    declarations: [
        HumanHealthRiskAssessmentComponent,
        HHRAGapComponent,
        HhraGapNonDietaryComponent,
        HumanHealthRiskAssessmentEndpointsComponent,
        HumanHealthRiskAssessmentSubstancePropertiesComponent,
        PersonalProtectionEquipmentSchemeComponent,
        HHRARunRiskAssessmentComponent,
        HhraRunRiskAssessmentOpexResultComponent,
        ToxExpoInputsComponent,
        ToxExpoInputsEndpointsComponent,
        HhraRunRiskAssessmentOccupationalHandlerExposureComponent,
        HhraRunRiskAssessmentGeneralButtonsComponent,
        HhraProjectQcComponent,
        HhraOpexParametersComponent
    ],
    exports: [
        HumanHealthRiskAssessmentComponent,
        HHRAGapComponent,
        HHRARunRiskAssessmentComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        EnvironmentalExposureAssessmentModule,
        AgGridModule,
    ]
})
export class HumanHealthRiskAssessmentModule { }
