<p-dialog [(visible)]="visible" [modal]="true" header="header" [resizable]="true"  [style]="{ width: '60rem' }" (onHide)="close()"
  [maximizable]="false">
  <p-header class="w-2rem h-2rem p-0" style="text-decoration: underline;" >
    <a pRipple class="flex align-items-center gap-2 p-menuitem-link">
    Use existing Data Set
    </a>
  </p-header>
  <br />
  <div>
    <div class="singleselection-dropDown-div" style="text-align: left; margin-top: 2px">
      <span class="font-semibold w-6rem">Select Parent: </span>
      <p-dropdown [style]="{ width: '40%', 'line-height': '16px' }" styleClass="filter-dropdown"
        styleClass="ddlFilterSelectionMultiselect" panelStyleClass="panel-filter-dropdown" placeholder="Select a value"
        [disabled]="parentDropDownOptions.length == 0" [options]="parentDropDownOptions" [(ngModel)]="selectedCompound"
        optionLabel="compoundName" (onChange)="selectionChange($event)">
      </p-dropdown>
    </div>
  </div>
  <br />
  <div class="p-2">
    <p-listbox *ngIf="filteredDatasets.length > 0 " [filter]="true" [showToggleAll]="false"
      [(ngModel)]="dataSetListAdded" [multiple]="true" [checkbox]="true" [options]="filteredDatasets"
      [optionLabel]="'description'" [style]="{ width: '100%' }" (onClick)="addDataSetToList($event)">"
      header="Filtered Datasets" >
    </p-listbox>
  </div>
  <div style="text-align: center">
    <p-header *ngIf="dataSetListAdded.length > 0">
      <i class="pi pi-arrow-down" style="font-size: 2rem"></i>
    </p-header>
  </div>
  <div class="p-2 right=100%">
    <p-listbox *ngIf="dataSetListAdded.length > 0" [(ngModel)]="selectedDataSetAdded"
      [showToggleAll]="false" [selectAll]="false" [filter]="false" [multiple]="true" [checkbox]="true"
      [options]="dataSetListAdded" [optionLabel]="'description'" [style]="{ width: '100%' }" (onChange)="getPk($event)"
      (onClick)="removeDataSetByProjectAndPk($event)">
    </p-listbox>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="setDataSetToProject()" label="Apply" pAutoFocus [autofocus]="true"></p-button>
  </ng-template>
  <app-loading *ngIf="isLoading" class="flex align-items-center justify-content-center"></app-loading>
</p-dialog>