import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectPearlInputsUsedInCalculationColdef } from './eea-output-project-pearl-inputs-used-in-calculation-coldef';
import { EEAOutputProjectInputsApiService } from 'src/app/shared/services/eea-output-project-inputs.api.service';
import { EEAOutputProjectInputsLogicService } from 'src/app/shared/services/out-put-project/eea-output-project-logic.service';

@Component({
  selector: 'app-eea-output-pearl-run-inputs-use-in-calculation',
  templateUrl: './eea-output-pearl-run-inputs-use-in-calculation.component.html',
  styleUrls: ['./eea-output-pearl-run-inputs-use-in-calculation.component.css']
})
export class EeaOutputPearlRunInputsUseInCalculationComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?:string;
  columDef: any;
  loading: boolean = true;
  rowData: any;
  constructor(private columndefPearl: EEAOutputProjectPearlInputsUsedInCalculationColdef,
    private cdRef: ChangeDetectorRef,
    private readonly inputsUsedInCalculationsService: EEAOutputProjectInputsApiService,
    private readonly eEAOutputProjectInputsLogicService: EEAOutputProjectInputsLogicService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.columDef = this.columndefPearl.getColumnsDefinition();
    if (this.selectedProject) {

      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async (data: any) => {
        this.rowData = data.filter((item:any)=> item.model == this.selectedModel);
        this.loading = false;
      });
      this.cdRef.detectChanges();
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      this.inputsUsedInCalculationsService.getRunInputsUseInCalculationByModel(this.selectedProject?.projectPk!,this.selectedModel!).subscribe(async (data: any) => {
        this.rowData = data.filter((item:any)=> item.model == this.selectedModel);
        this.loading = false;
      });
      this.cdRef.detectChanges();
    }
  }
}