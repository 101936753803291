import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { ExposureAssessmentOutputViewApiService } from '../../../eea-output/eea-output.api.service';
import { EEAOutputProjectTier1ukPecDistanceColdef } from './eea-output-project-tier1uk-pec-distance.coldef';
import { EEAOutputProjectTier1ukPecDistanceService } from './eea-output-project-tier1uk-pec-distance.service';

@Component({
  selector: 'app-eea-output-project-tier1uk-pec-distance',
  templateUrl: './eea-output-project-tier1uk-pec-distance.component.html',
  styleUrls: ['./eea-output-project-tier1uk-pec-distance.component.css'],
})
export class EEAOutputProjectTier1ukPecDistanceComponent
  implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  rowData: any;
  columDef: any;
  loading: boolean = false;

  constructor(
    private ukPecDistanceColDef: EEAOutputProjectTier1ukPecDistanceColdef,
    private pecService: EEAOutputProjectTier1ukPecDistanceService,
    private viewOutputService: ExposureAssessmentOutputViewApiService
  ) { }

  ngOnInit(): void {
    this.columDef = this.ukPecDistanceColDef.getColumnsDefinition();
    if (this.selectedProject) this.getPecDistance(this.selectedProject.projectPk);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['selectedProject'].currentValue.projectPk)
      this.getPecDistance(changes['selectedProject'].currentValue.projectPk);
  }

  getPecDistance(projectPk: number) {
    this.loading = true;
    if (projectPk != 0) {
      this.pecService
        .getPecDistance(projectPk)
        .pipe(take(1))
        .subscribe((result: any) => {
          this.rowData = result.Value;
          this.loading = false;
        });
    }
  }
}