import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { EEAGapApiService } from '../eea-gap.api.service';
import { EEAGapLogicService } from '../eea-gap.logic.service';
import { EEAGapCoreColdef } from './eea-gap-core.coldef';
import { Constants } from 'src/app/shared/utils/constants';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { CropList } from 'src/app/shared/models/crop-list';
import { EEAGAPMenuLogicService } from '../eea-gap/eea-gap-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Utils } from 'src/app/shared/utils/utils';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { RowNode } from 'ag-grid-enterprise';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-eea-gap-core',
  templateUrl: './eea-gap-core.component.html',
  styleUrls: ['./eea-gap-core.component.css']
})
export class EEAGapCoreComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() isInverseModeling: boolean = false;
  @Input() menuService!: TabMenuLogicService;
  @ViewChild('grid') grid!: GridComponent;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() public isValid = new EventEmitter<boolean>();

  compartmentList: Compartment[] = [];
  columsDef: any;
  loading: boolean = false;
  rowData: any;
  compartmentPk: number = 0;
  molecules: any[] = [];
  isCore: boolean = true;
  cropList: CropList[] = [];
  geographiesList: any[] = [];
  selectedGeographies: string | undefined = "";
  gridStyle: string = 'width: auto; height: 600px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  rowHeight: number = 45;
  destroyRef = inject(DestroyRef);
  geographies: Catalog[] = [];
  detailsColumnDef: any;
  columnSizeMode:string = "fit";


  constructor(private gapCoreColdef: EEAGapCoreColdef,
    private gapApiService: EEAGapApiService,
    private gapLogicService: EEAGapLogicService,
    private EEAGAPMenuLogicService: EEAGAPMenuLogicService,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService) {
    this.setLoadingState(this.gapLogicService.loading);
  }

  ngOnInit(): void {
    this.EEAGAPMenuLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.grid) return;
      this.saveData(this.grid.transactionList);
    });

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (!this.grid) return;
      this.saveData(this.grid.transactionList);
    });

    this.setLoadingState(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue)
      this.fillInitValues();
  }

  public fillInitValues(): void {
    if (!this.selectedProject) return;

    const geographiesToFilter = [Constants.CROP_GEOGRAPHIES.EUROPE_UNION, Constants.CROP_GEOGRAPHIES.UK, Constants.CROP_GEOGRAPHIES.USA];
    let promises = [
      this.gapLogicService.getCompartments(),
      this.gapLogicService.getCropList(Constants.CROP_GEOGRAPHIES.GLOBAL, '', Constants.CROP_PURPOSES.GAP_CROP),
      this.gapLogicService.getRegionCountry(this.selectedProject),
      this.gapLogicService.getCropList(Constants.CROP_GEOGRAPHIES.USA, '', ''),
    ]
    Promise.all(promises)
      .then((results) => {
        this.compartmentList = results[0];
        this.cropList = results[1];
        this.geographiesList = results[2];
        if (this.selectedProject?.geography == Constants.CROP_GEOGRAPHIES.USA) {
          this.cropList = results[3];
        }
        this.getCoreCompartment();
        this.getData()
      });
  }

  getCoreCompartment() {
    this.compartmentPk = Number(this.compartmentList?.filter((x: any) => x.compartment == Constants.COMPARTMENTS.CORE)?.map(x => x.endpointCompartmentPk).flat());
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  getData() {
    if (!this.selectedProject) return;
    this.getApplicationSchemes(this.selectedProject, this.compartmentPk);
  }

  getApplicationSchemes(selectedProject: Project, compartmentPk: number) {
    if (!this.selectedProject) return;
    if (selectedProject?.projectPk != 0) {
      this.loading = true;
      const colDefParams = {
        bbch: this.gapLogicService.bbch,
        numerOfApplications: this.gapLogicService.numerOfApplications,
        cropList: Utils.sortObject(this.cropList, 'CropName'),
        geographiesList: this.geographiesList,
        geographySelected: this.selectedProject?.geography,
      };

      const columnsDefinition = this.isInverseModeling
        ? this.gapCoreColdef.getInverseColumnsDefinition(colDefParams, this.selectedProject, this.isProjectOwnershipValid)
        : this.gapCoreColdef.getColumnsDefinition(colDefParams, this.isProjectOwnershipValid);

      const detailsColumnDef = this.gapCoreColdef.configureDetailGrid(this.isProjectOwnershipValid);

      this.gapApiService.getApplicationSchemesByProjectAndCompartment(selectedProject.projectPk, compartmentPk)
        .pipe(take(1))
        .subscribe((data: any) => {
          if (!this.selectedProject) return;
          this.gapLogicService.addActiveIngredientsRatesToGrid(data, columnsDefinition, this.isInverseModeling, this.selectedProject, this.isProjectOwnershipValid, Constants.COMPARTMENTS.CORE).then(() => {
            this.columsDef = this.gapLogicService.columsDef;
            this.gapLogicService.addActiveIngredientsRatesToDetailGrid(this.gapLogicService.rowData, detailsColumnDef, this.isInverseModeling, this.selectedProject!, this.isProjectOwnershipValid, Constants.COMPARTMENTS.CORE);
            this.rowData = this.gapLogicService.rowData;
            this.detailsColumnDef = this.gapLogicService.detailsColumnDef;
            this.setLoadingState(false);
          });
        });
    }
  }

  saveData(dataTransaction: any[]) {
    dataTransaction = dataTransaction.filter((x: any) => Utils.isEmptyValue(x.row?.isChild));
    this.gapLogicService.createTransactionsForRates(dataTransaction, this.grid, this.isInverseModeling);
    if (dataTransaction.length == 0) {
      this.EEAGAPMenuLogicService.setSuccess(true);
      this.menuService.setSuccess(true);
      return;
    }

    if (!this.selectedProject) return;

    this.gapLogicService.setParametersToNewRows(dataTransaction, this.compartmentPk, this.isInverseModeling, this.selectedProject.projectPk, this.isCore, null, [], this.selectedGeographies);
    this.gapLogicService.setActiveIngredientsRate(dataTransaction, this.isCore, this.compartmentList, this.isInverseModeling, Constants.COMPARTMENTS.CORE);
    this.gapApiService.save(dataTransaction).pipe(take(1)).subscribe({
      next: () => {
        this.EEAGAPMenuLogicService.setSuccess(true);
        this.menuService.setSuccess(true);
      },
      error: (err: any) => {
        console.warn(err);
        this.EEAGAPMenuLogicService.setSuccess(false);
        this.menuService.setSuccess(false);
      }
    });
  }

  deleteRow(event: any) {
    if (event) {
      this.gapApiService
        .save(event)
        .pipe(take(1))
        .subscribe(() => { });
    }
    if (this.grid.gridApi.getDisplayedRowCount() === 1) {
      this.grid.gridApi.redrawRows();
    }
  }

  geographiesListBoxSelectionChanged(params: any) {
    if (!this.selectedProject) return;
    let oldValue: any = params.data['geographies'];
    let values = params.selectedValues.map((x: any) => { return { geographyPk: x.key } })
    params.data.applicationSchemeXGeography = values;
    this.grid.CreateTransaction(params.id, params.id, oldValue, params.data);
  }

  onDropDownSelectionChanged(event: any) {
    event.row.geographies = event.row.applicationSchemeXGeography?.length > 0 ? this.geographiesList.filter((x: any) => event.row.applicationSchemeXGeography.map((y: any) => y.geographyPk).includes(x.key)) : [];
    if (event.field === Constants.GAP_FIELD_NAMES.APPLICATION_NUMBER) {
      if (event.value === 1) {
        event.row.applicationInterval = 1;
        event.row.hasVariableRates = false;
      }
      else {
        event.row.hasVariableRates = true;
        event.row.isExpanded = false;
        this.gapLogicService.toggleDetailGrid(event, this.grid);
      }
      this.gapLogicService.setInnerGridValues(event, this.grid);
      this.grid.gridApi.redrawRows();
    }
    if (event.field === Constants.GAP_FIELD_NAMES.HAS_VARIABLE_RATES) {
      if (!event.value) {
        event.row.isExpanded = false;
        this.gapLogicService.toggleDetailGrid(event, this.grid);
      }
      this.grid.gridApi.redrawRows();
    }
  }

  onFirstDataRendered(params: any): void {
    if (params?.type === Constants.GRID_EVENTS.FIRST_DATA_RENDERED) {
      this.grid.gridApi.forEachNode((node: RowNode) => {
        if (node?.data?.numberOfApplications === 1) {
          node!.data.applicationInterval = 1;
          node!.data.hasVariableRates = false;
        }
      });
      this.grid.gridApi.redrawRows();
    }
  }

  onButtonExpandClick(event: any) {
    this.gapLogicService.toggleDetailGrid(event, this.grid);
  }

  newRowAdded(newItems: any[]) {    
    this.enableControls(false);
  }

  public onBlurInputText({row} : {row:any}): void {
    this.checkIfApplicationSchemeIsValid(row.name, row.applicationSchemePk);
  }

  private async checkIfApplicationSchemeIsValid(applicationSchemeName: string | undefined, applicationSchemePk: number | undefined): Promise<void> {
    const invalidRows = this.rowData.some((row: any) => row.name == undefined || row.name == '');
    const transactionOcurrences = this.gapApplicationSchemeLogicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.gapApplicationSchemeLogicService.duplicateDataInRecord(transactionOcurrences);
    applicationSchemePk = applicationSchemePk ?? -1

    if(applicationSchemeName != undefined && applicationSchemeName != '' ){
      if(duplicateDatasetsInTransaction)
        Utils.showErrorMessage('The Application Scheme name already exists.','Please use another name');
    }

    if(invalidRows || duplicateDatasetsInTransaction)
      this.enableControls(false);
    else
      this.enableControls(true);
  }

  private enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;  
    this.grid.columnDefs.find((c: any) => c.colId == 'delete').cellRendererParams.disabled = !enable;
    this.grid.gridApi.setColumnDefs(this.columsDef);
  }

}
