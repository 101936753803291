import { Injectable } from '@angular/core';
import { HttpApiService } from './http.api.service';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';
import { Observable, map } from 'rxjs';
import { Constants } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class RoleApiService extends HttpApiService {

  override apiUrl: string = environment.BAMS_Api_URL;

  public systemRoles: Role[] = [];
  public userRolesBitmask = 0;
  activeUserID: string | undefined;

  getSystemsRoles(): Observable<Role[]> {
    const endpoint = 'api/role';
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getUserRoles(pUserID?: string,): Observable<Role[]> {
    const endpoint = `api/role/User/${pUserID}/${Constants.THIS_APPLICATION}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }
}
