<div *ngIf="!loading">
    <div style="left: 30px; display: flex; width: 200px;">
        <div class="mt-2 col-3 strong">
            Model:
        </div>
        <div class="col-6">
            <app-dropdown [options]="options" [model]="selectedModel" (onChangeEvent)="onSeletedModelChange($event)"
                class="w-10">
            </app-dropdown>
        </div>
    </div>
    &nbsp;&nbsp;
    <div *ngIf="selectedModel != ''">
        <p-scrollPanel [style]="{ width: '100%', height: '80vh' }">
            <app-eea-output-project-step1n2 *ngIf="selectedModel.trim().includes(MODELNAMES.STEP_1_2.trim())"
                [selectedModel]="this.selectedModel"
                [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"></app-eea-output-project-step1n2>
            <app-eea-output-project-swash-swan
                *ngIf="selectedModel.trim().includes(MODELNAMES.SWASH.trim() ) || selectedModel.trim().includes(MODELNAMES.SWAN.trim() )"
                [selectedModel]="this.selectedModel" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"></app-eea-output-project-swash-swan>
            <app-eea-output-project-tier1uk *ngIf="selectedModel.trim().includes(MODELNAMES.UK.trim())"
            [selectedModel]="this.selectedModel" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"></app-eea-output-project-tier1uk>
            <app-eea-output-project-macro-gw *ngIf="selectedModel.trim().includes(MODELNAMES.MACRO_GW.trim())"
            [selectedModel]="this.selectedModel" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"></app-eea-output-project-macro-gw>
            <app-eea-output-project-pelmo *ngIf="selectedModel.trim().includes(MODELNAMES.PELMO.trim())"
            [selectedModel]="this.selectedModel" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"> </app-eea-output-project-pelmo>
            <app-eea-output-project-pearl *ngIf="selectedModel.trim().includes(MODELNAMES.PEARL.trim())"
            [selectedModel]="this.selectedModel" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"> </app-eea-output-project-pearl>
            <app-eea-output-project-pwc *ngIf="selectedModel.trim().includes(MODELNAMES.PWC.trim())"
            [selectedModel]="this.selectedModel" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus"
                [selectedProjectRunsSaved]="selectedProjectRunsSaved"> </app-eea-output-project-pwc>
        </p-scrollPanel>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>
<div *ngIf="models.length<= 0 && !loading">
    <h4>No models where found</h4>
</div>