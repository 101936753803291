import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectInputsApiService {

  constructor(private http: HttpClient) { }

  getInputByProjectPk(pProjectPk: number): Observable<any[]> {
    return this.http.get(`${environment.BAMS_Api_URL}api/getInputsUsedInCalculations/${pProjectPk}`).pipe(map((data: any) => data.Value));
  }


  getInputsUseInCalculation(pProjectPk: number): Observable<any[]> {
    return this.http.get(`${environment.BAMS_Api_URL}api/GetInputsForModels/${pProjectPk}`).pipe(map((data: any) => data.Value));
  }


  getRunInputsUseInCalculation(pProjectPk: number): Observable<any[]> {
    return this.http.get(`${environment.BAMS_Api_URL}api/GetRunInputsUseInCalculation/${pProjectPk}`).pipe(map((data: any) => data.Value));
  }
  getRunInputsUseInCalculationByModel(pProjectPk: number,pSelectedModel:string): Observable<any[]> {
    return this.http.post(`${environment.BAMS_Api_URL}api/GetRunInputsUseInCalculationByModel/${pProjectPk}`,{modelName:pSelectedModel}).pipe(map((data: any) => data.Value));
  }
}