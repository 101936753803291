import { ChangeDetectorRef, Component } from '@angular/core';
import { take } from 'rxjs';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';
import { TriggersEcotoxService } from './triggers-ecotox.service';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { Datafield } from 'src/app/shared/models/echo/data-field';
import { Discipline } from 'src/app/shared/models/echo/discipline';

@Component({
  selector: 'app-triggers-ecotox',
  templateUrl: './triggers-ecotox.component.html',
  styleUrls: ['./triggers-ecotox.component.css']
})
export class TriggersEcotoxComponent {
  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  afterSearch: boolean = false;
  componentThatImplementsGrid = this;
  disciplineData: Discipline[] = [];
  geographies: Catalog[] = [];
  loading: boolean = false;
  selectedRegion: string = '';
  datafields: Datafield[] = [];
  datafieldValues: DatafieldValue[] = [];
  allTaxonTriggers: TriggerEcotox[] = [];
  eecTypesList: Catalog[] = [];
  pecTypesList: Catalog[] = [];
  triggerTypesList: Catalog[] = [
    { name: 'ETR' },
    { name: 'HQ' },
    { name: 'TER' },
  ].sort((a, b) => a.name.localeCompare(b.name));
  triggerTiersList: Catalog[] = [
    { name: 'Tier 1' },
    { name: 'Tier 2' },
    { name: 'Tier 3' },
    { name: 'Tier 4' },
  ].sort((a, b) => a.name.localeCompare(b.name));
  operatorsList: Catalog[] = [
    { name: '<' },
    { name: '≤' },
    { name: '>' },
    { name: '≥' },
    { name: '=' },
  ];

  constructor(
    private dataFieldValueService: DatafieldValueApiService,
    private triggerEcotoxService: TriggersEcotoxService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.loadDataFieldValues();
  }

  getData(): void {
    this.setLoadingState(true);
    this.triggerEcotoxService
      .getTaxonTriggerList()
      .subscribe((triggers: TriggerEcotox[]) => {
        this.allTaxonTriggers = triggers.filter((x) => {
          return x.geography == this.selectedRegion;
        });
        this.setLoadingState(false);
        this.setAfterSearch(true);
      });
  }

  filterDataByGeography(regionPk: string): void {
    this.setAfterSearch(false);
    this.selectedRegion = regionPk;
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.detectChanges();
    this.getData();
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
  }

  setAfterSearch(pState: boolean): void {
    this.afterSearch = pState;
  }

  loadDataFieldValues(): void {
    this.eecTypesList = [];
    this.pecTypesList = [];
    this.fillEecTypesList();
    this.fillPecTypesList();
    this.setLoadingState(false);
  }

  fillEecTypesList() {
    this.dataFieldValueService
      .getDatafieldValuesByFieldName('EEC Type')
      .pipe(take(1))
      .subscribe((data: DatafieldValue[]) => {
        data.forEach((datafieldvalue) => {
          let eecTypeCatalog: Catalog = {
            key: datafieldvalue.datafieldValuePk,
            name: datafieldvalue.value,
          };
          this.eecTypesList.push(eecTypeCatalog);
        });
        this.eecTypesList.sort((a, b) => {
          if (a.name !== undefined && b.name !== undefined) {
            return a.name.localeCompare(b.name);
          }
          return 0;
        });
      });
  }

  fillPecTypesList() {
    this.dataFieldValueService
      .getDatafieldValuesByFieldName('PEC Type')
      .pipe(take(1))
      .subscribe((data: DatafieldValue[]) => {
        data.forEach((datafieldvalue) => {
          let pecTypeCatalog: Catalog = {
            key: datafieldvalue.datafieldValuePk,
            name: datafieldvalue.value,
          };
          this.pecTypesList.push(pecTypeCatalog);
        });
        this.pecTypesList.sort((a, b) => {
          if (a.name !== undefined && b.name !== undefined) {
            return a.name.localeCompare(b.name);
          }
          return 0;
        });
      });
  }
}
