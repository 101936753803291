<div class="content" style="margin-left:36px; margin-top: 12px; width:1500px;">

    <app-discipline [componentThatImplementsGrid]='componentThatImplementsGrid'></app-discipline>

    <div class="grid-container" *ngIf="afterSearch" [ngStyle]="{'display': afterSearch == true? 'block': 'none' }" style="margin-bottom: -11px;">
        <app-model-library-grid [dataComponent]='componentThatImplementsGrid'></app-model-library-grid>
    </div>

    <app-loading *ngIf="loading"></app-loading>

    <div kendoWindowContainer></div>
</div>