import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, DomLayoutType } from 'ag-grid-community';
import { Constants } from 'src/app/shared/utils/constants';
import { SetFilterModule } from 'ag-grid-enterprise';
import { ExcelExportModule } from 'ag-grid-enterprise';
import Swal from 'sweetalert2';
import { AdminGridTransaction } from './admin-grid-transaction';
import { Observable } from 'rxjs';
import { CalendarAdminRenderer } from '../renderers/calendar/calendar.component';
import { DeleteButtonAdminRenderer } from '../renderers/delete-button/delete-button.component';
import { DropDownListAdminRenderer } from '../renderers/drop-down-list/drop-down-list.component';
import { ListboxAdminRenderer } from '../renderers/listbox/listbox.component';
import { MultiSelectAdminRenderer } from '../renderers/multi-select/multi-select.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { RoleApiService } from 'src/app/shared/services/role.api.service';

@Component({
  selector: 'app-admin-grid',
  templateUrl: './admin-grid.component.html',
  styleUrls: ['./admin-grid.component.css']
})
export class AdminGridComponent implements OnInit {

  @ViewChild('agGrid') agGrid!: AgGridAngular;
  @Input() columnDefs: any;
  @Input() rowData: any;
  @Input() rowHeight: number = 0;
  @Input() headerHeight: number = 30;
  @Input() rowSelection: 'single' | 'multiple' = 'single';
  @Input() suppressRowClickSelection = false;
  style :string;
  public detailCellRenderer: any = null;
  public gridApi: any;
  public autosize = false;
  public selectedIds: any;
  public context: any
  gridColumnApi: any;
  transactionList: Array<AdminGridTransaction>;
  dataIsModified = false;
  editing = false;
  shouldValidate = true;
  cloneIsVisible = true;
  saveConstant:string = 'save';
  cancelConstant:string = 'cancel';
  isMasterDetail:boolean = false;
  columnsForValidateInExport = ['relatedToPk', 'MetStructure'];
  columnsNotIncludedExcelExport = ['MetStructure'];
  thisComponent = this;
  hasDataToSave = false;
  SaveDialogElement = Constants.SaveDialogElement;
  CancelDialogElement = Constants.CancelDialogElement;
  selectedRegistrationStatus: any;
  selectedInputtingStatus: any;
  isSaving: boolean = false;
  filteringValue: string = '';
  endpointComponent: any;
  mainCompartment: string = '';
  dynamicId: string = 'agGrid';
  dataSet: any;

  batchUpload: boolean = false;
  currentUserCanEdit: boolean = true;

  saveBtn: boolean = false;

  public defaultColDef: ColDef = {
    editable: this.currentUserCanEdit,
    filter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    sortable: true,
    resizable: true,
  };
  
  deleteColumn: ColDef = {
    colId: 'action',
    width: 125,
    minWidth: 125,
    maxWidth: 125,
    editable: this.currentUserCanEdit,
    cellRenderer: 'deleteButtonRenderer',
    cellRendererParams: {
      context: this,
    },
    type: 'rightAligned',
  };

  public modules = [
    SetFilterModule,
    ExcelExportModule
  ];

  frameworkComponents = {
    deleteButtonRenderer: DeleteButtonAdminRenderer,
    dropdownlistRenderer: DropDownListAdminRenderer,
    multiselectionRenderer: MultiSelectAdminRenderer,
    calendarRenderer: CalendarAdminRenderer,
    listboxRenderer: ListboxAdminRenderer,
    inputTextRender:  InputTextRenderer,
    inputNumberRenderer:  InputNumberRenderer,
  };
  
  columnTypes = {
    default: { editable: true, flex: 1, resizable: true, sortable: true, hide: false},
    select: { resizable: true, sortable: true, hide: false },
  };
  domLayout: DomLayoutType = 'autoHeight';
  constructor() {
    this.style = `width: auto; text-align: left; z-index:-1;font-size:10px; color: #656565; ${this.domLayout == 'normal' ? 'height: 611px;' : ''}`;
    this.transactionList = new Array();    
  }
  
  ngOnInit(): void {
    this.rowHeight = 50;
    if (this.columnDefs !== undefined) {
      this.columnDefs.push(this.deleteColumn);
    }
  }
  
  deleteRowFromGrid(): void {
    const selectedData = this.gridApi.getSelectedRows();
    const primaryKey = Object.keys(selectedData[0])[0];
    const dbUniqueID = selectedData[0][primaryKey];
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const res = this.gridApi.applyTransaction({ remove: selectedData });

    const index = this.transactionList?.findIndex(
      (obj) => obj.getRowUniqueID() === selectedNodes[0].id
    );

    if (selectedData[0]['newRow'] === true) {
      if (index > -1) {
        this.transactionList.splice(index, 1);
      }
    } else {
      const gridTransaction: AdminGridTransaction = this.newTransaction(
        selectedNodes[0].id,
        AdminGridTransaction.delete
      );
      gridTransaction.setRow(selectedNodes[0].data);
      this.transactionList.push(gridTransaction);
      
    }
  }
  
  onCellClicked(event: any) {
    if (event.colDef.field == 'arrow') {
      let newValue = !event.data.isExpanded;
      event.node.setDataValue('isExpanded', newValue);
    }
  }
  
  onCellMouseOver(pEvent: any) {
    this.filteringValue = pEvent.colDef.field;
  }
  
  modelUpdated(event: any){
    let expandedDetails: boolean = false;
    if (this.gridApi) {
      this.gridApi.clientSideRowModel.rowsToDisplay.forEach((element: any) => {
        if (element.expanded === true) {
          expandedDetails = true;
        }
      });
    }
  }
  
  onCellEditingStarted(event: any) {
    this.gridApi.deselectAll();
    this.editing = true;
  }
  
  onCellEditingStopped(event: any): void {
      this.editing = false;
  }
  
  onCellValueChanged(event: any): void {
    const primaryKeyValue = event.data[Object.keys(event.data)[0]];
    this.CreateTransaction(primaryKeyValue, event.node.id, event.oldValue, event.data);
    const changedData = [event.data]
    event.api.applyTransaction({ update: changedData });
    event.api.refreshCells();
  }
  
  CreateTransaction(primaryKeyValue: any, id: any, oldValue: any, data: any): void {
    let gridTransaction: AdminGridTransaction | undefined = this.transactionList.find(
      (obj) => obj.getRowUniqueID() === id
    );

    let transactionType: number;
    if (primaryKeyValue !== true && data['newRow'] != true) {
      transactionType = AdminGridTransaction.update;
    } else {
      primaryKeyValue = '-1';
      transactionType = AdminGridTransaction.insert;
    }
    if (gridTransaction == undefined) {
       gridTransaction = this.newTransaction(
        id,
        transactionType
      );
      data.originalValue = oldValue;
      if(gridTransaction)
        this.transactionList.push(gridTransaction);
    }
   
    gridTransaction.setRow(data);
  }
  
  newTransaction(pRowUniqueID: any, pType: any): AdminGridTransaction {
    this.dataIsModified = true;
    let gridTransaction: AdminGridTransaction = new AdminGridTransaction();
    gridTransaction.setTransactionType(pType);
    gridTransaction.setRowUniqueID(pRowUniqueID);
    return gridTransaction;
  }
  
  revertAllChanges(): void {
    this.getData();
    this.transactionList = [];
    this.editing = false;
  }

  getData() {}
  
  //TODO: Check error being displayed on console even when saving is working.
  saveData(transactionList: any): Observable<any> {
    return transactionList;
  }
  
  save(): void {
    this.isSaving = true;
    this.saveData(this.transactionList).subscribe({
      next:()=>{
        this.afterSaveSuccess();
      }
    ,error:(error: any)=>{this.afterSaveError(error)
      }},
    );
    this.editing = false;
  }

  afterSaveSuccess(): void {
    this.transactionList = [];
    Swal.fire({
      text: Constants.MESSAGE_SAVED_SUCCESSFULLY,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
    });
    this.getData();
    this.selectedIds = null;
    this.isSaving = false;
  }

  afterSaveError(error: any) {
    Swal.fire({
      text: error.error.Value.detail,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Ok',
      icon: 'error',
    });
    this.selectedIds = null;
    this.isSaving = false;
  }
  
  add(): void {
    this.genericProcedureForAddingARow([this.createNewRowData()])
  }
  
  genericProcedureForAddingARow(pNewItems: any){
    this.dataIsModified = true;
      pNewItems = this.changeItemValuesBySpecificContext(pNewItems);  
    const location = this.getLocationFromPageFirstElement();
    const res = this.gridApi.applyTransaction({
      add: pNewItems,
      addIndex: location,
    });

    this.gridApi.startEditingCell({
      rowIndex: location,
      colKey: this.columnDefs[0].field,
    });
  }
  
  changeItemValuesBySpecificContext(pNewItems: any) {
    return pNewItems;
  }
  
  getLocationFromPageFirstElement() {
    let location = 0;
    if (this.gridApi.paginationProxy.currentPage > 0) {
      location =
        this.gridApi.paginationProxy.currentPage *
        this.gridApi.paginationProxy.pageSize;
    }
    return location;
  }
  
  createNewRowData() {
    return {
      newRow: true,
      deletable: true,
      sortable: true
    };
  }

  onGridReady(params: any): void {
    params.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  
  public showDialog(element: any, action: string, count = 0): void {
    Swal.fire({
      title: element.title,
      text: element.content,
      showCancelButton: true,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        if (action === 'save') {
          this.save();
        } else if (action === 'delete') {
          this.deleteRowFromGrid();
        } else if (action === 'cancel') {
          this.shouldValidate = false;
          this.revertAllChanges();
        }
        else if (action === 'changeStatus') {
          this.changeStatus();
        }
        else if (action === 'clone') {
          this.cloneIsVisible = true;
        }
      } else {
        if (action === 'clone') {
          this.cloneIsVisible = true;
        }
      }
    });
  }
  
  public changeStatus(): void {
    this.selectedIds.forEach((element: any) => {
      if ((element.data.isAdmin) || (element.data.isEditor && element.data.status === Constants.CONST_STATUS_PENDING_FOR_REVISION))
      {
        if (this.selectedInputtingStatus && this.selectedInputtingStatus.value > 0)
        {
          this.gridApi
            .getRowNode(element.id)
            .setDataValue('status', this.selectedInputtingStatus.text);
        }
        if (this.selectedRegistrationStatus && this.selectedRegistrationStatus.datafieldValuePk > 0)
        {
          this.gridApi
            .getRowNode(element.id)
            .setDataValue('registrationStatus', this.selectedRegistrationStatus.value);
        }
      }
    });
    this.gridApi.redrawRows();
  }
  
  onFirstDataRendered(params: any): void {
    if (this.autosize) {
      var allColumnIds: any[] = [];
      params.columnApi.getColumns().forEach(function (column: any) {
        if (column.colDef.width == undefined) {
          allColumnIds.push(column.colId);
        }
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
    }
  }
  
  onBtExport() {
    this.gridApi.exportDataAsExcel({
      processCellCallback: (params: any) => {
        if (this.columnsNotIncludedExcelExport.indexOf(params.column.colId) === -1 ) {
          if (this.columnsForValidateInExport.indexOf(params.column.colId) > -1) {
            params.value = this.getNameForRelatedToColumn(params);
          }
          return params.value;
        }
      },
    });
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data.is_tracking && !node.data.isExpanded && node.data.isAdmin) {
      return false;
    }
    return true;
  }
  
  onSelectionChanged(event: any): void {
    this.selectedIds = event.api.getSelectedNodes();
  }
  
  getNameForRelatedToColumn(params: any): any {
    const fieldFilter = params.column.colDef.cellRendererParams.fieldFilter;
    const valueFilter = params.node.data[fieldFilter];
    const data = params.column.colDef.filterParams.cellRendererParams.values;
    return data.filter(
      (x: any) => x[fieldFilter] === valueFilter && x.id === params.value
    )[0].name;
  }
}

