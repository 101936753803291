import { Injectable } from "@angular/core";
import { CellRendererComponent } from "ag-grid-community/dist/lib/components/framework/componentTypes";
import { CheckboxComponent } from "src/app/shared/renderers/checkbox/checkbox.component";
import { DropdownFilterRenderer } from "src/app/shared/renderers/dropdown-filter/dropdown-filter.component";
import { DropdownListRenderer } from "src/app/shared/renderers/dropdown-list/dropdown-list.component";
import { InputTextRenderer } from "src/app/shared/renderers/input-text/input-text.component";
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root',
})
  
export class EEACopyProjectDialogColDef { 

    constructor() { }

    public getColumnsDefinition(): any{
        return [
            {
                headerName: 'Parent',
                field: 'substanceName',
                type: 'default',
                resizable: true, 
                width: 150,
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                  return {
                    disabled: true,
                  };
                },
                editable: false,
                cellEditorParams: {
                  maxLength: 200,
                },
            },
            {
                headerName: 'Create a:',
                field: 'createCopyOrReference',
                width: 155,
                resizable: true, 
                type: 'select',
                suppressSizeToFit: true,
                filter: DropdownFilterRenderer,
                cellRenderer: DropdownListRenderer,
                cellRendererParams: (params: any) => {
                  return {
                    options: [{ key: Constants.COPY_PROJECT_CREATE_VALUES.COPY }, { key: Constants.COPY_PROJECT_CREATE_VALUES.REFERENCE }],
                    columnThatModifies: 'createCopyOrReference',
                    optionLabel: 'key',
                    optionValue: 'key',
                    parent: this,
                    defaultItem: Constants.COPY_PROJECT_CREATE_VALUES.REFERENCE,
                    disabled: this.isDatasetCreateColumEditable(params.data.isEditable)
                  };
                },
                editable: false,
            },
            {
                headerName: 'Dataset name',
                field: 'description',
                type: 'default',
                resizable: true, 
                width: 300,
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {                    
                  return {
                    disabled: this.isDatasetColumEditable(params.data.createCopyOrReference),
                    isRequired: true,
                  };
                },
                editable: false,
                cellEditorParams: {
                  maxLength: 200,
                },
            },       
        ]
    }

    isDatasetColumEditable(data: string): boolean{
        let result = false; 

        result = data == Constants.COPY_PROJECT_CREATE_VALUES.REFERENCE ? true : false;

        return result;
    }

    isDatasetCreateColumEditable(data: boolean): boolean{
      let result = true; 
      
      result = data ? false : true;
      
      return result;
    }
}