import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from 'src/environments/environment';
import { EraPecEccAquaticCountry,
         EraPecEccAquaticDiscipline,
         EraPecEccAquaticEEACompartment,
         EraPecEccAquaticSubstancesPks,
         EraPecEccAquaticRegion } from './era-pec-ecc-aquatic.model';


@Injectable({
  providedIn: 'root'
})
export class ERAPecEccAquaticEchoApiService extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  private eraApiPrefix: string = 'api/eraPecEccAquatic/';

  getEEACompartments(): Observable<EraPecEccAquaticEEACompartment[]> {
    const endpoint = `${this.eraApiPrefix}getEEACompartments`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getRegionsByCountryPk(countryPk: number): Observable<EraPecEccAquaticRegion[]> {
    const endpoint = `${this.eraApiPrefix}getRegionsByCountryPk/${countryPk}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getCountriesByRegionPk(regionPk: number): Observable<EraPecEccAquaticCountry[]> {
    const endpoint = `${this.eraApiPrefix}getCountriesByRegionPk/${regionPk}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getSubstancesByPks(substancesPks: EraPecEccAquaticSubstancesPks): Observable<string[]> {
    const body = JSON.stringify(substancesPks);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/eraPecEccAquatic/getSubstancesByPks`;
    return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getDisciplines(): Observable<EraPecEccAquaticDiscipline[]> {
    const endpoint = `${this.eraApiPrefix}getDisciplines`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

}
