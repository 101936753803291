import { Injectable } from '@angular/core';
import { HttpApiService } from '../http.api.service';
import { environment } from 'src/environments/environment';
import { Formulation } from '../../models/formulation';
import { Observable, firstValueFrom, map } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { FormulationConcentration } from '../../models/echo/formulation-concentration';

@Injectable({
  providedIn: 'root'
})
export class FormulationApiService extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  getFormulationByPk(formulationPk: number, completeData: boolean = false): Observable<Formulation>
  {
    const endpoint = `api/formulation/${formulationPk}/${completeData}`;
    return this.get(endpoint)
               .pipe(map((data: any) => data.Value));
  }
  getAll():Observable<Formulation[]>
  {
    const endpoint = `api/formulation/getFormulationsNameAndPk`;
    return this.get(endpoint)
    .pipe(map((data:any)=>data.Value));
  }

  getFormulationsByName(searchName: string): Observable<Formulation[]>
  {
    const body = JSON.stringify(searchName);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/formulationname`;
    return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getFormulationConcentration(formulationPk: number): Observable<FormulationConcentration[]>
  {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/formulationConcentration/GetFormulationConcentrationData/${formulationPk}`;
    return this.get<FormulationConcentration[]>(endpoint, headers).pipe(map((data: any) => data.Value));

  }
}