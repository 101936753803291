import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectStep1n2ScenarioColdef {

  constructor() { }

  private columnDefs = [
    {
      headerName: "Step",
      field: "step",
      width: 80,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
      pinned: true,
    },
    {
      headerName: "Region",
      field: "region",
      width: 170,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
      pinned: true,
    },
    {
      headerName: "Season",
      field: "season",
      width: 160,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
      pinned: true,
    },
    {
      headerName: "Distance to the \n water body (m)",
      field: "distanceToWater",
      width: 150,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Spraydrift for single \n application (% of application)",
      field: "spraydriftSingleApplication",
      width: 185,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Runoff + drainage \n (% of application)",
      field: "runoffDrainage",
      width: 150,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Ratio of field \n to water body",
      field: "ratioFieldToWater",
      width: 120,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Water \n depth (cm)",
      field: "waterDepth",
      width: 110,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Sediment \n depth (cm)",
      field: "sedimentDepth",
      width: 113,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Effective sediment \n depth for sorption (cm)",
      field: "effectiveSedimentDepth",
      width: 180,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Sediment \n OC (%)",
      field: "sedimentOC",
      width: 110,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Sed.bulk \n density (kg/L)",
      field: "sedBulkDensity",
      width: 130,
      filter: "agSetColumnFilter",
      wrapText: false,
      resizable: true,
      sortable: true,
    },
  ];

  public getColumnsDefinition() {
    return this.columnDefs;
  }
}
