import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert2';
import { Constants } from '../../utils/constants';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnChanges  {

  
  @Input() model: any;
  @Input() icon: any;
  @Input() style: string = '';
  @Input() placeholder: string = "";
  @Input() type: string = "text";
  @Input() class: string = "";
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() floating: boolean = false;
  @Input() useTitle: boolean = false;
  @Input() useIcon: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isValid: boolean = true;
  @Input() isValueDuplicated: boolean = false;
  @Input() isValueDuplicatedMessage: string = '';
  @Input() maxlength: number = 1000;
  originalLabel: string = '';
  infoPopupVisible:boolean = false;
  
  @Output() onChangeEvent= new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['label']) this.originalLabel = this.label;
    if(changes['isRequired'])
    {
      if(this.label && this.isRequired) this.label = `${this.label}*`;
      this.originalLabel = this.label;
    }
    if(changes['isValueDuplicated']) {
      this.label = this.isValueDuplicated ? `${this.originalLabel} - ${this.isValueDuplicatedMessage}`: this.originalLabel;
    }
  }

  modelChanged(value: string){
    this.onChangeEvent.emit(value);
  }

  showInfoPopup() { 
    Swal.fire({
      title: 'Information',
      html: Constants.MESSAGES.PROJECT_INFO,
      icon: 'info',
      width:350     
    });
  } 
  
}
