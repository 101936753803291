<p-dialog
  header="Endpoints to import"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '95vw' }"
  [draggable]="false"
  [resizable]="false"
>

  <p-tabView [(activeIndex)]="activeTabIndex" (onChange)="onChangeTab()">
    <p-tabPanel *ngFor="let tab of tabs" header="{{tab.label}}"></p-tabPanel>
  </p-tabView>

  <p class="m-0">

    <app-grid #grid
      rowSelection="multiple"
      [domLayout]="'autoHeight'"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowHeight]="37"
      [pagination]="false"
      [domLayout]="'normal'"
      [height]="490"
      [rowMultiSelectWithClick]="true"
    ></app-grid>

  </p>

    <div class="mt-3 flex flex-row-reverse flex-wrap">

      <app-button
      [outlined]="true"
      severity="warning"
      label="Import"
      (onClickEvent)="onImport()"
    ></app-button>

    </div>

</p-dialog>
