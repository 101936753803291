import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(value: any[], orderBy: string ,orderType: "asc" | "desc" = "asc",  orderBy2?: string,): any {
    return value.sort((a, b) => {
      if (orderType === "asc") {
        if(orderBy2) return a[orderBy][orderBy2]?.toString().toLowerCase() < b[orderBy][orderBy2]?.toString().toLowerCase() ? -1 : 1;
        return a[orderBy]?.toString().toLowerCase() < b[orderBy]?.toString().toLowerCase() ? -1 : 1;
      }
      if (orderType === "desc") {
        if(orderBy2) return b[orderBy][orderBy2]?.toString().toLowerCase() < a[orderBy][orderBy2]?.toString().toLowerCase() ? -1 : 1;
        return b[orderBy]?.toString().toLowerCase() < a[orderBy]?.toString().toLowerCase() ? -1 : 1;
      }
      return 0;
    });
  }

}
