import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DisciplineService } from '../filters/discipline/discipline.service';
import { Constants } from 'src/app/shared/utils/constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-endpoint-upload-template',
  templateUrl: './endpoint-upload-template.component.html',
  styleUrls: ['./endpoint-upload-template.component.css']
})
export class EndpointUploadTemplateComponent {
  @Input() displayModal: boolean = false;
  @Input() parentComponent: any;
  @Output() closeModal: EventEmitter<boolean>;

  dataSet: any;
  endpointTypeIdentity: string = '';
  endpointComponent: any;    
  uploadedFiles: any[] = [];
  file: any;
  uploadingTemplate: boolean = false;

  constructor(private disciplineService: DisciplineService) {
    this.closeModal = new EventEmitter();
  }

  ngOnInit(): void {
    this.dataSet = this.parentComponent.dataSet;
    this.endpointTypeIdentity = this.parentComponent.endpointTypeIdentity;
    this.endpointComponent =  this.parentComponent.endpointComponent;
  }     
  
  upload() {
    this.uploadingTemplate = true;
    this.endpointComponent.filters.compartment = this.endpointTypeIdentity;
    this.endpointComponent.filters.discipline = Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE;
    this.endpointComponent.filters.application = Constants.THIS_APPLICATION;
    
    this.disciplineService.uploadDisciplineExcelTemplate(
      this.file.currentFiles[0],
      this.endpointComponent.filters,
      Constants.APPLICATIONS.BaMS,
      this.dataSet ? this.dataSet.dataSetPk : 0
    ).subscribe(
      (data: any) => {
        this.close();
        this.parentComponent.endpointComponent.showingEndpoints = false;
        this.parentComponent.endpointComponent.showEndpoints(this.dataSet);
        Swal.fire({
          text: Constants.MESSAGE_UPLOAD_SUCCESSFULLY,
          confirmButtonColor: '#0069be',
          confirmButtonText: 'Ok',
        });
    },
    (error) => {
      this.close();
      console.error(error);
      Swal.fire({
        title: error.error.Value.title,
        html: '<span style="white-space: pre-line">' + error.error.Value.detail + '</span>',
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Ok',
        icon: 'error',
      });
    });
  }
  
  close() {
    this.file = undefined;
    this.uploadingTemplate = false;
    this.displayModal = false;
    this.closeModal.emit(this.displayModal);
  }
  
  fileSelected(pFile: { currentFiles: any[] }) {
    this.file = pFile;
  }
  
  isDisabled() {
    if (this.file == undefined)
      return true;
    return this.uploadingTemplate;
  }
}
