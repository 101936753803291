import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EraGapTerrestrialApiService extends HttpApiService {

  override apiUrl: string = environment.BAMS_Api_URL;

  private eraApiPrefix: string = 'api/gap/';

  insertUpdateAndDeleteEraPecEccAquatic(dataTransaction: any): Observable<boolean> {
    const body = JSON.stringify(dataTransaction);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `${this.eraApiPrefix}riskAssessmentApplicationSchemes`;
    return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getApplicationSchemesByProjectAndCompartment(pProjectPk: number, pCompartmentPk: number): Observable<any[]> {
    return this
      .get(`${this.eraApiPrefix}applicationSchemesByProjectAndCompartment/${pProjectPk}/${pCompartmentPk}`)
      .pipe(map((data: any) => data.Value));
  }
}
