<div class="grid flex justify-content-end flex-wrap mr-2 mb-2">
    <app-model-checkbox class="mr-5" *ngIf="useModels" [compartment]="compartment" [selectedProject]="selectedProject"
        [isProjectOwnershipValid]="isProjectOwnershipValid"
        (onModelOptionsChangedEvent)="onModelOptionsChanged($event)">
    </app-model-checkbox>
    <app-action-buttons-panel [useAddAction]="useAddAction" [useSaveAction]="useSaveAction" [useServerExcelExportAction] = "useServerExcelExportAction" (onBtAddEvent)="add()"
        (onBtSaveEvent)="save()" (onBtExportEvent)="onBtExport()" (onBtServerExcelExportEvent)="onBtServerExcelExport()">
    </app-action-buttons-panel>
</div>
<ag-grid-angular 
    #agGrid
    class="ag-theme-alpine"
    [domLayout]="domLayout" 
    [id]="gridId" 
    [style]="style" 
    [rowData]="rowData" 
    [pagination]="pagination" 
    [paginationPageSize]="paginationPageSize"
    [suppressColumnVirtualisation]="true" 
    [columnDefs]="columnDefs"
    [autoGroupColumnDef]="autoGroupColumnDef" 
    [detailCellRendererParams]="detailCellRendererParams"
    [columnTypes]="columnTypes" 
    [components]="frameworkComponents" 
    [detailCellRenderer]="detailCellRenderer"
    [rowSelection]="rowSelection" 
    [doesExternalFilterPass]="doesExternalFilterPass" 
    [defaultColDef]="defaultColDef"
    [suppressRowClickSelection]="suppressRowClickSelection" 
    [rowHeight]="rowHeight"
    [context]="context" 
    [suppressCopyRowsToClipboard]="suppressCopyRowsToClipboard"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick"
    [gridOptions]="gridOptions"
    [groupDisplayType]="groupDisplayType"
    [groupHideOpenParents]="groupHideOpenParents"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [treeData]="treeData"
    [getDataPath]="getDataPath"
    [groupIncludeFooter]="groupIncludeFooter"
    [groupIncludeTotalFooter]="groupIncludeTotalFooter"
    [masterDetail]="isMasterDetail" 
    [detailRowAutoHeight]="detailAutoRowHeigth" 
    [animateRows]="animateRows"
    (gridReady)="onGridReady($event)" 
    (cellValueChanged)="onCellValueChanged($event)"
    (cellClicked)="onCellClicked($event)" 
    (cellEditingStarted)="onCellEditingStarted($event)"
    (cellEditingStopped)="onCellEditingStopped($event)" 
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowDataChanged)="onFirstDataRendered($event)" 
    (bodyScroll)="onFirstDataRendered($event)"
    (selectionChanged)="onSelectionChanged($event)" 
    (columnGroupOpened)="onFirstDataRendered($event)"
    (modelUpdated)="modelUpdated($event)"
    (cellMouseOver)="onCellMouseOver($event)" 
    (rowClicked)="onRowClicked($event)"
    (rowSelected)="onRowSelected($event)">
</ag-grid-angular>
