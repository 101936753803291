export interface EndpointsParameterConfigurationPwc {
  displayName:                string;
  endpointFieldValue:         string;
  showMoles:                  boolean;
}


export interface EndpointsParameterPwc {
  parentEndpointPk?:          number;
  datasetPk?:                 number;
  daughterMetabolitePk?:      number;
  daughterModified:           boolean;
  daugtherValue?:             number;
  displayName:                string;
  endpointFieldValue:         string;
  granDaugtherValue?:         number;
  grandDaughterModified:      boolean;
  grandDaughterMetabolitePk?: number;
  moleculePk?:                number;
  moleDaughterModified?:      boolean;
  moleDaugtherValue?:         number;
  moleGranDautherModified?:   boolean;
  moleGrandDaughterValue?:    number;
  parentModified:             boolean;
  parentValue?:               number;
  showMoles:                  boolean;
  daugtherEndpointPk?:        number;
  granddaughterEndpointPk?:   number;
  moleDaugtherEndpointPk?:      number;
  moleGranddaughterEndpointPk?: number;
}

export interface ChildLevel {
  metabolitePk:   number;
  metaboliteName: string;
  childLevel:     string;
  isActive:       boolean;
}

export interface ParentDataset {
  compoundPk:   number,
  compoundName: string,
  compoundType: string
}

export interface KocKd {
  value:       Koc_Kd,
  modified:    boolean,
  endpointPk?: number
}

export enum Koc_Kd {
  Koc = 'koc',
  Kd  = 'kd'
}

export enum RelationLevel {
  Parent =        'Parent',
  Daugther =      'Daugther',
  GrandDaugther = 'GrandDaugther'
}
