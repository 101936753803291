<div *ngIf="showInfo()" class="grid">
    <div class="col-12">
        <label class="font-bold">Model Specific Parameters:</label>
        <hr class="mb-3">
        <div class="grid" [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">
            <ng-template ngFor let-group [ngForOf]="endpointsGroups">
                <div class="customCol" *ngIf="group != ''">
                    <p-fieldset legend="{{group}}" [toggleable]="true">
                        <ng-template ngFor let-subGroup [ngForOf]="endpointsSubGroups">
                            <p-divider *ngIf="subGroup.group == group && subGroup.subGroup != ''" align="left">
                                <div class="inline-flex align-items-center"><b>{{subGroup.subGroup}}</b></div>
                            </p-divider>
                            <ng-container *ngFor="let endpoint of endpointParameters" class="mb-1">
                                <div *ngIf="endpoint.configuration.group == group && endpoint.configuration.subGroup == subGroup.subGroup && group == subGroup.group" class="field grid">

                                  <label
                                    for="inputNumber"
                                    class="col-fixed font-medium label-input-specific">
                                    {{endpoint.configuration.displayName ? endpoint.configuration.displayName + (endpoint.configuration.unit != undefined ? ' ' + endpoint.configuration.unit: '') :
                                      (endpoint.dataValueName | titlecase) + (endpoint.configuration.unit != undefined ? ' ' + endpoint.configuration.unit: '')}}:
                                    </label>

                                    <div *ngIf="endpoint.configuration.controlType == 'input-number'" class="col">
                                        <p-inputNumber (onBlur)="onBlurControl(endpoint.tableField)" [mode]="endpoint.configuration.dataType" [disabled]="isDisable(endpoint)" [(ngModel)]="endpoint.value" [maxFractionDigits]="endpoint.configuration.qtyDecimals"> </p-inputNumber>
                                    </div>
                                    <div *ngIf="endpoint.configuration.controlType == 'input-number-exponential'" class="col">
                                        <input type="text" pKeyFilter="num" pInputText [disabled]="isDisable(endpoint)" [ngModel]="endpoint.value" (ngModelChange)="endpoint.value=$event" (blur)="onBlurControl(endpoint.tableField)" (keyup)="onKeyUp(endpoint.tableField, endpoint.value)" />
                                    </div>
                                    <div *ngIf="endpoint.configuration.controlType == 'input-number-exponential-insert'" class="col">
                                        <input type="text" pInputText [pKeyFilter]="regexExponentialNumbers" [pValidateOnly]="true" [disabled]="isDisable(endpoint)" [ngModel]="endpoint.value" (ngModelChange)="endpoint.value=$event" [disabled]="endpoint.configuration.disabled" (blur)="onBlurControl(endpoint.tableField)"
                                        />
                                    </div>
                                    <div *ngIf="endpoint.configuration.controlType == 'input'" class="col">
                                        <input pInputText type="text" [disabled]="isDisable(endpoint)" [(ngModel)]="endpoint.value" (blur)="onBlurControl(endpoint.tableField)" />
                                    </div>
                                    <div *ngIf="endpoint.configuration.controlType == 'dropdown'" class="col">
                                        <p-dropdown *ngIf="endpoint.tableField == 'pelmoLetter'" [options]="endpoint.configuration.options" [(ngModel)]="endpoint.value" placeholder="" (onBlur)="onBlurControl(endpoint.tableField)"></p-dropdown>                                                                                                                                                                
                                        <img  *ngIf="endpoint.tableField == 'pelmoLetter'" class="info-label" (click)="openPelmoLetterSchemeDialog()" src="assets/img/infoIcon.png" alt="Pelmo Letter Information" style="width: 24px; height: 24px;cursor: pointer;">
                                        <p-dropdown *ngIf="endpoint.tableField == 'kineticModel'" [options]="endpoint.configuration.options" [(ngModel)]="endpoint.value" [style]="{'width': '72px'}" placeholder="Select a Model" (onChange)="onDDLChange(endpoint.tableField)" (onBlur)="onBlurControl(endpoint.tableField)"></p-dropdown>
                                    </div>
                                    <div *ngIf="endpoint.configuration.controlType == 'radio-button'" class="formgroup-inline -mb-2 ml-2" style="min-height: 30px;">
                                        <div *ngFor="let option of endpoint.configuration.options" class="field-radiobutton">
                                            <p-radioButton (onBlur)="onBlurControl(endpoint.tableField)" [inputId]="option.key" name="category" [disabled]="isDisable(endpoint)" [value]="option" [(ngModel)]="endpoint.value"></p-radioButton>
                                            <label [for]="option.key" class="ml-1 text-xs">{{ option.value }}</label>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </p-fieldset>
                </div>
            </ng-template>
        </div>
        <div class="grid" *ngIf="showFormationFractions()">
            <div class="col-4" *ngIf="compartment == 'Surface Water' || compartment == 'Groundwater'">
                <app-eea-endpoints-formation-fraction [isProjectOwnershipValid]="isProjectOwnershipValid" [leyend]="'Fraction transformed in soil'" [precursorType]="'fractionInSoil'" [rowData]="fractionSoilRowData" [precursorOptions]="precursorOptions" (modifiedRowData)="onFormationFractionRowModified($event)"></app-eea-endpoints-formation-fraction>
            </div>
            <div class="col-4" *ngIf="compartment == 'Surface Water'">
                <app-eea-endpoints-formation-fraction [isProjectOwnershipValid]="isProjectOwnershipValid" [leyend]="'Fraction transformed in surface water'" [precursorType]="'fractionInSurfaceWater'" [rowData]="fractionSurfaceWaterRowData" [precursorOptions]="precursorOptions"
                    (modifiedRowData)="onFormationFractionRowModified($event)"></app-eea-endpoints-formation-fraction>
            </div>
            <div class="col-4" *ngIf="compartment == 'Surface Water'">
                <app-eea-endpoints-formation-fraction [isProjectOwnershipValid]="isProjectOwnershipValid" [leyend]="'Fraction transformed in sediment'" [precursorType]="'fractionInSediment'" [rowData]="fractionSedimentRowData" [precursorOptions]="precursorOptions" (modifiedRowData)="onFormationFractionRowModified($event)"></app-eea-endpoints-formation-fraction>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="pelmoLetterSchemeVisible" (onHide)="closePelmoLetterSchemeDialog()" [modal]="true" >
        <img src="assets/img/pelmoLetterScheme.png"  />
    </p-dialog>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
