import { Component, Input, OnInit } from '@angular/core';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';
import { AdminGridComponent } from '../../admin-grid/admin-grid.component';
import { GeographyMatchingService } from './geography-matching.service';
import { GeographyMatching } from 'src/app/shared/models/geography-matching';
import { RoleApiService } from 'src/app/shared/services/role.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-geography-matching-grid',
  templateUrl: './../../admin-grid/admin-grid.component.html',
})

export class GeographyMatchingGridComponent extends AdminGridComponent implements OnInit {
  @Input() parentComponent: any;
  modelGeographies: DatafieldValue[] = [];

  constructor(private geographyMatchingService: GeographyMatchingService,
    private userService: UserLogicService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.rowHeight = 190;
    this.context = { componentParent: this };
    this.batchUpload = true;
  }

  override onGridReady(params: any): void {
    params.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.configureGrid();
  }

  getNamesGeographyMatchingLists() {
    this.rowData.forEach((row: { gapGeographiesNames: string[]; gapGeographies: Catalog[]; modelGeographiesNames: string[]; modelGeographies: DatafieldValue[]; modelsNames: string[]; models: ModelLibrary[]; }) => {
      row.gapGeographiesNames = row.gapGeographies.filter(x => x !== undefined).map(x => x!.name as string);
      row.modelGeographiesNames = row.modelGeographies.filter(x => x !== undefined).map(x => x!.value as string);
      row.modelsNames = row.models.map(x => x.name);
    });
  }

  configureGrid() {
    this.modelGeographies = this.parentComponent.datafieldValues.filter((x: any) => x.datafieldPk == this.parentComponent.datafields.filter((df: any) => df.value.toLowerCase() === Constants.DATA_VALUES_NAMES.modelGeography)[0].datafieldPk);
    this.rowData = this.fillingGeographyMatchingsLists(this.parentComponent.geographyMatchings);
    this.getNamesGeographyMatchingLists();
    this.columnDefs = [
      {
        headerName: 'GAP Geography',
        field: 'gapGeographiesNames',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.parentComponent.geographies,
          optionValue: 'key',
          optionLabel: 'name',
          columnThatModifies: 'gapGeographies',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter'
      },
      {
        headerName: 'Model Geography',
        field: 'modelGeographiesNames',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.modelGeographies,
          optionValue: 'datafieldValuePk',
          optionLabel: 'value',
          columnThatModifies: 'modelGeographies',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Model',
        field: 'modelsNames',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.parentComponent.models,
          optionValue: 'modelPk',
          optionLabel: 'name',
          columnThatModifies: 'models',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'action',
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          editable: this.currentUserCanEdit,
        },
        type: 'rightAligned',
      },
    ];
  }

  override getData(): void {
    this.parentComponent.getData();
  }

  fillingGeographyMatchingsLists(pGeographyMatchings: GeographyMatching[]): any {
    pGeographyMatchings.forEach((geographyMatching: GeographyMatching) => {
      const gapGeographiesPks = geographyMatching.gapGeographies.map((x) => x.key).flat();
      geographyMatching.gapGeographies = this.parentComponent.geographies.filter((g: any) => gapGeographiesPks.includes(g.key));
      const modelsGeographiesPks = geographyMatching.modelGeographies.map((x) => x.datafieldValuePk).flat();
      geographyMatching.modelGeographies = this.modelGeographies.filter((g) => modelsGeographiesPks.includes(g.datafieldValuePk));
      const modelsPks = geographyMatching.models.map((x) => x.modelPk).flat();
      geographyMatching.models = this.parentComponent.models.filter((g: any) => modelsPks.includes(g.modelPk));
    });
    return pGeographyMatchings;
  }

  public setSelectionValues(pValues: any, pField: string, pRow: any, pId: number) {
    let oldvalue = pRow[pField];
    pRow[pField] = pValues;
    pRow.disciplinePk = this.parentComponent.disciplinePk;
    this.CreateTransaction(pRow.id, pId, oldvalue, pRow);
  }

  override saveData(pData: any) {
    return this.geographyMatchingService.saveData(pData);
  }
}
