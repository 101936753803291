<div class="flex flex-row-reverse flex-wrap">
    <app-button label="All files" iconPos="right" [outlined]="true" severity="secondary"
        [icon]="isAllFilesDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'"
        (onClickEvent)="onDownloadClick()"></app-button>
    <app-button *ngIf="isUSA" style="margin-right: 5px;" label="Re-Run" iconPos="right" [outlined]="true"
        severity="secondary" [icon]="isRefreshingIcon ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'"
        (onClickEvent)="refreshData()"></app-button>
    <div *ngIf="!isUSA" style="color: darkorange;  margin-right: 112.3pc; position: absolute; margin-left: -49px; margin-top: 3px;">
        <i class="pi pi-info-circle" style="font-size: 2rem;"></i>
    </div>
    <div *ngIf="!isUSA" class="col-2" style="position: absolute; margin-right: 77pc; font-style: FF8000; color: darkorange; margin-top: 3px; width: 562px;">
        <span>If you make changes in any of the inputs, please go first to the QC tab in order to run the assessment again</span>
    </div>
    <div class="col-2" style="padding-top: 8px; padding-left: 94px;">
        <span>Project Status: &nbsp; <strong>{{ projectStatusStyle.statusName
                }}</strong></span>&nbsp;
        <p-badge pTooltip="{{selectedProject?.description}}" tooltipPosition="top"
            styleClass="{{projectStatusStyle.badgeStyle}}"></p-badge>
    </div>
</div>