import { Injectable } from '@angular/core';
import { Utils } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class EeaOutputProjectPwcInputsUsedInCalculationColdefService {

  constructor() { }
  private columnDefs = [
    {
      headerName: 'General',
      instance: this,
      children: [
        {
          headerName: 'Run ID',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'runId',
          width: 150,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Model',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'model',
          width: 150,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Application Pattern (GAP)',
      instance: this,
      children: [
        {
          headerName: 'Application Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'applicationSchemeName',
          width: 300,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Crop',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'crop',
          width: 170,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Data type',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'dateType',
          width: 200,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Crop Event',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'cropEvent',
          width: 200,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
       
      ],
    },
    {
      headerName: 'Endpoints',
      instance: this,
      children: [
        {
          headerName: 'Endpoint Data Set',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'endpointsDataSetName',
          minWidth: 200,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Active Ingredient',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'activeIngredient',
          minWidth: 200,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Substance Name',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          cellRenderer: 'agGroupCellRenderer',
          field: 'substanceName',
          minWidth: 200,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Molecular mass \n (g/mol)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'molecularWeight',
          minWidth: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Saturated vapour \n pressure (Pa)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'saturatedVapourPressure',
          minWidth: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Solubility \n In Water (mg/L)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'solubilityInWater',
          width: 130,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Air diffusion coefficient \n (cm3/d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'airDiffusionCoefficient',
          width: 165,
          filter: 'agSetColumnFilter',
        },        
        {
          headerName: 'Kfoc \n (L/Kg)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'kfoc',
          width: 100,
          filter: 'agSetColumnFilter',
        }, 
        {
          headerName: 'Half-life in \n soil (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'halfLifeInSoil',
          width: 120,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Measured at \n (Half life in soil) (°C)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'measuredAtHalflifeInSoil',
          width: 180,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Half-life in \n sediment (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'halfLifeInSediment',
          width: 120,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Measured at \n (Half life in  sediment) (°C)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'measuredAtHalflifeInSoil',
          width: 180,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Half-life \n in water (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'halfLifeInWater',
          width: 120,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Measured at \n (Half life in water) (°C)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'measuredAtHalflifeInSoil',
          width: 190,
          filter: 'agSetColumnFilter',
        },    
        {
          headerName: 'Foliar half-life \n (day)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'foliarHalfLife',
          width: 150,
          filter: 'agSetColumnFilter',
        },        
      ],
    },
  ];

  getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
