import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { ExposureAssessmentOutputViewApiService } from '../../../eea-output/eea-output.api.service';
import { EEAOutputProjectStep1n2ResultsCalculationColdef } from './eea-output-project-step1n2-results-calculation.coldef';
import { EEAOutputProjectStep1n2ResultCalculationApiService } from './eea-output-project-step1n2-result-calculation.api.service';

@Component({
  selector: 'app-eea-output-project-step1n2-results-calculation',
  templateUrl:
    './eea-output-project-step1n2-results-calculation.component.html',
  styleUrls: ['./eea-output-project-step1n2-results-calculation.component.css'],
})
export class EEAOutputProjectResultsCalculationComponent
  implements OnInit, OnChanges
{
  constructor(
    private resultsCalculationService: EEAOutputProjectStep1n2ResultCalculationApiService,
    private step1n2ResultCalculationColDef: EEAOutputProjectStep1n2ResultsCalculationColdef,
    private viewOutputService: ExposureAssessmentOutputViewApiService
  ) {}

  @Input() selectedProject?: Project;
  rowData: any;
  columDef: any;
  loading: boolean = false;
  
  ngOnInit(): void {
    this.columDef = this.step1n2ResultCalculationColDef.getColumnsDefinition();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.getResultsCalculation(changes['selectedProject'].currentValue.projectPk)
    }
  }

  getResultsCalculation(pProjectPk: number) {
    if (pProjectPk != 0) {
      this.loading = true;
      this.resultsCalculationService.getResultsCalculation(pProjectPk).pipe(take(1)).subscribe((result: any) => {
        this.rowData = this.viewOutputService.transformData(result.Value);
        this.loading = false;
      });
    }
  }
}