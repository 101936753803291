<div class="pt-1 pr-5 pb-5 pl-5">
    <div class="col-12 w-12">
        <app-wizard class="surface-200" [items]="tabsToDisplay" [message]="message" [loading]="loading" [activeItem]="activeItem"  [isSetupValid]="true" (activeIndexChangeEmitter)="onActiveIndexChange($event)" [isSetupValid]="isApplicationSchemeValid"></app-wizard>
    </div>

    <div class="col-12 w-12">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-eea-gap-core [isProjectOwnershipValid] ="isProjectOwnershipValid" [isInverseModeling]="false" [selectedProject]="selectedProject" [menuService]="menuService" (isValid)="getIsApplicationSchemeValid($event)"></app-eea-gap-core>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 1&& !pwcFlag">
            <app-eea-gap-surfacewater [isProjectOwnershipValid] ="isProjectOwnershipValid" [isInverseModeling]="isInverseModeling" [selectedProject]="selectedProject" [menuService]="menuService" (isValid)="getIsApplicationSchemeValid($event)"></app-eea-gap-surfacewater>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 1 && pwcFlag">
            <app-eea-gap-surfacewater-pwc [isProjectOwnershipValid] ="isProjectOwnershipValid" [selectedProject]="selectedProject" [subTabMenuService]="EEAGAPMenuLogicService" [menuService]="menuService" (isValid)="getIsApplicationSchemeValid($event)"></app-eea-gap-surfacewater-pwc>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 2">
            <app-eea-gap-ground-water [isProjectOwnershipValid] ="isProjectOwnershipValid" [isInverseModeling]="isInverseModeling" [selectedProject]="selectedProject" [menuService]="menuService" (isValid)="getIsApplicationSchemeValid($event)"></app-eea-gap-ground-water>
        </ng-container>
        <ng-container *ngIf="activeItem?.index == 3">
            <app-eea-gap-soil [isProjectOwnershipValid] ="isProjectOwnershipValid" [isInverseModeling]="isInverseModeling" [selectedProject]="selectedProject" [menuService]="menuService" (isValid)="getIsApplicationSchemeValid($event)"></app-eea-gap-soil>
        </ng-container>
        <ng-container *ngIf="activeItem.index == 4">
            <app-era-gap-terrestrial [isProjectOwnershipValid]="isProjectOwnershipValid" [subTabMenuService]="EEAGAPMenuLogicService" [menuService]="menuService"
                [selectedProject]="selectedProject" [isInverseModeling]="isInverseModeling"></app-era-gap-terrestrial>
        </ng-container>
    </div>
</div>