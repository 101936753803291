import { Injectable } from "@angular/core";
import { DateTimeCellRendererComponent } from "../../renderers/date-time/date.time.component";

@Injectable({
  providedIn: 'root'
})

export class PreviosRunsColdef {
  constructor() { }

  public getColumnsDefinition(): any[] {
    return [
      {
        headerName: 'Output Folder',
        field: "lastOutputFolder",
        width: 155,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        editable: false,
        wrapText: true,
      },
      {
        headerName: 'Run Date',
        field: "lastOutputFolderDate",
        width: 100,
        cellRenderer: DateTimeCellRendererComponent,
        editable: false,
        wrapText: true,
        sort: 'asc'
      },
      {
        headerName: 'Models',
        field: "modelsByRun",
        width: 400,
        resizable: true,
        editable: false,
        wrapText: true,
      },
    ];
  }
}