import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListboxChangeEvent } from 'primeng/listbox';

@Component({
  selector: 'app-listbox',
  templateUrl: './listbox.component.html',
  styleUrls: ['./listbox.component.css']
})
export class ListboxComponent {

  @Input() options: any[] = [];
  @Input() model: any;
  @Input() optionLabel: string = "";
  @Input() style: object = {'width':'15rem'};
  @Input() listStyle: object = {'max-height': '220px'};
  @Input() checkbox: boolean = false;
  @Input() filter: boolean = false;
  @Input() multiple: boolean = false;
  @Input() showToggleAll: boolean = false;
  @Input() disabled: boolean = false;

  
  @Output() onChangeEvent = new EventEmitter<ListboxChangeEvent>();

  onChange(event: ListboxChangeEvent) {
    this.onChangeEvent.emit(event);
  }
  
}
