import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Project } from '../../models/project';
import { Constants } from '../../utils/constants';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.css']
})
export class TabMenuComponent implements OnChanges {

  @Input() items: MenuItem[] = [];
  @Input() selectedProject?: Project;
  @Input() selectedProjectStatus?: number;
  @Input() isSetupValid: boolean = false;
  @Input() activeItem: MenuItem | undefined;

  @Output() onActiveIndexChangeEvent = new EventEmitter<MenuItem>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes[Constants.SELECTED_PROJECT])
    {
      this.validateSelectedProject();
    }
    if(changes[Constants.IS_SETUP_VALID])
    {
      this.validateSetup();
    }
    if(changes[Constants.SELECTED_PROJECT_STATUS])
    {
      this.validateSelectedProject();
    }
  }

  onActiveItemChange(item: MenuItem)
  {
    this.onActiveIndexChangeEvent.emit(item);
  }

  validateSelectedProject()
  {
    if(this.selectedProject){
      const originalItems = [...this.items];
      let setupItem = originalItems.find(x => x.label === Constants.EEA_MENU_LABELS.Setup);
      if(setupItem)
        setupItem.disabled = false;
      this.items = originalItems;
      this.validateRunProjectTab();
    }
  }

  validateSetup()
  {
    const originalItems = [...this.items];
    originalItems.map(item => {
      item.disabled = !this.isSetupValid;
    });
    this.items = originalItems;
    this.validateRunProjectTab();
  }

  validateRunProjectTab(): void {
    const originalItems = [...this.items];
    let runProjectItem = originalItems.find(x => x.label === Constants.EEA_MENU_LABELS.Run_Project);
    if(runProjectItem)
      runProjectItem.disabled = (this.selectedProject && this.selectedProject.status === 1) || !this.isSetupValid;
    this.items = originalItems;
  }
}