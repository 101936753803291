import { Injectable } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ResultSummaryMacroService {

  constructor(private http: HttpClient) { }

  GetSummaryResults( pProjectPk:number): Observable<any[]> {
    const query = `${environment.BAMS_Api_URL}api/GetSummaryResultMacro/${pProjectPk}`;
    return this.http.get(query).pipe(map((data: any) => data));
  }
  GetSummaryResultsByModel( pProjectPk:number, pSelectedModel:string): Observable<any[]> {
    const query = `${environment.BAMS_Api_URL}api/GetSummaryResultMacroByModel/${pProjectPk}`;
    return this.http.post(query,{modelName:pSelectedModel}).pipe(map((data: any) => data));
  }
}
