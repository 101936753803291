<ng-container *ngIf="!floating && !useTitle">
    <label *ngIf="label">{{label}}</label>
    <textarea pInputTextarea [rows]="rows" [cols]="cols" [id]="id" [style]="style" [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
</ng-container> 

<span *ngIf="floating" class="p-float-label">
    <textarea class="w-12" [id]="id" [rows]="rows" [cols]="cols" pInputTextarea  [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
    <label [for]="id">{{label}}</label>
</span>

<div *ngIf="useTitle" class="flex flex-column gap-2">
    <label [for]="id">{{label}}</label>
    <textarea class="w-12" pInputTextarea [id]="id" [rows]="rows" [cols]="cols"  [disabled]="disabled" [(ngModel)]="model" (ngModelChange)="modelChanged($event)"></textarea>
</div>