import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root',
})

export class ERASetupColDef {

  public getColumnsDefinition(aquaticModels: ModelLibrary[], terrestrialModels: ModelLibrary[], isProjectOwnershipValid: boolean): ColDef[] {
    return [
      {
        headerName: 'Compound',
        field: 'metaboliteName',
        width: 250,
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Aquatic',
        field: Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: aquaticModels,
            optionValue: 'modelPk',
            optionLabel: 'nameVersion',
            checkbox: true,
            filter: false,
            multiple: true,
            disabled: !isProjectOwnershipValid || params.data.disabled,
            validateModel: true,
            compartment: Constants.COMPARTMENTS.SURFACE_WATER.replace(" ","").toLowerCase()
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      },
      {
        headerName: 'Terrestrial',
        field: Constants.COMPARTMENTS.PLANT.toLowerCase(),
        width: 250,
        cellRenderer: 'listBoxRenderer',
        cellRendererParams: (params: any) => {
          return {
            options: terrestrialModels,
            optionValue: 'modelPk',
            optionLabel: 'nameVersion',
            checkbox: true,
            filter: false,
            multiple: true,
            disabled: !isProjectOwnershipValid || params.data.disabled,
            validateModel: true,
            compartment: Constants.COMPARTMENTS.PLANT.toLowerCase()
          }
        },
        filter: 'agSetColumnFilter',
        wrapText: true,
        resizable: true,
        sortable: true,
        editable: false,
      }
    ];
  }
}
