import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnChanges, OnInit {
  @Input() options: any[] = [];
  @Input() model: any;
  @Input() optionLabel: string = "";
  @Input() optionValue: string = "";
  @Input() showClear: boolean = false;
  @Input() style: any = {};
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() floating: boolean = false;
  @Input() useTitle: boolean = false;
  @Input() hardDisabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() filter: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isValid: boolean = true;
  @Input() filterBy: string = "";
  @Input() class: string = "w-12";
  @Input() scrollHeight: string = "200px";


  spinnerIcon: string = 'pi pi-spin pi-spinner';
  angleDownIcon: string = 'pi pi-angle-down';

  dropdownIcon: string = this.angleDownIcon;
  disabled: boolean = false;

  @Output() onChangeEvent = new EventEmitter<DropdownChangeEvent>();
  @Output() onClearEvent = new EventEmitter();

  ngOnInit(): void {
    if(this.label && this.isRequired) this.label = `${this.label}*`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) this.options = changes['options'].currentValue;

    if (changes['loading']) {
      this.loading = changes['loading'].currentValue;
      this.verifyLoading();
    }
    if (changes['hardDisabled']) this.verifyLoading();
  }

  verifyLoading() {
    this.disabled = this.loading;
    if(this.hardDisabled) this.disabled = this.hardDisabled;
    this.dropdownIcon = this.loading ? this.spinnerIcon : this.angleDownIcon;
  }

  onSelectionChanged(event: DropdownChangeEvent) {
    this.onChangeEvent.emit(event);
  }

  public onClear(): void {
    this.onClearEvent.emit();
  }
}
