import { Injectable } from '@angular/core';
import { ActiveIngredientApiService } from '../../services/echo/active-ingredient.api.service';
import { DataSetApiService } from '../../services/echo/data-set.api.service';
import { Molecule } from '../../models/echo/molecule';
import { DataSet } from '../../models/echo/data-set';
import { EndpointDatasetService } from '../endpoint-dataset/endpoint-dataset.service';
import { lastValueFrom, map, take } from 'rxjs';
import { DataSetByProject } from '../../models/echo/data-set-by-project';

@Injectable({
    providedIn: 'root',
})
export class UseEndpointDatasetLogicService {
    constructor(
        private activeIngredientApiService: ActiveIngredientApiService,
        private datasetService: DataSetApiService,
        private endPointDatasetService: EndpointDatasetService

    ) {

    }

    public getMoleculeByMoleculePks(activeIngredientsRates: number[]): Promise<Molecule[]> {
        return new Promise<Molecule[]>((resolve, reject) => {
            this.activeIngredientApiService.getMoleculeByMoleculePks(activeIngredientsRates).subscribe({
                next: (activeIngredients: Molecule[]) => {
                    resolve(activeIngredients);
                },
                error: (error: Error) => {
                    reject(error);
                }
            });
        });
    }

    public async getDataSetByMoleculesPks(moleculesPks: number[], source: string): Promise<DataSet[]> {
        let datasets: DataSet[] = [];
        const source$ = this.endPointDatasetService.getDataseByMoleculesPks(moleculesPks, source).pipe(take(1));
        return datasets = await lastValueFrom(source$)
    }

    public distinctByDescription<T extends { description: string | undefined }>(items: T[]): T[] {
        const uniqueDescriptions = new Set<string>();
        return items.filter(item => {
            if (item.description !== undefined && !uniqueDescriptions.has(item.description)) {
                uniqueDescriptions.add(item.description);
                return true;
            }
            return false;
        });
    }

    public async save(dataset: DataSetByProject[]): Promise<void> {
        const source$ = this.datasetService.setDataSetByProject(dataset).pipe(take(1));
        return await lastValueFrom(source$);
    }


    public async getDatasets(projectPk: number, source: string, moleculePk: number): Promise<DataSet[]> {
        const source$ = this.endPointDatasetService.getDataSetsByMoleculeExcludeDSProject(projectPk, source, moleculePk).pipe(take(1));
        return await lastValueFrom(source$);
    }

    public async getDataSetByProject( projectPk: number,source: string): Promise<DataSet[]> {
        const source$ = this.endPointDatasetService.getDataSetsByProject(projectPk, source).pipe(take(1));
        return await lastValueFrom(source$);
    }

    public async removeDataSetByProjectAndPK(projectPk: number, dataSetPks: number[]): Promise<void> {
        const source$ = this.datasetService.removeDataSetByProjectAndPk(projectPk, dataSetPks).pipe(take(1));
        return await lastValueFrom(source$);
    }
}
