import { inject, Injectable, signal } from '@angular/core';
import { EeaGapSurfacewaterPwcScenariosApiService } from './eea-gap-surfacewater-pwc-scenarios.api.service';
import { lastValueFrom, take } from 'rxjs';
import { CropXRegionUI, ResponseCropXRegion } from 'src/app/shared/models/pwc/scenarios.interface';

@Injectable({
  providedIn: 'root'
})
export class EeaGapSurfacewaterPwcScenariosLogicService {

  public cropXRegion: CropXRegionUI[] = [];
  public visible = signal(false);
  public currentRowId = signal<number | undefined>(undefined)
  public crops: string[] = [];

  private apiService = inject(EeaGapSurfacewaterPwcScenariosApiService);


  public async getCropsXRegion(): Promise<void> {
    const source$ = this.apiService.getScenarios().pipe(take(1));
    const responseCropXRegion = await lastValueFrom(source$);
    this.cropXRegion = responseCropXRegion.map( cropXRegion => ({
      ...cropXRegion,
      fullName: `${cropXRegion.crop}-${cropXRegion.region}`
    }));
    this.getCrops();
  }

  private getCrops(): void {
    this.crops = [...new Set(this.cropXRegion.map(cropXRegion => cropXRegion.crop))];
    this.crops = this.crops.sort((a, b) => a.localeCompare(b));
  }

  public getRegionsByCrop( crop: string): CropXRegionUI[] {
    return this.cropXRegion.filter(cropXRegion => cropXRegion.crop === crop);
  }


}
