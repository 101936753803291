
<div class="content" style="margin-left:36px; margin-top: 12px; width:1500px;">
    <div class="container">
        <form class="form-inline" role="form">
            <div class="form-group">
                <label>Geography:</label>
                <label>Compartment:</label>          
            </div>
            <div>          
                <p-dropdown [style]="{'width': '120px'}" class="geography-filter-dropdown" [ngModelOptions]="{standalone: true}" [options]="geographies" [(ngModel)]="selectedGeography" [disabled]="geographies.length === 0" placeholder="Europe Union" [virtualScroll]="true" [virtualScrollItemSize]="15" (onChange)="geographySelectionChange($event)"></p-dropdown>
                <p-dropdown class="compartment-filter-dropdown form-group-ddl" [ngModelOptions]="{standalone: true}" [options]="compartments" [(ngModel)]="selectedCompartment" [disabled]="compartments.length === 0" placeholder="Surface Water" [virtualScroll]="true" [virtualScrollItemSize]="15" (onChange)="compartmentSelectionChange()"></p-dropdown>
            </div>
        </form>
    </div>
    <div class="grid-container" *ngIf="afterSearch" [ngStyle]="{'display': afterSearch == true? 'block': 'none' }" style="margin-bottom: -11px;">
        <app-crop-list-matching-grid #childGrid [parentComponent]='this'></app-crop-list-matching-grid>
    </div>
    <app-loading *ngIf="loading"></app-loading>
</div>


