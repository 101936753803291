import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectStep1n2ResultsCalculationColdef {

  constructor() { }


  private columnDefs = [
    {
      headerName: "Run ID",
      field: "runID",
      width: 120,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
      pinned: true,
    },
    {
      headerName: "Region",
      field: "region",
      width: 100,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
      pinned: true,
    },
    {
      headerName: "Season",
      field: "season",
      width: 100,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
      pinned: true,
    },
    {
      headerName: "Application Scheme",
      field: "applicationSchemeName",
      width: 200,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Crop",
      field: "cropName",
      width: 160,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Endpoints data set",
      field: "endpointsDataSet",
      width: 200,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Compound",
      field: "compound",
      width: 120,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Number of application \n per season considered for this run",
      field: "numberApplicationPerSeasonConsidered",
      width: 180,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Equivalent application \n rate for drift (g/ha)",
      field: "equivalentApplicationRateDrift",
      width: 155,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Equivalent application rate \n for runoff/drainage (g/ha)",
      field: "equivalentApplicationRateDrainage",
      width: 177,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Loading to water body \n per drift event (mg/m²)",
      field: "loadingWaterBodyDrift",
      width: 160,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Loading to water body via runoff/drainage \n of parent substance (mg/m²)",
      field: "loadingWaterBodyDrainage",
      width: 201,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Fraction of substance entering \n water body in water phase",
      field: "fractionSubstanceEnteringWaterInWaterPhase",
      width: 190,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Fraction of substance entering \n water body in sediment",
      field: "fractionSubstanceEnteringWaterInSedimentPhase",
      width: 190,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Total Loading to water \n body via drift (mg/m²)",
      field: "totalLoadingToWaterBodyViaDriftMgPerMSquare",
      width: 159,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Total Loading to water \n body via drift (%)",
      field: "totalLoadingToWaterBodyViaDriftPercentage",
      width: 151,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Total Loading to water body \n via water phase (mg/m²)',
      field: "totalLoadingToWaterBodyViaWaterPhaseMgPerMSquare",
      width: 181,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: 'Total Loading to water \n body via water phase (%)',
      field: "totalLoadingToWaterBodyViaWaterPhasePercentage",
      width: 161,
      filter: 'agSetColumnFilter',
      wrapText: false,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Total Loading to water \n body via sediment phase (mg/m²)",
      field: "totalLoadingToWaterBodyViaSedimentPhaseMgPerMSquare",
      width: 181,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Total Loading to water \n body via sediment phase (%)",
      field: "totalLoadingToWaterBodyViaSedimentPhasePercentage",
      width: 181,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Maximum PECSW (µg/L)",
      field: "maximumPECSW",
      width: 109,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Maximum PECSW \n occuring on day (µg/L)",
      field: "maximumPECSWDay",
      width: 141,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Maximum PECsed \n (µg/kg dry sediment)",
      field: "maximumPECSED",
      width: 150,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Maximum PECsed \n occuring on day (µg/kg)",
      field: "maximumPECSEDDay",
      width: 145,
      filter: 'agSetColumnFilter',
      wrapText: true,
      resizable: true,
      sortable: true,
    },

  ];

  public getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
