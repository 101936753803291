import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EeaGapSurfacewaterPwcOptionalOutputsLogicService {

  public visible = signal(false);
  public currentRowId = signal<number | undefined>(undefined)
}
