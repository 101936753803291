import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, EndpointGet, EndpointSaveFormat } from '../../models/endpoint';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RunEndpointFilter } from '../../models/echo/run-endpoint-filter';

@Injectable({
  providedIn: 'root'
})
export class EndpointApiService {
  constructor(private http: HttpClient) {}

  getRunEndpoints(pFilters: RunEndpointFilter): Observable<Endpoint[]> {
    const body = JSON.stringify(pFilters);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/runEndpoints`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  saveAndUpdateEndpoints( endpointSaveFormat: EndpointSaveFormat[] ): Observable<boolean> {
    const body = JSON.stringify(endpointSaveFormat);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/endpointFunctions/saveAndUpdateEndpoints`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getEndpoints( datasetPk: number ): Observable<EndpointGet[]> {
    const query = `${environment.ECHO_Api_URL}api/endpointFunctions/getEndpoints?datasetPk=${datasetPk}`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
}
