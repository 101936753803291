<div class="grid border-bottom-5 border-dotted border-300 border-x-none border-top-none pt-3 pb-1">
    <div class="col-12 flex justify-content-center">
        <div class="col-3"></div>
        <div class="col-7">
            <div class="grid">
                <div class="col-7 pb-0 flex justify-content-start">
                    <label class="pt-3 text-secundary text-xl">Select an existing Project:</label>
                </div>
                <div *ngIf="currentUserCanRunModels"  class="col-5 pb-0 flex justify-content-start">
                    <label class="pt-3 text-secundary font-italic text-xl">or create a new one:</label>
                    <app-button class="ml-2 pt-2" [icon]="'pi pi-plus'" [rounded]="true" [raised]="true"
                        [severity]="'secondary'" [size]="'small'" (onClickEvent)="initNewProject()"></app-button>
                </div>
            </div>
        </div>
        <div class="col-2"></div>
    </div>
    <div class="col-12 flex justify-content-center">
        <div class="col-3" style="text-align: center;padding-right: 13%">
            <ng-container *ngIf="isEEA">
                <img src="assets/img/ppt.png" style="width: 40px; height: 40px; display: block; margin: 0 auto; margin-top: -9px;">
                <div>
                    <a href="https://agcompany.sharepoint.com/:p:/r/teams/ras/regsci/Shared Documents/BaMS Training.pptx?d=we6d25c8c4f72485f8d7a838b30dd420e&csf=1&web=1" style="margin-left: 20px; color: #0069BE;" target="_blank">Slide-deck</a>            
                    <a href="https://agcompany.sharepoint.com/teams/ras/regsci/_layouts/15/stream.aspx?id=%2Fteams%2Fras%2Fregsci%2FShared Documents%2FBaMS Training Session - _2 (Attend one of the two)-20240912_100233-Meeting Recording.mp4&referrer=StreamWebApp.Web&referrerScenario=AddressBarCopied.view.6d359f2c-71c8-4f7b-bba4-a07dd0d16d10" style="margin-left: 10px; color: #0069BE" target="_blank">Training video</a>                        
                </div>            
            </ng-container>
        </div>
        <div class="col-7">
            <div class="grid">
                <div class="w-8_5">
                    <div class="flex flex-column h-full">
                        <p-dropdown #selectedProjectDDL [(ngModel)]="selectedProject" [options]="searchFilteredProjects"
                            [placeholder]="placeholder" optionLabel="name" [showClear]="false" [filter]="true"
                            filterBy="name, projectPk" [filterFields]="['name','projectPk']"  scrollHeight="600px"
                            (onChange)="onSelectionChanged($event.value)">
                            <ng-template pTemplate="selectedItem">
                                <div class="flex align-items-center text-lg"
                                    *ngIf="selectedProject && selectedProject.projectPk > 0 && !loading">
                                    <div>
                                        <p class="project"><b>{{ selectedProject.name }}
                                            </b><em>{{selectedProject.projectPk}}</em></p>
                                    </div>
                                </div>
                                <div class="flex align-items-center text-lg"
                                    *ngIf="!selectedProject && placeholder === ' '">
                                    <div>
                                        <p class="project h-1-5"></p>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template let-project pTemplate="item">
                                <div class="flex align-items-center text-lg">
                                    <div>
                                        <p><b>{{ project.name }} </b><em>{{project.projectPk}}</em></p>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="col pt-0">
                    <app-button [label]="'My Projects'" [size]="'large'" [text]="true" [plain]="true"
                        [icon]="myProjectsFilter ? 'pi pi-check-square': 'pi pi-stop'" [iconPos]="'left'"
                        (onClickEvent)="userFilterProjects(!myProjectsFilter)"></app-button>
                </div>
            </div>
        </div>
        <div class="col-2 flex justify-content-end pt-1">
            <app-button class="pt-1" [label]="'Advanced Filters'" [size]="'large'" [text]="true" [plain]="true"
                [icon]="'pi pi-filter'" [iconPos]="'left'"
                (onClickEvent)="showAdvancedFiltersDialog()"></app-button>
        </div>
    </div>
</div>
<app-advanced-filters-dialog (rowSelected)="handleAdvancedFilterRow($event)" *ngIf="this.advancedFiltersVisibles"></app-advanced-filters-dialog>