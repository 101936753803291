import { Component, Input } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';

@Component({
  selector: 'app-era-pec-ecc',
  templateUrl: './era-pec-ecc.component.html',
  styleUrls: ['./era-pec-ecc.component.css']
})
export class ERAPecEccComponent {

  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

}
