import { Component, DestroyRef, Input, inject } from '@angular/core';
import { Compartment } from 'src/app/shared/models/echo/compartment';
import { CompoundPkAndName } from 'src/app/shared/models/compound';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { MetabolitePkAndName, MetaboliteXModel } from 'src/app/shared/models/echo/metabolite';
import { EndpointParameterCompound, EndpointParameterDetail } from 'src/app/shared/models/endpoint-parameter';
import { Project } from 'src/app/shared/models/project';
import { ProjectXMolecule } from 'src/app/shared/models/project-x-molecule';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { MetaboliteApiService } from 'src/app/shared/services/echo/metabolite.api.service';
import { MetaboliteBamsApiService } from 'src/app/shared/services/metabolite-bams.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { HHRAConstants } from 'src/app/human-health-risk-assessments/hhra-constants';

@Component({
  selector: 'app-tox-expo-inputs',
  templateUrl: './tox-expo-inputs.component.html',
  styleUrls: ['./tox-expo-inputs.component.css']
})
export class ToxExpoInputsComponent { @Input() selectedProject?: Project;
  @Input() compartmentList: Compartment[] = [];
  @Input() compartment: string;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  public coreDefaultValues: EndpointParameterDetail[];
  public dataset: DataSet;
  public isLoading: boolean;
  public metabolitesOptions: MetabolitePkAndName[];
  public moleculePk: number;
  public precursorOptions: CompoundPkAndName[];
  public projectPk: number;
  public selectedCompound: EndpointParameterCompound | undefined;
  public selectedMetabolitePk?: number;
  public selectedModels: string[];
  public showDetail: boolean;

  private datasetPk: number;
  private metabolitesPk: number[];
  readonly environmentalAssessment = HHRAConstants.HUMAN_ASSESSMENTS.HUMAN_HEALTH_RISK_ASSESSMENT;
  destroyRef = inject(DestroyRef);

  constructor(private readonly dataSetApiService: DataSetApiService,
    private readonly metaboliteBamsApiService: MetaboliteBamsApiService,
    private readonly MetaboliteEchoApiService: MetaboliteApiService) {
    this.compartment = '';
    this.coreDefaultValues = [];
    this.dataset = {} as DataSet;
    this.datasetPk = 0;
    this.isLoading = false;
    this.metabolitesOptions = [];
    this.metabolitesPk = [];
    this.moleculePk = 0;
    this.precursorOptions = [];
    this.projectPk = 0;
    this.selectedModels = [];
    this.showDetail = false;
  }

  public getDatasetDataOutput(dataset: any): void {
    this.dataset = dataset;
    this.projectPk = dataset.projectPk;
    this.moleculePk = dataset.moleculePk;
    this.datasetPk = this.dataset.dataSetPk ? this.dataset.dataSetPk : 0;
    this.getSelectedActiveIngredient(dataset.moleculePk);
    this.getCoreParametersDefaultValues();
    this.fillMetabolitesAndPrecursorDropdown();
  }

  public getSelectedModels(models: any): void {
    this.selectedModels = models;
  }

  public getSelectedMetabolite(metabolitePk: number): void {
    this.selectedCompound = {
      compoundPk: metabolitePk,
      compoundType: 'Metabolite',
      datasetPk: this.datasetPk,
      projectPk: this.selectedProject?.projectPk ?? -1,
      activePk: this.moleculePk,
      compoundName: this.metabolitesOptions.find(o => o.metabolitePk == metabolitePk)!.metaboliteName
    };
    this.selectedMetabolitePk = metabolitePk;
  }

  public getSelectedActiveIngredient(activeIngredientPk: number): void {
    if (activeIngredientPk == undefined)
      this.selectedCompound = undefined;
    else
      this.selectedCompound = {
        compoundPk: activeIngredientPk,
        compoundType: 'Active',
        datasetPk: this.datasetPk,
        projectPk: this.selectedProject?.projectPk ?? -1,
        activePk: activeIngredientPk,
        compoundName: this.dataset.activeIngredient!
      };
    this.selectedMetabolitePk = undefined;
  }

  public showDetailOutput(show: boolean): void {
    this.showDetail = show;
    this.selectedCompound = show ? this.selectedCompound : undefined;
  }

  private getCoreParametersDefaultValues(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getCoreDataSet().then((dataset: DataSet[]) => {
        this.dataSetApiService.getCoreEndpointsByDataSets(dataset).subscribe({
          next: (data: any) => {
            data?.forEach((x: any) => {
              x.endpoints.forEach((e: any) => {
                const type: string = e.substanceType;
                const pk: number = e.metabolitePk ? e.metabolitePk : e.moleculePk;
                e.groupedEndpointFieldValues.forEach((g: any) => {
                  this.coreDefaultValues.push({
                    type: type,
                    pk: pk,
                    dataValueName: g.key,
                    value: g.value
                  });
                });

              });
            });
            this.isLoading = false;
            resolve();
          },
          error: (error: Error) => {
            reject(error);
          }
        });
      });
    });
  }

  private getCoreDataSet(): Promise<DataSet[]> {
    this.isLoading = true;
    return new Promise<DataSet[]>((resolve, reject) => {
      this.dataSetApiService.getCoreDataSetByProjectPkAndMoleculePk(this.projectPk, this.moleculePk).subscribe({
        next: (dataset: DataSet[]) => {
          resolve(dataset);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  private fillMetabolitesAndPrecursorDropdown(): void {
    this.metabolitesOptions.length = 0;
    this.getMetabolitesByProjectAndMolecule().then((metabolitesXModels: MetaboliteXModel[]) => {
      this.setMetabolitesPkByCompartment(metabolitesXModels);
      this.getMetabolitesPkAndName();
    });
  }

  private getMetabolitesByProjectAndMolecule(): Promise<MetaboliteXModel[]> {
    const projectXMolecule: ProjectXMolecule = { projectPk: this.projectPk, moleculepk: this.moleculePk };
    return new Promise<MetaboliteXModel[]>((resolve, reject) => {
      this.metaboliteBamsApiService.getMetabolitesByProjectAndMolecule(projectXMolecule).subscribe({
        next: (metabolitesXModels: MetaboliteXModel[]) => {
          resolve(metabolitesXModels);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  private setMetabolitesPkByCompartment(metabolitesXModels: MetaboliteXModel[]): void {
    const currentModels: string[] | undefined = Constants.SPECIFIC_MODELS_BY_COMPARTMENT.find(m => m.compartment == this.compartment)?.models;
    const metabolitesPks: number[] = [];
    metabolitesXModels.forEach(metaboliteXModel => {
      if (currentModels?.some(c => c == metaboliteXModel.model))
        metabolitesPks.push(metaboliteXModel.metabolitePK);
    });
    this.metabolitesPk = metabolitesPks;
  }

  private getMetabolitesPkAndName(): void {
    this.MetaboliteEchoApiService.getMetabolitesPkAndName().subscribe({
      next: (compoundPkAndName: MetabolitePkAndName[]) => {
        this.metabolitesPk.forEach(metabolitePk => {
          const metabolite = compoundPkAndName.find(c => c.metabolitePk == metabolitePk);
          if (!this.metabolitesOptions.some(option => option.metaboliteName == metabolite?.metaboliteName))
            this.metabolitesOptions.push(metabolite!);
        });
        this.fillPrecursorDropdown();
      }
    });
  }

  private fillPrecursorDropdown(): void {
    const { activeIngredient, moleculePk } = this.dataset;
    const compoundName = activeIngredient ?? '';
    const compoundPk = moleculePk ?? 0;
    this.precursorOptions.push({ compoundName: compoundName, compoundPk: compoundPk, compoundType: Constants.SUBSTANCE_TYPES.ACTIVE });
    this.metabolitesOptions.forEach(m => {
      this.precursorOptions.push({ compoundName: m.metaboliteName, compoundPk: m.metabolitePk, compoundType: Constants.SUBSTANCE_TYPES.METABOLITE })
    });
  }
}
