import { Component, Input, SimpleChanges } from '@angular/core';
import {EaaOutputProjectPwcResultSummaryColdefService} from "./eaa-output-project-pwc-result-summary-coldef";
import { EEAOutputProjectInputsLogicService } from '../../eea-output-project-inputs.logic.service';
import { Project } from 'src/app/shared/models/project';;



@Component({
  selector: 'app-eaa-output-project-pwc-result-summmary',
  templateUrl: './eaa-output-project-pwc-result-summmary.component.html',
  styleUrls: ['./eaa-output-project-pwc-result-summmary.component.css'],
})
export class EaaOutputProjectPwcResultSummmaryComponent {
  @Input() selectedProject?: Project;
  @Input() selectedModel?: string;
  columDef: any;
  loading: boolean=true;
  constructor(
    private coldef: EaaOutputProjectPwcResultSummaryColdefService,
    public inputUsedInCalculationService: EEAOutputProjectInputsLogicService,
  ) {}




  async ngOnInit(): Promise<void> {
    this.columDef = this.coldef.getColumnsDefinition();
    if (this.selectedProject) {
      try {
        await this.inputUsedInCalculationService.getData(this.selectedProject);
       
      } catch (error) {
        console.error('Error Trying to obtain the data:', error);
      } finally {
        this.loading = false;
      }
    }
  }

  

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.selectedProject) {
      try {
        await this.inputUsedInCalculationService.getData(this.selectedProject);
      } catch (error) {
        console.error('Error trying to obtain the data:', error);
      } finally {
        this.loading = false;
      }
    }
  }
}