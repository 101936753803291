import { Injectable } from '@angular/core';
import { EraAquaticRunRiskAssessmentGridEchoApiService } from './era-aquatic-run-risk-assessment-grid-echo.api.service';

@Injectable({
  providedIn: 'root'
})
export class EraAquaticRunRiskAssessmentGridLogicService {


  constructor(private readonly echoApiService: EraAquaticRunRiskAssessmentGridEchoApiService) {}

  public getFormulationNameByPk(formulationPk: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.echoApiService.getFormulationNameByPk(formulationPk).subscribe({
        next: (formulation) => {
          resolve(formulation);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }
}
