import { Component, Input, OnInit } from '@angular/core';
import { ERARunRiskAssessmentComponent } from '../era-run-risk-assessment.component';
import { Project } from 'src/app/shared/models/project';
import { EraRunRiskAssessmentBeesLogicService } from './era-run-risk-assessment-bees.logic.service';
import Swal from 'sweetalert2';
import { EraRunRiskAssessmentBeesColDef } from './era-run-risk-assessment-bees.coldef';
import { BeesForInputs } from 'src/app/shared/models/bees/bees-for-Inputs';

@Component({
  selector: 'app-era-run-risk-assessment-bees',
  templateUrl: './era-run-risk-assessment-bees.component.html',
  styleUrls: ['./era-run-risk-assessment-bees.component.css']
})
export class EraRunRiskAssessmentBeesComponent implements OnInit {

  @Input() eraRunRiskAssessmentComponent!: ERARunRiskAssessmentComponent;
  @Input() selectedProject?: Project;
  @Input() isInverseModule?: boolean;
  isLoading!: boolean;
  isAllFilesDownloading: boolean = false;
  rowData: any[] = [];
  columnDefs: any = [];

  constructor(private eraRunRiskAssessmentBeesLogicService: EraRunRiskAssessmentBeesLogicService,
              private eraRunRiskAssessmentBeesColDef: EraRunRiskAssessmentBeesColDef) { }

  ngOnInit(): void {
    this.setLoading(true);
    this.runBees();
  }


  onDownloadClick(): void {
    this.setButtonLoading(true);
    this.eraRunRiskAssessmentBeesLogicService
      .DownloadBeesOutputFilesByProject(this.eraRunRiskAssessmentBeesLogicService.createProjectObject(this.selectedProject!))
      .then((data: any) => {
        this.setButtonLoading(false);
        if (data) {
          window.open(data, '_blank');
        }
      }).catch((error) => {
        console.error(error);
        this.setButtonLoading(false);
        this.showErrorAlert('Files could not be downloaded');
      });

  }

  async runBees() {
    let promises = [
      this.eraRunRiskAssessmentBeesLogicService.getTaxonTriggers()
    ];
    Promise.all(promises).then(async (results) => {
      let taxonTriggerList = results[0].filter((x: any) => x.geography === this.selectedProject?.geography);
      await this.eraRunRiskAssessmentBeesLogicService.getGapBeesApplication(this.selectedProject!, taxonTriggerList).then((data: BeesForInputs[]) => {
        if (data.length > 0) {
          this.eraRunRiskAssessmentBeesLogicService.runBees(data).then((data: any) => {
            this.rowData = data;
            this.getGridData();
            this.isLoading = false;
          });
        }
      });
    });  

  }

  setButtonLoading(isAllFilesDownloading: boolean): void {
    this.isAllFilesDownloading = isAllFilesDownloading;
  }

  getGridData() {
    this.columnDefs = this.eraRunRiskAssessmentBeesColDef.getColumnsDefinition();
  }

  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  showErrorAlert(text: string) {
    Swal.fire({
      title: 'Notification',
      html: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'OK',
    }).then((result) => { });
  }
}
