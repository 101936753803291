import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';
import { EraPecEccAquaticCompound, EraPecEccAquaticCrop, EraPecEccAquaticModel, EraPecEccAquatic, EraPecEccAquaticDataTransaction } from './era-pec-ecc-aquatic.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ERAPecEccAquaticBamsApiService extends HttpApiService {

  override apiUrl: string = environment.BAMS_Api_URL;

  private eraApiPrefix: string = 'api/eraPecEccAquatic/';

  getCropsByGeography(geopgraphy: string): Observable<EraPecEccAquaticCrop[]> {
    const endpoint = `${this.eraApiPrefix}GetCropsByGeography/${geopgraphy}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getModelsByDisciplinePkAndGeography(disciplinePk: number, geographyPk: number, geographyType: string):Observable<EraPecEccAquaticModel[]> {;
    const endpoint = `${this.eraApiPrefix}getModelsByDisciplinePkAndGeography/${disciplinePk}/${geographyPk}/${geographyType}`;
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }

  getCompoundsByProjectPkAndDisciplinePk(projectPk: number, disciplinePk: number):Observable<EraPecEccAquaticCompound[]> {;
    const endpoint = `${this.eraApiPrefix}getCompoundsByProjectPkAndDisciplinePk/${projectPk}/${disciplinePk}`;
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }

  insertUpdateAndDeleteEraPecEccAquatic(dataTransaction: EraPecEccAquaticDataTransaction): Observable<boolean> {
    const body = JSON.stringify(dataTransaction);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `${this.eraApiPrefix}insertUpdateAndDeleteEraPecEccAquatic`;
    return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getPecEccAquaticByProjectPk(projectPk: number): Observable<EraPecEccAquatic[]> {
    const endpoint = `${this.eraApiPrefix}getPecEccAquaticByProjectPk/${projectPk}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getModelsByCompartmentPkAndGeography(compartmentPk: number, geographyPk: number, geographyType: string):Observable<EraPecEccAquaticModel[]> {;
    const endpoint = `${this.eraApiPrefix}getModelsByCompartmentPkAndGeography/${compartmentPk}/${geographyPk}/${geographyType}`;
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }
}
