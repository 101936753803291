import { Injectable } from '@angular/core';
import { LinkButtonRenderer } from 'src/app/shared/renderers/link-button/link-button.component';

@Injectable({
  providedIn: 'root',
})

export class EraEcotoxImportPopUpColDefService {

  public getColumnsDefinition(){
    return [
      {
        headerName: 'Taxon',
        field: 'taxon',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 230,
        pinned: "left"
      },
      {
        headerName: 'Substance type',
        field: 'substanceType',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 127
      },
      {
        headerName: 'Substance name',
        field: 'substanceName',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 163
      },
      {
        headerName: 'Endpoint type',
        field: 'endpointType',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 200
      },
      {
        headerName: 'Endpoint',
        field: 'endpoint',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 118
      },
      {
        headerName: 'Operator',
        field: 'Operator',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 95
      },
      {
        headerName: 'Quantitave value',
        field: 'quantitaveValue',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 127
      },
      {
        headerName: 'Unit',
        field: 'unit',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 73
      },
      {
        headerName: 'Species Scientific Name',
        field: 'speciesScientificName',
        filter: 'agSetColumnFilter',
        editable: false,
        type: 'default',
        width: 179
      },
      {
        headerName: 'Document',
        cellRenderer: LinkButtonRenderer,
        field: 'link_to_document',
        editable: false,
        width: 101
      }
    ]
  }

}
