<div class="pt-1 pr-5 pb-5 pl-5">
    <div class="col-12 w-12">
        <app-wizard class="surface-200" [items]="tabsToDisplay" [message]="message" [loading]="loadingWizard" [activeItem]="activeItem" (activeIndexChangeEmitter)="onActiveIndexChange($event)" [isSetupValid]="isEndpointByCompartmentValid"></app-wizard>
    </div>

    <div class="p-3 col-12 w-12" *ngIf="afterSearch">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-eea-endpoints-core  [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="EEAEndpointsLogicService" [menuService]="menuService" [selectedProject]="selectedProject"></app-eea-endpoints-core>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 1">
           <app-eea-endpoints-by-compartment [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="EEAEndpointsLogicService" [menuService]="menuService" compartment="Surface Water" [compartmentList]="compartmentList" [selectedProject]="selectedProject" (isValid)="getIsEndpointByCompartmentValid($event)"></app-eea-endpoints-by-compartment>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 2">
          <app-eea-endpoints-by-compartment [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="EEAEndpointsLogicService" [menuService]="menuService" compartment="Groundwater" [compartmentList]="compartmentList" [selectedProject]="selectedProject" (isValid)="getIsEndpointByCompartmentValid($event)"></app-eea-endpoints-by-compartment>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 3">
          <app-eea-endpoints-by-compartment  [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="EEAEndpointsLogicService" [menuService]="menuService" compartment="Soil" [compartmentList]="compartmentList" [selectedProject]="selectedProject" (isValid)="getIsEndpointByCompartmentValid($event)"></app-eea-endpoints-by-compartment>
        </ng-container>
    </div>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>
