<div class="grid">
  <div class="col-12">

    <label class="font-bold">PWC Endpoints:</label>
    <hr class="mb-3">

    <div class="flex justify-content-center align-items-center" [ngClass]="isProjectOwnershipValid? '': 'disabled'" [style.pointer-events]="isProjectOwnershipValid? 'auto': 'none'">

      <table>

        <tr>
          <th></th>
          <th></th>

          <th>{{parent?.metaboliteName}}</th>

          <th *ngIf="daugther">
            <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
          </th>

          <th *ngIf="daugther">
            {{daugther.metaboliteName}}
          </th>

          <th *ngIf="grandDaugther">
            <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
          </th>

          <th *ngIf="grandDaugther">
            {{grandDaugther.metaboliteName}}
          </th>

        </tr>

        <tr *ngFor="let config of configuration; let i = index">

          <td class="radio-button-td">
            <div *ngIf="i === 0" class="flex flex-wrap gap-2">
              <div class="flex align-items-center">
                  <p-radioButton
                    name="koc"
                    value="koc"
                    inputId="koc"
                    [(ngModel)]="koc_kd.value"
                    (onClick)="onKocKdClick()"
                  />
                  <label for="koc" class="ml-2">Koc</label>
              </div>

              <div class="flex align-items-center">
                  <p-radioButton
                    name="kd"
                    value="kd"
                    inputId="kd"
                    [(ngModel)]="koc_kd.value"
                    (onClick)="onKocKdClick()"
                  />
                  <label for="kd" class="ml-2">Kd</label>
              </div>
          </div>
          </td>

          <td class="td-displayName">
            <label
            class="col-fixed font-medium label-input-specific text-align: right">
            {{config.displayName}}
            </label>
          </td>

          <td>
            <p-inputNumber
              [maxFractionDigits]="18"
              [(ngModel)]="config.parentValue"
              (onBlur)="setAsModified( relationLevelParent, config )"
            />
          </td>

          <td *ngIf="daugther" >
            <p-inputNumber
              pTooltip="Moles of dauther produced per mole of parent degraded"
              tooltipPosition="bottom"
              *ngIf="config.showMoles"
              [maxFractionDigits]="18"
              [(ngModel)]="config.moleDaugtherValue"
              (onBlur)="setAsModifiedMole( relationLevelDaugther, config )"
            />
          </td>

          <td *ngIf="daugther" >
            <p-inputNumber
              [maxFractionDigits]="18"
              [(ngModel)]="config.daugtherValue"
              (onBlur)="setAsModified( relationLevelDaugther, config )"
            />
          </td>

          <td *ngIf="grandDaugther" >
            <p-inputNumber
              pTooltip="Moles of granddauther produced per mole of daugther degraded"
              tooltipPosition="bottom"
              *ngIf="config.showMoles"
              [maxFractionDigits]="18"
              [(ngModel)]="config.moleGrandDaughterValue"
              (onBlur)="setAsModifiedMole( relationLevelGrandDaugther, config )"
            />
          </td>

          <td *ngIf="grandDaugther" >
            <p-inputNumber
              [maxFractionDigits]="18"
              [(ngModel)]="config.granDaugtherValue"
              (onBlur)="setAsModified( relationLevelGrandDaugther, config )"
            />
          </td>

        </tr>

      </table>

    </div>

  </div>
</div>

<p-blockUI [blocked]="blockUi">
  <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</p-blockUI>
