import { Component, OnInit } from '@angular/core';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.css'],
})

export class LayoutHeaderComponent implements OnInit {

  constructor(public userService: UserLogicService) {}

  ngOnInit(): void {
  }
}