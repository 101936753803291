import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { AdvancedFilter, AdvancedfilterOption, AdvancedfilterOptionSource } from '../models/advanced-filter';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdvancedFilterService {


  constructor(private http: HttpClient) { }


  advancedFilter(pAdvancedFilter: AdvancedFilter): Observable<any> {
    const body = JSON.stringify(pAdvancedFilter);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = environment.BAMS_Api_URL + "api/GetProjectsByAdvancedFilter";
    return this.http.post(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getModelsOptions(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/models`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }


  getAllProjectOptions(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/projects`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }

  getUsersOptions(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/users`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }

  getFormulationsUsed(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/formulations`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  getFormulationsOptions(body: Number[]): Observable<AdvancedfilterOption[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/formulation/getFormulationsNameAndPk`;
    return this.http.post(query, body, { headers }).pipe(map((data: any) => data.Value));
  }

  getParentsUsed(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/parents`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  getParentsOptions(body: Number[]): Observable<AdvancedfilterOption[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/GetMoleculesNameAndPk`;
    return this.http.post(query, body, { headers }).pipe(map((data: any) => data.Value));
  }

  getMetabolitesUsed(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/metabolites`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  getMetabolitesOptions(body: Number[]): Observable<AdvancedfilterOption[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/metabolites/getMetabolitesNameAndPk`;
    return this.http.post(query, body, { headers }).pipe(map((data: any) => data.Value));
  }

  getCountriesUsed(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/countries`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  getRegionsUsed(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/regions`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
  getCountriesOptions(body: Number[]): Observable<AdvancedfilterOptionSource[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/countries/countriesByPk`;
    return this.http.post<AdvancedfilterOptionSource[]>(query, body, { headers });
  }
  getRegionsOptions(body: Number[]): Observable<AdvancedfilterOptionSource[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/regions/regionsByPk`;
    return this.http.post<AdvancedfilterOptionSource[]>(query, body, { headers });
  }

  getCropsOptions(): Observable<AdvancedfilterOption[]> {
    let query: any = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/crops`;
    return this.http
      .get(query)
      .pipe(map((data: any) => data.Value));
  }

  getDatesUsed(): Observable<AdvancedfilterOption[]> {
    const query = `${environment.BAMS_Api_URL}api/GetAdvancedFilterOptions/dates`;
    return this.http.get(query).pipe(map((data: any) => data.Value));
  }
}