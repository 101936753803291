import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { ExposureAssessmentOutputViewApiService } from '../../../eea-output/eea-output.api.service';
import { ExposureAssessmentOutputProjectTier1ukPecTimeColdef } from './eea-output-project-tier1uk-pec-time.coldef';
import { ExposureAssessmentOutputProjectTier1ukPecTimeService } from './eea-output-project-tier1uk-pec-time.service';

@Component({
  selector: 'app-eea-output-project-tier1uk-pec-time',
  templateUrl: './eea-output-project-tier1uk-pec-time.component.html',
  styleUrls: ['./eea-output-project-tier1uk-pec-time.component.css']
})
export class EEAOutputProjectTier1ukPecTimeComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  rowData: any;
  columDef: any;
  loading: boolean = false;

  constructor(
    private columnDef: ExposureAssessmentOutputProjectTier1ukPecTimeColdef,
    private pecTimeService: ExposureAssessmentOutputProjectTier1ukPecTimeService,
    private viewOutputService: ExposureAssessmentOutputViewApiService
  ) { }

  ngOnInit(): void {
    this.columDef = this.columnDef.getColumnsDefinition();
    if (this.selectedProject) this.getPecTime(this.selectedProject.projectPk);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) this.getPecTime(changes['selectedProject'].currentValue.projectPk);
  }

  getPecTime(projectPk: number) {
    this.loading = true;
    if (projectPk != 0) {
      this.pecTimeService
        .getPecTimes(projectPk)
        .pipe(take(1))
        .subscribe((result: any) => {
          this.columDef = this.columnDef.getColumnsDefinition();
          this.rowData = result.Value;
          this.loading = false;
        });
    }
  }
}