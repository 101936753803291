import { Component, Input, OnInit } from '@angular/core';
import { SelectionValues } from 'src/app/shared/models/selectionValues';
import { Constants } from 'src/app/shared/utils/constants';
import { MultiSelectAdminRenderer } from '../../renderers/multi-select/multi-select.component';
import { AdminGridComponent } from '../../admin-grid/admin-grid.component';
import { CustomDatafieldGroup } from 'src/app/shared/models/custom-datafield-group';
import { TaxonEndpointsMatchingService } from './taxon-endpoints-matching.service';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { RoleApiService } from 'src/app/shared/services/role.api.service';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-taxon-endpoints-matching-grid',
  templateUrl: '../../admin-grid/admin-grid.component.html',
})

export class TaxonEndpointsMatchingGridComponent extends AdminGridComponent implements OnInit {
  @Input() dataComponent: any;
  customDatafieldGroup: CustomDatafieldGroup[] = [];
  endpointList: any[] = [];
  speciesList: any[] = [];
  

  constructor(private taxonEndpointsMatchingService: TaxonEndpointsMatchingService,
    private userService: UserLogicService) { 
    super();
  }
  
  override async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.rowHeight = 50;
    this.context = { componentParent: this };
    this.batchUpload = true;
    this.speciesList = this.dataComponent.speciesList.map((x: any) => { return { id: x.id, name: x.latinName }}).sort((a: any, b: any) => a.name.localeCompare(b.name));
  }
  
  override onGridReady(pParams: any): void{
    pParams.api.showLoadingOverlay();
    this.gridApi = pParams.api;
    this.gridColumnApi = pParams.columnApi;
    this.setEndpointList();
    this.rowData = this.dataComponent.taxonEndpointsMatchingList;
    this.rowData.map((row: any) => {
      row.deletable = true;
    });
    this.configureGrid();    
  }
  
  configureGrid() {
    
    this.columnDefs = [
      {
        headerName: 'ECHO Endpoint type',
        field: 'endpointType',
        minWidth: 350,
        editable:false,
        cellRenderer: DropdownListRenderer,
        cellRendererParams: (params: any) => {   
          return {
            instance: this,
            options: this.dataComponent.customDatafieldGroupList.map((x: any) => { return x.endpoint_type[0] }).filter((value: any) => value !== undefined).sort(),
            columnThatModifies: 'endpointType',
            disabled: !this.currentUserCanEdit,
          }
        }
      },
      {
        headerName: 'ECHO Endpoint',
        field: 'endpoints',
        minWidth: 350,
        editable:false,
        cellRenderer: MultiSelectAdminRenderer,
        cellRendererParams: (params: any) => {
          return {
            options: params?.data?.endpointType !== undefined 
                      ? this.endpointList.find(x => x.id === params?.data?.endpointType).values.sort((a: any, b: any) => a.name.localeCompare(b.name))
                      : [],
            optionValue: 'name',
            optionLabel: 'name',
            columnThatModifies: 'endpoints',
            editable: (params?.data?.endpointType !== undefined ? true : false) && this.currentUserCanEdit,
          }          
        },
      },
      {
        headerName: 'Species',
        field: 'species',
        flex:1,
        cellRenderer: MultiSelectAdminRenderer,
        editable:false,
        cellRendererParams: (params: any) => {
          return {
            options: this.speciesList,
            optionValue: 'id',
            optionLabel: 'name',
            columnThatModifies: 'species',
            editable: this.currentUserCanEdit,
          }          
        },
        filter: 'agSetColumnFilter' ,
        filterCellRenderer: MultiSelectAdminRenderer,
        filterValueGetter: (params: any) => {
          return params.data.species
            .map((x: any) => {
              const species = this.speciesList.find(species => species.id === x);
              return species ? species.name : x;
            });
    }},
      {
        colId: 'action',
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          editable: this.currentUserCanEdit,
        },
        type: 'rightAligned',
      },
    ];
  }
  
  override getData(): void {
    this.dataComponent.getData();
  }
  
  override saveData(pData: any) {    
      this.dataComponent.setLoadingState(true);
      let geographyPk = this.dataComponent.selectedRegion;
      let taxonPk = this.dataComponent.selectedTaxon;
      pData.map((transaction: any) => {
        transaction.row.geographyPk = geographyPk;
        transaction.row.taxonPk = taxonPk;
      });

      return this.taxonEndpointsMatchingService.saveData(pData);
  }
  public setChangeValues(newData:any): void {
    let oldValue = newData.oldValue;
    this.CreateTransaction(
      newData.row.id,
      newData.id,
      oldValue,
      newData.row);
  }

  public dropDownSelectionChanged(newData: any): void {
    const {field, row} = newData;

    if(field === 'endpointType')
      row.endpoints = [];
    
    this.setSelectionValues(newData);
  }

  
  public setSelectionValues(pSelectionObject: SelectionValues): void {
    const oldvalue = pSelectionObject.row[pSelectionObject.field];

    pSelectionObject.row[pSelectionObject.field] = pSelectionObject.value;
    this.CreateTransaction(
      pSelectionObject.row.id,
      pSelectionObject.id,
      oldvalue,
      pSelectionObject.row
    );

    if(pSelectionObject.field === Constants.FIELD_NAMES.ENDPOINT_TYPE){
      this.gridApi.redrawRows();      
    }

  }
  
  setEndpointList(){
    this.endpointList = this.dataComponent.customDatafieldGroupList
            .filter((value: any) => value !== undefined)
            .map((x: any) => {return { id: x.endpoint_type[0], values: x.endpoint.map((y: any) => {return {id: y, name: y}})}});
  }
}
