import { Injectable } from '@angular/core';
import { HttpApiService } from '../http.api.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class EfateApiService extends HttpApiService {
  override apiUrl: string=environment.ECHO_Api_URL;

  saveData(pObject: any, pApplication: string): Observable<any> {
    const body = JSON.stringify(pObject);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/efate/SaveEfateData/${pApplication}`;
    return this.put(endpoint, body, headers)
                .pipe(map((data: any) => data.Value));
  }

  updateCoreParameters(pObject: any, projectPk: number): Observable<any> {
    const body = JSON.stringify(pObject);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const endpoint = `api/efate/updateCoreParameters/${projectPk}`;
    return this.put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }

}
