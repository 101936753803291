import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EraAquaticRunRiskAssessmentGridEchoApiService  extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  private prefix: string = 'api/era/';

  getFormulationNameByPk(forlumationPk: number): Observable<string> {
    const endpoint = `${this.prefix}eraAquaticRunRiskAssessmentGridGetFomulation/${forlumationPk}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }
}
