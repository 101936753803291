import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ResponseCropXRegion } from 'src/app/shared/models/pwc/scenarios.interface';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EeaGapSurfacewaterPwcScenariosApiService extends HttpApiService {

  override apiUrl: string = environment.BAMS_Api_URL;

  getScenarios(): Observable<ResponseCropXRegion[]> {
    let endpoint = `api/PWC/GetScenarios/`;
    return this.get(endpoint)
                .pipe(
                  map((data: any) => data.Value)
                );
  }

}
