import { Component, Input } from '@angular/core';
import { TriggersEcotoxService } from '../../regulatory-models/triggers-ecotox/triggers-ecotox.service';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';

@Component({
  selector: 'app-taxon',
  templateUrl: './taxon.component.html',
  styleUrls: ['./taxon.component.css']
})
export class TaxonComponent {
  @Input() componentThatImplementsGrid: any;
  selectedTaxon: number = -1;
  allTaxons: TriggerEcotox[] = [];
  taxons: TriggerEcotox[] = [];

  constructor(private triggersEcotoxService: TriggersEcotoxService) {}

  ngOnInit(): void {
    this.triggersEcotoxService.getTaxonTriggerList().subscribe((data: any) => {
      this.allTaxons = data;
    });
  }

  geographySelectionChange(pSelectedGeography: string) {
    this.taxons = this.allTaxons.filter(x => x.geography === pSelectedGeography)
  }

  taxonSelectionChange() {
    this.componentThatImplementsGrid.filterDataByTaxon(this.selectedTaxon);
  }
}
