import { Component } from '@angular/core';
import { Constants } from '../../utils/constants';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-status-flag',
  templateUrl: './status-flag.component.html',
  styleUrls: ['./status-flag.component.css']
})
export class StatusFlagRenderer  implements ICellRendererAngularComp {

  public hasData: boolean = false;
  public toolTip: string = '';

  public agInit(params: any): void {
    this.hasData = params.data.hasEndpoints;
    this.toolTip = this.hasData? Constants.BADGE_TOOLTIP_HAS_DATA : Constants.BADGE_TOOLTIP_NO_DATA;
  }

  public refresh(): boolean {
    return false;
  }

}
