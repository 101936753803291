import { Component, ViewChild } from '@angular/core';
import { CustomDatafieldGroup } from 'src/app/shared/models/custom-datafield-group';
import { Species } from 'src/app/shared/models/species';
import { CustomDatafieldGroupApiService } from 'src/app/shared/services/custom-datafield-group.api.service';
import { SpeciesApiService } from 'src/app/shared/services/species.api.service';
import { TaxonEndpointsMatchingService } from './taxon-endpoints-matching.service';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-taxon-endpoints-matching',
  templateUrl: './taxon-endpoints-matching.component.html',
  styleUrls: ['./taxon-endpoints-matching.component.css']
})
export class TaxonEndpointsMatchingComponent {
  afterSearch: boolean = false;
  loading: boolean = false;
  customDatafieldGroupList: CustomDatafieldGroup[] = [];
  speciesList: Species[] = [];
  componentThatImplementsGrid = this;
  selectedRegion: string = '';
  selectedTaxon: number = -1;
  taxonEndpointsMatchingList: any[] = [];
  @ViewChild('taxonFilterComponent') taxonFilterComponent: any;

  constructor(private customDataFieldGroupService: CustomDatafieldGroupApiService,
              private speciesService: SpeciesApiService,
              private taxonEndpointsMatchingService: TaxonEndpointsMatchingService) { }

  ngOnInit(): void {
    this.getListValues();
  }
  
  getListValues() {
    this.customDataFieldGroupService.GetCustomDatafieldGroupByDisciplineName(Constants.DISCIPLINE_IDENTITIES.ECOTOXICOLOGY)
    .subscribe((customDatafieldGroup: CustomDatafieldGroup[] ) => {
      this.speciesService.GetSpeciesByType(Constants.SPECIES_TYPES.STANDARD_TEST_SPECIES)
      .subscribe((species: Species[]) => {
        this.customDatafieldGroupList = customDatafieldGroup;
        this.speciesList = species;
      });
    })
  }
  
  filterDataByGeography(pRegionPk: string): void {
    this.afterSearch = false;
    this.selectedRegion = pRegionPk;
    this.taxonFilterComponent.geographySelectionChange(this.selectedRegion);
  }
  
  filterDataByTaxon(pTaxonPk: number): void {
    this.selectedTaxon = pTaxonPk;
    this.getData();
  }
  
  getData(): void {
    this.setLoadingState(true);
    this.taxonEndpointsMatchingService.getTaxonEndpointsMatching({geographyPk: this.selectedRegion , taxonPk: this.selectedTaxon}).subscribe((data: any[]) => {
      this.taxonEndpointsMatchingList = data;
      this.setLoadingState(false);
    })
  } 
  
  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }
}
