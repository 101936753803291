<p-dialog
  header="Select Scenarios"
  [visible]="true"
  [modal]="true"
  [style]="{ width: '760px' }"
  [draggable]="false"
  [resizable]="false"
  (visibleChange)="onClose()"
  (onShow)="onShow()"
>
  <div class="grid">

    <div class="col-3">
      <h3
        class="mb-3 mt-0"
        style="color: #0069BE;"
      >
        Crops
      </h3>
      <p-listbox
        [disabled]="!isProjectOwner"
        [options]="crops"
        [(ngModel)]="selectedCrop"
        [style]="{'width':'205px', 'height': listboxHeight + 'px'}"
        [listStyle]="{'max-height': listboxHeight + 'px'}"
        (onChange)="onCropChange($event)"
      ></p-listbox>
    </div>

    <div class="col-1"></div>

    <div class="col-2">
      <div class="flex align-items-center gap-1">
        <h3
          class="mb-3 mt-0 mr-2"
          style="color: #0069BE;"
        >
          Regions
        </h3>
        <p-checkbox
          *ngIf="selectedCrop"
          class="mb-2"
          [label]="regionCheckLabel"
          [binary]="true"
          [(ngModel)]="regionAllSelected"
          (onChange)="onRegionAllChange($event)"
        ></p-checkbox>
      </div>

      <p-listbox
        optionLabel="region"
        optionValue="crop_x_region_pk"
        [disabled]="!isProjectOwner"
        [multiple]="true"
        [metaKeySelection]="false"
        [options]="regions"
        [(ngModel)]="selectedRegions"
        [style]="{'width':'145px', height: listboxHeight + 'px'}"
        [listStyle]="{'max-height': listboxHeight + 'px'}"
        (onClick)="onClickRegion($event)"
      ></p-listbox>
    </div>

    <div class="col-1"></div>

    <div class="col-5">
      <h3
        class="mb-3 mt-0"
        style="color: #0069BE;"
      >
        Scenarios
      </h3>
      <p-listbox
      id="customListbox"
      optionLabel="fullName"
      optionValue="crop_x_region_pk"
      [disabled]="!isProjectOwner"
      [readonly]="true"
      [options]="selectedScenarios"
      [style]="{'width':'300px', 'height': listboxHeight + 'px'}"
      [listStyle]="{'max-height': listboxHeight + 'px'}"
      >
        <ng-template let-scenario pTemplate="item">
            <div class="flex align-items-center gap-2">
              <p-button
                severity="danger"
                icon="pi pi-trash"
                [disabled]="!isProjectOwner"
                [rounded]="true"
                [text]="true"
                (click)="removeScenario(scenario.crop_x_region_pk)"
              ></p-button>
              <span>{{ scenario.fullName }}</span>
            </div>
        </ng-template>
    </p-listbox>
    </div>

  </div>
</p-dialog>
