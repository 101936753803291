import { inject, Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard{

  constructor(private _msalService: MsalService, private router: Router) {  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const activeAccount = this._msalService.instance.getActiveAccount();

    if(!activeAccount){
      return true
    }
    const userRoles = activeAccount.idTokenClaims?.roles;
    
    
    if(!userRoles || userRoles.length === 0){
      return this.router.navigate(['/unauthorized']);
    }
    const allowedRoles = next.data["roles"];
    const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
    return matchingRoles.length > 0;
  }
}