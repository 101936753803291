import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectTier1ukDrainflowAndStreamService {

  constructor(private http: HttpClient) { }

    getDrainflowAndStream( pProjectPk:number): Observable<any[]> {
        const query = `${environment.BAMS_Api_URL}api/getDrainFlowAndStream/${pProjectPk}`;
        return this.http.get(query).pipe(map((data: any) => data));
      }
}
