import { Component, Input } from '@angular/core';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { ModelLibraryService } from '../../regulatory-models/model-library/model-library.service';

@Component({
  selector: 'app-discipline',
  templateUrl: './discipline.component.html',
  styleUrls: ['./discipline.component.css']
})
export class DisciplineComponent {

  constructor(private modelLibraryService: ModelLibraryService,              
    ) {}
  
    @Input() componentThatImplementsGrid: any;
    disciplines: Discipline[] = [];
    selectedDiscipline: number = -1;  
  
    ngOnInit(): void {
      this.modelLibraryService.getDisciplineInformation().subscribe((disciplineData: Discipline[]) => {
        this.disciplines = disciplineData;
        const selectedDisciplineMatch = this.disciplines.find(x => x.name && x.name === "Environmental Fate");   
        if (selectedDisciplineMatch) {
          this.selectedDiscipline = selectedDisciplineMatch.disciplinePk;
          this.disciplineSelectionChange();
        }  
      });  
    }
  
    disciplineSelectionChange() {
      this.componentThatImplementsGrid.filterDataByDiscipline(this.selectedDiscipline);    
    }
}
