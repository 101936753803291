import { Injectable } from '@angular/core';
import { HttpApiService } from '../../services/http.api.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { DataSet, DataSetPkAndDescription } from '../../models/echo/data-set';
import { HttpHeaders } from '@angular/common/http';
import { ImportDataSet } from '../../models/echo/import-data-set';
import { Project } from '../../models/project';
import { Endpoint } from '../../models/endpoint';

@Injectable({
  providedIn: 'root'
})
export class EndpointDatasetService extends HttpApiService {
  override apiUrl: string = environment.ECHO_Api_URL;

  existDataSetNameByProjectMolecule(dataSetName: string, projectPk: number, moleculePk: number): Observable<any> {
    let endpoint = `api/datasets/exist?datasetname=${dataSetName}&projectPk=${projectPk}&moleculePk=${moleculePk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  getAndCopyCoreDatasetsByProject(project?: Project, datasetList?: any[]): Observable<number> {
    const body = JSON.stringify({datasetListToCopy: datasetList, projectDTO: project});
    let endpoint = `api/getAndCopyCoreDatasetsByProject`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .put(endpoint, body, headers)
      .pipe(map((data: any) => data.Value));
  }
  getDataSetsByProject(projectPk: number, source: string): Observable<DataSet[]> {
    let endpoint = `api/datasetsByProject?projectPk=${projectPk}&source=${source}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  getDataSetsByMoleculeExcludeDSProject(projectPk: number, source: string,moleculePk:number): Observable<DataSet[]> {
    let endpoint = `api/dataset/by/DatasetByMoleculesPkExcludeProjectDS?projectPk=${projectPk}&source=${source}&moleculePk=${moleculePk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }
  save(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/dataSet`;
    return this
      .post(endpoint, body, headers).
      pipe(map((data: any) => data.Value));
  }

  deleteDataSetWithEndPointsByPK(dataSetPk:number): Observable<boolean> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/deleteDataSetWithEndPointsByPk?dataSetPk=${dataSetPk}`;
    return this
      .delete(endpoint,  headers).
      pipe(map((data: any) => data.Value));
  }

  getDatasetByProjectpkMoleculePkAndName(dataSetName: string, projectPk: number, moleculePk: number): Observable<any> {
    let endpoint = `api/dataset/byprojectpkandname?projectPk=${projectPk}&name=${dataSetName}&moleculePk=${moleculePk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

  existsDataset(dataSetName: string, moleculePk: number, datasetPk: number, substanceType: string): Observable<number> {
    let endpoint = `api/existsDataset?datasetname=${dataSetName}&moleculePk=${moleculePk}&datasetPk=${datasetPk}&substanceType=${substanceType}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.get(endpoint, headers).pipe(map((data: any) => data.Value));
  }

  existsDatasetWithFk(projectPk: number, datasetPk: number): Observable<number> {
    let endpoint = `api/existsDatasetFK/${datasetPk}/${projectPk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.get(endpoint, headers).pipe(map((data: any) => data.Value));
  }

  getDataseByMoleculesPks(moleculesPk: number[],source:string): Observable<DataSet[]> {
    const body = JSON.stringify(moleculesPk);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/dataset/by/moleculespks?source=${source}`;
    return this .post(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  importDataSetInfo(datasetToCopy: ImportDataSet): Observable<any> {
    const body = JSON.stringify(datasetToCopy);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/importDataSetInfo`;
    return this.post(query, body, headers).pipe(map((data: any) => data.Value));
  }

  getDatasetPkAndDescriptionByMoleculesPks(moleculesPk: number[]): Observable<DataSetPkAndDescription[]> {
    const body = JSON.stringify(moleculesPk);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `api/getDatasetPkAndDescriptionByMoleculesPks`;
    return this .post(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getAllProjectEndpointsInformation(projectPk: number | undefined, source: string): Observable<Endpoint[]> {
    let endpoint = `api/endpointsByProject?projectPk=${projectPk}&source=${source}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.get(endpoint,  headers )
      .pipe(
        map((data: any) => data.Value as Endpoint[])
      );
  }

}
