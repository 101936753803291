<div class="grid" *ngIf="!isLoading">
    <div class="col-12" >
        <div class="flex flex-row-reverse flex-wrap">
            <app-button label="All files" iconPos="right" [outlined]="true" severity="secondary" [icon]="isAllFilesDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" (onClickEvent)="onDownloadClick()"></app-button>
        </div>
    </div>
    <div class="col-12 pt-3">
        <app-grid #grid [domLayout]="'autoHeight'" [rowData]="rowData" [rowHeight]="40" rowSelection="single"
          [columnDefs]="columnDefs" [pagination]="true">
        </app-grid>
    </div>
</div>

<app-loading class="mt-2 flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>