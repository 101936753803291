import { Component, OnInit } from '@angular/core';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Datafield } from 'src/app/shared/models/echo/data-field';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { ModelLibraryService } from './model-library.service';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { RoleApiService } from 'src/app/shared/services/role.api.service';

@Component({
  selector: 'app-model-library',
  templateUrl: './model-library.component.html',
  styleUrls: ['./model-library.component.css']
})
export class ModelLibraryComponent implements OnInit {
  afterSearch: boolean = false;
  allModels : ModelLibrary[] = [];
  componentThatImplementsGrid = this;
  disciplineData: Discipline[] = [];
  geographies: Catalog[] = [];
  loading: boolean = false;
  models: ModelLibrary[] = [];
  selectedDiscipline: number = -1;
  datafields: Datafield[] = [];
  datafieldValues: DatafieldValue[] = [];

  constructor(
    private datafieldValueService: DatafieldValueApiService,
    private geographyService: GeographyApiService,
    private modelLibraryService: ModelLibraryService,
    ) { }

  ngOnInit(): void {
    this.setLoadingState(true);
    this.getDatafields()
  }

  getDatafields(): void {
    this.datafieldValueService.getDatafieldData(Constants.THIS_APPLICATION).subscribe((datafieldData: Datafield[]) => {
      this.datafieldValueService.getDatafieldValueInformation().subscribe((datafieldValueData: DatafieldValue[]) => {
        this.datafieldValues = datafieldValueData;
        this.datafields = datafieldData;
        this.getGeographies();
      });
    });
  }

  getGeographies(){
    this.geographyService.getRegionCountryCatalog().subscribe((geographies: Catalog[]) => {
      this.geographies = Utils.sortObject(geographies, 'name');
      this.getData();
    });
  }

  getData(): void {
    this.setLoadingState(true);
    this.modelLibraryService.getModels().subscribe((models: ModelLibrary[]) => {
      this.allModels = models;
      this.models = this.allModels.filter(x => x.disciplineXModel && x.disciplineXModel.includes(this.selectedDiscipline));
      this.setLoadingState(false);
    });
  }

  filterDataByDiscipline(disciplinePk:number): void {
    this.selectedDiscipline = disciplinePk;
    this.getData();
  }

  setLoadingState(pState: boolean): void{
    this.loading = pState;
    this.afterSearch = !pState;
  }

}
