import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ColDef, ColGroupDef, RowGroupOpenedEvent, RowNode, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { take } from 'rxjs';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { Project } from 'src/app/shared/models/project';
import { DropdownFilterRenderer } from 'src/app/shared/renderers/dropdown-filter/dropdown-filter.component';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { ListboxRenderer } from 'src/app/shared/renderers/listbox/listbox.component';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { EraGapTerrestrialApiService } from './era-gap-terrestrial.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import Swal from 'sweetalert2';
import { EEAGapLogicService } from 'src/app/environmental-exposure-assessment/eea-gap/eea-gap.logic.service';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { GapApplicationSchemeLogicService } from 'src/app/shared/services/gap-application-scheme.logic.service';

@Component({
  selector: 'app-era-gap-terrestrial',
  templateUrl: './era-gap-terrestrial.component.html',
  styleUrls: ['./era-gap-terrestrial.component.css']
})
export class ERAGapTerrestrialComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() isInverseModeling: boolean = false;

  @Output() public isValid = new EventEmitter<boolean>();

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  destroyRef = inject(DestroyRef);
  saveRef?: string;
  public columnDefs: any[] = [];
  public detailsColumnDef: (ColDef | ColGroupDef)[] | null = [];
  activeIngredientRatesColDef: (ColDef | ColGroupDef)[] | null = [];
  geographies: Catalog[] = [];
  rowData: any[] = [];
  geographiesList: Catalog[] = [];
  isLoading!: boolean;
  mainCompartment: string = 'Terrestrial Vertebrate';
  mainCompartmentPk: number = 0;
  selectedGeography: string | undefined = "";
  mesureUnit: string = 'lb/acre';

  @ViewChild('grid') grid!: GridComponent;

  constructor(private geographyApiService: GeographyApiService,
    private activeIngredientApiService: ActiveIngredientApiService,
    private datasetService: DataSetApiService,
    private EraGapTerrestrialApiService: EraGapTerrestrialApiService,
    private gapLogicService: EEAGapLogicService,
    private gapApplicationSchemeLogicService: GapApplicationSchemeLogicService) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }


  ngOnInit(): void {
    this.isLoading = true;
    this.getData();
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.menuService.setSuccess(true)
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
      this.saveData(this.grid?.transactionList)
      this.subTabMenuService.setSuccess(true);
    });
  }

  getData() {
    let promises = [
      this.getCompartments(),
    ]
    Promise.all(promises)
      .then((results) => {
        this.mainCompartmentPk = results[0].filter((x: any) => x.compartment == this.mainCompartment)[0]?.endpointCompartmentPk;
        this.EraGapTerrestrialApiService.getApplicationSchemesByProjectAndCompartment(this.selectedProject!.projectPk, this.mainCompartmentPk).subscribe({
          next: (applicationSchemes: any) => {
            this.configureGrid();
            this.configureDetailGrid();
            this.initSubscribes();
            this.addActiveIngredientsRatesToGrid(applicationSchemes, this.columnDefs, this.selectedProject!).then(() => {
              this.addActiveIngredientsRatesToDetailGrid(this.rowData, this.detailsColumnDef, this.selectedProject!).then(() => {
                this.setTerrestrialToRowData();
                this.refreshSelectedValuesTimeout();
                this.isLoading = false;
              });
            });
          }
        })
      })
  }

  setTerrestrialToRowData() {
    this.rowData?.forEach((row: any) => {
      if (row.applicationSchemeTerrestrial !== null) {
        row.halfLife = row.applicationSchemeTerrestrial.halfLife;
        row.seedTreatment = row.applicationSchemeTerrestrial.seedTreatment;
        row.use = row.applicationSchemeTerrestrial.use;
        row.variableRatesOrIntervals = row.applicationSchemeTerrestrial.variableRatesOrIntervals;
        row.useInProject = row?.useInProject;
      }
    });
  }

  deleteRow(event: any) {

    this.rowData = this.rowData.filter((r: any) => r.deleted === undefined || r.deleted === false);

    const deletedRow = event.find( (row: any) => row.transactionType === Constants.TRANSACTION_TYPE.DELETE );
    this.checkIfApplicationSchemeIsValid( deletedRow?.row.name, deletedRow?.row.applicationSchemePk );

    if (event != undefined) {
      this.EraGapTerrestrialApiService
        .insertUpdateAndDeleteEraPecEccAquatic(event)
        .pipe(take(1))
        .subscribe(() => { });
    }
    this.refreshSelectedValuesTimeout();
  }

  saveData(dataTransaction: any) {
    if (dataTransaction === undefined) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (dataTransaction.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    this.createTransactionsForRates(dataTransaction);
    this.createDetailTransactionsForRates(dataTransaction);
    this.setParametersToNewRows(dataTransaction, this.selectedProject?.projectPk);
    this.setActiveIngredientsRate(dataTransaction);

    this.EraGapTerrestrialApiService.insertUpdateAndDeleteEraPecEccAquatic(dataTransaction).subscribe({
      next: () => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
      },
      error: (err) => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
      }
    })
  }

  setParametersToNewRows(dataTransaction: any[], projectPk?: number) {
    dataTransaction.forEach(x => {
      if (x.transactionType == 1 || x.transactionType == 2) {
        x.row.projectPk = projectPk;
        x.row.compartmentPk = this.mainCompartmentPk;
        x.row.applicationSchemeXActiveIngredientRate = [...x.row.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(x.row)]
        x.row.children?.forEach((child: any) => {
          child.applicationSchemeXActiveIngredientRate = [...child.applicationSchemeXActiveIngredientRate, ...this.setMissingDetailApplicationSchemeXActiveIngredientRate(child)]
        });
      }
    })
  }

  closeInnerGrid(event: any) {
    var node1 = event.params.api.getDisplayedRowAtIndex(event?.id)!;
    this.grid.gridApi.setRowNodeExpanded(node1, false);
  }

  onSelectionChanged(event: any) {
    this.onSelectOrUnselectAll( event );
    if (event.row?.editedField === 'numberOfApplications') {
      this.setInnerGridValues(event);
      this.setGeographiesRows(event);
    }

    if (event.row?.editedField === 'seedTreatment' && event.row.seedTreatment !== undefined) {
      event.row.use = event.row.seedTreatment === Constants.AFFIRMATIVE_VALUES.NO ? undefined : event.row.use;
      this.closeInnerGrid(event);
      this.setGeographiesRows(event);
      this.setHeaderColumnName(event.row);
    }

    if (event.row?.editedField === 'variableRatesOrIntervals') {
      this.closeInnerGrid(event);
      this.setGeographiesRows(event);
      if (event.row.variableRatesOrIntervals === Constants.AFFIRMATIVE_VALUES.YES) {
        event.row.applicationInterval = null;
        Object.entries(event.row).forEach(([key, value]) => {
          if (key.startsWith(Constants.GAP_FIELD_NAMES.RATE)) {
            event.row[key] = null;
          }
        });
      }
    }

    this.grid.gridApi.redrawRows();
  }

  setGeographiesRows(event: any) {
    event.row.geographies = [];
    event.row.applicationSchemeXGeography?.map((geography: any) => {
      const geo = this.geographies.find((x: Catalog) => x.key == geography.geographyPk);
      if (geo) {
        event.row.geographies.push(geo);
      }
    });
  }

  geographiesListBoxSelectionChanged(params: any) {
    if (!this.selectedProject) return;
    let oldValue: any = params.data['geographies'];
    let values = params.selectedValues.map((x: any) => { return { geographyPk: x.key } })
    params.data.applicationSchemeXGeography = values;
    this.grid.CreateTransaction(params.id, params.id, oldValue, params.data);

  }

  hideApplications(variableRatesOrIntervals: string) {
    const columnDefs = this.grid?.gridColumnApi.getColumns();
    const hideColumn = variableRatesOrIntervals === Constants.AFFIRMATIVE_VALUES.YES ? true : false;
    columnDefs.forEach((column: any) => {
      if (column.colId == '1')
        this.grid?.gridColumnApi.setColumnVisible(column.colId, hideColumn);
    });
    this.grid?.gridApi.refreshCells();
  }

  setHeaderColumnName(rowValues: any) {
    const columnDefs = this.columnDefs;
    columnDefs?.forEach((colDef: any) => {
      if (colDef.headerName === 'Rate (lb/acre)' && rowValues.seedTreatment === Constants.AFFIRMATIVE_VALUES.YES)
        colDef.headerName = 'Rate (fl oz/cwt)'
      if (colDef.headerName === 'Rate (fl oz/cwt)' && rowValues.seedTreatment === Constants.AFFIRMATIVE_VALUES.NO)
        colDef.headerName = 'Rate (lb/acre)'
    });

    columnDefs[0].children.forEach((value: any) => {
      if (value.headerName === "") {
        if (rowValues.variableRatesOrIntervals !== undefined && rowValues.variableRatesOrIntervals === Constants.AFFIRMATIVE_VALUES.YES) {
          value.hide = false;
        }
        else {
          value.hide = true;
        }
      }
    });

    this.grid.gridApi.setDefaultColDef({
      columnDefs,
      editable: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true
    });
    this.setDetailColumnHeader(rowValues.seedTreatment);
  }

  setDetailColumnHeader(seedTreatment: string) {
    const detailsColumnDef = this.detailsColumnDef;
    detailsColumnDef?.forEach((colDef: any) => {
      if (colDef.headerName === 'Rate (lb/acre)' && seedTreatment === Constants.AFFIRMATIVE_VALUES.YES)
        colDef.headerName = 'Rate (fl oz/cwt)'
      if (colDef.headerName === 'Rate (fl oz/cwt)' && seedTreatment === Constants.AFFIRMATIVE_VALUES.NO)
        colDef.headerName = 'Rate (lb/acre)'
    });
    this.grid.gridApi.setDefaultColDef({
      detailsColumnDef,
      editable: false,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true
    });
    this.grid.gridApi.refreshHeader();
  }

  setInnerGridValues(event: any) {
    if (event.row.numberOfApplications === 1) {
      event.row.children = [];
      event.row.variableRatesOrIntervals = Constants.AFFIRMATIVE_VALUES.NO;
      this.closeInnerGrid(event);
      this.hideApplications(Constants.AFFIRMATIVE_VALUES.NO);
      return;
    }

    if (event.row.children === undefined || event.row.children.length === 0) {
      event.row.children = [];
      return this.setChildrenValues(event.row, event.row.numberOfApplications);
    }

    if (event.row.children.length < event.row.numberOfApplications) {
      const newNumberApplication = event.row.numberOfApplications - event.row.children.length;
      let actualNumberApplication = event.row.children.length + 1;
      for (let index = 0; index < newNumberApplication; index++) {
        event.row.children.push({
          applicationNumber: actualNumberApplication++,
        })
      }
    }

    if (event.row.children.length > event.row.numberOfApplications) {
      const deleteNumberApplication = event.row.children.length - event.row.numberOfApplications;
      for (let index = 0; index < deleteNumberApplication; index++) {
        event.row.children.pop();
      }
    }
  }

  setChildrenValues(event: any, numberApplication: number) {
    let childrenValues: any[] = [];

    for (let index = 0; index < numberApplication; index++) {
      childrenValues.push({
        applicationNumber: index + 1,
        applicationInterval: index === 0 ? 0 : null,
      })
    }
    event.children.push(...childrenValues);
  }

  public onInputNumberChanged(params: any): void {
    this.grid.gridApi.redrawRows();
  }

  onCellChanged(event: any) {
  }

  getNumberOfApplications() {
    let numberOfApplications: number[] = [];
    for (let index = 1; index <= 10; index++) {
      numberOfApplications.push(index);
    }
    return numberOfApplications;
  }

  configureGrid(): void {
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            headerName: 'Application Scheme',
            field: 'name',
            type: 'default',
            pinned: 'left',
            resizable: true, width: 300,
            headerCheckboxSelection: this.isProjectOwnershipValid,
            checkboxSelection: () => { return this.isProjectOwnershipValid },
            showDisabledCheckboxes: true,
            cellRenderer: InputTextRenderer,
            cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
              isRequired: true
            },
            editable: false,
            cellEditorParams: {
              maxLength: 200,
            },
          },
          {
            headerName: this.selectedProject?.geography !== Constants.CROP_GEOGRAPHIES.USA ? 'Country' : 'Region',
            field: 'geographies',
            type: 'default',
            filter: 'agSetColumnFilter',
            hide: (this.selectedGeography !== Constants.CROP_GEOGRAPHIES.USA),
            filterParams: {
              cellRenderer: (pParams: any) => {
                return Utils.formatFilterValues(pParams, this.geographiesList, 'key', 'name')
              },
            },
            cellRenderer: ListboxRenderer,
            cellRendererParams: {
              options: this.geographiesList,
              optionValue: 'key',
              optionLabel: 'name',
              checkbox: true,
              filter: false,
              multiple: true,
              disabled: false,
              isProjectOwnershipValid: this.isProjectOwnershipValid,
              validateModel: false,
            },
            wrapText: true,
            resizable: true,
            sortable: true,
            width: 175,
            editable: false,
          },
          {
            headerName: 'Seed Treatment?',
            field: 'seedTreatment',
            width: 115,
            resizable: true, type: 'select',
            suppressSizeToFit: true,
            filter: DropdownFilterRenderer,
            cellRenderer: DropdownListRenderer,
            cellRendererParams: (params: any) => {
              return {
                options: [{ key: Constants.AFFIRMATIVE_VALUES.YES }, { key: Constants.AFFIRMATIVE_VALUES.NO }],
                columnThatModifies: 'seedTreatment',
                optionLabel: 'key',
                optionValue: 'key',
                parent: this,
                disabled: !this.isProjectOwnershipValid,
              };
            },
            editable: false,
          },
          {
            headerName: 'Use',
            field: 'use',
            width: 300,
            resizable: true,
            type: 'select',
            suppressSizeToFit: true,
            filter: DropdownFilterRenderer,
            cellRenderer: DropdownListRenderer,
            cellRendererSelector: (params: any) => {
              return {
                component: params.data.seedTreatment == Constants.AFFIRMATIVE_VALUES.YES ? DropdownListRenderer : InputTextRenderer,
                params: params.data.seedTreatment == Constants.AFFIRMATIVE_VALUES.YES ? {
                  options: Constants.SEED_TREATMENTS_T_REX,
                  columnThatModifies: 'use',
                  optionLabel: 'name',
                  optionValue: 'key',
                  disabled: !this.isProjectOwnershipValid,
                  parent: this,
                } : { disabled: !this.isProjectOwnershipValid, }
              };
            },
            editable: false,
          },
          {
            headerName: 'Number of Applications',
            field: 'numberOfApplications',
            type: 'select',
            suppressSizeToFit: true,
            width: 120,
            resizable: true, editable: false,
            filter: 'agSetColumnfilter',
            cellRenderer: DropdownListRenderer,
            cellRendererParams: (params: any) => {
              return {
                instance: this,
                options: this.getNumberOfApplications().map((x: number) => { return { value: x } }),
                optionValue: 'value',
                optionLabel: 'value',
                columnThatModifies: 'numberOfApplications',
                height: '300px',
                disabled: !this.isProjectOwnershipValid,
              };
            }
          },
        ]
      },
      {
        headerName: '',
        children: [
          {
            headerName: 'Variable rates or intervals?',
            field: 'variableRatesOrIntervals',
            width: 140,
            resizable: true, editable: false,
            type: 'select',
            suppressSizeToFit: true,
            filter: 'agSetColumnfilter',
            filterParams: {
              valueFormatter: (params: any) => {
                return params.value === Constants.AFFIRMATIVE_VALUES.YES ? Constants.AFFIRMATIVE_VALUES.YES : !Utils.isEmptyValue(params.value) ? Constants.AFFIRMATIVE_VALUES.NO : '(Blanks)';
              }
            },
            cellRenderer: DropdownListRenderer,
            cellRendererParams: (params: any) => {
              return {
                options: [{ key: Constants.AFFIRMATIVE_VALUES.YES }, { key: Constants.AFFIRMATIVE_VALUES.NO }],
                columnThatModifies: 'variableRatesOrIntervals',
                disabled: !this.isProjectOwnershipValid,
                optionLabel: 'key',
                optionValue: 'key',
                parent: this,
              };
            },
            cellStyle: (params: any) => {
              if (params.data.numberOfApplications == 1) {
                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
              }
              return null;
            },
          },
          {
            headerName: '',
            field: 'expandButton',
            cellRenderer: 'agGroupCellRenderer',
            width: 50,
            resizable: true,
            suppressSizeToFit: true,
            hide: false,
            type: 'default',
            autoHeight: true,
            editable: false,
            cellStyle: (params: any) => {
              if (params.data.numberOfApplications == 1 || params.data.variableRatesOrIntervals == Constants.AFFIRMATIVE_VALUES.NO) {
                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
              }
              return null;
            },
          },
        ]
      },
      {
        headerName: '',
        children: [
          {
            headerName: 'Application Interval',
            field: 'applicationInterval',
            width: 120,
            resizable: true,
            suppressSizeToFit: true,
            editable: false,
            cellRenderer: InputTextRenderer,
            cellRendererParams: (params: any) => {
              return {

                disabled: !this.validateVariableRatesOrIntervals(params) || !this.isProjectOwnershipValid,
              };
            },
          },
        ]
      },
      {
        headerName: 'Rate (lb/acre)',
        resizable: true,
        children: [
          {
            headerName: 'Default Active Ingredient',
            field: 'defaultAI',
            cellRenderer: '',
            editable: false,
            resizable: true,
          },
        ]
      },
      {
        headerName: '',
        children: [
          {
            resizable: true,
            headerName: 'Half-life (d)',
            field: 'halfLife',
            width: 100,
            suppressSizeToFit: true,
            editable: false,
            cellRenderer: InputTextRenderer,
            cellRendererParams: (params: any) => {
              return {
                disabled: !this.isProjectOwnershipValid,
              };
            },
          }
        ]
      },
      {
        colId: 'delete',
        width: 125,
        minWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          disabled: !this.isProjectOwnershipValid
        },
        type: 'rightAligned'
      }
    ];
  }

  validateVariableRatesOrIntervals(params: any) {
    if (params.data.variableRatesOrIntervals === Constants.AFFIRMATIVE_VALUES.YES) {
      return false;
    }
    return true;
  }

  /*configureDetailGrid(): void { //TODO The texboxComponet need to be check
    this.detailsColumnDef = [
      {
        headerName: '',
        children: [
          {
            headerName: 'Application Number',
            field: 'applicationNumber',
            width: 110,
            resizable: true,
            cellRenderer: InputTextRenderer,
            cellRendererParams: (params: any) => {
              return {
                disabled: !this.isProjectOwnershipValid,
              };
            },
            editable: false,
          },
        ]
      },
      {
        headerName: '',
        children: [
          {
            headerName: 'Application Interval',
            field: 'applicationInterval',
            width: 110,
            editable: false,
            resizable: true,
            cellRenderer: InputTextRenderer,
            cellRendererParams: (params: any) => {
              return {
                disabled: params.data.applicationNumber === 1 ? true : false && !this.isProjectOwnershipValid,
              };
            },
          }
        ]
      },
      {
        headerName: 'Rate (lb/acre)',
        children: [
          {
            headerName: 'Default Active Ingredient',
            field: 'defaultAI',
            cellRenderer: InputTextRenderer,
            cellRendererParams: (params: any) => {
              return {
                disabled: !this.isProjectOwnershipValid,
              };
            },
            editable: false,
          },
        ]
      },

    ]
  }*/

    configureDetailGrid(): void {
      this.detailsColumnDef = [
        {
          headerName: '',
          children: [
            {
              headerName: 'Application Number',
              field: 'applicationNumber',
              width: 110,
              minWidth: 110,
              maxWidth: 110,
              filter: 'agSetColumnFilter',
              type: 'default',
              editable: this.isProjectOwnershipValid,
            },
          ]
        },
        {
          headerName: '',
          children: [
            {
              headerName: 'Application Interval', //'Day of Application',
              field: 'applicationInterval',
              width: 110,
              minWidth: 110,
              maxWidth: 110,
              filter: 'agSetColumnFilter',
              type: 'default',
              editable: (params: any) => params.data.applicationNumber === 1 ? false : true && this.isProjectOwnershipValid,
            }
          ]
        },
        {
          headerName: 'Rate (lb/acre)',
          children: [
            {
              headerName: 'Default Active Ingredient',
              field: 'defaultAI',
              cellRenderer: '',
              editable: !this.isProjectOwnershipValid,
            },
          ]
        },

      ]
    }


  createTransactionsForRates(dataTransaction: any[]) {
    this.grid.gridApi.forEachNode((node: RowNode) => {
      let transaction = dataTransaction.find((x: any) => x.rowUniqueID === node.id);
      if (transaction) {

        if ((transaction.transactionType == 1 || transaction.transactionType == 2) && transaction.rowUniqueID === node.id) {
          if (!transaction.row?.applicationSchemeXActiveIngredientRate) {
            transaction.row.applicationSchemeXActiveIngredientRate = [];
          }
          transaction.row.applicationSchemeXActiveIngredientRate = [...transaction.row.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(transaction.row)]
        }
      } else {
        if (!node.data?.applicationSchemeXActiveIngredientRate) {
          node.data.applicationSchemeXActiveIngredientRate = [];
        }
        node.data.applicationSchemeXActiveIngredientRate = [... node.data.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(node.data)];
        let rates = [...node.data.applicationSchemeXActiveIngredientRate, ...this.setMissingApplicationSchemeXActiveIngredientRate(node.data)];
        node.data.applicationSchemeXActiveIngredientRate = rates;
        this.grid.CreateTransaction(node.id, node.id, null, node.data);
      }
    })
  }

  createDetailTransactionsForRates(dataTransaction: any[]) {
    this.grid.gridApi.forEachNode((node: RowNode) => {
      let transaction = dataTransaction.find((x: any) => x.rowUniqueID === node.id);
      if (transaction) {
        if (transaction.row.children) {
          if ((transaction.transactionType == 1 || transaction.transactionType == 2) && transaction.rowUniqueID === node.id) {
            transaction.row.children.forEach((child: any) => {
              if (!child?.applicationSchemeXActiveIngredientRate) {
                child.applicationSchemeXActiveIngredientRate = [];
              }
              child.applicationSchemeXActiveIngredientRate = [...child.applicationSchemeXActiveIngredientRate, ...this.setMissingDetailApplicationSchemeXActiveIngredientRate(child)];
            });
          }
        }
      } else {
        node.data?.children?.forEach((child: any) => {
          if (!child?.applicationSchemeXActiveIngredientRate) {
            child.applicationSchemeXActiveIngredientRate = [];
          }
          child.applicationSchemeXActiveIngredientRate = [...child.applicationSchemeXActiveIngredientRate, ...this.setMissingDetailApplicationSchemeXActiveIngredientRate(child)];
          let rates = [...node.data.children.applicationSchemeXActiveIngredientRate, ...this.setMissingDetailApplicationSchemeXActiveIngredientRate(child)];
          child.applicationSchemeXActiveIngredientRate = rates;
        });
        this.grid.CreateTransaction(node.id, node.id, null, node.data);

      }
    })
  }

  setActiveIngredientsRate(dataTransaction: any[]) {
    dataTransaction?.forEach(data => {
      data.row.applicationSchemeXActiveIngredientRate?.map((x: any) => {
        x.rate = data.row[`rate${x.unitOfMeasure}${x.moleculePk}`];
        x.minRate = data.row[`minRate${x.unitOfMeasure}${x.moleculePk}`];
        x.maxRate = data.row[`maxRate${x.unitOfMeasure}${x.moleculePk}`];
        x.incrementRate = data.row[`incrementRate${x.unitOfMeasure}${x.moleculePk}`];
      });

      data.row.children?.forEach((child: any) => {
        child.applicationSchemeXActiveIngredientRate?.map((x: any) => {
          x.rate = child[`rate${x.unitOfMeasure}${x.moleculePk}`];
          x.minRate = child[`minRate${x.unitOfMeasure}${x.moleculePk}`];
          x.maxRate = child[`maxRate${x.unitOfMeasure}${x.moleculePk}`];
          x.incrementRate = child[`incrementRate${x.unitOfMeasure}${x.moleculePk}`];
        });
      });

    })

    return dataTransaction;
  }

  setMissingApplicationSchemeXActiveIngredientRate(data: any): any[] {
    let activeIngredients: any[] = []
    this.activeIngredientRatesColDef?.forEach((x: any) => {
      const fieldName = "ratelbacr";
      let moleculePk = x.field?.toLowerCase().replace(fieldName.toLowerCase(), "");
      if (moleculePk) {
        let existMolecule = data.applicationSchemeXActiveIngredientRate.find((x: any) => x.moleculePk == moleculePk);
        if (!existMolecule) {
          activeIngredients.push({
            moleculePk,
            rate: null,
            unitOfMeasure: 'lbacr'
          });
        }
      }
    })

    return activeIngredients;
  }

  setMissingDetailApplicationSchemeXActiveIngredientRate(data: any): any[] {
    let activeIngredients: any[] = []
    this.activeIngredientRatesColDef?.forEach((x: any) => {
      const fieldName = "ratelbacr";
      let moleculePk = x.field?.toLowerCase().replace(fieldName.toLowerCase(), "");
      if (moleculePk) {
        let existMolecule = data.applicationSchemeXActiveIngredientRate.find((x: any) => x.moleculePk == moleculePk);
        if (!existMolecule) {
          activeIngredients.push({
            moleculePk,
            rate: null,
            unitOfMeasure: 'lbacr'
          });
        }
      }
    })

    return activeIngredients;
  }

  addActiveIngredientsRatesToGrid(data: any, columsDef: any, selectedProject: Project): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const compoundMoleculePks = this.gapLogicService.getCompoundMoleculePks(data, selectedProject);
      this.activeIngredientApiService.getMoleculeByMoleculePks(compoundMoleculePks).pipe(take(1)).subscribe({
        next: (activeIngredients: any) => {
          this.transformData(data);
          const ghaColDefs = this.createActiveIngredientsAsColDef(activeIngredients, 'gha');
          const lbacrColDefs = this.createActiveIngredientsAsColDef(activeIngredients, 'lbacr');
          this.insertRatesTocolumnDefs(columsDef, ghaColDefs, lbacrColDefs, false);
          resolve(true);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  addActiveIngredientsRatesToDetailGrid(data: any, columsDef: any, selectedProject: Project): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const compoundMoleculePks = this.gapLogicService.getCompoundMoleculePks(data, selectedProject);
      this.activeIngredientApiService.getBamsMoleculePkAndNameByPks(compoundMoleculePks).pipe(take(1)).subscribe({
        next: (activeIngredients: any) => {
          this.transformDetailData(data);
          const ghaColDefs = this.createActiveIngredientsAsDetailColDef(activeIngredients, 'gha');
          const lbacrColDefs = this.createActiveIngredientsAsDetailColDef(activeIngredients, 'lbacr');
          this.insertRatesTocolumnDefs(columsDef, ghaColDefs, lbacrColDefs, true);
          resolve(true);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  insertRatesTocolumnDefs(columDef: ColGroupDef[], rateGHAcolumnDefs: ColGroupDef[], rateLBACREcolumnDefs: ColGroupDef[], isDetail: boolean,) {
    columDef.map((columDef: any) => {
      if (columDef.headerName === Constants.COLUMN_NAMES.RATEGHA) {
        this.activeIngredientRatesColDef = rateGHAcolumnDefs;
        columDef.children = rateGHAcolumnDefs;
      }
      if (columDef.headerName === Constants.COLUMN_NAMES.RATELBACRE) {
        this.activeIngredientRatesColDef = rateLBACREcolumnDefs;
        columDef.children = rateLBACREcolumnDefs;
      }
    });

    if (isDetail)
      return this.detailsColumnDef = columDef;

    return this.columnDefs = columDef;
  }

  transformData(rowData: any) {
    rowData?.map((row: any) => {
      row.geographies = [];
      row.applicationSchemeXGeography.map((geography: any) => {
        const geo = this.geographies.find((x: Catalog) => x.key == geography.geographyPk);
        if (geo) {
          row.geographies.push(geo);
        }
      });
      row.applicationSchemeXActiveIngredientRate.map((rate: any) => {
        row[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
        row[`minRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.minRate;
        row[`maxRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.maxRate;
        row[`incrementRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.incrementRate;
      });
    });
    this.rowData = rowData;
  }

  transformDetailData(rowData: any) {
    rowData?.map((row: any) => {
      row.children.forEach((child: any) => {
        child.applicationSchemeXActiveIngredientRate.map((rate: any) => {
          child[`rate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.rate;
          child[`minRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.minRate;
          child[`maxRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.maxRate;
          child[`incrementRate${rate.unitOfMeasure}${rate.moleculePk}`] = rate.incrementRate;
        });
      });
    });
    const uniqueRowData = this.removeDuplicates(rowData);
    this.rowData = uniqueRowData !== null ? uniqueRowData : [];
  }

  removeDuplicates(rowData: any[]): any[] {
    const seen = new Set();
    return rowData.filter((row: any) => {
      const identifier = row.applicationSchemePk || row.applicationSchemePk;
      if (seen.has(identifier)) {
        return false;
      } else {
        seen.add(identifier);
        return true;
      }
    });
  }

  createActiveIngredientsAsColDef(activeIngredients: any[], unitOfMeasure: string): any[] {
    return this.createColDef(activeIngredients, unitOfMeasure);
  }

  createActiveIngredientsAsDetailColDef(activeIngredients: any[], unitOfMeasure: string): any[] {
    return this.createDetailColDef(activeIngredients, unitOfMeasure);
  }
  createColDef(activeIngredients: any, unitOfMeasure: string): any[] {
    let rateColdef: any[] = [];
    activeIngredients?.forEach((activeIngredient: any) => {
      rateColdef.push(
        {
          headerName: activeIngredient.moleculeName,
          field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 150,
          minWidth: 150,
          editable: false,
          cellStyle: (params: any) => {
            if (!this.validateVariableRatesOrIntervals(params)) {
              return { 'background-color': '#f0f0f0' };
            }
            return null;
          },
          cellRenderer: InputTextRenderer,
          cellRendererParams: (params: any) =>  {
            return {
              disabled: !this.isProjectOwnershipValid || !this.validateVariableRatesOrIntervals(params),
            }
          },
        }
      );
    });
    return rateColdef;
  }

  createDetailColDef(activeIngredients: any, unitOfMeasure: string): any[] {
    let rateColdef: any[] = [];
    activeIngredients?.forEach((activeIngredient: any) => {
      rateColdef.push(
        {
          headerName: activeIngredient.moleculeName,
          field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
          type: 'default',
          filter: 'agSetColumnFilter',
          width: 150,
          minWidth: 150,
          editable: (params: any) => this.validateVariableRatesOrIntervals(params),
          cellStyle: (params: any) => {
            if (!this.validateVariableRatesOrIntervals(params)) {
              return { 'background-color': '#f0f0f0' };
            }
            return null;
          }
        }
      );
    });
    return rateColdef;
  }

  /*createColDef(activeIngredients: any, unitOfMeasure: string): any[] { //TODO The texboxComponet need to be check
    let rateColdef: any[] = [];
    activeIngredients?.forEach((activeIngredient: any) => {
      rateColdef.push(
        {
          resizable: true,
          headerName: activeIngredient.moleculeName,
          field: `rate${unitOfMeasure}${activeIngredient.moleculePk}`,
          cellRenderer: InputTextRenderer,
          cellRendererParams: (params: any) => {
            return {
              disabled: !this.validateVariableRatesOrIntervals(params) || !this.isProjectOwnershipValid,
            };
          },
          width: 150,
          editable: false,
        }
      );
    });
    return rateColdef;
  }*/

  getCompartments(): Promise<any[]> {
    return new Promise<number[]>((resolve, reject) => {
      this.datasetService.getCompartments(Constants.CONST_COMPARTMENT).subscribe({
        next: (comparments: any) => {
          resolve(comparments);
        },
        error: (error: Error) => {
          reject(error);
        }
      });
    });
  }

  onFirstDataRendered(params: any): void {
    if (params?.type === Constants.GRID_EVENTS.FIRST_DATA_RENDERED) {

      this.grid.gridApi.forEachNode((node: RowNode) => {
        if (node?.data?.numberOfApplications === 1) {
          node!.data.applicationInterval = 1;
          node!.data.variableRatesOrIntervals = Constants.AFFIRMATIVE_VALUES.NO;
        }
        if (node?.data?.variableRatesOrIntervals === Constants.AFFIRMATIVE_VALUES.YES) {
          node!.data.applicationInterval = null;
          Object.entries(node?.data).forEach(([key, value]) => {
            if (key.startsWith(Constants.GAP_FIELD_NAMES.RATE)) {
              node!.data[key] = null;
            }
          });
        }
      });
      this.grid.gridApi.redrawRows();
    }
  }

  public onBlurInputText({ row }: { row: any }): void {
    this.checkIfApplicationSchemeIsValid(row.name, row.applicationSchemePk);
  }

  private async checkIfApplicationSchemeIsValid(applicationSchemeName: string | undefined, applicationSchemePk: number | undefined): Promise<void> {
    const invalidRows = this.rowData.some(row => row.name == undefined || row.name == '');
    const transactionOcurrences = this.gapApplicationSchemeLogicService.getTransactionOccurrences(this.rowData);
    const duplicateDatasetsInTransaction = this.gapApplicationSchemeLogicService.duplicateDataInRecord(transactionOcurrences);
    applicationSchemePk = applicationSchemePk ?? -1

    if (applicationSchemeName != undefined && applicationSchemeName != '') {
      if (duplicateDatasetsInTransaction)
        Utils.showErrorMessage('The Application Scheme name already exists.', 'Please use another name');
    }

    if (invalidRows || duplicateDatasetsInTransaction)
      this.enableControls(false);
    else
      this.enableControls(true);
  }

  private enableControls(enable: boolean): void {
    this.isValid.emit(enable);
    this.grid.useAddAction = enable;
    this.grid.gridApi.setColumnDefs(this.columnDefs);
  }

  public onAdd(event: any): void {
    event[0].seedTreatment = Constants.AFFIRMATIVE_VALUES.NO,
      event[0].variableRatesOrIntervals = Constants.AFFIRMATIVE_VALUES.NO,
      event[0].halfLife = 35,
      this.grid.setLastRowSelected('useInProject');
    this.refreshSelectedValues();
    this.enableControls(false);
  }

  private refreshSelectedValuesTimeout(): void {
    setTimeout(() => {
      this.refreshSelectedValues();
    }, 75);
  }

  private refreshSelectedValues(): void {
    if(! this.grid ) return;
    this.grid.refreshSelectedValues('useInProject');
  }

  public onRowExpanded(event: RowGroupOpenedEvent): void {
    const { rowIndex, node } = event;

    if(!node.data.useInProject)
      this.grid.selectOrUnselectRow(rowIndex!, false);

    this.refreshSelectedValues();
  }

  public onSelectedRow (event: RowSelectedEvent): void {
    if( !this.grid ) return;
    this.createTransactionForSelectedRow( event.node );
  }

  public onSelectOrUnselectAll ( event: SelectionChangedEvent ){
    if( event.source !== 'uiSelectAll' || !this.grid ) return;
    this.grid.gridApi.forEachNode((node: any) => {
     this.createTransactionForSelectedRow(node);
    });
  }

  private createTransactionForSelectedRow( node: any ): void {
    const { id, data } = node;
    data.useInProject = node.isSelected();
    this.grid.CreateTransaction(id, id, data.useInProject, data);
  }

}
