<p-fieldset [legend]="leyend" [toggleable]="true">
  <div class="grid">
    <div class="col-12">
      <div class="flex justify-content-end flex-wrap mr-2 mb-3 "  >
          <app-button  [disabled]="!isProjectOwnershipValid" [outlined]="true" severity="warning" label="Add" (onClickEvent)="onAdd()"></app-button>
      </div>
    </div>
  </div>
<ag-grid-angular #agGrid class="ag-theme-alpine"
[domLayout]="domLayout"
[rowData]="rowData"
[columnDefs]="columnDef"
[components]="components"
[context]="context"
[rowHeight]="40"
(gridReady)="onGridReady($event)"
></ag-grid-angular>
</p-fieldset>
