<app-selected-project></app-selected-project>
<div class="isProjectOwnershipValid">
    <p [hidden]="isProjectOwnershipValid" >You can not modify or run someone's project</p>
</div>
<div class="pt-1 pr-5 pb-5 pl-5">
    <app-wizard class="surface-200" [items]="ESALogicService.items" [message]="message" [loading]="loading"
        [activeItem]="activeItem" [selectedProject]="selectedProject" [selectedProjectStatus]="selectedProjectStatus" [isSetupValid]="isSetupValid"
        (activeIndexChangeEmitter)="onActiveIndexChange($event)"></app-wizard>
    <div class="shadow-8 surface-0 p-3">
        <ng-container *ngIf="activeItem?.index == 0">
            <app-esa-setup [menuService]="ESALogicService" [disciplinePk]="disciplinePk" (isSetupValidEvent)="validateSetup($event)"></app-esa-setup>
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 1">
          <!-- TODO: For Run Project -->
        </ng-container>

        <ng-container *ngIf="activeItem?.index == 2">
            <!-- TODO: For Output -->
        </ng-container>
    </div>
</div>
