<div class="grid"  style="margin-top: 20px;">
    <div class="col-12">
        <app-endpoint-dataset
          [isProjectOwnershipValid]="isProjectOwnershipValid"
          [subTabMenuService]="subTabMenuService"
          [menuService]="menuService"
          [selectedProject]="selectedProject"
          [environmentalAssessment]="environmentalAssessment"
          [savedEraDatasetPk]="savedDatasetPk"
          [compartment]="taxonGroup!"
          (datasetData)="getDatasetDataOutput($event)"
          (showDetail)="showDetailOutput($event)"
          (isValid)="getIsDatasetValid($event)"
        ></app-endpoint-dataset>
    </div>
    <div *ngIf="showDetail && !isLoading" class="col-12 mt-2">
        <div class="grid">
            <div class="col-12">
                <app-era-ecotox-endpoints
                  [regionPk]="regionPk"
                  [subTabMenuService]="subTabMenuService"
                  [menuService]="menuService"
                  [dataSet]="dataset"
                  [endpointComponent]="eraEcotoxDatasetsComponent"
                  [taxonGroup]="taxonGroup"
                  [isProjectOwnershipValid]="isProjectDataSetOwnershipValid"
                  [selectedProject]="selectedProject"
                  [source]="environmentalAssessment"
                  (detailTransactionToSave)="saveDetailData($event)"
                  (emitSavedDatasetpk)="getEmittedDatasetPk($event)"
                ></app-era-ecotox-endpoints>
            </div>
        </div>
    </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
