import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Metabolite, MetabolitePkAndName, MetabolitePkAndWeight } from '../../models/echo/metabolite';
import { HttpApiService } from '../http.api.service';

@Injectable({
  providedIn: 'root',
})

export class MetaboliteApiService extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  getMetabolites(): Observable<Metabolite[]> {
    const endpoint = "api/metabolite";
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getMetabolitesByMolecule(moleculePk: number): Observable<Metabolite[]> {
    const endpoint = `api/metabolite/${moleculePk}/${true}`;
    return this.get(endpoint).pipe(map((data: any) => data.Value));
  }

  getMetabolitesPkAndName(): Observable<MetabolitePkAndName[]> {
    let endpoint = `api/getMetabolitesPkAndName`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint,  headers )
      .pipe(map((data: any) => data.Value));
  }

  getMetaboliteWeightByPk(metabolitePk:number): Observable<MetabolitePkAndWeight[]> {
    let endpoint = `api/getMetaboliteWeightByPk/${metabolitePk}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint,  headers )
      .pipe(map((data: any) => data.Value));
  }

  getMetabolitesByPk(metabolitePks: Number[]): Observable<MetabolitePkAndName[]> {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/metabolites/getMetabolitesByPk`;
    return this.put(query, metabolitePks, headers)
    .pipe(map((data: any) => data));
  }

}
