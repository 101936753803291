import { Component, Input } from '@angular/core';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { TemplateDataSet } from 'src/app/shared/models/template-data-set';
import { DisciplineService } from '../filters/discipline/discipline.service';
import Swal from 'sweetalert2';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-endpoint-batch-upload',
  templateUrl: './endpoint-batch-upload.component.html',
  styleUrls: ['./endpoint-batch-upload.component.css']
})
export class EndpointBatchUploadComponent {

  @Input() parentComponent: any;  
  @Input() dataSet: any;
  @Input() endpointTypeIdentity: string = '';
  @Input() endpointComponent: any;
  
  batchUploadItems: MenuItem[] = [];  
  isDownloadingTemplate: boolean = false;
  customLabel: string = 'Batch Upload';
  componentThatImplementsGrid = this; 
  showUploadTemplate: boolean = false;
  
  templateDataSet: TemplateDataSet = {
    columnsDefs: [],
    filters: {},
  };

  constructor( private disciplineService: DisciplineService ) { 
   }

  ngOnInit(): void {
    this.endpointComponent = this.parentComponent.endpointComponent;
    this.batchUploadItems = [
      {
          label: 'Download File',
          icon: PrimeIcons.DOWNLOAD,
          command: () => { this.downloadTemplate(); }
      },
      {
          label: 'Upload File',
          icon: PrimeIcons.UPLOAD,
          command: () => { this.uploadTemplate(); }
      }];
  }
  
  downloadTemplate() {
    this.setDownloadingFlag(true); 
    const columnsDefs = this.parentComponent.columnDefs;    
    const temp = columnsDefs.map((o: any) => ({field: o.field, headerName: o.headerName, editable: o.editable, hide: o.hide})).filter((x: any) => x.field && x.headerName);
 
    this.endpointComponent.filters.compartment = this.endpointTypeIdentity; 
    this.endpointComponent.filters.application = Constants.THIS_APPLICATION;
    this.setDiscipline();
    this.templateDataSet = {
      filters: this.endpointComponent.filters,
      columnsDefs: columnsDefs.map((o: any) => ({field: o.field, headerName: o.headerName, editable: o.editable, hide: o.hide, width: o.width,
                                          type: o.type, validationType: o.validationType})).filter((x: any) => x.field && x.headerName && x.hide === false && x.validationType)
    };      
    this.disciplineService.getDisciplineExcelTemplate(this.templateDataSet, this.dataSet ? this.dataSet.dataSetPk : 0).subscribe(
      (data: any) => {
        this.downloadFile(data);
    },
    (error: any) => {
      this.setDownloadingFlag(false);
      console.error(error);
      Swal.fire({
        title: error.error.Value.Title,
        text: error.error.Value.Detail,
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Ok',
        icon: 'error',
      });
    }); 
  }
  
  setDiscipline(){    
    this.endpointComponent.filters.discipline = Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE;  
  }
  
  setDownloadingFlag(pValue: boolean) {
    this.isDownloadingTemplate = pValue;
    this.customLabel = pValue? 'Downloading' : 'Batch Upload';
  }
  
  downloadFile(data: any): void {
    const byteString = window.atob(data.FileContents);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const downloadLink = document.createElement('a');
    const b = new Blob([int8Array], { type: 'application/xlsx' });
    downloadLink.href = window.URL.createObjectURL(b);
    downloadLink.setAttribute('download', data.FileDownloadName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    if(downloadLink.parentNode)
      downloadLink.parentNode.removeChild(downloadLink);
    this.setDownloadingFlag(false);
  }
  
  uploadTemplate(){
    this.showUploadTemplate = true;
  }  
  
  closeModal(pValue: boolean){
    this.showUploadTemplate = pValue;
  }
}
