  <span class="filter-label">Taxon: </span>
    <p-dropdown
      [style]="{ 'width': '40%', 'line-height' : '16px' }"
      styleClass="taxon-filter-dropdown"
      styleClass="ddlFilterSelectionMultiselect"
      panelStyleClass="panel-filter-dropdown"
      placeholder="Select a value"
      [disabled]="taxons.length == 0"
      [options]="taxons"
      [(ngModel)]="selectedTaxon"
      optionLabel="description"
      optionValue="id"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      (onChange)="taxonSelectionChange()"
    >
    </p-dropdown>
