

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutFooterComponent } from './layout-footer/layout-footer.component';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { LayoutMenuComponent } from './layout-menu/layout-menu.component';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';



@NgModule({
  declarations: [
    LayoutFooterComponent,
    LayoutHeaderComponent,
    LayoutMenuComponent,
    LayoutComponent,
    HomeComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
  ]
})
export class LayoutModule { }
