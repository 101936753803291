import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class GapApplicationSchemeLogicService {

    constructor(){       
    }

    public getTransactionOccurrences(rowData: any[]): Record<string, number> {
        let occurrences = rowData.reduce((acumulator: Record<string, number>, rowData) => {
            const key = `${rowData.name?.toLocaleLowerCase()}`;
            acumulator[key] = (acumulator[key] || 0) + 1;
            return acumulator;
        }, {} as Record<string, number>);
        return occurrences;
    }

    public duplicateDataInRecord(transactions: Record<string, number>) : boolean {
        for (let transaction in transactions) {
            if (transactions[transaction] > 1) {
            return true;
            }
        }
        return false;
    }
}