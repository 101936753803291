
<div class="card grid" *ngIf="!activeItem?.disabled && !setupLoading">
    <div class="mt-2 ml-2 col-12">
        <app-esa-setup-inputs [selectedProject]="selectedProject"  [isProjectOwnershipValid]="isProjectOwnershipValid" [currentUserName]="currentUserName" (isSetupValidEvent)="validateSetupInfoInProject($event)"></app-esa-setup-inputs>
    </div>
    <div class="mt-5 ml-2 col-8">
        <app-esa-setup-models-to-be-run [modelsLoading]="modelsLoading"></app-esa-setup-models-to-be-run>
    </div>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="setupLoading"></app-loading>
