import { Injectable } from '@angular/core';
import { ApplicationMethodMatchingService } from 'src/app/administrator/regulatory-models/application-method-matching/application-method.matching.service';
import { ModelLibraryService } from 'src/app/administrator/regulatory-models/model-library/model-library.service';
import { ApplicationDateGroundWater, ApplicationDateSurfaceWater } from 'src/app/shared/models/application-date';
import { ApplicationMethodMatching } from 'src/app/shared/models/application-method-matching';
import { ApplicationScheme } from 'src/app/shared/models/application-scheme';
import { CropInterception } from 'src/app/shared/models/crop-interception';
import { CropMatching } from 'src/app/shared/models/crop-matching';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { Formulation } from 'src/app/shared/models/formulation';
import { List } from 'src/app/shared/models/list';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { ApplicationDateApiService } from 'src/app/shared/services/application-date.api.service';
import { CropInterceptionApiService } from 'src/app/shared/services/crop-interception.api.service';
import { CropMatchingApiService } from 'src/app/shared/services/crop-matching.api.service';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { FormulationApiService } from 'src/app/shared/services/echo/formulation.api.service';
import { ListManagerApiService } from 'src/app/shared/services/list-manager.api.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { GapApiService } from 'src/app/shared/services/gap.api.service';
import { firstValueFrom, take } from 'rxjs';
import { ActiveIngredient } from 'src/app/shared/models/active-ingridient';

@Injectable({
  providedIn: 'root'
})
export class ExposureAssessmentOutputViewApiService {

  applicationSchemeList: ApplicationScheme[] = [];
  dataFieldValue: DatafieldValue[] = [];
  dataSetList: DataSet[] = [];
  models: ModelLibrary[] = [];
  applicationMethodMatchings: ApplicationMethodMatching[] = [];
  cropInterceptions: CropInterception[] = [];
  applicationDatesSurfaceWater: ApplicationDateSurfaceWater[] = [];
  applicationDatesGroundWater: ApplicationDateGroundWater[] = [];
  cropMatchings: CropMatching[] = [];
  crops: List[] = [];

  constructor(private datafieldValueService: DatafieldValueApiService,
              private dataSetService: DataSetApiService,
              private listManagerService: ListManagerApiService,
              private modelLibraryService: ModelLibraryService,
              private applicationMethodMatchingService: ApplicationMethodMatchingService,
              private cropInterceptionService: CropInterceptionApiService,
              private applicationDateService: ApplicationDateApiService,
              private cropMatchingService: CropMatchingApiService,
              private formulationService: FormulationApiService,
              private gapService: GapApiService) {
    this.getAllFields();
  }

  async getAllFields(): Promise<void> {
    await Promise.all([
      this.getDatafields(),
      this.getDataSets(),
      this.getModels(),
      this.getCropMatchings(),
      this.getApplicationDatesGroundWater(),
      this.getApplicationDatesSurfaceWater(),
      this.getApplicationMethodMatchings(),
      this.getCrops(),
    ]);
  }

  getDatafields(): void {
    this.datafieldValueService.getDatafieldValueInformation().pipe(take(1)).subscribe((datafieldValueData: DatafieldValue[]) => {
      this.dataFieldValue = datafieldValueData;
    });
  }

  getApplicationMethodMatchings() {
    this.applicationMethodMatchingService.getApplicationMethodMatchings()
    .pipe(take(1))
      .subscribe((applicationMethodMatchings: ApplicationMethodMatching[]) => {
        this.applicationMethodMatchings = applicationMethodMatchings;
      });
  }

  getCrops() {
    const commodityList = Constants.listManagerLists[0];
    const tags: string[] = ['Commodity'];
    this.listManagerService.getList(
      commodityList.comodities.listLevel1,
      commodityList.comodities.listLevel2,
      commodityList.comodities.listLevel3,
      commodityList.comodities.mappingValueTest,
      tags
    )
    .pipe(take(1))
      .subscribe((cropList:any)=>{
        this.crops = Utils.sortObject(cropList, 'item_name');
      });
  }

  getCropMatchings() {
    this.cropMatchingService.getCropMatchings(-1).pipe(take(1)).subscribe((cropMatchings:any)=>{
      this.cropMatchings = cropMatchings;}
    );
  }

  getCropInterception() {
    this.cropInterceptionService.getCropInterception()
    .pipe(take(1))
      .subscribe((cropInterception:CropInterception[])=>{
          this.cropInterceptions = cropInterception;
        });
  }

  getApplicationDatesSurfaceWater() {
    this.applicationDateService.getApplicationDatesSurfaceWater()
    .pipe(take(1))
      .subscribe((applicationDatesSW: ApplicationDateSurfaceWater[]) =>{
          this.applicationDatesSurfaceWater = applicationDatesSW;
        });
  }

  getApplicationDatesGroundWater() {
    this.applicationDateService.getApplicationDatesGroundWater()
    .pipe(take(1))
      .subscribe((applicationDatesSW: ApplicationDateGroundWater[]) => {
        this.applicationDatesGroundWater = applicationDatesSW;
      });
  }

  async getDataSets(): Promise<void> {
    try {
      const dataSet = await this.dataSetService.getDataSets().pipe(take(1)).toPromise();
      this.dataSetList = dataSet ?? [];
      this.dataSetList=this.dataSetList.filter(x => x.useInProject ===true );
    } catch (error) {
      console.error('Error al obtener conjuntos de datos:', error);
    }
  }

  getModels() {
    this.modelLibraryService.getModels().pipe(take(1)).subscribe((models: ModelLibrary[]) => {
      this.models = Utils.sortObject(models, 'nameVersion');
    });
  }

  getApplicationSchemes(formulationPk: any): void {
    this.gapService.getGapByApplicationSchemes({
      formulationPK: formulationPk,
    })
    .pipe(take(1))
      .subscribe((sortedAppSchemes: ApplicationScheme[]) => {
        this.applicationSchemeList = sortedAppSchemes.filter((appScheme: ApplicationScheme) => appScheme.useInProject===true);
      });
  }

  transformData(pGridData: any[]): any[] {
    pGridData.map(async row => {
      var dataset = this.dataSetList.find(x => x.dataSetPk == row.endpointsDataSetPk);
      var DataSet = this.dataSetList.find(x => x.dataSetPk?.toString() === row.Dataset);

      row.applicationSchemePk = this.applicationSchemeList.find(x => x.applicationSchemePk == row.applicationSchemePk)?.description;
      row.modelCropPk = this.dataFieldValue.find(x => x.datafieldValuePk == row.modelCropPk)?.value;
      row.ModelCropPk = this.dataFieldValue.find(x => x.datafieldValuePk === row.ModelCropPk)?.value;
      row.endpointsDataSetPk = dataset?.description;
      row.Dataset = DataSet?.description;

      if(row.formulationPK){
        await firstValueFrom(this.formulationService.getFormulationByPk(row.formulationPK, true)).then((formulation: Formulation) => {
          formulation.activeIngredients?.filter(({activeIngredientPk}: ActiveIngredient) => activeIngredientPk == dataset?.moleculePk)
          .map((active: ActiveIngredient) => {
            row.formulationPk = active.activeIngredientName;
          });
        });
      }
    });
    return pGridData;
  }

  GetDataSetName(pGridData: any[]): any[] {
    pGridData.map(row => {
      var dataset = this.dataSetList.find(x => x.dataSetPk == row.dataSetPk);
      row.dataSetName = dataset?.description;
    });
    return pGridData;
  }
}
