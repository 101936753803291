<div class="formgrid grid mr-2">
  <div class="col-5 font-bold">
    <app-input-text [disabled]="!selectedProject || !isProjectOwnershipValid" [label]="'Project Name'" [useTitle]="true"
      [id]="'nameInput'" [model]="selectedProject?.name" [isValid]="isProjectNameValid" [isValueDuplicated]="isProjectNameDuplicated"
      [isValueDuplicatedMessage]="'This project name already exists'" [isRequired]="true"
      (onChangeEvent)="projectNameChanged($event)">
    </app-input-text>
  </div>
  <div class="col-1 font-bold mt-4">
    <app-button [disabled]="!selectedProject || !isProjectOwnershipValid || !isProjectNameValid" [label]="'Import'" [size]="'small'"
      [severity]="'warning'" [outlined]="true"(onClickEvent)="copyProject()">
    </app-button>
  </div>
  <div class="col-6 font-bold">
    <app-input-textarea [disabled]="!selectedProject || !isProjectOwnershipValid" [label]="'Project Description'" [useTitle]="true" class="w-10"
      [id]="'descriptionInput'" [autoResize]="false" [rows]="1" [model]="selectedProject?.description" (onChangeEvent)="projectDescriptionChanged($event)">
    </app-input-textarea>
  </div>
</div>

<app-esa-copy-project-dialog #appDialog (onClickSaveButtonEvent)="onSaveDataProject($event)"> </app-esa-copy-project-dialog>

<div class="formgrid grid mt-2 mr-2">
  <div class="col-6 font-bold created-by">
    <app-input-text [label]="'Created by'" [useTitle]="true" class="w-10" [id]="'descriptionInput'" [disabled]="true" [model]="createdBy"></app-input-text>
  </div>
  <div class="col-1 font-bold archive-project-button">
    <app-button [disabled]="isArchiveButtonDisabled" [label]="'Archive Project'" [size]="'small'" [severity]="'warning'" [outlined]="true" (onClickEvent)="archiveProject()"></app-button>
  </div>
</div>

<app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>
