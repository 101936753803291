import { Injectable } from "@angular/core";
import { InputTextRenderer } from "src/app/shared/renderers/input-text/input-text.component";
import { Constants } from "src/app/shared/utils/constants";

@Injectable({
    providedIn: 'root'
})
export class EeaGapSurfacewaterPwcOptionalOutputsColdef {

    public getColumnsDefinition(isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: 'Item',
                width: 105,
                wrapHeaderText: true,
                field: 'item',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Chem',
                width: 105,
                wrapHeaderText: true,
                field: 'chem',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Mode',
                width: 125,
                field: 'mode',
                cellRenderer: 'dropdownlistRenderer',
                cellRendererParams: (params: any) => {
                  return {
                    options: Constants.PWC_OPTIONAL_OUTPUTS_MODE_OPTIONS,
                    optionValue: 'key',
                    optionLabel: 'key',
                    filterable: true,
                    disabled: !isProjectOwnershipValid,
                  }
                },
                filter: 'agSetColumnFilter',
                wrapText: false,
                resizable: true,
                sortable: true,
                editable: false,
              },
            {
                headerName: 'Arg 1',
                width: 105,
                wrapHeaderText: true,
                field: 'arg1',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Arg 2',
                width: 105,
                wrapHeaderText: true,
                field: 'arg2',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid,
                    };
                },
                editable: false,
            },
            {
                headerName: 'Multiplier',
                width: 105,
                wrapHeaderText: true,
                field: 'multiplier',
                resizable: true,
                filter: 'agSetColumnFilter',
                cellRenderer: InputTextRenderer,
                cellRendererParams: (params: any) => {
                    return {
                        disabled: !isProjectOwnershipValid,
                    };
                },
                editable: false,
            },
            {
                headerName: '',
                width: 120,
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: 'deleteButtonRenderer',
                type: 'rightAligned',
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid
                }
            }
        ]
    }
}