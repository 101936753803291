import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectAdminRenderer {
  public params: any;
  public selectedValues: any;
  public options: any[] = [];
  public parent: any;
  public optionValue: any;
  public optionLabel: any;
  public columnThatModifies: any;

  editable: boolean = false;
  constructor() {}

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
  
  agInit(params: any): void {
    this.params = params;
    this.selectedValues = params.value;
    this.options = params.options;
    this.options = this.setOrderBySelectedValues(params.options);
    this.parent = params.context.componentParent;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel;
    this.columnThatModifies = params.columnThatModifies;   
    this.defaultValueProcess()
    this.editable = params.editable;
  }
  
  defaultValueProcess() {
    if (this.params.data.newRow === true) {
      var index = this.defaultValueIndex();
      if (index !== -1) {
        this.selectedValues = [];
        this.selectedValues.push(this.options[index].value);
        let onChangeableObject: { value: any[] } = { value: [] };
        onChangeableObject.value.push(this.options[index].datafieldValuePk);
        this.onChange(onChangeableObject);
      }
    }
  }
  
  defaultValueIndex()
  {
    return this.options.findIndex(x => {
      if (x.isDefault && x.isDefault === true) {
        return true;
      }
      return false;
    });
  }
  
  public onChange(pValues: any): void {
    this.parent.setSelectionValues({
      field:this.columnThatModifies,
      id:this.params.node.id,
      row:this.params.data,
      sourceValues:this.options,
      value: pValues.value
    });

    this.options = this.setOrderBySelectedValues(this.options);
  }
  
  setOrderBySelectedValues(options: any){   
    var items = options.map((x: any) => {
      let isSelected = this.selectedValues?.includes(x.id);
      if(isSelected){
        x.selected = true;
      }
      else {
        x.selected = false;
      }
      return x;
    });

    return items.sort((a: any, b: any) => a.name?.localeCompare(b.name)).sort((a: any, b: any) => b.selected - a.selected);
  }
}
