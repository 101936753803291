<p-dialog header="File Uploader" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false" (onHide)="close()">
    <p-fileUpload accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" [showUploadButton]="false" [showCancelButton]="false" (onSelect)="fileSelected($event)">
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
        </ng-template>
    </p-fileUpload>
    <ng-template pTemplate="footer">
        <p-button icon="{{uploadingTemplate ? 'pi pi-spin pi-spinner' : 'pi pi-upload' }}" (click)="upload()" label="{{uploadingTemplate ? 'Uploading' : 'Upload'}}" styleClass="p-button-text saveButton" [disabled]="isDisabled()"></p-button>
        <p-button icon="pi pi-times" (click)="close()" label="Close" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>