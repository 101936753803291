import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InverseModelingEnvironmentalExposureAssesmentComponent } from './inverse-modeling-environmental-exposure-assesment/inverse-modeling-environmental-exposure-assesment.component';
import { SharedModule } from "../shared/shared.module";
import { EnvironmentalExposureAssessmentModule } from "../environmental-exposure-assessment/environmental-exposure-assessment.module";
import { InverseModelingEndpointsComponent } from './inverse-modeling-endpoints/inverse-modeling-endpoints.component';
import { EnvironmentalRiskAssessmentModule } from '../environmental-risk-assessment/environmental-risk-assessment.module';



@NgModule({
    declarations: [
        InverseModelingEnvironmentalExposureAssesmentComponent,
        InverseModelingEndpointsComponent
       
    ],
    exports: [
        InverseModelingEnvironmentalExposureAssesmentComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        EnvironmentalExposureAssessmentModule,        
        EnvironmentalRiskAssessmentModule
    ]
})
export class InverseModelingEnvironmentalExposureAssesmentModule { }
