import { Component, Input, OnInit } from '@angular/core';
import { AdminGridComponent } from '../../admin-grid/admin-grid.component';
import { CropListMatchingService } from './crop-list-matching.service';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { CropList } from 'src/app/shared/models/crop-list';
import { Constants } from 'src/app/shared/utils/constants';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-crop-list-matching-grid',
  templateUrl: '../../admin-grid/admin-grid.component.html',
})

export class CropListMatchingGridComponent extends AdminGridComponent implements OnInit {
  @Input() parentComponent: any;
  allCrops: CropList[] = [];
  constructor(private cropListMatchingService: CropListMatchingService,
    private userService: UserLogicService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.allCrops = this.parentComponent.allCrops;
    this.rowHeight = 190;
    this.context = { componentParent: this };
    this.batchUpload = true;
  }

  override onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.configureGrid();
  }

  configureGrid() {
    this.rowData = this.filterCropMatchingsBySelectedGeographyAndCompartment() ?? [];
    this.getNamesCropListMatchings();
    this.columnDefs = [
      {
        headerName: 'GAP Crop',
        field: 'gapCropNames',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.allCrops.filter(x => x.Geography == "Global"),
          optionValue: 'CropListPk',
          optionLabel: 'CropName',
          columnThatModifies: 'gapCrops',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          comparator: (valueA: string, valueB: string) => {
            if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
            return (valueA?.toLowerCase() > valueB?.toLowerCase()) ? 1 : -1;
          }
        },
      },
      {
        headerName: 'Interception',
        field: 'interceptionCropNames',
        hide: (this.parentComponent.selectedGeography == 'Europe Union' && this.parentComponent.selectedCompartment != 'Groundwater') ||
          (this.parentComponent.selectedGeography == 'UK' && this.parentComponent.selectedCompartment != 'Surface Water'),
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.allCrops.filter(x => x.Purpose == "Interception" && x.Compartment === this.parentComponent.selectedCompartment)
            .filter(x => { if (this.parentComponent.selectedGeography !== 'Global') { return x.Geography === this.parentComponent.selectedGeography } else { return true } })
            .filter(
              (crop, index, self) =>
                index === self.findIndex((t) => t.CropName === crop.CropName)
            ),
          optionValue: 'CropListPk',
          optionLabel: 'CropName',
          columnThatModifies: 'interceptionCrops',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          comparator: (valueA: string, valueB: string) => {
            if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
            return (valueA?.toLowerCase() > valueB?.toLowerCase()) ? 1 : -1;
          }
        },
      },
      {
        headerName: 'Drift-Interception',
        field: 'driftInterceptionCropNames',
        hide: this.parentComponent.selectedGeography != 'Europe Union' || this.parentComponent.selectedCompartment != 'Surface Water',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.allCrops.filter(x => x.Purpose == "Drift-Interception" && x.Geography === this.parentComponent.selectedGeography && x.Compartment === this.parentComponent.selectedCompartment).filter(
            (crop, index, self) =>
              index === self.findIndex((t) => t.CropName === crop.CropName)
          ),
          optionValue: 'CropListPk',
          optionLabel: 'CropName',
          columnThatModifies: 'driftInterceptionCrops',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          comparator: (valueA: string, valueB: string) => {
            if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
            return (valueA?.toLowerCase() > valueB?.toLowerCase()) ? 1 : -1;
          }
        },
      },
      {
        headerName: 'AppDate',
        field: 'appDateCropNames',
        hide: this.parentComponent.selectedGeography !== 'Europe Union',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.allCrops.filter(x => x.Purpose == "AppDate" && x.Compartment == this.parentComponent.selectedCompartment && x.Geography === this.parentComponent.selectedGeography).filter(
            (crop, index, self) =>
              index === self.findIndex((t) => t.CropName === crop.CropName)
          ),
          optionValue: 'CropListPk',
          optionLabel: 'CropName',
          columnThatModifies: 'appDateCrops',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          comparator: (valueA: string, valueB: string) => {
            if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
            return (valueA?.toLowerCase() > valueB?.toLowerCase()) ? 1 : -1;
          }
        },
      },
      {
        headerName: 'Spray',
        field: 'sprayCropNames',
        hide: this.parentComponent.selectedGeography != 'UK' || this.parentComponent.selectedCompartment != 'Surface Water',
        minWidth: 320,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.allCrops.filter(x => x.Purpose == "Spray" && x.Geography === this.parentComponent.selectedGeography && x.Compartment === this.parentComponent.selectedCompartment).filter(
            (crop, index, self) =>
              index === self.findIndex((t) => t.CropName === crop.CropName)
          ),
          optionValue: 'CropListPk',
          optionLabel: 'CropName',
          columnThatModifies: 'sprayCrops',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          comparator: (valueA: string, valueB: string) => {
            if (valueA?.toLowerCase() == valueB?.toLowerCase()) return 0;
            return (valueA?.toLowerCase() > valueB?.toLowerCase()) ? 1 : -1;
          }
        },
      },
      {
        colId: 'action',
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          editable: this.currentUserCanEdit,
        },
        type: 'rightAligned',
      },
    ];
  }

  filterCropMatchingsBySelectedGeographyAndCompartment(): any {
    var cropListMatchings: CropListMatching[] = this.parentComponent.cropListMatchings;
    return cropListMatchings.filter(x => x.geography == this.parentComponent.selectedGeography && x.compartment == this.parentComponent.selectedCompartment);

  }

  getNamesCropListMatchings() {
    this.rowData.forEach((row: any) => {
      row.gapCropNames = [];
      row.interceptionCropNames = [];
      row.appDateCropNames = [];
      row.driftInterceptionCropNames = [];
      row.sprayCropNames = [];

      row.gapCrops?.forEach((g: number) => {
        row.gapCropNames.push(this.allCrops.find((c: CropList) => c.CropListPk === g)?.CropName!);
      });
      row.interceptionCrops?.forEach((g: number) => {
        row.interceptionCropNames.push(this.allCrops.find((c: CropList) => c.CropListPk === g)?.CropName!);
      });
      row.appDateCrops?.forEach((a: number) => {
        row.appDateCropNames.push(this.allCrops.find((c: CropList) => c.CropListPk === a)?.CropName!);
      });
      row.driftInterceptionCrops.forEach((s: number) => {
        row.driftInterceptionCropNames.push(this.allCrops.find((c: CropList) => c.CropListPk === s)?.CropName!);
      });
      row.sprayCrops?.forEach((s: number) => {
        row.sprayCropNames.push(this.allCrops.find((c: CropList) => c.CropListPk === s)?.CropName!);
      });
    });
  }

  formatStatusFilterValues(pParams: any, pCropList: CropList[]): string {
    let filterName = pCropList.find(x => x.CropListPk == pParams.value);
    return filterName ? filterName.CropName : pParams.value;
  }

  override getData(): void {
    this.parentComponent.getData();
  }

  override onBtExport() {
    this.gridApi.exportDataAsExcel({
      processCellCallback: (params: any) => {
        var cropNames: (string | undefined)[] = [];
        params.value?.forEach((element: number) => {
          var cropName = this.allCrops.find(x => x.CropListPk == element)?.CropName
          cropNames.push(cropName);
        });

        return cropNames;
      },
    });
  }

  public setSelectionValues(pValues: CropList[], pField: string, pRow: any, pId: number) {
    let oldvalue = pRow[pField];
    pRow[Constants.FIELD_NAMES.COMPARTMENT] = this.parentComponent.selectedCompartment;
    pRow[Constants.FIELD_NAMES.GEOGRAPHY] = this.parentComponent.selectedGeography;
    pRow[pField] = pValues.map(x => x.CropListPk).flat();

    this.CreateTransaction(pRow.id, pId, oldvalue, pRow);
  }

  override saveData(pData: any) {
    return this.cropListMatchingService.saveData(pData);
  }


}
