import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectTier1ukDrainflowAndStreamColdef } from './eea-output-project-tier1uk-drainflow-and-stream.coldef';
import { EEAOutputProjectTier1ukDrainflowAndStreamService } from './eea-output-project-tier1uk-drainflow-and-stream.service';

@Component({
  selector: 'app-eea-output-project-tier1uk-drainflow-and-stream',
  templateUrl: './eea-output-project-tier1uk-drainflow-and-stream.component.html',
  styleUrls: ['./eea-output-project-tier1uk-drainflow-and-stream.component.css']
})
export class EEAOutputProjectTier1ukDrainflowAndStreamComponent implements OnInit {

  @Input() selectedProject?: Project;
  rowData: any;
  columDef: any;
  loading: boolean = false;



  constructor(private tier1UkDrainflowAndStream: EEAOutputProjectTier1ukDrainflowAndStreamColdef,
    private drainflowAndStreamService: EEAOutputProjectTier1ukDrainflowAndStreamService) { }


  ngOnInit(): void {
    this.columDef = this.tier1UkDrainflowAndStream.getColumnsDefinition();
  }

  ngOnChanges(pChanges: SimpleChanges): void {
    if (pChanges['selectedProject'].currentValue.projectPk)
      this.getDrainflowAndStream(pChanges['selectedProject'].currentValue.projectPk);
  }

  getDrainflowAndStream(pProjectPk: number) {
    this.loading = true;
    if (pProjectPk != 0) {
      this.drainflowAndStreamService
        .getDrainflowAndStream(pProjectPk)
        .subscribe((result: any) => {
          this.rowData = result.Value;
          this.loading = false;
        });
    }
  }
}