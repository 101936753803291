import { Injectable } from "@angular/core";
import { HttpApiService } from "./http.api.service";
import { environment } from 'src/environments/environment';
import { PWCInputs } from "../models/run/pwc-inputs";
import { HttpHeaders } from "@angular/common/http";
import { map } from "rxjs";
@Injectable({
    providedIn: 'root'
})
export class PWCRunApiService extends HttpApiService {
    override apiUrl: string = environment.BAMS_Api_URL;

    runPwc(pwcList: PWCInputs[]) {
        const body = JSON.stringify(pwcList);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/runPwc`;
        return this
            .put(query, body, headers)
            .pipe(map((data: any) => data));
    }
}