import { EventEmitter, Injectable, NgModule } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelWriterService {
  public downloadCompleted = new EventEmitter<void>();
  constructor() { }

  public exportToExcel(transformedData: any[], filename: string, groupKey: string) {
    const groupedData = this.groupDataByKey(transformedData, groupKey);

    const workbook = XLSX.utils.book_new();

    for (const [groupName, groupData] of Object.entries(groupedData)) {
        const flatData = this.flattenTransformedData(groupData, groupKey);
        const worksheet = XLSX.utils.json_to_sheet(flatData);

        const columnWidths: { wch: number }[] = [];

        Object.keys(flatData[0] || {}).forEach((header, index) => {
            const maxContentLength = flatData.reduce((max, row) => {
                const cellValue = row[header];
                return Math.max(max, cellValue ? cellValue.toString().length : 0);
            }, 0);

            columnWidths[index] = { wch: Math.max(header.length, maxContentLength, 20) };
        });

        worksheet['!cols'] = columnWidths;
        XLSX.utils.book_append_sheet(workbook, worksheet, groupName);
    }

    XLSX.writeFile(workbook, `${filename}.xlsx`);
    this.downloadCompleted.emit();
  }


  private groupDataByKey(data: any[], key: string): { [key: string]: any[] } {
    return data.reduce((acc, item) => {
        const groupValue = item[key] || 'Undefined';
        if (!acc[groupValue]) {
            acc[groupValue] = [];
        }
        acc[groupValue].push(item);
        return acc;
    }, {} as { [key: string]: any[] });
  }

  private flattenTransformedData(data: any[], excludeKey: string): any[] {
    const flatData: any[] = [];

    data.forEach((item) => {
        const flatItem: { [key: string]: any } = {};

        for (const key in item) {
            if (key === excludeKey) continue;

            if (Array.isArray(item[key]) && item[key].length > 0) {
                item[key].forEach((subItem: any) => {
                    for (const subKey in subItem) {
                        let colName = subKey;
                        if (colName in flatItem) {
                            let counter = 2;
                            while (`${colName}_${counter}` in flatItem) {
                                counter++;
                            }
                            colName = `${subKey}_${counter}`;
                        }
                        flatItem[colName] = subItem[subKey];
                    }
                });
            } else if (item[key] !== undefined && !Array.isArray(item[key])) {
              flatItem[key] = item[key];
            }
        }

        flatData.push(flatItem);
    });

    return flatData;
}
}
