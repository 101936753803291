import { Injectable } from '@angular/core';
import { ModelLibrary } from '../models/model-library';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from '../utils/constants';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RunFilter } from '../models/run-filter';
import { CatalogFilter } from '../models/catalog-filter';
import { Run } from '../models/run/run';
import { Project } from '../models/project';
import { RunModel } from '../models/run/run-model';

@Injectable({
  providedIn: 'root'
})
export class RunApiService {

  public swanModel!: ModelLibrary;

  constructor(private http: HttpClient) {}

  setSwanModelIfWasSelected(pModels: ModelLibrary[]) {
    this.swanModel = pModels.filter(x => x.name.toLowerCase() === Constants.MODELS.SWAN.toLowerCase())[0];
  }

  createRuns(project: Project, environmentalAssessment: string ): Observable<any> {
    const body = JSON.stringify(project);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/createRunsByProject/${environmentalAssessment}`; 
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getRunsByProjectPk(pProjectPk: number): Observable<any[]> {
    return this.http
      .get(`${environment.BAMS_Api_URL}api/runsByProject/${pProjectPk}`)
      .pipe(map((data: any) => data.Value));
  }

  save(pData:any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/runs`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  runModels(runModel: RunModel): Observable<any> {
    const body = JSON.stringify(runModel);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/runModels`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data)); 
  }

  getAdvancedFiltersInfo(): Observable<CatalogFilter> {
    return this.http
      .get(`${environment.BAMS_Api_URL}api/getAdvancedFiltersInfo`)
      .pipe(map((data: any) => data.Value));
  }

  getRunsByFilter(pRunFilter: RunFilter): Observable<Run[]> {
    const body = JSON.stringify(pRunFilter);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/getRunsByFilter`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  runsHaveBeenSaved(pProjectPk: number): Observable<boolean> {
    return this.http
      .get(
        `${environment.BAMS_Api_URL}api/runsHaveBeenSaved/${pProjectPk}`
      )
      .pipe(map((data: any) => data.Value));
  }
}
