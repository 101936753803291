import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Project } from "src/app/shared/models/project";
import { TRexForRun } from "src/app/shared/models/trex/trex-inputs";
import { HttpApiService } from "src/app/shared/services/http.api.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  
export class EraRunRiskAssessmentTerrestrialBamsApi extends HttpApiService {
    override apiUrl: string = environment.BAMS_Api_URL;
    runTREX(dataTransaction: TRexForRun[]): Observable<any> {
        const body = JSON.stringify(dataTransaction);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        let endpoint = `api/runTRex`;
        return this.put(endpoint, body, headers).pipe(map((data: any) => data.Value));
      }

      DownloadTRexOutputFilesByProject(pProject: Project): Observable<string> {
        const body = JSON.stringify(pProject);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/downloadTRexOutputFilesByProject`;
        return this.put(query, body, headers)
                    .pipe(map((data: any) => data.Value));
      }
}                                                                                                                                             