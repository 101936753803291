import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Discipline } from 'src/app/shared/models/echo/discipline';
import { SearchObject } from 'src/app/shared/models/search-object';
import { TemplateDataSet } from 'src/app/shared/models/template-data-set';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DisciplineService {
  constructor(private http: HttpClient) {}

  get(query: string) {
    return this.http.get(environment.ECHO_Api_URL + query, {});
  }
  
  getDisciplineInformation(): Observable<Discipline[]> {
    return this.get(`api/discipline`).pipe(map((data: any) => data.Value));
  }
  
  getDisciplineExcelTemplate(pTemplateDataSet: TemplateDataSet, pDataSetPk: number): Observable<any> {
    const body = JSON.stringify(pTemplateDataSet);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.ECHO_Api_URL}api/getDisciplineExcelTemplate/${pDataSetPk}`;
    return this.http.put(query, body, { headers })
     .pipe(map((data: any) => data));
  }
  
  uploadDisciplineExcelTemplate(pfile: Blob, pSearchObject: SearchObject, pApplication: string, pDataSetPk: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', pfile);
    formData.append('searchObject', JSON.stringify(pSearchObject));
    const query = `${environment.ECHO_Api_URL}api/upload/${pApplication}/${pDataSetPk}`;
    return this.http.post(query, formData);
  }
}
