import { Injectable } from '@angular/core';
import { ColGroupDef } from 'ag-grid-enterprise';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { Constants } from 'src/app/shared/utils/constants';
import { HHRAConstants } from '../../hhra-constants';

@Injectable({
    providedIn: 'root',
})

export class PPESSolublesColdef {

    public getColumnsDefinition(isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'PPE Scheme',
                        field: 'name',
                        minWidth: 400,
                        type: 'default',
                        filter: true,
                        cellRenderer: InputTextRenderer,
                        headerCheckboxSelection: isProjectOwnershipValid,
                        checkboxSelection: isProjectOwnershipValid,
                        cellRendererParams: {
                          isRequired: true,
                          disabled: !isProjectOwnershipValid
                        },
                        editable:false,
                        pinned: 'left',
                    }
                ]
            },
            {
                headerName: 'PPE - Mixing and loading',
                children: [

                    {
                        headerName: 'Gloves',
                        field: 'mixingLoadingGloves',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_GLOVES_SOLUBLES,
                            optionLabel: 'name',
                            optionValue: 'value',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 135,
                        minWidth: 135,
                        editable: false,
                    },
                    {
                        headerName: 'Clothing',
                        field: 'mixingLoadingClothing',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_CLOTHING,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 320,
                        minWidth: 320,
                        editable: false,
                    },
                    {
                        headerName: 'Head and respiratory PPE',
                        field: 'mixingLoadingHeadRespiratory',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_HEAD_RESPIRATORY,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 200,
                        minWidth: 200,
                        editable: false,
                    },
                    {
                        headerName: 'Water soluble bag',
                        field: 'mixingLoadingWaterSolubleBag',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_WATER_SOLUBLE_BAG,
                            optionValue: 'value',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 120,
                        minWidth: 120,
                        editable: false,
                    },

                ]
            },
            {
                headerName: 'PPE - Application',
                children: [

                    {
                        headerName: 'Gloves',
                        field: 'applicationGloves',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_GLOVES_SOLUBLES,
                            optionLabel: 'name',
                            optionValue: 'value',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 135,
                        minWidth: 135,
                        editable: false,
                    },
                    {
                        headerName: 'Clothing',
                        field: 'applicationClothing',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_CLOTHING,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 320,
                        minWidth: 320,
                        editable: false,
                    },
                    {
                        headerName: 'Head and respiratory PPE',
                        field: 'applicationHeadRespiratory',
                        type: 'default',
                        filter: 'agSetColumnFilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: HHRAConstants.HHRA_PPES_HEAD_RESPIRATORY,
                            optionValue: 'name',
                            optionLabel: 'name',
                            disabled: !isProjectOwnershipValid,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 200,
                        minWidth: 200,
                        editable: false,
                    },
                    {
                        colId: 'action',
                        width: 125,
                        minWidth: 125,
                        editable: false,
                        cellRenderer: 'deleteButtonRenderer',
                        cellRendererParams: {
                          disabled: !isProjectOwnershipValid
                        },
                        type: 'rightAligned'
                    }
                ]
            }
        ];
    }
}
