import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-status-panel-render',
  templateUrl: './status-panel-render.component.html',
  styleUrls: ['./status-panel-render.component.css']
})
export class StatusPanelRenderComponent implements IStatusPanelAngularComp {
  public parent: any;
  public selectionCount: number = 0;
  public isPrivilegeFull: boolean = true;
  useDetailAddButton: boolean = true;


  constructor() {

  }

  async agInit(params: any): Promise<void> {
    this.parent = params.context;
    this.useDetailAddButton = this.parent.useDetailAddButton;
    let selectedRow = undefined;

    await new Promise(resolve => setTimeout(() => {
      selectedRow = params.context.selectedRow;

    }, 500));
  }

  onAddClick() {
    this.parent.AddDetailClicked();
  }

  onDeleteClick(event: any) {
    this.parent.DetailDeleteClicked();
  }


  setSelectionCount(selectionCount: number) {
    this.selectionCount = selectionCount;
  }


  // confirmation = (target: EventTarget, callback: { (confirmed: any): void; (arg0: boolean): void; }) => {
  //   this.confirmationService.confirm({
  //     target: target,
  //     message: 'Are you sure that you want to Delete?',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => {
  //       callback(true);
  //       this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Delete action completed' });
  //     },
  //     reject: () => {
  //       callback(false);
  //       this.messageService.add({ severity: 'error', summary: 'Canceled', detail: 'Delete action canceled' });
  //     }
  //   });
  // }
}