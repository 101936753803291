import { Component, Input, SimpleChanges } from '@angular/core';
import { EEAOutputProjectPelmoResultSummaryColdef } from './eea-output-project-pelmo-result-summary-coldef';
import { Project } from 'src/app/shared/models/project';
import { ResultSummaryMacroService } from '../../eea-output-project.macrogw/eea-output-project-macrogw-result-summary/eea-output-project-result-summary-macro.service';
import { Constants } from '../../../../../../app/shared/utils/constants';

@Component({
  selector: 'eea-output-project-pelmo-result-summary',
  templateUrl: './eea-output-project-pelmo-result-summary.component.html',
  styleUrls: ['./eea-output-project-pelmo-result-summary.component.css'],
})
export class EEAOutputProjectPelmoResultSummaryComponent {
  @Input() selectedProject?: Project;
  @Input() selectedModel?: string;

  rowData: any;
  columDef: any;
  loading: boolean = false;
  def: any;
  constructor(
    private columnDefService: EEAOutputProjectPelmoResultSummaryColdef,
    private service: ResultSummaryMacroService
  ) {}

  ngOnInit(): void {
    if (this.selectedProject) {
      this.def = this.columnDefService.getColumnsDefinition();
      this.GetSummaryPelmo(this.selectedProject.projectPk, this.def);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedProject'].currentValue.projectPk) {
      this.def = this.columnDefService.getColumnsDefinition();
      this.GetSummaryPelmo(
        changes['selectedProject'].currentValue.projectPk,
        this.def
      );
    }
  }

  AddIngredientstoColumnDef(
    columnDefinition: any[],
    summaryResult: any[]
  ): any {
    var SummaryInfo = columnDefinition?.find((x) => x.headerName === 'Summary');
    summaryResult.forEach((summary) => {
      summary.ingredients?.forEach((ingredient: any) => {
        if (
          !SummaryInfo.children?.some(
            (column: any) => column.headerName === ingredient.SubstanceName
          )
        ) {
          var newChildren = { ...this.columnDefService.children };
          newChildren.headerName = ingredient.SubstanceName;
          SummaryInfo.children.push(newChildren);
        }
      });
    });
    return columnDefinition;
  }

  AddingApplicationtoTheColumnDef(
    columnDefinition: any[],
    summaryResult: any[]
  ): any {
    if (Array.isArray(columnDefinition)) {
      let columnDefinitionCopy = [...columnDefinition];
      var maxAmountOfGaps =
        this.columnDefService.GetMaxAmountOfGaps(summaryResult);

      for (var i = 0; i < maxAmountOfGaps; i++) {
        let newApplication = { ...this.columnDefService.EntireColumAplication };
        newApplication.headerName = `Aplication ${i + 1}`;
        newApplication.children = newApplication.children.map((child) => ({
          ...child,
          name: child.name === '' ? `Aplication ${i + 1}` : child.name,
        }));
        columnDefinitionCopy.push(newApplication);
      }
      summaryResult.forEach((summary) => {
        if (
          summary &&
          summary.Gapsummary &&
          Array.isArray(summary.Gapsummary)
        ) {
          var index = 1;
          summary.Gapsummary.forEach((gap: any) => {
            if (gap) {
              gap.headerName = `Aplication ${index}`;
              index++;
            }
          });
        }
      });
      return columnDefinitionCopy;
    }
  }

  GetSummaryPelmo(projectPk: number, def: any[]) {
    try {
      this.loading = true;
      if (projectPk != 0) {
        this.service
        .GetSummaryResultsByModel(projectPk,this.selectedModel??"")
        .pipe()
          .subscribe((result: any) => {
            this.def = this.columnDefService.getColumnsDefinition();
            this.def = this.AddIngredientstoColumnDef(this.def, result.Value);
            this.columDef = this.AddingApplicationtoTheColumnDef(
              this.def,
              result.Value
            );
            this.rowData = result.Value.filter((x:any) => x.Model===Constants.MODELS.PELMO);
            this.loading = false;
          });
      }
    } catch (e) {}
    this.loading = false;
  }
}