import { Injectable } from '@angular/core';
import { HttpApiService } from '../http.api.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EcotoxicologyApiService extends HttpApiService{
  override apiUrl: string = environment.ECHO_Api_URL;
  
  saveData(pObject: any): Observable<any> {
    const body = JSON.stringify(pObject);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `api/ecotoxicology/SaveEcotoxicologyData`;
    return this.put(query, body, headers)
                .pipe(map((data: any) => data.Value));
  }
}
