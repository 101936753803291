import { Injectable } from '@angular/core';
import { RowDataTransaction } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GridLogicService {

  private addClickedSubject: BehaviorSubject<boolean> = new BehaviorSubject({} as boolean);
  public readonly addClicked: Observable<boolean> = this.addClickedSubject.asObservable();

  private _transaction!: RowDataTransaction; // Make this private so we can expose it via a get/set
  private transactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public transaction$ = this.transactionChange$.asObservable(); // This is our Observable

  // Detail Grid Transaction
  private _detailTransaction!: RowDataTransaction; // Make this private so we can expose it via a get/set
  private detailTransactionChange$ = new Subject<RowDataTransaction>(); // This will be used to create our Observable
  public detailTransaction$ = this.detailTransactionChange$.asObservable(); // This is our Observable

  // Saves the state of the column groups in a table(open, closed)
  private columnGroupState:any;


  // Detail Grid Transaction
  // private _quickFilter: IQuickFilter; // Make this private so we can expose it via a get/set
  // private quickFilterChange$ = new Subject<IQuickFilter>(); // This will be used to create our Observable
  //public quickFilter$ = this.quickFilterChange$.asObservable(); // This is our Observable

  private _redrawRows: boolean | undefined; // Make this private so we can expose it via a get/set
  private redrawRowsChange$ = new Subject<boolean>(); // This will be used to create our Observable
  public redrawRows$ = this.redrawRowsChange$.asObservable(); // This is our Observable
  constructor() { }

  setAddClicked(addClicked: boolean): void {
    this.addClickedSubject.next(addClicked);
  }
  //Main Grid Transaction


  set RedrawRows(val: boolean) {
    this._redrawRows = val; // Set the new value
    this.redrawRowsChange$.next(val); // Trigger the subject, which triggers the Observable
  }

  set Transaction(val: RowDataTransaction) {
    this._transaction = val; // Set the new value
    this.transactionChange$.next(val); // Trigger the subject, which triggers the Observable

  }

  get Transaction() {
    return this._transaction;
  }

  getColumnGroupState(componentId:number):any{
    let state = localStorage.getItem(`agGridState-${componentId}`);
    return state ? JSON.parse(state) : null;
  }

  setColumnGroupState(componentId:any,state:any){
    localStorage.setItem(`agGridState-${componentId}`, JSON.stringify(state))
  }

  set DetailTransaction(val: RowDataTransaction) {
    this._detailTransaction = val; // Set the new value
    this.detailTransactionChange$.next(val); // Trigger the subject, which triggers the Observable

  }

  get DetailTransaction() {
    return this._detailTransaction;
  }

  // set QuickFIlter(val: any) {
  //   this._quickFilter = val; // Set the new value
  //   this.quickFilterChange$.next(val); // Trigger the subject, which triggers the Observable
  // }

  // get QuickFIlter() {
  //   return this._quickFilter;
  // }

  public setRedrawRows(data: boolean){
    this.RedrawRows = data;
  }

  public addTransaction(data: any[]){
    this.Transaction = { addIndex: 0, add : data };
  }

  public updateTransaction(data: any[]){
    this.Transaction = { update : data };
  }

  public removeTransaction(data: any[]){
    this.Transaction = { remove : data };
  }

  public addDetailTransaction(data: any[]){
    this.DetailTransaction = { addIndex: 0,  add : data };
  }

  public updateDetailTransaction(data: any[]){
    this.DetailTransaction = { update : data };
  }

  public removeDetailTransaction(data: any[]){
    this.DetailTransaction = { remove : data };
  }
  public getTransactions(){
    return this._transaction;
  }
  public getRowIDNode(){
    return this._transaction;
  }
}
