import { Component } from '@angular/core';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.css']
})
export class DropDownListAdminRenderer {
  public params: any;
  public options: Array<any> = [];
  public optionsParam: Array<any> = [];
  public optionValue: string = '';
  public optionLabel: string = '';
  public filterable: boolean = true;
  public editable: boolean = true;
  public defaultItem: any;
  public selectedValue: any;
  public columnThatModifies: string = '';
  public parent: any;
  width: number = 0;
  public placeholder: string = 'Select an option';
  
  constructor() { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.defaultItem = params?.defaultItem
    this.parent =  params.context ? params.context.componentParent : params.instance;
    this.selectedValue = this.setValue(params);
    this.options = this.defaultValueProcess([...params.options]);
    this.placeholder = params.placeholder !== undefined ? params.placeholder : this.placeholder;
    this.optionValue = params.optionValue;
    this.optionLabel = params.optionLabel; 
    this.editable = params?.editable === false || params.data.automatic ? false : true; 
    this.columnThatModifies = params.columnThatModifies;
    this.isFilterable(params);
  }
  
  isFilterable(pParams: any) {
    this.filterable = !Constants.COLUMNS_NO_FILTREABLES.includes(
      pParams.colDef.field
    );
  }
  
  defaultValueProcess(pOptions: any): any {
    if (this.params.data.newRow === true && !this.params.value) {
      var index = this.defaultValueIndex(pOptions);
      if (index && index !== -1) {
        this.selectedValue = pOptions[index].value ? pOptions[index].value.replace(Constants.defaultValueFlag, '')
          : pOptions[index].replace(Constants.defaultValueFlag, '');
        if (pOptions[index].value)
          pOptions[index].value = this.selectedValue;
        else
          pOptions[index] = this.selectedValue;
        this.onChange({ value: this.selectedValue });
      }
    }
    return pOptions;
  }
  
  defaultValueIndex(pOptions: any) {
    return pOptions?.findIndex((x: any) => {
      if (x.isDefault && x.isDefault === true) {
        return true;
      }
      if (typeof x === 'string' && x.includes(Constants.defaultValueFlag)) {
        return true;
      }
      return false;
    });
  }
  
  setValue(params: any): any {
    if (params.columnThatModifies === Constants.ELEMENTS_TAGS.archive && params.value === undefined)
      return 'No';
    return this.setDefaultItem(params);
  }
  
  public onChange(pValue: any): void {
    this.parent.setSelectionValues({
      field: this.columnThatModifies,
      id: this.params.node.id,
      row: this.params.data,
      sourceValues: this.options,
      value: pValue.value,
    });
  }
  
  onShow(event: any) {
  }
  
  setDefaultItem(pParams: any): any {
    const value = pParams?.value;
    if ((value === undefined || value === null || value === '') && pParams?.defaultItem !== undefined)
      return pParams?.defaultItem;
    return value;    
  }
}
