import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectPearlInputsUsedInCalculationColdef {

  constructor() { }


  private columnDefs = [
    {
      headerName: 'General',
      instance: this,
      children: [
        {
          headerName: 'Run ID',
          editable: false, 
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'runId',
          width: 120,
          filter: 'agSetColumnFilter', 
          pinned: 'left'
        },
        {
          headerName: 'Model',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'model',
          width: 110,
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
      ],
    },
    {
      headerName: 'Application Pattern (GAP)',
      instance: this,
      children: [
        {
          headerName: 'Location',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'location',
          width: 160,
          minWidth: 150,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Application Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationSchemeName',
          minWidth: 300,
          width: 300,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Crop',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'crop',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Number Of Applications',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationsNumber',
          width: 110,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Application \n Interval  (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'applicationInterval',
          width: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Soil Depth \n Incorporated (cm)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'soilDepthIncorporated',
          width: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'Rate \n (g/ha)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'rate',
          width: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
        {
          headerName: 'BBCH \n (earliest)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'BBCHEarliest',
          width: 150,
          type: 'select',
          filter: 'agSetColumnFilter',
          instance: this,
        },
      ],
    },
    {
      headerName: 'Endpoints',
      instance: this,
      children: [
        {
          headerName: 'Endpoint Data Set',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'endpointsDataSetName',
          minWidth: 200,
          width: 200,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Substance Name',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'substanceName',
          width: 200,
          minWidth: 200,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Parent',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          cellRenderer: 'agGroupCellRenderer',
          field: 'activeIngredient',
          width: 200,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Molecular mass \n (g/mol)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'molecularWeight',
          width: 140,
          filter: 'agSetColumnFilter',
        },
        {
          headerName:`Air Diffusion \n Coefficient (cm³/d)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'airDiffusionCoefficient',
          width: 135,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Saturated vapour \n pressure (Pa)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'saturatedVapourPressure',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Water Diffusion Coefficient (m²/d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'waterDiffusionCoefficient',
          width: 140,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Kfom - \n (L/Kg)',
          editable: false,
          wrapText: false, 
          resizable: true,
          sortable: true,
          field: 'kfom',
          width: 100,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Freundlich sorption \n exponent in soil (-)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'freundlichSorptionExponentInSoil',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Coefficient for uptake \n by plant(TSCF)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'coefficientForUptakeByPlant',
          width: 150,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Fraction of non-eq.-sites to eq.-sites (f_ne)', 
          editable: false,
          wrapText: true,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'fractionOfNonEqSitesToEqSites',
          width: 170,
          filter: 'agSetColumnFilter',
        },
        {
          headerName: 'Half-life \n in soil (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          filterable: true,
          field: 'halfLifeInSoil',
          width: 110,
          filter: 'agSetColumnFilter',
        },
      ],
    },
  ];
  
  getColumnsDefinition(): any {
    return this.columnDefs;
  }
}
