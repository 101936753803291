import { Component } from '@angular/core';
import { ApplicationMethodMatching } from 'src/app/shared/models/application-method-matching';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Constants } from 'src/app/shared/utils/constants';
import { ApplicationMethodMatchingService } from './application-method.matching.service';
import { ModelLibraryService } from '../model-library/model-library.service';
import { Datafield } from 'src/app/shared/models/echo/data-field';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';

@Component({
  selector: 'app-application-method-matching',
  templateUrl: './application-method-matching.component.html',
  styleUrls: ['./application-method-matching.component.css']
})
export class ApplicationMethodMatchingComponent {
  afterSearch: boolean = false;
  allModels: ModelLibrary[] = [];
  applicationMethodMatchingComponent = this;
  applicationMethodMatchings: ApplicationMethodMatching[] = [];
  datafields: Datafield[] = [];
  datafieldValues: DatafieldValue[] = [];
  disciplinePk: number = 0;
  loading: boolean = false;
  models: ModelLibrary[] = [];
  
  constructor(
    private applicationMethodMatchingService: ApplicationMethodMatchingService,
    private datafieldValueService: DatafieldValueApiService,
    private modelLibraryService: ModelLibraryService,
  ) {}

  ngOnInit(): void {
    this.getDatafields();
  }
  
  getDatafields(): void {
    this.datafieldValueService.getDatafieldData(Constants.THIS_APPLICATION).subscribe((datafieldData: Datafield[]) => {
      this.datafieldValueService.getDatafieldValueInformation().subscribe((datafieldValueData: DatafieldValue[]) => {
        this.datafieldValues = datafieldValueData;
        this.datafields = datafieldData;
        this.getData();
      });
    });    
  }
  
  getData() {
    this.setLoadingState(true);
    this.modelLibraryService.getModels().subscribe((models: ModelLibrary[]) => {
      this.allModels = models;
      this.filterDataByDiscipline(this.disciplinePk);
    });
  }
  
  filterDataByDiscipline(pDisciplinePk: number) {
    this.setLoadingState(true);
    this.disciplinePk = pDisciplinePk;
    this.models = this.allModels.filter((x) => x.disciplineXModel && x.disciplineXModel.includes(pDisciplinePk));
    if (this.disciplinePk)
      this.applicationMethodMatchingService.getApplicationMethodMatchings(pDisciplinePk).subscribe((applicationMethodMatchings: ApplicationMethodMatching[]) => {
          this.applicationMethodMatchings = applicationMethodMatchings;
          this.setLoadingState(false);
        });
  }
  
  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }
}
