import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class EEAOutputProjectStep1n2Inputs {

  constructor() { }

  getColumnsDefinition(): any {
    return [
      {
        headerName: 'General',
        children:[
          {
            headerName: "Run ID",
            field: "runId",
            width: 150,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
        ]
      },
      {
        headerName: 'Application Pattern (GAP)',
        children:[
          {
            headerName: "Application Scheme",
            field: "applicationSchemeName",
            width: 200,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: "Crop",
            field: "crop",
            width: 120,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: "Number Of Applications",
            field: "applicationsNumber",
            width: 120,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName:`Interval Between \n Application (d)`,
            field: "applicationInterval",
            width: 140,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: "Substance Name",
            field: "substanceName",
            width: 150,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: "Parent",
            field: "activeIngredient",
            width: 150,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Rate \n (g/ha)`,
            field: "rate",
            width: 95,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Bbch \n (Earliest)`,
            field: "BBCHEarliest",
            width: 100,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: "Crop Covarage",
            field: "cropCovarage",
            width: 130,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: "Interception (%)",
            field: "interception",
            width: 125,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          
        ]

      },
      {
        headerName: 'Endpoints',
        children:[
          {
            headerName: "Endpoints Data Set",
            field: "endpointsDataSetName",
            width: 200,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName:`Molecular \n Weight (g/mol)`,
            field: "molecularWeight",
            width: 110,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Solubility \n in Water (mg/L)`,
            field: "solubilityInWater",
            width: 100,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Kom (L/Kg)`,
            field: "kfom",
            width: 90,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName:`Kfoc` ,
            field: "kfoc",
            width: 90,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName:`Half-Life \n in soil (d)` ,
            field: "halfLifeInSoil",
            width: 100,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName:`Half-life \n In Water (d)` ,
            field: "halfLifeInWater",
            width: 100,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Half-life In \n Sediment (d)`,
            field: "halfLifeInSediment",
            width: 110,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName:`Half-life In \n Sediment/water System (d)` ,
            field: "halflifeInSedimentwaterSystem",
            width: 150,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Maximum \n Occurrence In Soil (%)`,
            field: "maximumOccurrenceInSoil",
            width: 150,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          },
          {
            headerName: `Maximum Occurrence \n In  Water/sediment (%)`,
            field: "maximumOccurrenceInWatersediment",
            width: 158,
            filter: 'agSetColumnFilter',
            wrapText: false,
            resizable: true,
            sortable: true,
            editable: false,
          }
        ]
      },
    ];
  }
}