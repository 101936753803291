import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CropListMatchingService {
  constructor(private http: HttpClient) {}

  getCropListMatchings(): Observable<any[]> {
    return this.http
      .get(`${environment.BAMS_Api_URL}api/cropListMatchings`)
      .pipe(map((data: any) => data.Value));
  }

  saveData(pData: any) {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/cropListMatchings`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }
}
