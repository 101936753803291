import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationScheme, SearchApplicationSchemeObject } from '../models/application-scheme';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GapApiService {

  constructor(private http: HttpClient) {}

  getGapByApplicationSchemes(
    pFilter: SearchApplicationSchemeObject
  ): Observable<ApplicationScheme[]> {
    const body = JSON.stringify(pFilter);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.GAP_Api_URL}api/gapsByApplicationSchemes`;
    return this.http.put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }
}