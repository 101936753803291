import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UdlControlledVocabulary } from '../models/udl-controlled-vocabulary.model';

@Injectable({
  providedIn: 'root'
})

export class EsaUdlOverlapToolService {

  constructor(private http: HttpClient) {

  }

  /**
   * Gets items from udl_controlled_vocabulary table based on the key specified
   * @param key unique key name
   * @returns list of ControlledVocabularyItems that matches with the key
   */
  getControlledVocabularyItemsByKey(key: string): Observable<UdlControlledVocabulary[]> {

    const apiUrl = `${environment.BAMS_Api_URL}api/ControlledVocabulary/${key}`;
    return this.http.get(apiUrl).pipe(map((data: any) => data.Value));
  }
}