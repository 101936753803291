import { Injectable } from '@angular/core';
import { Catalog } from '../../models/echo/catalog';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpApiService } from '../http.api.service';

@Injectable({
  providedIn: 'root'
})
export class GeographyApiService extends HttpApiService {
  override apiUrl: string = environment.ECHO_Api_URL;

  getRegionCountryCatalog(): Observable<Catalog[]> {
    const endpoint = 'api/formulation/regionCountryCatalog';
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }

  getRegionWithCountries(): Observable<Catalog[]> {
    const endpoint = 'api/region';
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }

  getCountries(): Observable<Catalog[]> {
    const endpoint = 'api/country';
    return this.get(endpoint)
      .pipe(map((data: any) => data.Value));
  }

}
