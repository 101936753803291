    <div class="pt-1 pr-5 pb-5 pl-5">
        <div class="col-12 w-12">
            <app-wizard class="surface-200"  [items]="InverseModelingEndpointsLogicService.items" [message]="message" [loading]="loading" [activeItem]="activeItem" (activeIndexChangeEmitter)="onActiveIndexChange($event)" [isSetupValid]="isEraEcotoxDatasetValid"></app-wizard>
        </div>

        <div class="p-3 col-12 w-12" >
            <ng-container *ngIf="activeItem.index == 0">
                <app-era-ecotox-datasets [subTabMenuService]="InverseModelingEndpointsLogicService" [menuService]="menuService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.AQUATIC" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
            </ng-container>
            <ng-container *ngIf="activeItem.index == 1">
                <app-era-ecotox-datasets [menuService]="InverseModelingEndpointsLogicService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.TERRESTRIAL_VERTEBRATES" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
            </ng-container>
            <ng-container *ngIf="activeItem.index == 2">
                <app-era-ecotox-datasets [menuService]="InverseModelingEndpointsLogicService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.BEES" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
            </ng-container>
            <ng-container *ngIf="activeItem.index == 3">
                <app-era-ecotox-datasets [menuService]="InverseModelingEndpointsLogicService" [selectedProject]="selectedProject" [taxonGroup]="taxonGroups.ARTHROPODS" [isProjectOwnershipValid]="isProjectOwnershipValid" (isValid)="getIsEraEcotoxDatasetValid($event)"></app-era-ecotox-datasets>
            </ng-container>
        </div>
    </div>
    <app-loading class="flex align-items-center justify-content-center" *ngIf="loading"></app-loading>

