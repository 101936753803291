import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatafieldValueApiService {
  constructor(private http: HttpClient) {}

  Get(query: string) {
    return this.http.get(environment.ECHO_Api_URL + query, {});
  }
  
  getDatafieldData(pApplication: string): Observable<any> {
    return this.Get(`api/datafieldList/${pApplication}`).pipe(map((data: any) => data.Value));
  }
  
  getDatafieldValueInformation(): Observable<any> {
    return this.Get(`api/datafieldValues/false`).pipe(map((data: any) => data.Value));
  }

  getDatafieldValuesByFieldName(name:string): Observable<any> {
    return this.Get(`api/dataFieldListByName/${name}`).pipe(map((data: any) => data.Value));
  }
}
