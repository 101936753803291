import { inject, Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';

@Injectable({
  providedIn: 'root'
})
export class EeaEndpointsCoreService {

  private datasetService = inject(DataSetApiService);

  public async GetCoreDatasetsByProject(projectPk: number): Promise<DataSet[]> {
    const source$ = this.datasetService.GetCoreDatasetsByProject(projectPk).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async getCoreEndpointsByDataSets(dataSets: DataSet[]): Promise<DataSet[]> {
    const source$ = this.datasetService.getCoreEndpointsByDataSets(dataSets).pipe(take(1));
    return await lastValueFrom(source$);
  }

}
