import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { AutoComplete } from '../../models/uiControllers';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteRenderer implements ICellRendererAngularComp {

  public filteredOptions: AutoComplete[];
  public selectedOption: AutoComplete;

  private parent: any;
  private params: any;
  private options: AutoComplete[];
  private field: string;
  private tempSelectedOption: AutoComplete;

  constructor(){
    this.options = [];
    this.filteredOptions = [];
    this.selectedOption = {} as AutoComplete;
    this.tempSelectedOption = {} as AutoComplete;
    this.field = '';
  }

  public refresh(): boolean {
    return false;
  }

  public agInit(params: any): void {
    this.params = params;
    this.options = params.options;
    this.field = params.colDef.field;
    this.parent = params.context;

    this.setSelectedOption();
  }

  private setSelectedOption(): void {
    const selectedOption = this.params.data[this.field];
    if(selectedOption)
      this.selectedOption = selectedOption;
    else
      this.selectedOption = {key: -1, label: ''};
  }

  public search(event: AutoCompleteCompleteEvent) {
    if(!this.options)
      return;

    let filtered: AutoComplete[] = [];
    let query = event.query;

    for (let i = 0; i < (this.options).length; i++) {
      let option = (this.options)[i];

      if (option.label.toLowerCase().indexOf(query.toLowerCase()) == 0)
        filtered.push(option);
    }
    this.filteredOptions = filtered;
  }

  public onSelect(){
    this.tempSelectedOption = this.selectedOption;
    this.emmitSelectedOption();
  }

  private emmitSelectedOption(){
    this.params.data[this.field] = this.selectedOption;
    const params = {
      data: this.params.data,
      value: this.selectedOption,
      field: this.field,
      node: this.params.node
    }
    this.parent.onAutoCompleteSelected(params);
  }

  public onBlur(){
    if (JSON.stringify(this.selectedOption) !== '{}')
    setTimeout(() => {
      if(this.tempSelectedOption != this.selectedOption){
        const key = this.selectedOption.key;
        if(!key){
          const label: string = this.selectedOption as any;
          this.selectedOption = {key: -1, label: label};
          this.emmitSelectedOption()
        }
      }
    }, 500);
  }
}
