import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-info-grid-header-component',
  templateUrl: './info-grid-header-component.component.html',
  styleUrls: ['./info-grid-header-component.component.css']
})
export class InfoGridHeaderComponentComponent implements IHeaderAngularComp {
  public params!: IHeaderParams 
  name:string = "";
  infoText:string = "";
  popupWidth:number = 0;
  agInit(params: IHeaderParams | any) : void {
    this.params = params;
    this.name = params.name;
    this.infoText = params.infoText;
    this.popupWidth = params.popupWidth;
  }
  refresh(params: IHeaderParams) {
    return false;
  }
  showInfoPopup() { 
    Swal.fire({
      title: 'Information',
      html: this.infoText,
      icon: 'info',
      width:this.popupWidth      
    });
  }
}
