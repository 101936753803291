import { Component } from '@angular/core';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';

@Component({
  selector: 'app-input-text-julian-date',
  templateUrl: './input-text-julian-date.component.html',
  styleUrls: ['./input-text-julian-date.component.css']
})
export class InputTextJulianDateComponent extends InputTextRenderer {
  public override agInit(params: any): void {
    super.agInit(params);
    this.manageJulianDate();
  }
  public override onBlur(event: any): void {
    super.onBlur(event)
    this.manageDateWithJulianDate(Number(this.value));  
    this.parent.refreshGrid();
  }
  private manageJulianDate(): void {
    const date = new Date(this.params.data.recAppDate);
    const start = new Date(date.getFullYear(), 0, 0); 
    const diff = date.getTime() - start.getTime(); 
    const oneDay = 1000 * 60 * 60 * 24; 
    const dayOfYear = Math.floor(diff / oneDay); 
    this.value = dayOfYear.toString();
  }
  private manageDateWithJulianDate(dayOfYear: number): void {
    if (this.value.length > 0 && Number(this.value) > 0 && Number(this.value) < 366) {
      const year = 1901;
      const date = new Date(year, 0, dayOfYear);
      const yearString = date.getFullYear().toString();
      const monthString = (date.getMonth() + 1).toString().padStart(2, '0');
      const dayString = date.getDate().toString().padStart(2, '0');

      this.params.data.recAppDate = `${yearString}-${monthString}-${dayString}T00:00:00`;
    }
  }
  restrictToNumbers(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  restrictOnPaste(event: ClipboardEvent) {
    const pasteData = event.clipboardData?.getData('text') || '';
    const pastedNumber = parseInt(pasteData, 10);
    if (isNaN(pastedNumber) || pastedNumber < 1 || pastedNumber > 365) {
      event.preventDefault();
    }
  }

  onInputChange(event: any): void {     
    const newValue = parseInt(event.target.value, 10);
    if (newValue >= 1 && newValue <= 365) {
      this.value = newValue.toString();
      this.isValid = true;
    } else {
      this.value = '';
      this.isValid = false;
    }

    this.manageDateWithJulianDate(Number(this.value));    
  }
}
