import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { ERAEndpointsLogicService } from './era-endpoints.logic.service';
import { Message } from 'primeng/api';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { EnviromentalRiskAssessmentService } from '../../environmental-risk-assessment/environmental-risk-assessment.service';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';

@Component({
  selector: 'app-era-endpoints',
  templateUrl: './era-endpoints.component.html',
  styleUrls: ['./era-endpoints.component.css']
})

export class ERAEndpointsComponent implements OnInit, OnDestroy {
  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;

  @Output() isValid = new EventEmitter<boolean>();

  public isEraEcotoxDatasetValid: boolean = true;
  activeItem!: any;
  items: CustomTabMenuItem[] = []
  destroyRef = inject(DestroyRef)
  afterSearch: boolean = false;
  loading: boolean = false;
  wizardLoading: boolean = false;
  nextIndex?: number;
  message!: Message;
  taxonGroups: any = Constants.TAXON_GROUPS;
  tabsToDisplay: CustomTabMenuItem[] = [];

  constructor(public ERAEndpointsLogicService: ERAEndpointsLogicService, 
              public userLogicService: UserLogicService,
              private ERALogicService: EnviromentalRiskAssessmentService) { }

  ngOnInit(): void {
    let isInit = true;

    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => { this.menuService.setSuccess(true) });
    
    this.ERAEndpointsLogicService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.ERAEndpointsLogicService.setSuccess(true));

    this.ERALogicService.usedModelsSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((usedModels: string[]) => {
      this.tabsToDisplay = this.ERAEndpointsLogicService.items.filter(item => usedModels.map(model=> model.toLowerCase()).includes(item.model ? item.model.toLowerCase(): ''));    
      if (this.selectedProject?.geography === Constants.CROP_GEOGRAPHIES.UK) {
        this.tabsToDisplay = this.tabsToDisplay.filter(item => item.label !== Constants.RISK_ASSESSMENT_ENDPOINTS[0].label);
        if(this.tabsToDisplay.length === 1) {
          isInit = false;
          this.ERAEndpointsLogicService.setActiveItem(this.tabsToDisplay[0]);
        }
      }
    });

    this.ERAEndpointsLogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => this.activeItem = this.activeItem = isInit ? {} : activeItem);
    this.ERAEndpointsLogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.wizardLoading = false;

      if (success) {
        const nextItem = this.ERAEndpointsLogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem  && !isInit)
          this.ERAEndpointsLogicService.setActiveItem(nextItem);
      }
      isInit = false;
    });
  }
     
  ngOnDestroy() { 
    this.ERAEndpointsLogicService.resetActiveItem(); 
  } 

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.ERAEndpointsLogicService.setActiveIndexChanged(true, index);
  }

  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  public getIsEraEcotoxDatasetValid(isValid: boolean): void {
    this.isEraEcotoxDatasetValid = isValid;
    this.isValid.emit(isValid);
  }
}
