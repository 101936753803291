import { inject, Injectable, signal } from '@angular/core';
import { EeaEndpointsParametersPwcApiService } from './eea-endpoints-parameters-pwc.api.service';
import { ChildLevel, ParentDataset } from './eea-endpoints-parameters-pwc.inteface';
import { lastValueFrom, take } from 'rxjs';
import { EndpointApiService } from 'src/app/shared/services/echo/endpoint.api.service';
import { EndpointGet, EndpointSaveFormat } from 'src/app/shared/models/endpoint';

@Injectable({
  providedIn: 'root'
})
export class EeaEndpointsParametersPwcLogicService {

  private apiService = inject(EeaEndpointsParametersPwcApiService);
  private endpointApiService = inject(EndpointApiService);

  public async getChildLevelByDataset(datasetPk: number): Promise<ChildLevel[]> {
    const source$ = this.apiService.getChildLevelByDataset(datasetPk).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async saveAndUpdateEndpoints( endpointSaveFormat: EndpointSaveFormat[] ): Promise<boolean> {
    const source$ = this.endpointApiService.saveAndUpdateEndpoints(endpointSaveFormat).pipe(take(1));
    return await lastValueFrom(source$);
  }

  public async getEndpoints( datasetPk: number ): Promise<EndpointGet[]> {
    const source$ = this.endpointApiService.getEndpoints(datasetPk).pipe(take(1));
    return await lastValueFrom(source$);
  }


}
