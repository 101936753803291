import { Component, Input } from '@angular/core';
import { Constants } from 'src/app/shared/utils/constants';
import { Geography } from 'src/app/shared/models/echo/geography';
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent {
  selectedRegionName: string = '';

  constructor(private geographyService: GeographyApiService) {}

  @Input() componentThatImplementsGrid: any;
  regions: Geography[] = [];
  selectedRegion: number = -1;
  selectedGeography: any;


  ngOnInit(): void {
    this.geographyService
      .getRegionCountryCatalog()
      .subscribe((regionData: any) => {
        this.regions = regionData.filter(
          (x: any) =>
            x.name == Constants.GEOGRAPHYS.USA ||
            x.name == Constants.GEOGRAPHYS.EUROPE_UNION
        );
      });
  }

  regionSelectionChange() {
    this.componentThatImplementsGrid.filterDataByGeography(this.selectedRegion);
  }
}
