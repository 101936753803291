import { HttpHeaders } from "@angular/common/http";
import { HttpApiService } from "./http.api.service";
import { environment } from 'src/environments/environment';
import { Observable, map } from "rxjs";
import { ApplicationSchemeXApplication } from "../models/application-scheme";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class PWCApplicationSchemeXApplicationService extends HttpApiService {
    override apiUrl: string = environment.BAMS_Api_URL;

    getApplicationSchemeXApplicationPK(applicationSchemePk: number): Observable<ApplicationSchemeXApplication[]> {
        let endpoint = `api/applicationSchemesXApplication/${applicationSchemePk}`;
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        return this
            .get(endpoint, headers)
            .pipe(map((data: any) => data.Value));
    }

}