import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from '../../utils/utils';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberRenderer implements ICellRendererAngularComp {

  public params: any;
  public parent: any;
  public field: any;
  public value: number | undefined;
  public min: number | undefined;
  public max: number | undefined;
  public maxDecimals: number;
  public disabled: boolean = false;
  public excludeRange: boolean = false;
  private range: string;
  public useGrouping: boolean = true;
  public isGroupRendered?: boolean;

  constructor() {
    this.min = undefined;
    this.max = undefined;
    this.maxDecimals = 100;
    this.range = '';
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  agInit(params: any): void {
    this.isGroupRendered = params.node.group;
    this.disabled = params.disabled;
    this.params = params;
    this.value = params.value;
    this.parent = params.context;
    this.field = params.colDef.field;
    this.min = params.min;
    this.max = params.max;
    this.excludeRange = params.excludeRange;
    this.useGrouping = params.useGrouping? params.useGrouping : true;
    this.createRange();
    if (!Utils.isEmptyValue(params.maxDecimals)){
      this.maxDecimals = params.maxDecimals;
    }
  }

  private createRange(): void {
    if (this.min !== undefined && this.max !== undefined && !this.excludeRange)
      this.range = `${this.min} - ${this.max}`;
  }

  onBlur(event: any) {
    const cleanValue = event.target.value.replace(/,/g, '');
    const valueFormated = Utils.CheckValueInRanges(this.range, cleanValue);
    this.params.data.value = valueFormated;
    this.params.data[this.field] = valueFormated;
    this.params.data.modified = true;
    try{
      this.parent.onInputNumberBlur(this.params);
      if(this.isGroupRendered) return;
      this.parentSetValues(valueFormated.toString());
    }
    catch(e){}
    try{
      this.params.context.componentParent.setChangeValues({
        field: this.field,
        id: this.params.node.id,
        row: this.params.data,
        value: valueFormated,
      })
    }catch(e){}
    }
    

  private parentSetValues(value: string): void {
  let changeValues = {
    field: this.field,
    id: this.params.node.id,
    row: this.params.data,
    value: value,
  };

  if (this.parent && typeof this.parent.setChangeValues === 'function') {
    this.parent.setChangeValues(changeValues);
  } else if (this.params.context.componentParent && typeof this.params.context.componentParent.setChangeValues === 'function') {
    this.params.context.componentParent.setChangeValues(changeValues);
  }
}
}
