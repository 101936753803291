import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListboxChangeEvent } from 'primeng/listbox';

@Component({
  selector: 'app-eea-setup-gap-outputs',
  templateUrl: './eea-setup-gap-outputs.component.html',
  styleUrls: ['./eea-setup-gap-outputs.component.css']
})
export class EeaSetupGapOutputsComponent {

  @Input() selectedOutputs = [];
  @Input() gapOutputs: any[] = [];
  @Input() isProjectOwnershipValid: boolean = false;
  
  @Output() onOutputSelectionChangeEvent = new EventEmitter<ListboxChangeEvent>();

  onOutputSelectionChanged(event: ListboxChangeEvent)
  {
    this.onOutputSelectionChangeEvent.emit(event);
  }
}