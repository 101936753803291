import { Component, DestroyRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { Project } from 'src/app/shared/models/project';
import { HHRARunLogicService } from './hhra-run.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import Swal from 'sweetalert2';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OpexInputs } from 'src/app/shared/models/opex-Inputs';
import { UsHandlerExposureLogicService } from 'src/app/shared/services/human-health-us-handler-exposure-logic.service';
import { UsHandlerExposureInputs } from 'src/app/shared/models/us-handler-exposure-Inputs';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { take } from 'rxjs';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { HhraRunRiskAssessmentOpexResultComponent } from './hhra-run-risk-assessment-opex-result/hhra-run-risk-assessment-opex-result.component';
import { HumanHealthOpexLogicService } from 'src/app/shared/services/human-health-opex-logic.service';

@Component({
  selector: 'app-hhra-run-risk-assessment',
  templateUrl: './hhra-run-risk-assessment.component.html',
  styleUrls: ['./hhra-run-risk-assessment.component.css']
})
export class HHRARunRiskAssessmentComponent {

  @ViewChild(HhraRunRiskAssessmentOpexResultComponent) child: HhraRunRiskAssessmentOpexResultComponent | undefined;

  @Input() selectedProject?: Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Output() isProjectRunning: EventEmitter<boolean>;
  @Output() isUSA: boolean = false;


  isLoading!: boolean;
  headerDietary: string = Constants.HUMAN_HEALTH_RISK_ASSESSMENT_RUN.DIETARY;
  headerNonDietary: string = Constants.HUMAN_HEALTH_RISK_ASSESSMENT_RUN.NON_DIETARY;
  occupationalHandlerExposure: string = Constants.HUMAN_HEALTH_RISK_ASSESSMENT_RUN.OCCUPATIONAL_HANDLER_EXPOSURE;
  occupationalReEntryExposure: string = Constants.HUMAN_HEALTH_RISK_ASSESSMENT_RUN.OCCUPATIONAL_RE_ENTRY_EXPOSURE;
  ResidentialExposure: string = Constants.HUMAN_HEALTH_RISK_ASSESSMENT_RUN.RESIDENTIAL_EXPOSURE;
  destroyRef = inject(DestroyRef);
  loading: boolean = false;
  isAllFilesDownloading: boolean = false;
  isRefreshingIcon: boolean = false;
  usHandlerExposureCombinations: UsHandlerExposureInputs[] = [];
  opexInputsCombinations: OpexInputs[] = [];
  isUsHandlerExposure: boolean = false;
  projectStatusStyle = {
    statusId: 0,
    statusName: "",
    badgeStyle: ""
  }
  showWarningAlert: any;
  warningAlertText: any;
  isNotStarted!: boolean;

  constructor(public HHRARunLogicService: HHRARunLogicService,
    private usHandlerExposureLogicService: UsHandlerExposureLogicService,
    private readonly runRiskApiService: RunRiskApiService,
    private readonly humanHealthOpexLogicService: HumanHealthOpexLogicService,
    private projectApiService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService) {
    this.isProjectRunning = new EventEmitter();
  }

  ngOnInit() {
    this.isUSA = this.selectedProject?.geography == Constants.GEOGRAPHYS.USA;
    this.setStatusStyle(this.selectedProject?.status!)
    this.isLoading = true;
    this.initSubscribes();
  }

  async initSubscribes() {
    if (this.isUSA) {
      this.runUSHandlerExposureInputs(false);
    } else {
      if (!this.isUSA) {
        this.validateStatus();
      }
    }
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.menuService.setSuccess(true);
    });
  }

  validateStatus() {
    if (this.humanHealthOpexLogicService.getOpexInputsCombinations().length > 0) {
      this.projectApiService.getProjectStatus(this.selectedProject!.projectPk).pipe(take(1)).subscribe({
        next: (status) => {
          if (status === Constants.PROJECT_STATUS_ID.NOT_STARTED) {
            this.selectedProject!.status = Constants.PROJECT_STATUS_ID.IN_PROGRESS;
            this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
            this.updateProjectStatus(false);
          };
          if (status === Constants.PROJECT_STATUS_ID.FAILED || status === Constants.PROJECT_STATUS_ID.COMPLETED) {
            this.selectedProject!.status = Constants.PROJECT_STATUS_ID.IN_PROGRESS;
            this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
            this.updateProjectStatus(true);
          }
        }
      });
    } else {
      if (this.selectedProject?.status == Constants.PROJECT_STATUS_ID.NOT_STARTED) {
        this.createWarningAlert(Constants.HHRA_QC_MESSAGES.NOT_STARTED);
      }else{
        this.isLoading = false;
      }
    }
  }

  updateProjectStatus(isRefresh: boolean) {
    let project: any = {
      "transactionType": 1,
      "row": this.selectedProject
    }
    this.projectApiService.saveData(project).pipe(take(1))
      .subscribe({
        next: async (result) => {
          this.isProjectRunning.emit(true);
          this.setStatusStyle(this.selectedProject?.status!);
          this.runRiskApiService.RunOpex(this.humanHealthOpexLogicService.getOpexInputsCombinations(), this.selectedProject?.projectPk!, isRefresh).subscribe((data: OpexInputs[]) => {
            this.humanHealthOpexLogicService.setOpexInputsCombinations([]);
          });
          this.isLoading = false;
        }
      });
  }

  createWarningAlert(message: string): void {
    this.isNotStarted = this.selectedProject?.status == Constants.PROJECT_STATUS_ID.NOT_STARTED;
    this.warningAlertText = message;
    this.showWarningAlert = this.isNotStarted;
  }

  async runUSHandlerExposureInputs(isRefresh: boolean): Promise<void> {
    this.isUsHandlerExposure = true;
    this.usHandlerExposureCombinations = await this.usHandlerExposureLogicService.fillUsHandlerExposureInputs(this.selectedProject!);
    //TODO@George:Remove console log when testing is finished for US Handler Exposure.
    console.log("Is US Handler Exposure:", this.usHandlerExposureCombinations)
  }

  async runHandlerInputs(isRefresh: boolean): Promise<void> {
    if (this.selectedProject?.status === Constants.PROJECT_STATUS_ID.NOT_STARTED) {
      this.usHandlerExposureCombinations = await this.usHandlerExposureLogicService.fillUsHandlerExposureInputs(this.selectedProject!);
      this.setStatusStyle(Constants.PROJECT_STATUS_ID.IN_PROGRESS);
      this.runRiskApiService.RunHandler(this.usHandlerExposureCombinations, this.selectedProject?.projectPk!, isRefresh).subscribe((data: UsHandlerExposureInputs[]) => {
      });
    }
  }

  setStatusStyle(statusId: number) {
    switch (statusId) {
      case 0:
        this.projectStatusStyle = {
          badgeStyle: '',
          statusId: statusId,
          statusName: Constants.PROJECT_STATUS.NOT_STARTED
        }
        break;
      case 1:
        this.projectStatusStyle = {
          badgeStyle: Constants.BADGE_STYLE.IN_PROGRESS,
          statusId: statusId,
          statusName: Constants.PROJECT_STATUS.IN_PROGRESS.concat("\u00A0")
        }
        break;
      case 2:
        this.projectStatusStyle = {
          badgeStyle: Constants.BADGE_STYLE.COMPLETED,
          statusId: statusId,
          statusName: Constants.PROJECT_STATUS.COMPLETE.concat("\u00A0")
        }
        break;
      case 3:
        this.projectStatusStyle = {
          badgeStyle: Constants.BADGE_STYLE.FAILED,
          statusId: statusId,
          statusName: Constants.PROJECT_STATUS.FAILED.concat("\u00A0")
        }
        break;
    }
  }

  onDownloadClick(): void {
    this.setButtonLoading(true);
    this.HHRARunLogicService.DownloadOpexOutputFilesByProject(this.createProjectObject())
      .subscribe({
        next: (url: string) => {
          this.setButtonLoading(false);
          if (url != "") {
            window.open(url, '_blank');
          }
        },
        error: (error: any) => {
          console.error(error);
          this.setButtonLoading(false);
          this.showErrorAlert("Files could not be downloaded")
        },
      });
  }

  createProjectObject(): Project {
    const project: Project = {
      projectPk: this.selectedProject?.projectPk!,
      active: this.selectedProject?.active!,
      deletable: this.selectedProject?.deletable!,
      description: this.selectedProject?.description!,
      formulationPk: this.selectedProject?.formulationPk!,
      name: this.selectedProject?.name!,
      createdBy: this.selectedProject?.createdBy!,
      createdDate: this.selectedProject?.createdDate!,
      status: this.selectedProject?.status!,
    };
    return project;
  }

  setButtonLoading(isAllFilesDownloading: boolean): void {
    this.isAllFilesDownloading = isAllFilesDownloading;
  }

  showErrorAlert(text: string) {
    Swal.fire({
      title: 'Notification',
      html: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'OK',
    }).then((result) => {

    });
  }

  closeModal(pValue: any) {
    this.projectStatusStyle = {
      badgeStyle: pValue.badgeStyle,
      statusId: pValue.statusId,
      statusName: pValue.statusName
    };
  }

  refreshData() {
    this.isRefreshingIcon = true;
    this.selectedProject!.status = Constants.PROJECT_STATUS_ID.NOT_STARTED;
    let project: any = {
      "transactionType": 1,
      "row": this.selectedProject
    }
    this.projectApiService.saveData(project).pipe(take(1))
      .subscribe({
        next: (result) => {
          if (this.selectedProject?.geography == Constants.GEOGRAPHYS.USA) {
            this.runHandlerInputs(true);
          }
          this.selectedProjectApiService.updateSelectedProjectStatus(Constants.PROJECT_STATUS_ID.NOT_STARTED);
          this.isRefreshingIcon = false;
          this.child?.ngOnInit();
          this.setStatusStyle(this.selectedProject!.status);
        }
      });
  }

  isProjectRunningQC() {
    this.isProjectRunning.emit(true);
  }
}