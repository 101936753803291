<ng-container *ngIf="!floating && !useTitle">
  <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" *ngIf="label">{{label}}</label>
  <p-dropdown
    class="w-12"
    [disabled]="disabled"
    [dropdownIcon]="dropdownIcon"
    [filter]="filter"
    [filterBy]="filterBy"
    [id]="id"
    [ngClass]="!isValid && isRequired ? 'dd-invalid' : 'dd-valid'"
    [optionLabel]="optionLabel"
    [options]="options"
    [optionValue]="optionValue"
    [placeholder]="placeholder"
    [scrollHeight]="scrollHeight"
    [showClear]="showClear"
    [style]="style"
    [(ngModel)]="model"
    (onChange)="onSelectionChanged($event)"
    (onClear)="onClear()"
  />
</ng-container>

<span *ngIf="floating" class="p-float-label">
  <p-dropdown
    class="w-12"
    [disabled]="disabled"
    [dropdownIcon]="dropdownIcon"
    [filter]="filter"
    [filterBy]="filterBy"
    [id]="id"
    [ngClass]="!isValid && isRequired ? 'dd-invalid' : 'dd-valid'"
    [optionLabel]="optionLabel"
    [options]="options"
    [optionValue]="optionValue"
    [placeholder]="placeholder"
    [scrollHeight]="scrollHeight"
    [showClear]="showClear"
    [style]="style"
    [(ngModel)]="model"
    (onChange)="onSelectionChanged($event)"
    (onClear)="onClear()"
  />
  <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" [htmlFor]="id">{{label}}</label>
</span>

<div *ngIf="useTitle" class="flex flex-column gap-2">
  <label [ngClass]="!isValid && isRequired ? 'lbl-invalid' : 'lbl-valid'" [htmlFor]="id">{{label}}</label>
  <p-dropdown
    class="w-12"
    [disabled]="disabled"
    [dropdownIcon]="dropdownIcon"
    [filter]="filter"
    [filterBy]="filterBy"
    [id]="id"
    [ngClass]="!isValid && isRequired ? 'dd-invalid' : 'dd-valid'"
    [optionLabel]="optionLabel"
    [options]="options"
    [optionValue]="optionValue"
    [placeholder]="placeholder"
    [scrollHeight]="scrollHeight"
    [showClear]="showClear"
    [style]="style"
    [(ngModel)]="model"
    (onChange)="onSelectionChanged($event)"
    (onClear)="onClear()"
  />
</div>
