import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-dataset-reference-icon',
  templateUrl: './dataset-reference-icon.component.html',
  styleUrls: ['./dataset-reference-icon.component.css']
})
export class DatasetReferenceIconRenderer implements ICellRendererAngularComp {
  params: any;
  parent: any;
  disabled: boolean = false;
  icon?: string = undefined;
  toolTipMessage?: string = undefined;
  constructor() {}

 refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.parent = params.context;
    this.disabled = params.disabled;
    this.icon = params.icon;
    this.toolTipMessage = params.toolTipMessage;
  }

}
