import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExposureAssessmentOutputProjectTier1ukPecTimeColdef {
  constructor() { }

  public getColumnsDefinition(): any {
    return [
      {
        headerName: 'Run ID',
        field: 'runID',
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
        pinned: true,
      },
      {
        headerName: 'Application Scheme',
        field: 'applicationScheme',
        width: 300,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'Crop',
        field: 'modelCrop',
        width: 170,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'Endpoints Data Set',
        field: 'endpointsDataSet',
        width: 200,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'Compound',
        field: 'compound',
        width: 200,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'PECINI \n (ug/kg)',
        field: 'PECIni',
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'PECSW \n (ug/kg)',
        field: 'PECsw',
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'TWASW',
        field: 'TWAsw',
        width: 200,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'PECSED \n (ug/kg)',
        field: 'PECsed',
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
      {
        headerName: 'TWASED',
        field: 'TWAsed',
        width: 200,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        sortable: true,
      },
    ];
  }
}
