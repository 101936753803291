<div class="ag-filter">
  <div class="ag-filter-wrapper ag-focus-managed">
      <div class="ag-filter-body-wrapper ag-set-filter-body-wrapper">
          <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
              <input ref="eInput" class="ag-input-field-input ag-text-field-input" type="text" id="ag-187-input" aria-label="Search filter values" placeholder="Search..." [(ngModel)]="label">
          </div>
          <div class="scroll">
              <div style="margin-top: 10px;">
                  <div class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                      <input class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" type="checkbox" id="selectAll" [(ngModel)]="selectAll" (change)="selectAllCheck()" />
                      <label class="ag-input-field-label ag-label ag-checkbox-label" for="selectAll">(Select All)</label>
                  </div>
              </div>
              <div *ngFor="let item of validFilters | dropdownFilter: label" style="margin-top: 10px;">
                  <div class="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
                      <input class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" type="checkbox" [id]="item.value" (change)="onSelectionChanged(item)" [(ngModel)]="item.checked" />
                      <label class="ag-input-field-label ag-label ag-checkbox-label" [for]="item.value">{{ item.labelId }}</label>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
