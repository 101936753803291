import { Injectable } from '@angular/core';
import { HttpApiService } from '../http.api.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { Discipline } from '../../models/echo/discipline';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DisciplineApiService extends HttpApiService {
  override apiUrl: string = environment.ECHO_Api_URL;

  getDisciplineInformation(): Observable<Discipline[]> {
    let endpoint = `api/discipline`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.get(endpoint, headers).pipe(map((data: any) => data.Value));
  }

  getDisciplineByName(name: string): Observable<Discipline> {
    let endpoint = `api/discipline/${name}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.get(endpoint, headers).pipe(map((data: any) => data.Value));
  }
}
