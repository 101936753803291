import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectSummaryResultSurfaceWaterApiService extends HttpApiService {

  apiUrl = environment.BAMS_Api_URL; 

  getSummariesResult(pProjectPk: number): Observable<any[]> {
    const endpoint = `api/getResultSummary/${pProjectPk}`;
    return this.get<any[]>(endpoint).pipe(map((data: any) => data));
  }

  getSummariesResultsSurfaceWater(pProjectPk: number): Observable<any[]> {
    const endpoint = `api/GetSummaryResultSurfaceWater/${pProjectPk}`;
    return this.get<any[]>(endpoint).pipe(map((data: any) => data));
  }
  getSummariesResultsSurfaceWaterByModel(pProjectPk: number, pSelectedModel:string ): Observable<any[]> {
    const endpoint = `api/GetSummaryResultSurfaceWaterByModel/${pProjectPk}`;
    return this.post<any[]>(endpoint,{modelName:pSelectedModel}).pipe(map((data: any) => data));
  }
}