import { Injectable } from '@angular/core';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root'
})
export class UsersLogicService {
  private activeUserID!: string;
  private profile!: Profile;

  constructor() {}

  setActiveUserID(userID: string) {
    this.activeUserID = userID;
  }

  getActiveUserID() {
    return this.activeUserID;
  }

  setProfile(profile: Profile) {
    this.profile = profile;
  }

  getProfile() {
    return this.profile;
  }
}
