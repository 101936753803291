import { Component } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarAdminRenderer {
  dateValue!: Date;
  params: any;
  parent: any;
  highlightDate!: Date;
  editable: boolean = false;
  constructor() { }

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.parent = params.context.componentParent;
    this.highlightDate = this.params?.highlightDate;
    if (this.params.node.data[this.params.columnThatModifies]) {
      this.dateValue = new Date(
        this.params.node.data[this.params.columnThatModifies]
      );
    }
    this.editable = params.editable;
  }
  
  onSelect(): void {
    this.params.node.data[this.params.columnThatModifies] = this.dateValue.getUTCDate();
    this.parent.setSelectionValues({
      field: this.params.columnThatModifies,
      id: this.params.node.id,
      row: this.params.node.data,
      value: this.dateValue.getUTCDate()
    });

  }
}
