

<div class="grid">
  <div class="col-12">
    <app-endpoint-dataset [isProjectOwnershipValid] ="isProjectOwnershipValid" [subTabMenuService]="subTabMenuService" [menuService]="menuService" [selectedProject]="selectedProject" (datasetData)="getDatasetDataOutput($event)" (showDetail)="showDetailOutput($event)" [compartment]="compartment" [environmentalAssessment]="environmentalAssessment" (isValid)="getIsDatasetValid($event)"></app-endpoint-dataset>
  </div>
  <div *ngIf="showDetail && !isLoading" class="col-12 ml-2">
    <div class="grid">

      <div class="col-6 mt-4 mb-3">
        <app-eea-endpoints-select-compound
          *ngIf="!isPwc"
          [compartment]="compartment"
          [dataSet]="dataset"
          [isProjectOwnershipValid]="isProjectDataSetOwnershipValid"
          [metabolitesOptions]="metabolitesOptions"
          [moleculePk]="moleculePk"
          [projectPk]="projectPk"
          (selectedActiveIngredientPk)="getSelectedActiveIngredient($event)"
          (selectedMetabolitePk)="getSelectedMetabolite($event)"
        />
      </div>

      <div class="col-6 mt-3">
        <app-model-checkbox
          *ngIf="selectedCompound != undefined && !isPwc"
          [compartment]="compartment"
          [isProjectOwnershipValid]="isProjectDataSetOwnershipValid"
          [metabolitePk]="selectedMetabolitePk"
          [selectedProject]="selectedProject"
          (onModelOptionsChangedEvent)="getSelectedModels($event)"
        />
      </div>

    </div>
  </div>
  <div class="col-12" *ngIf="selectedCompound != undefined && !isLoading">

    <app-eea-endpoints-parameters-pwc
      *ngIf="isPwc"
      [compartment]="compartment"
      [dataset]="dataset"
      [isProjectOwnershipValid]="isProjectDataSetOwnershipValid"
      [menuService]="menuService"
      [subTabMenuService]="subTabMenuService"
    />

    <app-eea-endpoints-parameters
      *ngIf="!isPwc"
      [compartment]="compartment"
      [coreDefaultValues]="coreDefaultValues"
      [dataset]="dataset"
      [endpointParameterCompound]="selectedCompound"
      [isProjectOwnershipValid]="isProjectDataSetOwnershipValid"
      [menuService]="menuService"
      [precursorOptions]="precursorOptions"
      [selectedModels]="selectedModels"
      [selectedProject]="selectedProject"
      [subTabMenuService]="subTabMenuService"
    />

  </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>
