import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-view-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.css']
})
export class ViewButtonRenderer implements ICellRendererAngularComp {

  public params: any;
  public parent: any;
  public disabled:boolean = false;
  public label: string = 'View';
  public field: string = '';

  agInit(params: any): void {
    this.params = params;
    this.disabled=this.params.disabled;
    this.parent = params.context ? params.context : params.instance;
    this.setLabel(params.label);
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }

  public onClick(): void {
    this.parent.onClickButton({
      rowNode: this.params.node,
      data : this.params.data,
      field: this.params.field
    });
  }

  public setLabel( label: string | undefined ): void {
    if( !label ) return;
    this.label = label;
  }

}
