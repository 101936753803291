import { Component, ViewChild } from '@angular/core';
import { CropListMatching } from 'src/app/shared/models/crop-list-matching';
import { CropListMatchingService } from './crop-list-matching.service';
import { CropListService } from '../crop-list/crop-list.service';
import { Crop } from 'src/app/shared/models/crop';
import { CropListMatchingGridComponent } from './crop-matching-list-grid.component';

@Component({
  selector: 'app-crop-list-matching',
  templateUrl: './crop-list-matching.component.html',
  styleUrls: ['./crop-list-matching.component.css']
})
export class CropListMatchingComponent {
  afterSearch: boolean = false;
  allCrops: Crop[] = [];
  applicationMethodMatchingComponent = this;
  cropListMatchings: CropListMatching[] = [];
  loading: boolean = false;
  geographies: string[] = ['Europe Union', 'Global', 'UK'];
  compartments: string[] = ['Groundwater', 'Surface Water'];
  selectedGeography: string = 'Europe Union';
  selectedCompartment: string = 'Surface Water';
  @ViewChild('childGrid') childGrid!:CropListMatchingGridComponent
  constructor(
    private cropListMatchingService: CropListMatchingService,
    private cropListService: CropListService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }
  
  getData() {
    this.getCrops();
  }
    
  getCrops() {    
    this.setLoadingState(true);
    this.cropListService.getAllCropsList().subscribe((crops: Crop[]) => {
      this.allCrops = crops;
      this.getCropListMatchings();
    });
  }
  
  getCropListMatchings() {
    this.setLoadingState(true);        
    this.cropListMatchingService.getCropListMatchings().subscribe((cropListMatchings: CropListMatching[]) => {
        this.cropListMatchings = cropListMatchings;
        this.setLoadingState(false);

      });
  }
  
  setLoadingState(pState: boolean): void {
    this.loading = pState;
    this.afterSearch = !pState;
  }

  geographySelectionChange(event:any)
  {
    this.childGrid.configureGrid();
  }

  compartmentSelectionChange()
  {
    this.childGrid.configureGrid();
  }
}
