<div class="grid">
  <div class="col-12">
    <app-endpoint-dataset [isProjectOwnershipValid]="isProjectOwnershipValid" [subTabMenuService]="subTabMenuService"
      [menuService]="menuService" [selectedProject]="selectedProject" (datasetData)="getDatasetDataOutput($event)"
      (showDetail)="showDetailOutput($event)"
      [environmentalAssessment]="environmentalAssessment"></app-endpoint-dataset>
  </div>
  <div *ngIf="showDetail && !isLoading" class="col-12 mt-2">
    <div class="grid">
      <div class="col-12">
        <app-tox-expo-inputs-endpoints [isProjectOwnershipValid]="isProjectOwnershipValid" [subTabMenuService]="subTabMenuService"
        [menuService]="menuService" [selectedProject]="selectedProject" [dataSet]="dataset"></app-tox-expo-inputs-endpoints>
      </div>
    </div>
  </div>
</div>
<app-loading class="flex align-items-center justify-content-center" *ngIf="isLoading"></app-loading>