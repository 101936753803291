import { Injectable } from '@angular/core';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { DeleteButtonRenderer } from 'src/app/shared/renderers/delete-button/delete-button.component';
import { InputSearchRenderer } from 'src/app/shared/renderers/input-search/input-search.component';
import { NumericEditorRenderer } from 'src/app/shared/renderers/numeric-editor/numeric-editor.component';
import { Utils } from 'src/app/shared/utils/utils';
import { Constants } from 'src/app/shared/utils/constants';
import { CropDropdownListRenderer } from '../shared/renderers/crop-dropdown-list/crop-dropdown-list.component';
import { Project } from 'src/app/shared/models/project';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { ListboxRenderer } from 'src/app/shared/renderers/listbox/listbox.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';

@Injectable({
    providedIn: 'root'
})
export class EEAGapSoilColdef {

    constructor() { }

    public getColumnsDefinition(options: any, isProjectOwnershipValid: boolean): any[] {

        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        headerComponent:'infoGridHeaderComponentComponent',
                        headerComponentParams: {
                          name: 'Application Scheme',
                          infoText: Constants.MESSAGES.APP_SCHEME_INFO,
                          popupWidth:450
                        },                            
                        field: 'name',
                        type: 'default',
                        resizable: true,
                        pinned: 'left',
                        width: 300,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        headerCheckboxSelection: isProjectOwnershipValid,
                        checkboxSelection: isProjectOwnershipValid,
                        cellRenderer: InputTextRenderer,
                        cellRendererParams: {
                          disabled: !isProjectOwnershipValid,
                          isRequired: true
                        },
                        cellEditorParams: {
                            maxLength: 200,
                        },
                    },
                    {
                        headerName: 'Crop',
                        field: 'cropInterceptionPk',
                        type: 'select',
                        resizable: true,
                        width: 180,
                        editable: false,
                        hide: false,
                        filter: 'agSetColumnfilter',
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                            },
                        },
                        cellRenderer: CropDropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.GROUND_WATER, //Soil uses the same GW matchings
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropInterceptionPk',
                                reloadCore: params?.data?.reloadCore,
                                disabled: !isProjectOwnershipValid,
                            }
                        }
                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        field: 'geographies',
                        type: 'select',
                        filter: 'agSetColumnFilter',
                        // hide: ((JSON.stringify(options.selectedModels) === JSON.stringify(new Array(Constants.MODELS.UK)))) || (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA), // TODO: Luis Miranda - uncomment when the new Geographies component is implemented
                        hide: true,
                        filterParams: {
                            cellRenderer: (pParams: any) => {
                                return Utils.formatFilterValues(pParams, options.geographiesList, 'key', 'name')
                            },
                        },
                        cellRenderer: ListboxRenderer,
                        cellRendererParams: {
                            options: options.geographiesList,
                            optionValue: 'key',
                            optionLabel: 'name',
                            checkbox: true,
                            filter: false,
                            multiple: true,
                            disabled: false,
                            validateModel: false,
                            compartment: Constants.COMPARTMENTS.SOIL,
                        },
                        wrapText: true,
                        resizable: true,
                        sortable: true,
                        width: 150,
                        editable: false,
                    },
                    {
                        headerName: 'Number of Applications',
                        field: 'numberOfApplications',
                        type: 'select',
                        resizable: true, width: 120,
                        editable: false,
                        filter: 'agSetColumnfilter',
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.numerOfApplications,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'numberOfApplications',
                                height: '300px',
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Application Interval',
                        field: 'applicationInterval',
                        type: 'default',
                        resizable: true,
                        width: 110,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        cellStyle: (params: any) => {
                            if (params.data.numberOfApplications === 1) {
                                return { pointerEvents: 'none', backgroundColor: '#f0f0f0' };
                            }
                            return null;
                        },
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAI',
                        cellRenderer: '',
                        editable: isProjectOwnershipValid,
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'BBCH (earliest)',
                        field: 'bbchEarliest',
                        type: 'select',
                        resizable: true, width: 100,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: InputSearchRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.bbch,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'bbchEarliest',
                                forceSelection: true,
                                disabled: !isProjectOwnershipValid,
                            };
                        }
                    },
                    {
                        headerName: 'Interception (%)',
                        field: 'cropInterception',
                        filter: 'agSetColumnFilter',
                        width: 110,
                        resizable: true, hide: false,
                        type: 'default',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                    },
                    {
                        headerName: 'Initial Soil Depth (cm)',
                        field: 'soilDepth',
                        type: 'default',
                        width: 120,
                        resizable: true,
                        hide: false,
                        filter: 'agSetColumnfilter',
                        editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        valueFormatter: (params: any) => { return params.data.soilDepth == null || params.data.plateauSoilDepth == "" ? '5' : params.data.soilDepth },
                    },
                    {
                        headerName: 'Tillage',
                        field: 'tillage',
                        type: 'select',
                        filter: 'agSetColumnfilter',
                        hide: false,
                        width: 110,
                        resizable: true,
                        editable: false,
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: {
                            options: ['No', 'Yes'],
                            columnThatModifies: 'tillage',
                            disabled: !isProjectOwnershipValid,
                        },
                    },
                    {
                        headerName: 'Plateau Soil Depth (cm)',
                        field: 'plateauSoilDepth',
                        type: 'default',
                        filter: 'agSetColumnfilter',
                        hide: false,
                        width: 120,
                        resizable: true, editable: false,
                        cellRenderer: InputNumberRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                        },
                        valueFormatter: (params: any) => { return params.data.plateauSoilDepth == null || params.data.plateauSoilDepth == "" ? '5' : params.data.plateauSoilDepth },
                    },
                    {
                        headerName: 'Simulation Year (years)',
                        field: 'simulationYear',
                        type: 'select',
                        resizable: true, width: 110,
                        filter: 'agSetColumnfilter',
                        hide: false,
                        editable: false,
                        cellRenderer: DropdownListRenderer,
                        cellRendererParams: (params: any) => {
                            return {
                                instance: this,
                                options: options.simulatorYear,
                                optionValue: 'value',
                                optionLabel: 'value',
                                columnThatModifies: 'simulationYear',
                                disabled: !isProjectOwnershipValid,
                            }
                        },
                    },
                ]
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: DeleteButtonRenderer,
                editable: false,
                type: 'rightAligned',
                width: 125,
                resizable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }
        ];
    }

    public getInverseColumnsDefinition(options: any, { useNumberOfApplications, useIntervalBetweenApplications, useRateGHA, useBBCH, useApplicationWindow }: Project, isProjectOwnershipValid: boolean): any[] {
        return [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'Application Scheme',
                        field: 'name',
                        type: 'default',
                        resizable: true,
                        pinned: 'left',
                        width: 300,
                        filter: 'agSetColumnFilter',
                        editable: false,
                        cellRenderer: InputTextRenderer,
                        cellRendererParams: {
                            disabled: !isProjectOwnershipValid,
                            isRequired: true
                        },
                        cellEditorParams: {
                            maxLength: 200,
                        },
                    },
                    {
                        headerName: 'Crop',
                        children: [{
                            headerName: '',
                            field: 'cropInterceptionPk',
                            type: 'select',
                            resizable: true,
                            width: 180,
                            editable: false,
                            hide: false,
                            filter: 'agSetColumnfilter',
                            filterParams: {
                                cellRenderer: (pParams: any) => {
                                    return Utils.formatFilterValues(pParams, options.cropList, 'CropListPk', 'CropName')
                                },
                            },
                            cellRenderer: CropDropdownListRenderer,
                            cellRendererParams: {
                                options: options.cropList,
                                purpose: Constants.CROP_PURPOSES.INTERCEPTION,
                                matchings: options.cropListMatching,
                                compartment: Constants.COMPARTMENTS.GROUND_WATER, //Soil uses the same GW matchings
                                geography: options.geographySelected,
                                optionValue: 'CropListPk',
                                optionLabel: 'CropName',
                                columnThatModifies: 'cropInterceptionPk',
                                disabled: !isProjectOwnershipValid,
                            }
                        }]
                    },
                    {
                        headerName: options?.geographiesList[0]?.source,
                        children: [{
                            headerName: '',
                            field: 'countryPk',
                            type: 'select',
                            resizable: true, hide: (options.geographySelected !== Constants.CROP_GEOGRAPHIES.USA),
                            filter: 'agSetColumnfilter',
                            filterParams: {
                                cellRenderer: (pParams: any) => {
                                    return Utils.formatFilterValues(pParams, options.geographiesList, 'key', 'name')
                                },
                            },
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: {
                                options: options.geographiesList,
                                optionValue: 'key',
                                optionLabel: 'name',
                                columnThatModifies: 'countryPk',
                                disabled: !isProjectOwnershipValid,
                            },
                            editable: false,
                        }]
                    },
                    {
                        headerName: 'Number of Applications',
                        hide: !useNumberOfApplications,
                        children: [
                            {
                                headerName: 'Min',
                                hide: !useNumberOfApplications,
                                field: 'minNumberOfApplications',
                                type: 'select',
                                resizable: true, width: 120,
                                editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: DropdownListRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.numerOfApplications,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        height: '300px',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Max',
                                hide: !useNumberOfApplications,
                                field: 'maxNumberOfApplications',
                                type: 'select',
                                resizable: true, width: 120,
                                editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: DropdownListRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.numerOfApplications,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        height: '300px',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Increment',
                                hide: !useNumberOfApplications,
                                field: 'incrementNumberOfApplications',
                                type: 'select',
                                resizable: true, width: 120,
                                editable: false,
                                filter: 'agSetColumnfilter',
                                cellRenderer: DropdownListRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.numerOfApplications,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        height: '300px',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            }]
                    },
                    {
                        headerName: 'Application Interval',
                        hide: !useIntervalBetweenApplications,
                        children: [
                            {
                                headerName: 'Min',
                                hide: !useIntervalBetweenApplications,
                                field: 'minApplicationInterval',
                                type: 'default',
                                resizable: true,
                                width: 110,
                                filter: 'agSetColumnfilter',
                                cellEditor: NumericEditorRenderer,
                                editable: isProjectOwnershipValid,
                            },
                            {
                                headerName: 'Max',
                                hide: !useIntervalBetweenApplications,
                                field: 'maxApplicationInterval',
                                type: 'default',
                                resizable: true, width: 100,
                                filter: 'agSetColumnfilter',
                                cellEditor: NumericEditorRenderer,
                                editable: isProjectOwnershipValid,
                            },
                            {
                                headerName: 'Increment',
                                hide: !useIntervalBetweenApplications,
                                field: 'incrementApplicationInterval',
                                type: 'default',
                                resizable: true, width: 100,
                                filter: 'agSetColumnfilter',
                                cellEditor: NumericEditorRenderer,
                                editable: isProjectOwnershipValid,
                            }],
                    },
                ]
            },
            {
                headerName: 'Rate (g/ha)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAIGHA',
                        cellRenderer: '',
                        editable: isProjectOwnershipValid,
                    },
                ]
            }, {
                headerName: 'Rate (lb/acre)',
                children: [
                    {
                        headerName: 'Default Active Ingredient',
                        field: 'defaultAILBACRE',
                        cellRenderer: '',
                        editable: isProjectOwnershipValid,
                    },
                ]
            },
            {
                headerName: '',
                children: [
                    {
                        headerName: 'BBCH (earliest)',
                        hide: !useBBCH,
                        children: [
                            {
                                headerName: 'Min',
                                hide: !useBBCH,
                                field: 'minBbchEarliest',
                                type: 'select',
                                width: 100,
                                resizable: true, filter: 'agSetColumnfilter',
                                editable: false,
                                cellRenderer: InputSearchRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.bbch,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        forceSelection: true,
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Max',
                                hide: !useBBCH,
                                field: 'maxBbchEarliest',
                                type: 'select',
                                width: 100,
                                resizable: true, filter: 'agSetColumnfilter',
                                editable: false,
                                cellRenderer: InputSearchRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.bbch,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        forceSelection: true,
                                        instance: this,
                                        columnThatModifies: 'maxBbchEarliest',
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            },
                            {
                                headerName: 'Increment',
                                hide: !useBBCH,
                                field: 'incrementBbchEarliest',
                                type: 'select',
                                width: 100,
                                resizable: true, filter: 'agSetColumnfilter',
                                editable: false,
                                cellRenderer: InputSearchRenderer,
                                cellRendererParams: (params: any) => {
                                    return {
                                        options: options.bbch,
                                        optionValue: 'value',
                                        optionLabel: 'value',
                                        forceSelection: true,
                                        disabled: !isProjectOwnershipValid,
                                    };
                                }
                            }
                        ]
                    },
                    {
                        headerName: 'Interception (%)',
                        children: [{
                            headerName: '',
                            field: 'cropInterception',
                            type: 'default',
                            filter: 'agSetColumnFilter',
                            resizable: true,
                            editable: isProjectOwnershipValid,
                            width: 110,
                        }],
                    },
                    {
                        headerName: 'Initial Soil Depth (cm)',
                        children: [{
                            headerName: '',
                            field: 'soilDepth',
                            type: 'default',
                            width: 120,
                            resizable: true, hide: false,
                            filter: 'agSetColumnfilter',
                            editable: isProjectOwnershipValid,
                            valueFormatter: (params: any) => { return params.data.soilDepth == null || params.data.plateauSoilDepth == "" ? '5' : params.data.soilDepth },
                        }]
                    },
                    {
                        headerName: 'Tillage',
                        children: [{
                            headerName: '',
                            field: 'tillage',
                            type: 'select',
                            width: 110,
                            filter: 'agSetColumnfilter',
                            hide: false,
                            resizable: true, editable: false,
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: {
                                options: ['No', 'Yes'],
                                columnThatModifies: 'tillage',
                                disabled: !isProjectOwnershipValid,
                            },
                        }]
                    },
                    {
                        headerName: 'Plateau Soil Depth (cm)',
                        children: [{
                            headerName: '',
                            field: 'plateauSoilDepth',
                            type: 'default',
                            filter: 'agSetColumnfilter',
                            hide: false,
                            width: 120,
                            resizable: true, editable: isProjectOwnershipValid,
                            valueFormatter: (params: any) => { return params.data.plateauSoilDepth == null || params.data.plateauSoilDepth == "" ? '5' : params.data.plateauSoilDepth },
                        }]
                    },
                    {
                        headerName: 'Simulation Year (years)',
                        children: [{
                            headerName: '',
                            field: 'simulationYear',
                            type: 'select',
                            resizable: true, width: 110,
                            filter: 'agSetColumnfilter',
                            editable: isProjectOwnershipValid,
                            hide: false,
                            cellRenderer: DropdownListRenderer,
                            cellRendererParams: (params: any) => {
                                return {
                                    instance: this,
                                    options: options.simulatorYear,
                                    optionValue: 'value',
                                    optionLabel: 'value',
                                    columnThatModifies: 'simulationYear',
                                    disabled: !isProjectOwnershipValid,
                                }
                            },
                        }]
                    },
                ]
            },
            {
                headerName: '',
                colId: 'delete',
                field: 'deleteButton',
                cellRenderer: DeleteButtonRenderer,
                editable: false,
                type: 'rightAligned',
                width: 125,
                resizable: true,
                cellRendererParams: {
                    disabled: !isProjectOwnershipValid,
                }
            }
        ];
    }

}
