
    <span class="filter-label">Geography: </span>
    <p-dropdown
      [style]="{ 'width': '40%' , 'line-height' : '16px'}"
      styleClass="geoprahy-filter-dropdown"
      styleClass="ddlFilterSelection"
      panelStyleClass="panel-filter-dropdown"
      placeholder="Select a value"
      [disabled]="regions.length == 0"
      [options]="regions"
      [(ngModel)]="selectedRegion"
      optionLabel="name"
      optionValue="name"
      [virtualScroll]="true"
      [virtualScrollItemSize]="30"
      (onChange)="regionSelectionChange()"
    >
    </p-dropdown>