<p-dialog header="Advanced Filters" [modal]="true" [(visible)]="visible" [style]="{ width: '97%', height:'845px' }">
    <div class="container panel-margin header-line">
        <app-loading class="loading-animation-centered" *ngIf="loading"></app-loading>
        <form *ngIf="!loading" [formGroup]="form" (ngSubmit)="onSearch()">
            <div class="grid">
                <div class="col-4">
                    <label for="project">Project</label>
                    <p-multiSelect [options]="projects" optionLabel="label" [virtualScrollItemSize]="30"
                        [virtualScroll]="true" optionValue="value" [filter]="true" [maxSelectedLabels]="1"
                        formControlName="project" placeholder="Select Projects"></p-multiSelect>
                </div>
                <div class="col-2">
                    <label for="createdBy">Created by</label>
                    <p-multiSelect [options]="createdBy" [virtualScrollItemSize]="30" [virtualScroll]="true"
                        optionLabel="label" optionValue="value" [filter]="true" [maxSelectedLabels]="1"
                        formControlName="createdBy" placeholder="Select Users"></p-multiSelect>
                </div>
                <div class="col-2">
                    <label for="creationDate">Creation date</label>
                    <p-calendar   dateFormat="M d, yy" 
                    formControlName="creationDate" 
                    selectionMode="range"
                    [maxDate]="maxDate" 
                    [minDate]="minDate"
                    [showClear]="true"
                    (onClear)="clearFilter()"></p-calendar>
                </div>
                <div class="col-2">
                    <label for="formulation">Formulation</label>
                    <p-multiSelect [options]="formulations" optionLabel="label" optionValue="value" [filter]="true"
                        [filter]="true" loading="loading" [virtualScrollItemSize]="30" [virtualScroll]="true"
                        [maxSelectedLabels]="1" formControlName="formulation"
                        placeholder="Select Formulations"></p-multiSelect>
                </div>
                <div class="col-2">
                    <label for="parent">Parent</label>
                    <p-multiSelect [virtualScrollItemSize]="30" [virtualScroll]="true" [options]="parents"
                        optionLabel="label" optionValue="value" [filter]="true" [maxSelectedLabels]="1"
                        formControlName="parent" placeholder="Select Parent Molecules"></p-multiSelect>
                </div>
            </div>
            <div class="grid mt-2">
                <div class="col-2">
                    <label for="metabolite">Metabolite</label>
                    <p-multiSelect [virtualScrollItemSize]="30" [virtualScroll]="true" [options]="metabolites"
                        optionLabel="label" optionValue="value" [filter]="true" [maxSelectedLabels]="1"
                        formControlName="metabolite" placeholder="Select Metabolites"></p-multiSelect>
                </div>
                <div class="col-2">
                    <label for="model">Model</label>
                    <p-multiSelect [virtualScrollItemSize]="30" [virtualScroll]="true" [options]="models"
                        optionLabel="label" optionValue="value" [filter]="true" [maxSelectedLabels]="1"
                        formControlName="model" placeholder="Select Models"></p-multiSelect>
                </div>
                <div class="col-2">
                    <label for="geography">Geography</label>
                    <p-multiSelect [virtualScrollItemSize]="30" [virtualScroll]="true" [options]="geographies"
                        optionLabel="label" optionValue="label" [filter]="true" [maxSelectedLabels]="1"
                        formControlName="geography" placeholder="Select Geographies"></p-multiSelect>
                </div>
                <div class="col-2">
                    <label for="crop">Crop</label>
                    <p-multiSelect [options]="crops" optionLabel="label" optionValue="label" [filter]="true"
                        [maxSelectedLabels]="1" formControlName="crop" placeholder="Select Crops"></p-multiSelect>
                </div>
                <div class="col-1">
                    <label for="archive">Archived</label>
                    <p-dropdown [filter]="false" [(ngModel)]="defaultArchived"
                    [options]="[{label: 'Any',value:3},{label: 'Yes', value: 1}, {label: 'No', value: 0}]"
                        formControlName="archive"></p-dropdown>
                </div>
                <div class="col-2">
                    <label for="status">Status</label>
                    <p-multiSelect [options]="statuses" [filter]="false" [maxSelectedLabels]="1"
                        formControlName="status"></p-multiSelect>
                </div>
                <div class="col-1">
                    <label for="status">&nbsp;</label>
                    <p-button [disabled]="!form.dirty" type="submit" label="Search" class="btnSearch"
                        [outlined]="true"></p-button>
                </div>
            </div>

        </form>
    </div>
    <div class="panel-margin-table">
        <app-loading class="loading-animation-centered" *ngIf="isSearching"></app-loading>
        <ng-container *ngIf="searched">
            <ag-grid-angular #agGrid style="width: 100%; height: 530px;" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" [paginationPageSize]="9" class="ag-theme-alpine"
                [rowHeight]="45"
                [pagination]="true" [rowHeight]="rowHeight" rowClass="cursor-pointer" (rowClicked)="onRowClicked($event)"></ag-grid-angular>
        </ng-container>
    </div>
</p-dialog>