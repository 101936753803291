import { Constants } from "src/app/shared/utils/constants";
import { EndpointsParameterConfigurationPwc } from "./eea-endpoints-parameters-pwc.inteface";

export abstract class EndpointsConfigurationPwc {

  public static readonly CONFIGURATION: EndpointsParameterConfigurationPwc[] = [
    {
      displayName: 'Sorption (ml/g)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.Kfoc
    },
    {
      displayName:'Water Column Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.halfLifeWater
    },
    {
      displayName: 'Water Reference Temperature (°C)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.measuredHalfLifeWater
    },
    {
      displayName: 'Benthic Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.halfLifeSediment
    },
    {
      displayName: 'Benthic Reference Temperature (°C)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.measuredHalfLifeSediment
    },
    {
      displayName: 'Aqueous Photolysis Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.aqueousPhotolysisHalfLife
    },
    {
      displayName: 'Photolysis Reference Latitude (°N)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.photolysisReferenceLatitude
    },
    {
      displayName: 'Hydrolysis Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.hydrolysisHalfLife
    },
    {
      displayName: 'Soil Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.halfLifeInSoil
    },
    {
      displayName: 'Soil Reference Temperature (°C)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.measuredHalfLifeSoil
    },
    {
      displayName: 'Foliar Halflife (day)',
      showMoles: true,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.foliarHalfLife
    },
    {
      displayName: 'Foliar Washoff (cm¯¹)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.foliarWashOfFactor
    },
    {
      displayName: 'Molecular Weight (g/mol)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.molecularWeight
    },
    {
      displayName: 'Vapor Pressure (torr)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.saturatedVapourPressure
    },
    {
      displayName: 'Solubility (mg/L)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.solubilityInWater
    },
    {
      displayName: 'Henry’s Coefficient (vol/vol)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.henrysConstant
    },
    {
      displayName: 'Air Diffusion Coefficient (cm²/day)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.airDiffusionCoefficient
    },
    { displayName: 'Heat of Henry (j/mol)',
      showMoles: false,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.heatOfHenry
    },
  ]
}
