import { Injectable } from '@angular/core';
import { HttpApiService } from './http.api.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalProtectionEquipmentSchemeSharedApiService extends HttpApiService {
  override apiUrl: string = environment.BAMS_Api_URL;

  getAndCopyPersonalProtectionEquipmentSchemes(projectPk?: number,projectNew?: number): Observable<number> {
    let endpoint = `api/ppes/getAndCopyPersonalProtectionEquipmentSchemes/${projectPk}/${projectNew}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }
}
