import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { EEAOutputProjectSwanSwashResultSummaryColdef } from './eeea-output-project-swan-swash-result-summary-coldef';
import { EEAOutputProjectSummaryResultSurfaceWaterApiService } from '../../../../../shared/services/out-put-project/eea-output-project-step1n2-summary-result.api.service';
import { ColDef } from 'ag-grid-community';
import { Constants } from 'src/app/shared/utils/constants';

@Component({
  selector: 'app-eea-output-project-swash-swan-result-summary',
  templateUrl: './eea-output-project-swash-swan-result-summary.component.html',
  styleUrls: ['./eea-output-project-swash-swan-result-summary.component.css']
})
export class EEAOutputProjectSwashSwanResultSummaryComponent implements OnInit, OnChanges {
  @Input() selectedProject?: Project;
  @Input() selectedModel?: string;
  columnDef?: ColDef[];
  loading: boolean = true;
  rowData: any;
  summaryResult?: any[];

  constructor(
    private summaryresultColDef: EEAOutputProjectSwanSwashResultSummaryColdef,
    private summaryResultService: EEAOutputProjectSummaryResultSurfaceWaterApiService
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    let hideBufferDistance = true;
    if (this.selectedModel)
      hideBufferDistance = this.selectedModel == Constants.MODELS.SWASH ? true : false;

    this.columnDef = this.summaryresultColDef.getColumnsDefinition(hideBufferDistance);
    this.getSummaryResult(this.selectedProject?.projectPk);
  }

  getSummaryResult(projectPk?: number) {
    if (projectPk) {
      this.loading = true;
      this.summaryResultService
        .getSummariesResultsSurfaceWaterByModel(projectPk, this.selectedModel ?? "")
        .pipe(take(1))
        .subscribe((result: any) => {
          this.rowData = result.Value;
          this.loading = false;
        });
    }
  }


}
