import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EEAOutputProjectSwashSwanInputsColdef {
  constructor(private http: HttpClient) {}

  getColumnsDefinition(): any {
    return [
      {
        headerName: 'General',
        children: [ 
          {
            headerName: 'Run ID', 
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            field: 'runId',
            width: 120,
            minWidth: 100,
            type: 'default',
            filter: 'agSetColumnFilter',
            pinned: 'left',
          },
          {
            headerName: 'Model',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            field: 'model',
            width: 150,
            minWidth: 100,
            type: 'default',
            filter: 'agSetColumnFilter',
            pinned: 'left',
          },
        ],
      },
      {
        headerName: 'Application Pattern (GAP)',

        children: [
          
          {
            headerName: `Application Scheme`,
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            field: 'applicationSchemeName',
            minWidth: 300,
            width: 300,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Crop',
            wrapText: false,
            hide: false,
            editable: false,
            field: 'crop',
            minWidth: 160,
            width: 160,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Number Of Applications`,
            wrapText: false,
            hide: false,
            editable: false,
            field: 'applicationsNumber',
            minWidth: 140,
            width: 140,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Interval Between \n Application (d)` ,
            wrapText: false,
            hide: false,
            editable: false,
            field: 'applicationsNumber',
            minWidth: 160,
            width: 160,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Rate \n (g/ha)` ,
            wrapText: false,
            hide: false,
            editable: false,
            field: 'rate',
            minWidth: 100,
            width: 100,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `BBCH  \n (Earliest)` ,
            wrapText: false,
            hide: false,
            editable: false,
            field: 'BBCHEarliest',
            minWidth: 110,
            width: 110,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Scenario` ,
            wrapText: false,
            hide: false,
            editable: false,
            field: 'scenearios',
            minWidth: 110,
            width: 110,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Water Type` ,
            wrapText: false,
            hide: false,
            editable: false,
            field: 'waterType',
            minWidth: 110,
            width: 110,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Application Method` ,
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'applicationMethod',
            minWidth: 135,
            width: 135,
            type: 'select',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Chemical Application Method',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'chemicalApplicationMethod',
            width: 200,
            minWidth: 200,
            type: 'select',
            filter: 'agSetColumnFilter',
            cellRendererParams: (params: any) => {
              return {
                columnThatModifies: 'chemicalApplicationMethod',
                pkVariableThatModifies: 'chemicalApplicationMethodPk',
                optionLabel: 'value',
              };
            },
          },
          {
            headerName: `Soil Depth \n Incorporated (cm)`,
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'soilDepthIncorporated',
            width: 150,
            minWidth: 150,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Endpoints',

        children: [
          {
            headerName: 'Endpoint Data Set',
            hide: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'endpointsDataSetName',
            minWidth: 300,
            width: 300,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Parent',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            field: 'activeIngredient',
            width: 200,
            minWidth: 200,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Substance Name',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            cellRenderer: 'agGroupCellRenderer',
            field: 'substanceName',
            width: 210,
            minWidth: 210,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Molecular \n Weight (g/mol)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'molecularWeight',
            width: 130,
            minWidth: 130,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Saturated Vapour \n Pressure (Pa)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'saturatedVapourPressure',
            width: 145,
            minWidth: 145,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Solubility \n In Water (mg/L)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'solubilityInWater',
            width: 135,
            minWidth: 135,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName:`Air Diffusion \n  Coefficient (cm³/d)` ,
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            field: 'airDiffusionCoefficient',
            width: 160, 
            minWidth: 160,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Water Diffusion \n Coefficient (m²/d)`,
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'waterDifussionCoefficient',
            width: 145,
            minWidth: 145,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Kom \n (L/Kg)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'kfom',
            width: 90,
            minWidth: 90,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Kfoc',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'kfoc',
            width: 90,
            minWidth: 90,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: `Freundlich Sorption Exponent In Soil (-)`,
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            hide: false,
            field: 'freundlichSorptionExponentInSoil',
            width: 165,
            minWidth: 165,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Half-life \n In Soil (d)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'halfLifeInSoil',
            width: 100,
            minWidth: 100, 
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Half-life \n in water (d)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            width: 115,
            minWidth: 115,
            field: 'halfLifeInWater',
            type: 'default',
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Half-life \n in sediment (d)',
            editable: true,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'halfLifeInSediment',
            width: 125,
            minWidth: 125,
            type: 'default',
            filter: 'agSetColumnFilter',
          },
        ],
      },
    ];
  }
}
