import { ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { ESALogicService } from './udl-overlap-tool.logic.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';
import { Message } from 'primeng/api';
import { Project } from 'src/app/shared/models/project';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { Constants } from 'src/app/shared/utils/constants';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';
import { firstValueFrom, take } from 'rxjs';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { Discipline } from 'src/app/shared/models/echo/discipline';

@Component({
  selector: 'app-udl-overlap-tool',
  templateUrl: './udl-overlap-tool.component.html',
  styleUrls: ['./udl-overlap-tool.component.css']
})
export class UDLOverlapToolComponent implements OnInit {
  activeItem!: CustomTabMenuItem;
  message!: Message;
  loading: boolean = false;
  nextIndex?: number;
  selectedProject?: Project;
  selectedProjectStatus?: number;
  isSetupValid: boolean = false;
  destroyRef = inject(DestroyRef)
  pwcFlag: boolean = false;
  isProjectOwnershipValid: boolean = true;
  columnDef: any[] = [];
  disciplinePk: number = 0;
  showLoadingForOutput: boolean = true;

  constructor(public ESALogicService: ESALogicService,
              private selectedProjectApiService: SelectedProjectApiService,
              private userLogicService:UserLogicService,
              private disciplineApiService: DisciplineApiService,
              private cdr: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.getDiscipline();
    this.initSubscribes();
  }

  async getDiscipline(): Promise<void> {
    await firstValueFrom(this.disciplineApiService.getDisciplineByName(Constants.DISCIPLINE_IDENTITIES.ENVIRONMENTAL_E_FATE).pipe(take(1))).then((discipline: Discipline) =>{
        this.disciplinePk = discipline?.disciplinePk ?? -1;
    });
  }

  onActiveIndexChange(index: number) {
    this.nextIndex = index;
    this.ESALogicService.setActiveIndexChanged(true, index);
    if(index ==  Constants.TAB_INDEX.OUTPUT){
      if(!this.showLoadingForOutput){
        this.loading = false;
      }
    }
  }

  initSubscribes(): void {
    this.ESALogicService.activeItem.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(activeItem => {
      this.activeItem = activeItem;
    });

    this.selectedProjectApiService.selectedProject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((project?: Project) => {
      this.selectedProject = project;
      this.isProjectOwnershipValid = this.selectedProject ? this.userLogicService.verifyProjectOwnership(this.selectedProject?.createdBy) : true;
      this.onActiveIndexChange(0);
      this.isPWC();
    });

    this.selectedProjectApiService.selectedProjectStatus.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((status?: number) => {
      this.selectedProjectStatus = status;
    });

    this.ESALogicService.isLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(isLoading => {
      this.loading = isLoading;
    });

    this.ESALogicService.success.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(success => {
      this.loading = false;
      if (success) {
        const nextItem = this.ESALogicService.items.find(i => i.index == this.nextIndex);
        if (nextItem)
          this.ESALogicService.setActiveItem(nextItem);
      }
    });
  }

  validateSetup(event: boolean) {
    this.isSetupValid = event;
    this.cdr.detectChanges();
  }

  isPWC() {
    this.pwcFlag = this.selectedProject?.projectXCompoundXModel?.map(a => a.ModelName).includes(Constants.MODELS.PWC) ?? false;
  }

  public getIsValidEndpoint(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }

  onUpdateLoadingStatus(loadingStatus: boolean) {
    this.loading = loadingStatus;
  }

  onShowLoadingForOutput(showLoadingForOutput: boolean) {
    this.showLoadingForOutput = showLoadingForOutput;
  }

  public getIsValidApplicationScheme(isValid: boolean): void {
    this.isSetupValid = isValid;
    this.cdr.detectChanges();
  }
}
