<div class="formgrid grid mr-2" style="width: 150px;">
    <label>{{ compound }}</label>
    <div class="font-bold" *ngIf="showAlternativeName()">
        <div class="flex flex-column gap-2">
            <div class="label">
                <label [ngClass]="!isAlternativeNameValid && isRequired ? 'lbl-invalid' : 'lbl-valid'">{{label}}</label>
            </div>
            <input class="w-12" [ngClass]="!isAlternativeNameValid && isRequired? 'ng-invalid ng-dirty' : 'ng-valid'"
                pInputText [id]="'alternativeNameInput'" [(ngModel)]="alternativeCompoundName" [disabled]="disabled"
                [maxlength]="maxlength" (keypress)="omitIllegalCharactersInSubstanceNames($event)"
                (ngModelChange)="alternativeNameChanged($event)" />
        </div>
    </div>
</div>  