import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Constants } from '../../utils/constants';
import { Run } from '../../models/run/run';
import { OPEXConstants } from '../../utils/opex-constants';

@Component({
  selector: 'app-qc-icon',
  templateUrl: './qc-icon.component.html',
  styleUrls: ['./qc-icon.component.css']
})
export class QcIconComponent implements ICellRendererAngularComp {
  public params: any;
  public data: any;
  public run!: Run;
  public field: any;
  public value!: string;
  parent: any;

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.data = params.data;
    this.run = params.data.run;
    this.field = params.colDef.field;
    this.parent = params.context ? params.context : params.instance;
  }

  getTooltipContent(): string {
    let missingColumnsMessage = this.buildMissingColumnsTooltipMissingColumnsMessage();
    if (missingColumnsMessage) {
      return missingColumnsMessage;
    }

    return this.buildMissingColumnsTooltipForMetabolites();
  }

  private buildMissingColumnsTooltipForMetabolites() {
    var substancesNames: (string | undefined)[] = [];
    let columns: string[] = this.params.data.componentQC == OPEXConstants.GENERAL.componentQC ? OPEXConstants.ENDPOINTS_BY_MODEL[OPEXConstants.MODELS.EU_OPEX] : Constants.COLUMS_BY_METABOLITES;
    var message: string[] = [];
    if (columns.includes(this.field)) {
      if (!Constants.REQUIRED_FRACTIONS_BY_METABOLITES.includes(this.field)) {
        this.data.missingParentColumns.includes(this.field) && substancesNames.push(this.run.substanceName);
      }
      this.data.missingColumnsByMetabolites?.forEach((metabolite: { [key: string]: { column: string; message: string; }[]; }) => {
        Object.entries(metabolite).forEach(([key, columns]) => {
          let column = columns.find(x => x.column == this.field);
          if (column) {
            if (column?.message && !message.includes(column.message)) {
              message.push(column.message);
            }
            substancesNames.push(key);
          }
        });
      });
    }
    else if (this.field === Constants.FIELD_NAMES.RATE) {
      substancesNames = Object.keys(this.data.missingColumnsByApplications)?.filter(key => {
        return this.data.missingColumnsByApplications[key].includes(Constants.FIELD_NAMES.RATE);
      });
    }

    let finalMessage = `${message.length > 0 ? message.join(', ') : 'Missing Data'} for: \n- ${substancesNames.join('\n-  ')}`;
    return substancesNames.length > 0 ? finalMessage : '';
  }

  private buildMissingColumnsTooltipMissingColumnsMessage() {
    if (this.data.missingColumnsMessages && this.data.missingColumnsMessages.length > 0) {
      let missingColumnsMessage = this.data.missingColumnsMessages.find((x: { column: any; }) => x.column === this.field)?.message;
      if (missingColumnsMessage) {
        return missingColumnsMessage;
      }
    }
  }
}