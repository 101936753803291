import { environment } from 'src/environments/environment';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EcotoxEndpointFlatValues } from './era-ecotox-import-popup.interface';

@Injectable({
  providedIn: 'root'
})
export class EraEcotoxImportPopupApiService extends HttpApiService {

  override apiUrl: string = environment.ECHO_Api_URL;

  public getEcotoxEndpointFlatValues(datasetToImportPk: number, geography: string): Observable<EcotoxEndpointFlatValues> {
    let endpoint = `api/ecotoxicology/getEcotoxEndpointFlatValues/${datasetToImportPk}/${geography}`;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this
      .get(endpoint, headers)
      .pipe(map((data: any) => data.Value));
  }

}
