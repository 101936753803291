import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CropListService {

  constructor(private http: HttpClient) { }

  getCropList(pGeography: string, pCompartment?: string, pPurpose?: string): Observable<any[]> {
    if (pCompartment == '')
      pCompartment = "N";
    if (pPurpose == '')
      pPurpose = "N";

    let query: any = `${environment.BAMS_Api_URL}api/cropList/${pGeography}` + `/${pCompartment}` + `/${pPurpose}`;
    return this.http
      .get(query)
      .pipe(map((data: any) => data.Value));
  }

  getAllCropsList(): Observable<any[]> {
    let query: any = `${environment.BAMS_Api_URL}api/allCropsList`;
    return this.http
      .get(query)
      .pipe(map((data: any) => data.Value));
  }

  saveData(pData: any): Observable<any> {
    const body = JSON.stringify(pData);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    const query = `${environment.BAMS_Api_URL}api/SaveCropList`;
    return this.http
      .put(query, body, { headers })
      .pipe(map((data: any) => data.Value));
  }

  getCropInterceptions(): Observable<any[]> {
    let query: any = `${environment.BAMS_Api_URL}api/cropInterceptions`;
    return this.http
      .get(query)
      .pipe(map((data: any) => data.Value));
  }

  getCropNameByPk(cropCoreGap:any): Observable<any[]> {
    let query: any = `${environment.BAMS_Api_URL}api/getCropNameByPk/${cropCoreGap}`;
    return this.http
      .get(query)
      .pipe(map((data: any) => data.Value));
  }
}
