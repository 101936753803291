<div class="grid">
    <div class="col ">
        <div class="flex justify-content-end flex-wrap">
            <p-button icon="{{isSaving ?'pi pi-spin pi-spinner' : 'pi pi-save'}}" class="p-button-outlined mr-2" *ngIf="(transactionList.length > 0 || hasDataToSave) && !editing  && currentUserCanEdit" (onClick)="showDialog(SaveDialogElement, saveConstant)" label="{{isSaving ? 'Saving' : 'Save'}}" [disabled]="isSaving"></p-button>
            <p-button icon="pi pi-plus" class="right-100" *ngIf="!editing && !saveBtn && !context.componentParent.isAutomatic && currentUserCanEdit" (onClick)="add()" class="p-button-outlined mr-2" label="Add" [disabled]="isSaving"></p-button>
            <p-button icon="pi pi-file-excel" *ngIf="!editing" (onClick)="onBtExport()" class="p-button-outlined excel-button mr-2" [disabled]="isSaving"></p-button>
            <p-button icon="pi pi-ban" class="p-button-outlined mr-2" *ngIf="transactionList.length > 0 || hasDataToSave || editing" label="Cancel" (onClick)="showDialog(CancelDialogElement, cancelConstant)"></p-button>
            <app-endpoint-batch-upload *ngIf="!editing && !batchUpload && !context.componentParent.isAutomatic && currentUserCanEdit" [dataSet]="dataSet" [parentComponent]="thisComponent" [endpointComponent]="endpointComponent" [endpointTypeIdentity]="mainCompartment"></app-endpoint-batch-upload>
        </div>
    </div>
    <div class="col-12">
        <ag-grid-angular [defaultColDef]="defaultColDef" #agGrid [id]="dynamicId" style="width: auto; height: 711px;text-align: left; z-index:-1;font-size:14px; color: #656565;" class="ag-theme-alpine" [rowData]="rowData" [pagination]="true" [suppressColumnVirtualisation]="true" [columnDefs]="columnDefs"
            [columnTypes]="columnTypes" [components]="frameworkComponents" [detailCellRenderer]="detailCellRenderer" [rowSelection]="rowSelection" [doesExternalFilterPass]="doesExternalFilterPass" [suppressRowClickSelection]="suppressRowClickSelection"
            [rowHeight]="rowHeight" [headerHeight]="headerHeight" [animateRows]="true" [context]="context" (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)" (cellClicked)="onCellClicked($event)" (cellEditingStarted)="onCellEditingStarted($event)" (cellEditingStopped)="onCellEditingStopped($event)"
            (firstDataRendered)="onFirstDataRendered($event)" (rowDataChanged)="onFirstDataRendered($event)" (bodyScroll)="onFirstDataRendered($event)" (selectionChanged)="onSelectionChanged($event)" (columnGroupOpened)="onFirstDataRendered($event)" [masterDetail]="isMasterDetail"
            (modelUpdated)="modelUpdated($event)" (cellMouseOver)="onCellMouseOver($event)">
        </ag-grid-angular>
    </div>
</div>