import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RoleApiService } from 'src/app/shared/services/role.api.service';

@Injectable({
  providedIn: 'root',
})

export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(private roleService: RoleApiService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({headers: req.headers.set(
        'RTag',
        btoa(this.roleService.userRolesBitmask.toString())
      ),
    });
    return next.handle(clonedRequest);
  }
}
