import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { AgPromise, IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.css']
})
export class DropdownFilterRenderer implements IFilterAngularComp {
  params!: IFilterParams;
  validFilters: any = [];
  rowModel: any;
  rowNodes: any = null;
  source!: string;
  selectAll!: boolean;
  field!: string;
  tempFilters: any = [];
  labelId!: string;
  gridApi: any;
  label: string = "";
  parentComponent: any;
  model: any;


  constructor() { }


  agInit(params: any): void {

    this.params = params;
    this.source = params.source;
    this.rowModel = params.rowModel as any;
    this.rowNodes = this.rowModel.rowsToDisplay;
    this.field = params.colDef.field;
    this.selectAll = true;
    this.labelId = params.labelId;
    this.gridApi = params.api;
    this.parentComponent = params.context.componentParent;
  }


  getFilterValues() {

    this.tempFilters = [];
    let uniqueFilters = [];
    const map = new Map();

    this.gridApi.forEachNodeAfterFilter((rowNode: { data: { [x: string]: string; }; }) => {
      if (rowNode.data[this.field])
        this.getDropDownFieldValue(rowNode.data[this.field], rowNode.data[this.labelId]);
    });

    for (const item of this.tempFilters) {
      if (!map.has(item.value)) {
        map.set(item.value, true);
        uniqueFilters.push({
          value: item.value,
          labelId: item.labelId,
          checked: item.checked
        });
      }
    }
    const index = this.parentComponent.filteredColumns.findIndex((x: { key: string; }) => x.key === this.field);
    const value = this.parentComponent.filteredColumns.filter((x: { key: string; }) => x.key === this.field);
    if (this.validFilters.length == 0 || (this.validFilters.length != uniqueFilters.length
      && this.parentComponent.filteredColumns.some((x: { value: boolean; }) => x.value === false) && value[0].value)) {
      this.validFilters = [];

      for (const item of uniqueFilters) {
        map.set(item.value, true);
        this.validFilters.push({
          value: item.value,
          labelId: item.labelId,
          checked: item.checked
        });
      }

      if (index === -1) this.parentComponent.filteredColumns.push({ key: this.field, value: true });

      this.validFilters = this.validFilters.sort((a: { labelId: string; }, b: { labelId: string; }) => a.labelId.toLowerCase().localeCompare(b.labelId.toLowerCase(), undefined, { sensitivity: 'accent' }));
    }
    else
      this.parentComponent.filteredColumns[index].value = false;
  }

  getDropDownFieldValue(value: string, labelId: string) {
    this.tempFilters.push(this.getDropDownFieldValueAsObject(value, labelId));
  }


  isFilterActive(): boolean {
    return this.validFilters.filter((x: { checked: boolean; }) => x.checked == false).length > 0;
  }


  doesFilterPass(params: IDoesFilterPassParams<any>): boolean {
    let filterPass = false;
    if (this.validFilters.filter((x: { value: any; checked: boolean; }) => x.value == params.data[this.field] && x.checked == true).length > 0)
      filterPass = true;
    return filterPass;
  }


  getModel() {

    return this.model;
  }


  setModel(model: any): void | AgPromise<void> {

    this.model = model;
    if (model) {
      this.addBlanksToModel(model);
      var isIncluded = this.validFilters.includes(this.model?.values);
      if (!isIncluded && model?.values.length != this.validFilters.filter((x: { checked: boolean; }) => x.checked == true).length)
        this.setValues();
    }
  }


  onNewRowsLoaded?(): void {
    this.validFilters.map((x: { checked: boolean; }) => x.checked = true);
    this.selectAll = true;
    this.params.filterChangedCallback();
  }


  afterGuiAttached?(_params?: IAfterGuiAttachedParams): void {
    this.getFilterValues();
  }


  getDropDownFieldValueAsObject(value: string, pTitle: string) {
    return { value: value, labelId: pTitle, checked: true };
  }



  onSelectionChanged(pItem: any) {

    var checkedFilters = this.validFilters.filter((x: { checked: boolean; }) => x.checked == true);
    if (checkedFilters.length < this.validFilters.length) {
      this.selectAll = false;
      var model = {
        filterType: 'set',
        values: checkedFilters.map((x: { labelId: any; }) => x.labelId).flat(),
      };
      this.setModel(model);
    }
    if (checkedFilters.length == this.validFilters.length) {
      this.selectAll = true;

      const index = this.parentComponent.filteredColumns.findIndex((x: { key: string; }) => x.key === this.field);
      this.parentComponent.filteredColumns[index].value = false;
      this.setModel(null);
    }

    this.params.filterChangedCallback();
  }


  selectAllCheck() {
    this.validFilters.forEach((filter: { checked: boolean; }) => filter.checked = this.selectAll);
    //Ref. I01
    this.setModel(null);
    this.getFilterValues();
    this.params.filterChangedCallback();
  }


  setValues(): void {
    this.getFilterValues();
    this.selectAll = false;
    this.validFilters.forEach((x: { labelId: string; checked: boolean; }) => {
      if (this.model.values.includes(x.labelId) || x.labelId == '(Blanks)') {
        x.checked = true;
      }
      else x.checked = false;
    });
  }


  addBlanksToModel(pModel: any): void {
    if (!this.validFilters.some((x: { value: undefined; }) => x.value == undefined)) {
      pModel.values.some((x: undefined) => x == undefined) ? this.addBlanksFilter() : '';
    }
  }

  addBlanksFilter(): void {
    this.validFilters.splice(0, 0, {
      value: undefined,
      labelId: '(Blanks)',
      checked: true,
    })
  }

}
