import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EEAOutputProjectTier1ukPecDistanceColdef {

  constructor() { }

  public getColumnsDefinition(): any {
    return [
      {
        headerName: "Run ID",
        field: "runID",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true,
        pinned: true
      },
      {
        headerName: "Application Scheme",
        field: "applicationScheme",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        editable: false,
        sortable: true
      },
      {
        headerName: "Crop",
        field: "modelCrop",
        width: 150,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "Endpoints Data Set",
        field: "endpointsDataSet",
        width: 250,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "Compound",
        field: "compound",
        width: 200,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "Distance \n (m)",
        field: "distance",
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "Drift rate \n (%)",
        field: "driftRate",
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "PECsw ini \n (µg/L)",
        field: "PECsw",
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        editable: false,
        resizable: true,
        sortable: true
      },
      {
        headerName: "PECsed \n (ug/kg)",
        field: "PECsed",
        width: 120,
        filter: 'agSetColumnFilter',
        wrapText: false,
        resizable: true,
        editable: false,
        sortable: true
      }
    ];
  }
}
