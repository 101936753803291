import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, map, take } from 'rxjs';
import { GridTransaction } from 'src/app/shared/components/grid/grid-transaction';
import { HHRAApplicationScheme, HHRAApplicationSchemeExposure, HHRAApplicationSchemeExposureScenario } from 'src/app/shared/models/hhraApplicationSchemes';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HhraGapApiService extends HttpApiService {

  override apiUrl: string = environment.BAMS_Api_URL;

  private hhraApiPrefix: string = 'api/hhra/gap';

  private exposureScenarios: HHRAApplicationSchemeExposureScenario[] = [];

  saveHumanHealthRiskAssessmentApplicationSchemes(dataTransaction: GridTransaction[]): Observable<boolean> {
    const body = JSON.stringify(dataTransaction);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `${this.hhraApiPrefix}/riskAssessmentApplicationSchemes`;
    return this.put<boolean>(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  saveHumanHealthRiskAssessmentApplicationSchemeExposure(dataTransaction: GridTransaction[]): Observable<boolean> {
    const body = JSON.stringify(dataTransaction);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    let endpoint = `${this.hhraApiPrefix}/riskAssessmentApplicationSchemeExposure`;
    return this.put<boolean>(endpoint, body, headers).pipe(map((data: any) => data.Value));
  }

  getHumanHealthApplicationSchemesByProjectAndCompartment(pProjectPk: number, pCompartmentPk: number): Observable<HHRAApplicationScheme[]> {
    return this
      .get<HHRAApplicationScheme[]>(`${this.hhraApiPrefix}/applicationSchemesByProjectAndCompartment/${pProjectPk}/${pCompartmentPk}`)
      .pipe(map((data: any) => data.Value));
  }

  getHumanHealthApplicationSchemeExposureByProjectAndCompartment(pProjectPk: number, pCompartmentPk: number): Observable<HHRAApplicationSchemeExposure[]> {
    return this
      .get<HHRAApplicationSchemeExposure[]>(`${this.hhraApiPrefix}/applicationSchemeExposureByProjectAndCompartment/${pProjectPk}/${pCompartmentPk}`)
      .pipe(map((data: any) => data.Value));
  }

  async getHumanHealthApplicationSchemeExposureScenarios() {
    if (this.exposureScenarios.length == 0)
      this.exposureScenarios = await firstValueFrom(this.get<HHRAApplicationSchemeExposureScenario[]>(`${this.hhraApiPrefix}/applicationSchemeExposureScenarios`).pipe(map((data: any) => data.Value)));
  }

  async getUniqueExposureScenarioValue(field: string) {
    if (this.exposureScenarios.length == 0)
      await this.getHumanHealthApplicationSchemeExposureScenarios();

    const mappedValues = this.exposureScenarios.map(scenario => scenario[field as keyof typeof scenario]);
    const uniqueValues = [...new Set(mappedValues)];
    const sortedUniqueValues = Array.from(uniqueValues).sort();
    return sortedUniqueValues.map(value => ({ label: value, value: value }));
  }

  async getFilteredExposureScenarioValue(params: any): Promise<HHRAApplicationSchemeExposureScenario[]> {
    if (this.exposureScenarios.length == 0)
      await this.getHumanHealthApplicationSchemeExposureScenarios();
    const row = params.row;
    const linkFields = params.params.link;

    let filteredScenarios = [...this.exposureScenarios];
    linkFields.forEach((field: any) => {
      const value = row[field];
      if (value !== undefined && value !== null && value != '') {
      filteredScenarios = filteredScenarios.filter(scenario =>
        String(scenario[field as keyof typeof scenario]) == String(value)
      );
    }
    });
    return filteredScenarios;
  }
}
