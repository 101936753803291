import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  ECHOexternalPageURL: string = `${environment.ECHO_Api_URL}Country`;
  GAPexternalPageURL: string = `${environment.GAPURI}ApplicationSchemes`;

  constructor() { }

  ngOnInit(): void {
  }
}
