import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Constants } from 'src/app/shared/utils/constants';
import { AdminGridComponent } from '../../admin-grid/admin-grid.component';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { ApplicationMethodMatchingService } from './application-method.matching.service';
import { ApplicationMethodMatching } from 'src/app/shared/models/application-method-matching';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { UserLogicService } from 'src/app/shared/services/user.logic.service';

@Component({
  selector: 'app-application-method-matching-grid',
  templateUrl: '../../admin-grid/admin-grid.component.html',
})

export class ApplicationMethodMatchingGridComponent extends AdminGridComponent implements OnInit {
  @Input() parentComponent: any;
  gapApplicationMethods: DatafieldValue[] = [];
  modelApplicationMethods: DatafieldValue[] = [];
  modelChemicalApplicationMethods: DatafieldValue[] = [];

  dataToSave: any;
  invalidColumns: string[] = [];
  requiredColumns = [
    {
      field: 'gapApplicationMethods',
      headerName: 'GAP Application Method',
    },
    {
      field: 'modelApplicationMethods',
      headerName: 'Model Application Method',
    },
    {
      field: 'models',
      headerName: 'Model',
    },
  ];

  constructor(private applicationMethodMatchingService: ApplicationMethodMatchingService,
              private userService: UserLogicService) {
    super();
  }

  override async ngOnInit(): Promise<void> {
    this.currentUserCanEdit = await this.userService.currentUserCanEditAdmin();
    this.rowHeight = 190;
    this.context = { componentParent: this };
    this.batchUpload = true;
  }

  override onGridReady(params: any): void {
    params.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.configureGrid();
  }
  
  configureGrid() {
    this.modelChemicalApplicationMethods =  this.parentComponent.datafieldValues.filter((x: any) => x.datafieldPk == this.parentComponent.datafields.filter((df: any) => df.value.toLowerCase() === Constants.DATA_VALUES_NAMES.modelChemicalApplicationMethods)[0].datafieldPk);
    this.gapApplicationMethods = this.parentComponent.datafieldValues.filter((x: any) => x.datafieldPk == this.parentComponent.datafields.filter((df: any) => df.value.toLowerCase() === Constants.DATA_VALUES_NAMES.applicationMethod)[0].datafieldPk);
    this.modelApplicationMethods = this.parentComponent.datafieldValues.filter((x: any) => x.datafieldPk == this.parentComponent.datafields.filter((df: any) => df.value.toLowerCase() === Constants.DATA_VALUES_NAMES.modelApplicationMethod)[0].datafieldPk);
    this.rowData = this.fillingGeographyMatchingsLists(this.parentComponent.applicationMethodMatchings);
    this.getNamesGeographyMatchingLists();
    this.columnDefs = [      
      {
        headerName: 'GAP Application Method',
        field: 'gapApplicationMethodNames',
        minWidth: 300,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.gapApplicationMethods,
          optionValue: 'datafieldValuePk',
          optionLabel: 'value',
          columnThatModifies: 'gapApplicationMethods',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
      },      
      {
        headerName: 'Model Application Method',
        field: 'modelApplicationMethodNames',
        minWidth: 300,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.modelApplicationMethods,
          optionValue: 'datafieldValuePk',
          optionLabel: 'value',
          columnThatModifies: 'modelApplicationMethods',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Model Chemical Application Method',
        field: 'modelChemicalApplicationMethodNames',
        minWidth: 300,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.modelChemicalApplicationMethods,
          optionValue: 'datafieldValuePk',
          optionLabel: 'value',
          columnThatModifies: 'modelChemicalApplicationMethods',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter'
      },
      {
        headerName: 'Model',
        field: 'modelsNames',
        minWidth: 300,
        type: 'select',
        editable: false,
        cellRenderer: 'listboxRenderer',
        cellRendererParams: {
          options: this.parentComponent.models,
          optionValue: 'modelPk',
          optionLabel: 'name',
          columnThatModifies: 'models',
          editable: this.currentUserCanEdit,
        },
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'action',
        width: 125,
        minWidth: 125,
        maxWidth: 125,
        editable: false,
        cellRenderer: 'deleteButtonRenderer',
        cellRendererParams: {
          editable: this.currentUserCanEdit,
        },
        type: 'rightAligned',
      },
    ];
  } 
  
  getNamesGeographyMatchingLists() {
    this.rowData.forEach((row: { modelChemicalApplicationMethodNames: string[]; modelChemicalApplicationMethods: DatafieldValue[]; gapApplicationMethodNames: string[]; gapApplicationMethods: DatafieldValue[];
                                 modelApplicationMethodNames: string[]; modelApplicationMethods: DatafieldValue[]; modelsNames: string[]; models: ModelLibrary[]; }) => {            
                                  row.modelChemicalApplicationMethodNames = row.modelChemicalApplicationMethods.filter(x => x !== undefined).map(x => x!.value as string);
                                  row.gapApplicationMethodNames = row.gapApplicationMethods.filter(x => x !== undefined).map(x => x!.value as string);
                                  row.modelApplicationMethodNames = row.modelApplicationMethods.filter(x => x !== undefined).map(x => x!.value as string);
      row.modelsNames = row.models.map(x => x.name);
    });
  }
  
  override getData(): void {
    this.parentComponent.getData();
  }
  
  fillingGeographyMatchingsLists(pApplicationMethodMatching: ApplicationMethodMatching[]): any {
    pApplicationMethodMatching.forEach((applicationMethodMatching: ApplicationMethodMatching) => {
      const modelChemicalApplicationMethodsPks = applicationMethodMatching.modelChemicalApplicationMethods.map((x) => x.datafieldValuePk).flat();
      applicationMethodMatching.modelChemicalApplicationMethods = this.modelChemicalApplicationMethods.filter((g) => modelChemicalApplicationMethodsPks.includes(g.datafieldValuePk));
      const gapApplicationMethodsPks = applicationMethodMatching.gapApplicationMethods.map((x) => x.datafieldValuePk).flat();
      applicationMethodMatching.gapApplicationMethods = this.gapApplicationMethods.filter((g) => gapApplicationMethodsPks.includes(g.datafieldValuePk));
      const modelApplicationMethodsPks = applicationMethodMatching.modelApplicationMethods.map((x) => x.datafieldValuePk).flat();
      applicationMethodMatching.modelApplicationMethods = this.modelApplicationMethods.filter((g) => modelApplicationMethodsPks.includes(g.datafieldValuePk));
      const modelsPks = applicationMethodMatching.models.map((x) => x.modelPk).flat();
      applicationMethodMatching.models = this.parentComponent.models.filter((g: any) => modelsPks.includes(g.modelPk));
    });
    return pApplicationMethodMatching;
  }
  
  public setSelectionValues(pValues: any, pField: string, pRow: any, pId: number) {
    let oldvalue = pRow[pField];
    pRow[pField] = pValues;
    pRow.disciplinePk = this.parentComponent.disciplinePk;
    this.CreateTransaction(pRow.id, pId, oldvalue, pRow);
  }
  
    override saveData(pData: any) {
    this.dataToSave = pData;    
    if (this.dataRequiredColumnsValidation()) {
      return this.applicationMethodMatchingService.saveData(pData);
    } 
    else{
      this.isSaving = false;
      Swal.fire({
        title: Constants.requiredColumnsElement.title,
        text: Constants.requiredColumnsElement.content + this.invalidColumns.join(', '),
        confirmButtonColor: '#0069be',
        confirmButtonText: 'Ok',
        icon: 'warning',
      });
      return pData;
    }
  }
  
  dataRequiredColumnsValidation(): boolean {
    let isValid = true;
    this.invalidColumns = [];
    this.dataToSave.forEach((transaction: any) => {
      this.requiredColumns.forEach((column) => {
        if (!transaction.row[column.field]){
          isValid = false;
          if (!this.invalidColumns.includes(column.headerName)) {
            this.invalidColumns.push(column.headerName);
          }
        }
      });
    });
    return isValid;
  }

}
