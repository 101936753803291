import { Injectable } from '@angular/core';
import { firstValueFrom, forkJoin, take } from 'rxjs';
import { ApplicationDateGroundWater, ApplicationDateSurfaceWater } from 'src/app/shared/models/application-date';
import { ApplicationMethodMatching } from 'src/app/shared/models/application-method-matching';
import { ApplicationScheme } from 'src/app/shared/models/application-scheme';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { CropGrowthStages } from 'src/app/shared/models/crop';
import { CropInterception } from 'src/app/shared/models/crop-interception';
import { CropMatching } from 'src/app/shared/models/crop-matching';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { DatafieldValue } from 'src/app/shared/models/echo/data-field-value';
import { Formulation } from 'src/app/shared/models/formulation';
import { List } from 'src/app/shared/models/list';
import { ModelLibrary } from 'src/app/shared/models/model-library';
import { Project } from 'src/app/shared/models/project';
import { Run } from 'src/app/shared/models/run/run';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import { ExposureAssessmentOutputViewApiService } from '../eea-output/eea-output.api.service';
import { GapApiService } from 'src/app/shared/services/gap.api.service';
import { ActiveIngredientApiService } from 'src/app/shared/services/echo/active-ingredient.api.service';
import { RunApiService } from 'src/app/shared/services/run.api.service';
import { ListManagerApiService } from 'src/app/shared/services/list-manager.api.service';
import { EndpointApiService } from 'src/app/shared/services/echo/endpoint.api.service';
import { Endpoint } from 'src/app/shared/models/endpoint';
import { DatafieldValueApiService } from 'src/app/shared/services/echo/data-field-value.api.service';
import { Datafield } from 'src/app/shared/models/echo/data-field';
import { Gap } from 'src/app/shared/models/gap';
import {EeaOutputProjectPwcSumarryResultApiService} from "../eea-output-project/eea-output-project-pwc/eaa-output-project-pwc-result-summmary/eea-output-project-pwc-sumarry-result-api.service";
import ResultSummaryPWC from "./eea-output-project-pwc/eaa-output-project-pwc-result-summmary/eea-output-pwc-result-summary-interfacePWC";
import { GeographyApiService } from 'src/app/shared/services/echo/geography.api.service';
import { EEAOutputProjectInputsApiService } from '../../../shared/services/eea-output-project-inputs.api.service';
import { FormulationApiService } from 'src/app/shared/services/echo/formulation.api.service';

@Injectable({
  providedIn: 'root'
})

export class EEAOutputProjectInputsLogicService {
  substanceCatalog: Catalog[] = [];
  applicationSchemesByFormulationPk: ApplicationScheme[] = [];
  compartments?: DatafieldValue[];
  crops: List[] = [];
  dataSets?: DataSet[];
  modelCrops?: DatafieldValue[];
  runs: Run[] = [];
  endpointsObtained: boolean = false;
  cropMatchings?: CropMatching[];
  applicationMethods?: DatafieldValue[];
  modelApplicationMethods?: DatafieldValue[];
  chemicalApplicationMethod?: DatafieldValue[];
  rotation?: DatafieldValue[];
  cropGrowthStagesByPk: CropGrowthStages[] = [];
  datafieldValues: DatafieldValue[] = [];
  applicationMethodMatchings?: ApplicationMethodMatching[];
  cropInterceptions?: CropInterception[];
  applicationDatesGroundWater?: ApplicationDateGroundWater[];
  applicationDatesSurfaceWater?: ApplicationDateSurfaceWater[];
  models: ModelLibrary[] = [];
  loading: boolean = false;
  projectPk: number = 0;
  formulation?: Formulation;
  pwcInfo:ResultSummaryPWC[]=[];
  step1n2:any[]=[];

  constructor(
    private listManagerService: ListManagerApiService,
    private runService: RunApiService,
    private activeIngredientService: ActiveIngredientApiService,
    private gapService: GapApiService,
    private viewOutputService: ExposureAssessmentOutputViewApiService,
    private endpointService: EndpointApiService,
    private datafieldValueService: DatafieldValueApiService,
    private pwcService : EeaOutputProjectPwcSumarryResultApiService,
    private geographyApiService: GeographyApiService,
    private inputsUsedInCalculationsService: EEAOutputProjectInputsApiService,
    private formulationService: FormulationApiService
  ) {}

  setLoadingState(loading:boolean){
    this.loading = loading;
  }

  ngOnDestroy(){
    this.setLoadingState(false);
  }

  async getData({ formulationPk, projectPk }: Project): Promise<void> {
    this.setLoadingState(true);
    this.projectPk = projectPk;
    this.getDatafieldValues();
    const commodityList = Constants.listManagerLists[0];
    const tagsBBCH: string[] = ['Secondary Stage'];
    this.dataSets = this.viewOutputService.dataSetList;

    if(formulationPk){
      await firstValueFrom(this.formulationService.getFormulationByPk(formulationPk, true)).then((formulation: Formulation) => {
        this.formulation = formulation;
      });
    }
    
    this.applicationMethodMatchings =
      this.viewOutputService.applicationMethodMatchings;
    this.applicationDatesSurfaceWater =
      this.viewOutputService.applicationDatesSurfaceWater;
    this.applicationDatesGroundWater =
      this.viewOutputService.applicationDatesGroundWater;
    this.cropInterceptions = this.viewOutputService.cropInterceptions;
    this.cropMatchings = this.viewOutputService.cropMatchings;
    this.crops = this.viewOutputService.crops;
    this.models = this.viewOutputService.models;

    forkJoin([
      this.gapService.getGapByApplicationSchemes({
        formulationPK: formulationPk,
      }),
      this.activeIngredientService.getSubstancesCatalog(),
    ])
      .pipe(take(1))
      .subscribe({
        next: ([gapAppSche, substanceCatalog]) => {
          this.substanceCatalog = substanceCatalog;
          this.applicationSchemesByFormulationPk = Utils.sortObject(
            gapAppSche,
            'description'
          );
          var pCrops = this.crops.filter((x) =>
            this.applicationSchemesByFormulationPk
              .map((x) => x.cropPk)
              .flat()
              .includes(x.id_list)
          );

          this.runService
            .getRunsByProjectPk(projectPk)
            .pipe(take(1))
            .subscribe({
              next: (runs: Run[]) => {
                this.setRowValues(projectPk, runs);

                if (runs.length > 0 && runs[0]?.gapXRun !== null) {
                  this.getSavedRunsEndpoints(runs);
                } else {
                  this.getRunsEndpoints(runs);
                }
                if (pCrops.length > 0) {
                  pCrops.forEach((crop) => {
                    this.listManagerService
                      .getGrowthStageMatchByCrop(
                        commodityList.comodities.listLevel1,
                        commodityList.comodities.listLevel2,
                        commodityList.comodities.listLevel3,
                        commodityList.comodities.mappingValueTest,
                        crop.item_name,
                        tagsBBCH
                      )
                      .subscribe({
                        next: (cropList: List[]) => {
                          let growthStages = Utils.sortObject(
                            cropList,
                            'data_point'
                          );
                          this.cropGrowthStagesByPk.push({
                            cropPk: crop.id_list,
                            growthStages,
                          });
                          this.formatData(runs, projectPk);
                          this.runs = runs;
                          if(runs[0].model?.name==='PWC_SW')  this.getPwcInformation(projectPk, runs);
                          this.loading = false;
                        },
                      });
                  });
                }
              },
            });
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
  }

  async setDataSetp1_n_2({ formulationPk, projectPk }: Project){
    this.loading = true;
    await this.viewOutputService.getApplicationSchemes(formulationPk);
    await this.viewOutputService.getAllFields();
    if(projectPk){
      this.inputsUsedInCalculationsService.getInputByProjectPk(projectPk).pipe(take(1)).subscribe((data: any) => {
        this.step1n2=data;
        this.step1n2 = this.viewOutputService.transformData(this.step1n2);
        this.loading = false;
      });
    }
  }


  formatData(runs: any,projectPk:any) {
    runs.map((run : any) => {
      if (run.substanceType) {
        if (run.substanceType === 'Metabolite') {
          run.formationFraction = run.fractionInSoil;
          const metabolite = run.metabolites.find(
            (meta : any) => meta.substanceName === run.substanceName
          );
          if (run.komString) {
            run.komString = Math.round(run.komString * 1000000) / 1000000;
          }
          if (metabolite) {
            run.exponent = metabolite.exponent;
            run.halfLifeInSoil = metabolite.halfLifeInSoil;
          }
        }
      }

      if (run.rotationPk) {
        run.rotationName = this.datafieldValues.find(
          (value) => value.datafieldValuePk === run.rotationPk
        )?.value;

      }
      if (run.gapXRun.firstApplicationDate !== null) {
        if (run.gapXRun.firstApplicationDate.length > 11) {
          run.gapXRun.firstApplicationDate = Utils.formatDashedStringDate(
            run.gapXRun.firstApplicationDate
          );
        }
      }
      if (run.gapXRun.beginningDate !== null) {
        if (run.gapXRun.beginningDate.length > 11) {
          run.gapXRun.beginningDate = Utils.formatDashedStringDate(
            run.gapXRun.beginningDate
          );
        }
      }
      if (run.gapXRun.endDate !== null) {
        if (run.gapXRun.endDate.length > 11) {
          run.gapXRun.endDate = Utils.formatDashedStringDate(
            run.gapXRun.endDate
          );
        }
      }
      if (run.endpointXRun.kom !== null) {
        run.endpointXRun.kom = Math.round(run.endpointXRun.kom * 10000) / 10000;
      }
    });
  }

  async getPwcInformation(projectPk: number, run: any): Promise<void> {
    this.loading = true;
    var RegionsCatalog:any=await this.geographyApiService.getRegionWithCountries().pipe(take(1)).toPromise();
    if (this.pwcInfo.length === 0) {
      try {
        const result: any = await this.pwcService.getSummariesResult(projectPk).pipe(take(1)).toPromise();
        this.pwcInfo = result.Value;
        this.pwcInfo.forEach((x: ResultSummaryPWC) => {
          var runInfo = this.runs.find(run => run.id === x.RunID);
          if (runInfo !== null) {
            var aplicationNumber: number = parseInt(x.ApplicationScheme??"0", 10);
            var dataSetNumber: number = parseInt(x.EndPointsDataSet??"0", 10);
            var regionArray=RegionsCatalog.filter((region:any)=> x.RegionsPk.includes(region.regionPk) ); 
            x.ApplicationScheme = this.applicationSchemesByFormulationPk.find(application => application.applicationSchemePk===aplicationNumber)?.description;
            x.Crop = runInfo?.gapXRun?.cropDrain;
            x.EndPointsDataSet=this.dataSets?.find(data => data.dataSetPk===dataSetNumber)?.description;
            x.SimulationType=runInfo?.simulationType;
            x.Compound=runInfo?.activeIngredient;
            x.Region=regionArray.length>0?regionArray.map((region:any) => region.name).join(', '):'';
          }
        });
        this.loading = false;
      } catch (error) {
        console.error('Error getting the information:', error);
        this.loading = false;
        throw error;
      }
    } else {
      this.loading = false;
    }
  }
  
  getRunsEndpoints(runs: Run[], pIsOnCreate: boolean = false) {
    this.loading = true;
    this.endpointService
      .getRunEndpoints({
        activeIngredientPks: runs
          ?.map((x) => x.activeIngredientPk)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index),
        dataSetPks: runs
          ?.map((x) => x.dataSetPk)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index),
      })
      .pipe(take(1))
      .subscribe({
        next: (endpoints: Endpoint[]) => {
          this.loading = false;
          this.setEndpointsToRuns(endpoints, pIsOnCreate, runs);
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
  }

  setEndpointsToRuns(
    pEndpoints: Endpoint[],
    pIsOnCreate: boolean = false,
    runs: Run[]
  ) {
    runs.forEach((row: { [index: string | symbol | number]: any }) => {
      const endpoint = pEndpoints.find(
        (endpoint) =>
          endpoint.dataSetPk == row['dataSetPk'] &&
          endpoint.moleculePk == row['activeIngredientPk'] &&
          endpoint.groupedEndpointFieldValues &&
          endpoint.groupedEndpointFieldValues.length > 0
      );
      if (endpoint) {
        row['molecularWeight'] = endpoint.molecularWeight;
        row['exponent'] = endpoint.exponent;
        row['substanceName'] = endpoint.substanceName;
        row['groupedEndpointFieldValues'] = endpoint.groupedEndpointFieldValues;
        row['metabolites'] = pIsOnCreate
          ? this.processMetabolites(endpoint.metabolites)
          : this.getMetabolitesFromEndpoints(row, endpoint.metabolites);
        row['precursors'] = endpoint.precursors;
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((surfaceWaterEndpoint) => {
          const value = endpoint.groupedEndpointFieldValues.find(
            (x) => x.key?.toLowerCase() == surfaceWaterEndpoint.name
          )?.value;
          if (value || value === 0) row[surfaceWaterEndpoint.field] = value;
          if (surfaceWaterEndpoint.field == Constants.DATA_VALUES_NAMES.Kfoc) {
            if (row['kfoc']) row['kom'] = Utils.getKomValue(row['kfoc']);
          }
        });
      }
    });
  }


  setSubstanceNameToMetabolite(metabolites: Endpoint[]): Endpoint[] {
    metabolites.forEach((metabolite) => {
      if (metabolite.metabolitePk !== null) {

        metabolite.substanceName = this.substanceCatalog.filter(
          (x) =>
            x.source === Constants.SUBSTANCE_TYPES.METABOLITE &&
            x.key === metabolite.metabolitePk
        )[0].name;
      };
    });
    return metabolites;
  }

  processMetabolites(pData: any[]): any[] {
    pData.forEach((row) => {
      Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
        if (
          row.groupedEndpointFieldValues.filter(
            (x: any) => x.key.toLowerCase() == endpointValue.name
          )[0]?.value
        ) {
          row[endpointValue.field] = row.groupedEndpointFieldValues.filter(
            (x: any) => x.key.toLowerCase() == endpointValue.name
          )[0]?.value;
        }
        if (endpointValue.field == Constants.DATA_VALUES_NAMES.Kfoc)
          row.kom = Utils.getKomValue(row.kfoc);
      });
      row.precursors.forEach(
        (precursor: {
          substanceNamePK: any;
          metabolitePk: any;
          moleculePk: any;
        }) => {
          precursor.substanceNamePK = precursor.metabolitePk
            ? precursor.metabolitePk
            : precursor.moleculePk;
        }
      );
    });
    return pData;
  }

  setEndpointsToSavedRuns(pEndpoints: Endpoint[], runs: Run[]) {
    runs.forEach((row) => {
      const endpoint = pEndpoints.find(
        (endpoint) =>
          endpoint.dataSetPk == row.dataSetPk &&
          endpoint.moleculePk == row.activeIngredientPk &&
          endpoint.groupedEndpointFieldValues &&
          endpoint.groupedEndpointFieldValues.length > 0
      );
      if (endpoint) {
        row.molecularWeight = row.endpointXRun?.molecularWeight;
        row.exponent = row.endpointXRun?.exponent;
        row.substanceName = endpoint.substanceName;
        row.precursors = row.endpointXRun?.precursors;
        row.kom = row.endpointXRun?.kom;
        row.groupedEndpointFieldValues = endpoint.groupedEndpointFieldValues;
        Constants.PEC_SURFACEWATER_ENDPOINTS.forEach((endpointValue) => {
          let field = endpointValue.field as string;
          const value = endpoint.groupedEndpointFieldValues.find(
            (x) => x.key?.toLowerCase() == endpointValue.name
          )?.value;
          if (row.endpointXRun) {
            const temp = row.endpointXRun as any;
            row = { ...row, [field]: temp[field] };
          }
          if (value || value === 0) row = { ...row, [field]: value };
        });
        if(row.metabolites){
          row.metabolites = this.setSubstanceNameToMetabolite(row.metabolites);
          if (row.metabolites?.length > 0) {
            const metaboliteRows = this.addMetaboliteRows(row);
            if (metaboliteRows.length > 0) {
              runs.push(...metaboliteRows);
              this.formatData(runs,this.projectPk);
              this.runs = runs;

            }
          }
        }
      }
    });
  }

  getSavedRunsEndpoints(runs: Run[]) {
    this.loading = true;
    this.endpointsObtained = false;
    this.endpointService
      .getRunEndpoints({
        activeIngredientPks: runs
          ?.map((x) => x.activeIngredientPk)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index),
        dataSetPks: runs
          ?.map((x) => x.dataSetPk)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index),
      })
      .subscribe({
        next: (endpoints: Endpoint[]) => {
          this.setEndpointsToSavedRuns(endpoints, runs);
          this.endpointsObtained = true;
        },
        error: (error) => {
          console.error(error);
          this.endpointsObtained = true;
          this.loading = false;
        },
      });
  }

  getDatafieldValues(): void {
  let subscription =  this.datafieldValueService.getDatafieldValueInformation()
      .subscribe({
        next: (datafieldValues: DatafieldValue[]) => {
          this.setLoadingState(true);
          this.datafieldValues = datafieldValues;
          this.loadSpecificDatafield(Constants.DATA_VALUES_NAMES.compartment);
          this.loadSpecificDatafield(Constants.DATA_VALUES_NAMES.modelCrop);
          this.loadSpecificDatafield(Constants.DATA_VALUES_NAMES.applicationMethod);
          this.loadSpecificDatafield(Constants.DATA_VALUES_NAMES.modelApplicationMethod);
          this.loadSpecificDatafield(Constants.DATA_VALUES_NAMES.modelChemicalApplicationMethods);
          this.loadSpecificDatafield(Constants.DATA_VALUES_NAMES.rotation);
          this.setLoadingState(false);
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
        complete: ()=> {
          subscription.unsubscribe()
        }
      });
  }

  private loadSpecificDatafield(dataValueName: string): void {
    let subscription = this.datafieldValueService.getDatafieldData(Constants.THIS_APPLICATION)
      .subscribe({
        next: (datafields: Datafield[]) => {
          const datafieldPk = this.getDatafieldPk(dataValueName, datafields);
          (this as any)[dataValueName] = this.datafieldValues.filter(
            (x:any) => x.datafieldPk === datafieldPk
          );
        },
        complete:(()=>{
            subscription.unsubscribe
        })
      });
  }

  private getDatafieldPk(dataValueName: string, datafields: Datafield[]): number {
    const datafieldValue = datafields.find(
      (df) => df.value.toLowerCase() === (Constants.DATA_VALUES_NAMES as any)[dataValueName]
    );
    return datafieldValue ? datafieldValue.datafieldPk : 0;
  }

  setRowValues(projectPk: number, runs: Run[]): void {
    runs.forEach((row) => {
      row.modelCrop = this.modelCrops?.find(
        (x) => x.datafieldValuePk === row.modelCropPk
      )?.value;
      row.applicationScheme = this.applicationSchemesByFormulationPk?.find(
        (x) => x.applicationSchemePk === row.applicationSchemePk
      )?.description;
      row.applicationSchemeData = this.applicationSchemesByFormulationPk?.find(
        (x) => x.applicationSchemePk === row.applicationSchemePk
      );
      //this value assignment is going to be worked by Toucans
      // row.dataSet = this.dataSets?.find(
      //   (x) => x.dataSetPk === row.dataSetPk
      // )?.description;
      row.compartment = this.compartments?.find(
        (x) => x.datafieldValuePk === row.compartmentPk
      )?.value;
      row.model = this.models?.find((x) => x.modelPk === row.model?.modelPk);
      row.activeIngredientPk = this.dataSets?.find(
        (x) => x.dataSetPk === row.dataSetPk
      )?.moleculePk;
      row.activeIngredient = this.formulation?.activeIngredients?.find((x) => x.activeIngredientPk === row.activeIngredientPk)?.activeIngredientName;
      row.projectPk = projectPk; //Ref.H03
      row.soilDepthIncorporated = 4;
      row.kfoc = row.endpointXRun?.kfoc;
      row.kom = row.endpointXRun?.kom;
      row.coefficientForUptakeByPlant = row.endpointXRun?.coefficientForUptakeByPlant??0;
      row.halfLifeinSediment = row.endpointXRun?.halfLifeinSediment??0;
      row.halfLifeInSedimentWaterSystem = row.endpointXRun?.halfLifeInSedimentWaterSystem;
      row.halfLifeInSoil = row.endpointXRun?.halfLifeInSoil;
      row.halfLifeInWater = row.endpointXRun?.halfLifeInWater;
      row.maximumOccurrenceInSoil = row.endpointXRun?.maximumOccurrenceInSoil;
      row.maximumOccurrenceInWaterSediment = row.endpointXRun?.maximumOccurrenceInWaterSediment;
      row.saturatedVapourPressure = row.endpointXRun?.saturatedVapourPressure;
      row.solubilityInWater = row.endpointXRun?.solubilityInWater;
      row.molecularWeight = row.endpointXRun?.molecularWeight;
      row.substanceType = row.endpointXRun?.substanceType;
      row.measuredAtSediment = row.endpointXRun?.measuredAtSediment;
      row.measuredAtSoil = row.endpointXRun?.measuredAtSoil;
      row.measuredAtWater = row.endpointXRun?.measuredAtWater;
      row.aqueousPhotolysisHalfLife = row.endpointXRun?.aqueousPhotolysisHalfLife;
      row.photolysisReferenceLatitude = row.endpointXRun?.photolysisReferenceLatitude;
      row.hydrolysisHalflifeDay = row.endpointXRun?.hydrolysisHalflifeDay;
      row.foliarHalflife = row.endpointXRun?.foliarHalflife;
      row.henrysConstant = row.endpointXRun?.henrysConstant;
      row.airDiffusionCoefficient = row.endpointXRun?.airDiffusionCoefficient;
      row.heatOfHenry = row.endpointXRun?.heatOfHenry;
      row.fne = row.endpointXRun?.fne;
      row.kdes = row.endpointXRun?.kdes;
      row.halfLifeInSedimentWaterSystem = row.endpointXRun?.halfLifeInSedimentWaterSystem;

      if (row.gapXRun !== null) {
        this.setSavedValuesForGap(row);
        this.setSavedApplication(row);
      } else {
        this.setValuesDependentOnGap(row);
      }
    });
  }

  setSavedApplication(pRow: any) {
    pRow.applicationNumber = pRow.applicationsXRun
      ? pRow.applicationsXRun.length
      : 0;
    if (pRow.gap) pRow.gaps[0].applicationDate = pRow.beginningDate;
    pRow.children = [];
    pRow.applicationsXRun?.forEach((application:any) => {
      pRow.children.push({
        applicationXRunPk: application.applicationXRunPk,
        applicationNumber: this.datafieldValues.find(
          (x) => x.datafieldValuePk == application.applicationNumberPk
        )?.value,
        minApplicationInterval: this.datafieldValues.find(
          (x) => x.datafieldValuePk == application.minApplicationIntervalPk
        )?.value,
        applicationNumberPk: application.applicationNumberPk,
        applicationIntervalMinPk: application.minApplicationIntervalPk,
        applicationActiveIngredientePK: application.activeIngredientPk,
        activeIngredient: this.formulation?.activeIngredients?.find(x => x.activeIngredientPk = application.activeIngredientPk)?.activeIngredientName,
        rate: application.rate,
        gapPk: application.gapPk,
        applicationDate: application.applicationDate,
        daysSince: application.daysSince,
        applicationMethod: application.applicationMethod,
        depth: application.depth,
        tBandSplit: application.tBandSplit,
        efficiency: application.efficiency,
        drift: application.drift,
      });
    });
  }

  setSoilDepthIncorporatedValue(pValue: any) {
    if (pValue === null || pValue === undefined) {
      return 4;
    }
    return pValue;
  }

  setSavedValuesForGap(pRow: any) {
    if (pRow.gapXRun) {
      pRow.rotationPk = pRow.gapXRun.rotationPk;
      pRow.soilDepthIncorporated = this.setSoilDepthIncorporatedValue(
        pRow.gapXRun.soilDepthIncorporated
      );
      pRow.minBBCH = pRow.gapXRun.earliestBbch;
      pRow.maxBBCH = pRow.gapXRun.latestBbch;
      pRow.firstApplicationDate = Utils.formatDashedStringDate(
        pRow.gapXRun.firstApplicationDate
      );
      pRow.nozzleDriftReduction10m = pRow.gapXRun.nozzleDriftReduction10m;
      pRow.nozzleDriftReduction20m = pRow.gapXRun.nozzleDriftReduction20m;
      pRow.dateType = pRow.gapXRun.dateType
        ? pRow.gapXRun.dateType
        : Constants.DATE_TYPE_VALUES.ABSOLUTE;
      pRow.cropEvent = pRow.gapXRun.cropEvent;
      pRow.cropCoverage = pRow.gapXRun.cropCoverage;
      pRow.cropInterception = pRow.gapXRun.cropInterception;
      pRow.cropDrain = pRow.gapXRun.cropDrain;
      pRow.gapApplicationMethodPk = pRow.gapXRun.gapApplicationMethodPk;
      pRow.applicationMethodPk = pRow.gapXRun.applicationMethodPk;
      pRow.applicationMethod = this.datafieldValues?.find(
        (x) => x.datafieldValuePk == pRow.applicationMethodPk
      )?.value;
      pRow.chemicalApplicationMethodPk =
        pRow.gapXRun.chemicalApplicationMethodPk;
      pRow.chemicalApplicationMethod = this.datafieldValues?.find(
        (x) => x.datafieldValuePk == pRow.chemicalApplicationMethodPk
      )?.value;
      pRow.formulation = pRow.gapXRun.formulation ?? this.formulation?.tradeName;
      pRow.specifyYears = pRow.gapXRun.specifyYears === 'true' ? true : false;
      pRow.simulationType = pRow.gapXRun.simulationType;
      pRow.applicationOccurance =
        pRow.gapXRun.applicationOccurance == null
          ? 1
          : pRow.gapXRun.applicationOccurance;
      pRow.applicationOccuranceFromYear =
        pRow.gapXRun.applicationOccuranceFromYear == null
          ? 1
          : pRow.gapXRun.applicationOccuranceFromYear;
      pRow.applicationOccuranceToYear =
        pRow.gapXRun.applicationOccuranceToYear == null
          ? 30
          : pRow.gapXRun.applicationOccuranceToYear;
      pRow.applyPesticideWindows = pRow.gapXRun.applyPesticideWindows;
      pRow.applyPesticideSteps = pRow.gapXRun.applyPesticideSteps;
    }
    this.setSavedApplicationMethods(pRow);
    this.updateApplicationDatesSurfaceWater(pRow);
    pRow.gaps = this.applicationSchemesByFormulationPk
      .filter((x) => x.applicationSchemePk == pRow.applicationSchemePk)
      .map((x) => x.gaps)
      .flat();
    if (pRow.gaps[0]) this.setRowApplicationData(pRow);
  }

  setRowApplicationData(pRun: any) {
    pRun.applicationNumber = pRun.gaps.length;
    pRun.children = [];
    pRun.gaps.forEach((gap:any) => {
      if (gap.activeIngredientRates) {
        const activeIngredientRates = gap.activeIngredientRates.filter(
          (x:any) => x.activeIngredientPk == pRun.activeIngredientPk
        );
        if (activeIngredientRates && activeIngredientRates.length > 0) {
          activeIngredientRates.forEach((air:any) => {
            this.addApplication(pRun, gap, air);
          });
        } else {
          this.addApplication(
            pRun,
            gap,
            this.formulation?.activeIngredients?.find((x) => x.activeIngredientPk == pRun.activeIngredientPk)
          );
        }
      }
    });
  }

  addApplication(pRow: any, pGap: any, pActiveIngredient: any) {
    if (!pActiveIngredient) return;

    const ai = this.formulation?.activeIngredients?.find(x => x.activeIngredientPk = pActiveIngredient.activeIngredientPk)?.activeIngredientName;
    pRow.children.push({
      applicationNumber: this.datafieldValues.find(
        (x) => x.datafieldValuePk == pGap.applicationNumberPk
      )?.value,
      minApplicationInterval: this.datafieldValues.find(
        (x) => x.datafieldValuePk == pGap.applicationIntervalMinPk
      )?.value,
      applicationNumberPk: pGap.applicationNumberPk,
      applicationIntervalMinPk: pGap.applicationIntervalMinPk,
      applicationActiveIngredientePK: pActiveIngredient.activeIngredientPk,
      activeIngredient: ai,
      rate: pActiveIngredient.max,
      gapPk: pGap.gapPk,
      applicationDate: pGap.firstApplicationDate,
    });
  }

  updateApplicationDatesSurfaceWater(pRow: any, pDynamic: boolean = false) {
    if (
      pRow.minBBCH &&
      pRow.modelCropXScenario &&
      pRow.modelCropXScenario.scenario
    ) {
      let applicationDateValues: any =
        this.applicationDatesSurfaceWater?.find(
          (x) =>
            x.bbch == parseInt(pRow.minBBCH, 10) &&
            x.location == pRow.modelCropXScenario.scenario.substring(0, 2) &&
            x.season == pRow.modelCropXScenario.season &&
            x.modelCropPk == pRow.modelCropPk
        );
      if (applicationDateValues) {
        const run = pRow?.gapXRun;
        const beginningDate = !Utils.isEmptyValue(run?.beginningDate)
          ? Utils.formatDashedStringDate(run.beginningDate)
          : Utils.formatDashedStringDate(applicationDateValues.beginWindow);
        const endDate = !Utils.isEmptyValue(run?.endDate)
          ? Utils.formatDashedStringDate(run.endDate)
          : Utils.formatDashedStringDate(
              Utils.getEndWindowTakingIntoAccountApplicationIntervals(
                applicationDateValues.endWindow,
                pRow
              )
            );
        pRow.beginningDate = pDynamic
          ? Utils.formatDashedStringDate(applicationDateValues.beginWindow)
          : beginningDate;
        pRow.endDate = pDynamic
          ? Utils.formatDashedStringDate(
              Utils.getEndWindowTakingIntoAccountApplicationIntervals(
                applicationDateValues.endWindow,
                pRow
              )
            )
          : endDate;
        pRow.emergenceDate = Utils.formatDashedStringDate(
          applicationDateValues.emergencyDate
        );
        pRow.harvestDate = Utils.formatDashedStringDate(
          applicationDateValues.harvestDate
        );
      }
    } else {
      const run = pRow?.gapXRun;
      const beginningDate = !Utils.isEmptyValue(run?.beginningDate)
        ? Utils.formatDashedStringDate(run?.beginningDate)
        : null;
      const endDate = !Utils.isEmptyValue(run?.endDate)
        ? Utils.formatDashedStringDate(run.endDate)
        : null;
      pRow.beginningDate = pDynamic
        ? Utils.formatDashedStringDate(pRow?.beginningDate)
        : beginningDate;
      pRow.endDate = pDynamic
        ? Utils.formatDashedStringDate(pRow?.endDate)
        : endDate;
    }
  }

  setSavedApplicationMethods(pRow: any) {
    pRow.gapApplicationMethodPk = pRow.gapXRun.gapApplicationMethodPk;
    pRow.applicationMethodPk = pRow.gapXRun.applicationMethodPk;
    pRow.applicationMethod = this.datafieldValues?.find(
      (x) => x.datafieldValuePk == pRow.applicationMethodPk
    )?.value;
    pRow.firstApplicationMethod = this.datafieldValues?.find(
      (x) => x.datafieldValuePk == pRow.applicationMethodPk
    )?.value;
    pRow.chemicalApplicationMethodPk = pRow.gapXRun.chemicalApplicationMethodPk;
    pRow.chemicalApplicationMethod = this.datafieldValues?.find(
      (x) => x.datafieldValuePk == pRow.chemicalApplicationMethodPk
    )?.value;
  }

  setValuesDependentOnGap(pRow: any) {
    pRow.gaps = this.applicationSchemesByFormulationPk
      .filter((x) => x.applicationSchemePk == pRow.applicationSchemePk)
      .map((x) => x.gaps)
      .flat();
    if (pRow.gaps[0]) {
      pRow.soilDepthIncorporated = this.setSoilDepthIncorporatedValue(
        pRow.gaps[0].soilDepthIncorporated
      );
      pRow.minBBCH = pRow.gaps[0].cropGrowthStageInit;
      pRow.maxBBCH = pRow.gaps[0].cropGrowthStageFinal;
      pRow.firstApplicationDate = Utils.formatDashedStringDate(
        pRow.gaps[0].firstApplicationDate
      );
      pRow.nozzleDriftReduction10m = pRow.gaps[0].nozzleDriftReduction10m;
      pRow.nozzleDriftReduction20m = pRow.gaps[0].nozzleDriftReduction20m;
      pRow.dateType = pRow.gaps[0].dateType
        ? pRow.gaps[0].dateType
        : 'Absolute';
      pRow.cropEvent = pRow.gaps[0].cropEvent;
      pRow.formulation = this.formulation?.tradeName;
      pRow.specifyYears = false;
      pRow.applicationOccurance = 1;
      pRow.applicationOccuranceFromYear = 1;
      pRow.applicationOccuranceToYear = 30;
      this.setApplicationMethods(pRow, pRow.gaps[0]);
      this.setCropInterception(pRow);
      this.setRowApplicationData(pRow);
      this.setApplicationDatesSurfaceWater(pRow);
      this.setApplicationDatesGroundWater(pRow);
    }
  }

  setApplicationDatesGroundWater(pRow: any) {
    if (
      pRow.gaps[0].cropGrowthStageInit &&
      pRow.modelCropXLocationGW &&
      pRow.modelCropXLocationGW.location
    ) {
      let applicationDateValues: any;
      if(this.applicationDatesGroundWater)
      applicationDateValues =
        this.applicationDatesGroundWater.find(
          (x) =>
            x.requestedBBCH == parseInt(pRow.gaps[0].cropGrowthStageInit, 10) &&
            x.location == pRow.modelCropXLocationGW.location &&
            x.season == pRow.modelCropXLocationGW.season &&
            x.modelCropPk == pRow.modelCropPk
        );
      if (applicationDateValues) {
        pRow.beginningDate = Utils.formatDashedStringDate(
          applicationDateValues.recAppDate
        );
      }
    }
  }

  setCropInterception(pRow: any) {
    var modelForCropInterception = this.getModelCropForInterceptionPk(pRow);
    var currentCropInterception = this.cropInterceptions?.find(
      (x) =>
        x.minBbch <= parseInt(pRow.minBBCH) &&
        x.maxBbch >= parseInt(pRow.minBBCH) &&
        x.modelCropPk == modelForCropInterception?.datafieldValuePk
    );
    pRow.cropInterception = currentCropInterception?.interceptionPercentage;
    pRow.cropCoverage = currentCropInterception?.stageName;
    pRow.cropDrain = modelForCropInterception?.value;
  }

  getModelCropForInterceptionPk(pRow:any): any {
    var cropMatchingElementsRequired = this.cropMatchings?.filter(
      (x) =>
        x.modelCrops
          .map((y) => y.datafieldValuePk)
          .flat()
          .includes(pRow.modelCropPk) &&
        x.models
          .map((y) => y.modelPk)
          .flat()
          .includes(pRow.model.modelPk)
    );
    var allModelCropPksInCropMatchingElement = cropMatchingElementsRequired
      ?.map((item) => item.modelCrops.map((x) => x.datafieldValuePk))
      .flat();
    var modelCropForInterception = this.datafieldValues.find(
      (x) =>
        allModelCropPksInCropMatchingElement?.includes(x.datafieldValuePk) &&
        !this.runModelCropIncludesUKCropGroupValue(x.value)
    );
    return modelCropForInterception;
  }
  
  runModelCropIncludesUKCropGroupValue(pModelCrop:any): boolean {
    return Object.values(Constants.UKCropGroups).some((value) =>
      value.includes(pModelCrop)
    );
  }

  setApplicationMethods(pRow: any, pFirstGap: Gap) {
    let allMatchingApplicationMethods: any =
      this.applicationMethodMatchings?.filter((x) =>
        x.gapApplicationMethods
          .map((x) => x.datafieldValuePk)
          .flat()
          .includes(pFirstGap.applicationMethodPk)
      );
    let matchingApplicationMethodsOnModel = allMatchingApplicationMethods?.find(
      (x:any) =>
        x.models
          .map((x:any) => x.modelPk)
          .flat()
          .includes(pRow.model.modelPk)
    );
    if (matchingApplicationMethodsOnModel) {
      let modelApplicationMethodPk =
        matchingApplicationMethodsOnModel.modelApplicationMethods[0]
          ?.datafieldValuePk;
      pRow.gapApplicationMethodPk = pFirstGap.applicationMethodPk;
      pRow.applicationMethod = this.datafieldValues?.find(
        (x) => x.datafieldValuePk == modelApplicationMethodPk
      )?.value;
      pRow.applicationMethodPk = modelApplicationMethodPk;
      pRow.firstApplicationMethod = this.datafieldValues?.find(
        (x) => x.datafieldValuePk == modelApplicationMethodPk
      )?.value;
      let chemicalApplicationMethodPk =
        matchingApplicationMethodsOnModel.modelChemicalApplicationMethods[0]
          ?.datafieldValuePk;
      pRow.chemicalApplicationMethod = this.datafieldValues?.find(
        (x) => x.datafieldValuePk == chemicalApplicationMethodPk
      )?.value;
      pRow.chemicalApplicationMethodPk = chemicalApplicationMethodPk;
    }
  }

  getMetabolitesFromEndpoints(pRow: any, pMetabolites: Endpoint[]): Endpoint[] {
    const metabolitesXRun = pRow.metabolitesXRun?.map((x: any) => x.endpointPk);
    return pMetabolites.filter((x) => metabolitesXRun.includes(x.endpointPk));
  }

  setApplicationDatesSurfaceWater(pRow: any) {
    if (
      pRow.gaps[0].cropGrowthStageInit &&
      pRow.modelCropXScenario &&
      pRow.modelCropXScenario.scenario
    ) {
      let applicationDateValues: any =
        this.applicationDatesSurfaceWater?.find(
          (x) =>
            x.bbch == parseInt(pRow.gaps[0].cropGrowthStageInit, 10) &&
            x.location == pRow.modelCropXScenario.scenario.substring(0, 2) &&
            x.season == pRow.modelCropXScenario.season &&
            x.modelCropPk == pRow.modelCropPk
        );
      if (applicationDateValues) {
        if (
          this.validCropGrowthStages(pRow, pRow.gaps[0].cropGrowthStageInit)
        ) {
          pRow.beginningDate = Utils.formatDashedStringDate(
            applicationDateValues.beginWindow
          );
          pRow.endDate = Utils.formatDashedStringDate(
            Utils.getEndWindowTakingIntoAccountApplicationIntervals(
              applicationDateValues.endWindow,
              pRow
            )
          );
        } else {
          this.updateApplicationDatesSurfaceWater(pRow, true);
        }
        pRow.emergenceDate = Utils.formatDashedStringDate(
          applicationDateValues.emergencyDate
        );
        pRow.harvestDate = Utils.formatDashedStringDate(
          applicationDateValues.harvestDate
        );
      }
    }
  }

  validCropGrowthStages(pRow: any, cropInit?: string): boolean {
    let cropGrowthStages = this.cropGrowthStagesByPk.find(
      (x) => x.cropPk === pRow?.applicationSchemeData?.cropPk
    )?.growthStages;
    return cropGrowthStages?.some((x) =>
      x.data_point?.includes(cropInit !== undefined ? cropInit : pRow.minBBCH)
    )??false;
  }

  addMetaboliteRows(run: Run): Run[] {
    let addedRows: Run[] = [];
    if (run.metabolites)
      run?.metabolites.forEach((metabolite: any) => {
        let newRow: Run = { ...run };
        newRow.saturatedVapourPressure=metabolite.saturatedVapourPressure;
        newRow.substanceName = metabolite.substanceName;
        newRow.precursormetabolite=this.SetFormationFraction(metabolite);
        newRow.solubilityInWater = metabolite.solubilityInWater;
        newRow.substanceType = metabolite.substanceType;
        newRow.molecularWeight = metabolite.molecularWeight;
        newRow.maximumOccurrenceInWaterSediment =
          metabolite.maximumOccurrenceInWaterSediment;
        newRow.kom = metabolite.kom;
        newRow.maximumOccurrenceInWaterSediment = metabolite.maximumOccurrenceInWaterSediment;
        newRow.maximumOccurrenceInSoil = metabolite.maximumOccurrenceInSoil;
        newRow.halfLifeInSedimentWaterSystem = metabolite.halfLifeInSedimentWaterSystem;
        newRow.saturatedVapourPressure = metabolite.saturatedVapourPressure;
        newRow.komString = metabolite.kom;
        newRow.kfoc = metabolite.kfoc;
        newRow.kdes = metabolite.kdes;
        newRow.halfLifeinSediment = metabolite.halfLifeinSediment;
        newRow.halfLifeInWater = metabolite.halfLifeInWater;
        newRow.halfLifeInSoil = metabolite.halfLifeInSoil;
        newRow.fne = metabolite.fne;
        newRow.halfLifeInSoil=metabolite.halfLifeInSoil;
        newRow.exponent=metabolite.exponent;
        newRow.airDiffusionCoefficient = metabolite.airDiffusionCoefficient;
        newRow.fractionInSediment = metabolite.precursors.length > 0
          ? metabolite.precursors.find(
            (x:any) => x.precursorType == 'fractionInSediment'
          )?.formationFraction
          : undefined;
        newRow.fractionInSurfaceWater = metabolite.precursors.length > 0
          ? metabolite.precursors.find(
            (x:any) => x.precursorType == 'fractionInSurfaceWater'
          )?.formationFraction
          : undefined;
        newRow.fractionInSoil = metabolite.precursors.length > 0
          ? metabolite.precursors.find(
            (x:any) => x.precursorType == 'fractionInSoil'
          )?.formationFraction
          : undefined;
        addedRows.push(newRow);
      });
    return addedRows;
  }

  SetFormationFraction(run: any): string {
    if (run.precursors.length > 0) {
      var substance = this.substanceCatalog.find(substance => run.precursors[0].substanceNamePK === substance.key);
      return substance?.name || "";
    }
    return "";
  }
}