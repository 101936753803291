import { Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { OptionalOutputs, OptionalOutputsByRow } from 'src/app/shared/models/pwc/optional-outputs';
import { EeaGapSurfacewaterPwcOptionalOutputsLogicService } from './eea-gap-surfacewater-pwc-optional-outputs.logic.service';
import { EeaGapSurfacewaterPwcOptionalOutputsColdef } from './eea-gap-surfacewater-pwc-optional-outputs.coldef';
import { Constants } from 'src/app/shared/utils/constants';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { ApplicationScheme } from 'src/app/shared/models/pwc/application-scheme';
import { GridLogicService } from 'src/app/shared/components/grid/grid.logic.service';

@Component({
  selector: 'app-eea-gap-surfacewater-pwc-optional-outputs',
  templateUrl: './eea-gap-surfacewater-pwc-optional-outputs.component.html',
  styleUrls: ['./eea-gap-surfacewater-pwc-optional-outputs.component.css']
})
export class EeaGapSurfacewaterPwcOptionalOutputsComponent {
  @Input() row?: ApplicationScheme;
  @Input({required: true}) isProjectOwner! : boolean;
  
  @Output() onSelectOptionalOutputs = new EventEmitter<OptionalOutputsByRow>;

  @ViewChild('grid') grid!: GridComponent;

  columnDefs: any;
  rowData: any = [];
  upperLeftCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_UPPER_LEFT;
  upperRightCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_UPPER_RIGHT;
  bottomLeftCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_BOTTOM_LEFT;
  bottomRightCheckbox = Constants.PWC_OPTIONAL_OUTPUTS_CHECKBOX_BOTTOM_RIGHT;
  optionalOutputs?: any;

  private logicService = inject(EeaGapSurfacewaterPwcOptionalOutputsLogicService);

  constructor(
    private eeaGapSurfacewaterPwcOptionalOutputsColdef: EeaGapSurfacewaterPwcOptionalOutputsColdef,
    private gridService: GridLogicService,
  ){}

  public ngOnInit(): void {
    this.getColumnDef();
    if( !this.row ) {
      this.setInitialData();
      return
    };
    if(!this.row.optionalOutputs) {
      this.setInitialData();
      return
    }
    this.optionalOutputs = this.row.optionalOutputs;
    this.rowData = this.optionalOutputs?.optionalOutputOtherOutputs ?? [];
  }

  getColumnDef() {
    this.columnDefs = this.eeaGapSurfacewaterPwcOptionalOutputsColdef.getColumnsDefinition(this.isProjectOwner);
  }

  public onShow(): void {
  }

  deleteRow(event: any) {
  }

  clearFields() {
    this.setInitialData();
    this.grid?.gridApi.redrawRows();
  }

  public onClose(): void { 
    this.optionalOutputs.optionalOutputOtherOutputs = [];

    this.grid?.gridApi.forEachNode((node:any) => {
      this.optionalOutputs?.optionalOutputOtherOutputs!.push({
        item: node.data.item,
        chem: node.data.chem,
        mode: node.data.mode,
        arg1: node.data.arg1,
        arg2: node.data.arg2,
        multiplier: node.data.multiplier,
      })
    });

    const optionalOutputsByRow: OptionalOutputsByRow = {
      rowId: this.logicService.currentRowId()!,
      optionalOutputs: this.optionalOutputs!
    }

    this.onSelectOptionalOutputs.emit( optionalOutputsByRow );

    this.logicService.visible.set( false );
    this.logicService.currentRowId.set( undefined );
    this.optionalOutputs = undefined;
    this.row = undefined;
    this.rowData = [];
  }

  setInitialData() {
    this.optionalOutputs = <OptionalOutputs>{
      optionalOutputOtherOutputs:[],
      dailyPesticideLeachedAtDepthOfCm: 0,
      pesticideDecayedInFieldFromCm: 0,
      pesticideDecayedInFieldToCm: 0,
      pesticideInPartSoilProfileFromCm: 0,
      pesticideInPartSoilProfileToCm: 0,
      infiltrationAtADepthOfCm: 0,
    };
    this.rowData = [];
  }
}
