import { Component, Input, OnInit } from '@angular/core';
import { ERARunRiskAssessmentComponent } from '../era-run-risk-assessment.component';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { ERARunRiskAssessmentTerrestrialLogicService } from './era-run-risk-assessment-terrestrial.logic.service';
import { Project } from 'src/app/shared/models/project';
import Swal from 'sweetalert2';
import { EraRunRiskAssessmentTerrestrialBamsApi } from './era-run-risk-assessment-terrestrial.bams.api.service';
import { EraRunRiskAssessmentTerrestrialColDef } from './era-run-risk-assessment-terrestrial.coldef';
import { Catalog } from 'src/app/shared/models/echo/catalog';

@Component({
  selector: 'app-era-run-risk-assessment-terrestrial',
  templateUrl: './era-run-risk-assessment-terrestrial.component.html',
  styleUrls: ['./era-run-risk-assessment-terrestrial.component.css'],
})
export class EraRunRiskAssessmentTerrestrialComponent implements OnInit {
  @Input() eraRunRiskAssessmentComponent!: ERARunRiskAssessmentComponent;
  @Input() selectedProject?: Project;
  @Input() isInverseModule?: boolean;

  isLoading!: boolean;
  isAllFilesDownloading: boolean = false;
  rowData: any[] = [];
  columnDefs: any = [];

  geographiesList: Catalog[] = [];
  mainCompartment: string = 'Terrestrial Vertebrate';
  mainCompartmentPk: number = 0;
  mainGeography?: string = '';

  constructor(
    private logicTerrestrialService: ERARunRiskAssessmentTerrestrialLogicService,
    private eraRunRiskAssessmentTerrestrialBamsApi: EraRunRiskAssessmentTerrestrialBamsApi,
    private eraRunRiskAssessmentTerrestrialColDef: EraRunRiskAssessmentTerrestrialColDef,
  ) { }

  ngOnInit(): void {
    this.setLoading(true);
    this.runTerrestrial();
  }

  public setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  runTerrestrial() {
    this.getData(
      this.eraRunRiskAssessmentComponent.tempDatasets,
      this.selectedProject
    );
  }

  public getData(dataSets: DataSet[], project?: Project) {
    let promises = [
      this.logicTerrestrialService.getRegionCountry(project),
      this.logicTerrestrialService.getCompartments(),
      this.logicTerrestrialService.getTaxonTriggers()
    ];
    Promise.all(promises).then(async (results) => {
      this.geographiesList = results[0];
      this.mainCompartmentPk = results[1].filter(
        (x: any) => x.compartment == this.mainCompartment
      )[0]?.endpointCompartmentPk;
      let taxonTriggerList = results[2].filter((x: any) => x.geography === this.selectedProject?.geography);
      this.logicTerrestrialService
        .getTRexRuns(dataSets, this.selectedProject!, this.mainCompartmentPk, taxonTriggerList)
        .then(async (data: any) => {
          this.logicTerrestrialService
            .RunTREX(await data)
            .then(async (data: any) => {
              this.rowData = data.sort((a: any, b: any) => b.rq - a.rq).sort((a: any, b: any) => a.result.localeCompare(b.result));;
              this.setLoading(false);
              this.getGridData();
            });
        });
    });
  }

  getGridData() {
    this.columnDefs = this.eraRunRiskAssessmentTerrestrialColDef.getColumnsDefinition();
  }

  onDownloadClick(): void {
    this.setButtonLoading(true);
    this.eraRunRiskAssessmentTerrestrialBamsApi
      .DownloadTRexOutputFilesByProject(this.createProjectObject())
      .subscribe({
        next: (url: string) => {
          this.setButtonLoading(false);
          if (url != '') {
            window.open(url, '_blank');
          }
        },
        error: (error) => {
          console.error(error);
          this.setButtonLoading(false);
          this.showErrorAlert('Files could not be downloaded');
        },
      });
  }

  createProjectObject(): Project {
    const project: Project = {
      projectPk: this.selectedProject?.projectPk!,
      active: this.selectedProject?.active!,
      deletable: this.selectedProject?.deletable!,
      description: this.selectedProject?.description!,
      formulationPk: this.selectedProject?.formulationPk!,
      name: this.selectedProject?.name!,
      createdBy: this.selectedProject?.createdBy!,
      createdDate: this.selectedProject?.createdDate!,
      status: this.selectedProject?.status!,
    };
    return project;
  }

  setButtonLoading(isAllFilesDownloading: boolean): void {
    this.isAllFilesDownloading = isAllFilesDownloading;
  }

  showErrorAlert(text: string) {
    Swal.fire({
      title: 'Notification',
      html: text,
      confirmButtonColor: '#0069be',
      confirmButtonText: 'OK',
    }).then((result) => { });
  }
}
