import { Component, DestroyRef, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { forkJoin, take } from 'rxjs';
import { ERAEcotoxDatasetsComponent } from 'src/app/environmental-risk-assessment/era-endpoints/era-ecotox-datasets/era-ecotox-datasets.component';
import { EndpointDatasetService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.service';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { CustomDatafieldGroup } from 'src/app/shared/models/custom-datafield-group';
import { Catalog } from 'src/app/shared/models/echo/catalog';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { TaxonMatching } from 'src/app/shared/models/echo/taxon-matching';
import { Project } from 'src/app/shared/models/project';
import { TriggerEcotox } from 'src/app/shared/models/trigger-ecotox';
import { DropdownFilterRenderer } from 'src/app/shared/renderers/dropdown-filter/dropdown-filter.component';
import { DropdownListRenderer } from 'src/app/shared/renderers/dropdown-list/dropdown-list.component';
import { InputNumberRenderer } from 'src/app/shared/renderers/input-number/input-number.component';
import { InputTextRenderer } from 'src/app/shared/renderers/input-text/input-text.component';
import { CustomDatafieldGroupApiService } from 'src/app/shared/services/custom-datafield-group.api.service';
import { DataSetApiService } from 'src/app/shared/services/echo/data-set.api.service';
import { DisciplineApiService } from 'src/app/shared/services/echo/discipline.api.service';
import { EcotoxicologyApiService } from 'src/app/shared/services/echo/ecotoxicology.api.service';
import { ExpoToxApiService } from 'src/app/shared/services/echo/expoTox.api.service';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { UsersLogicService } from 'src/app/shared/services/users.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { Utils } from 'src/app/shared/utils/utils';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tox-expo-inputs-endpoints',
  templateUrl: './tox-expo-inputs-endpoints.component.html',
  styleUrls: ['./tox-expo-inputs-endpoints.component.css']
})
export class ToxExpoInputsEndpointsComponent {

  @Input() endpointComponent!: ERAEcotoxDatasetsComponent;
  @Input() dataSet!: DataSet;
  @Input() menuService!: TabMenuLogicService;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() regionPk: any;
  @Input() taxonGroup?: string;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() selectedProject?: Project;
  @Input() source!: string;

  @Output() detailTransactionToSave = new EventEmitter<any>();
  @Output() emitSavedDatasetpk = new EventEmitter<number>();

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  isLoading!: boolean;
  columnDefs: any = [];
  endpointTypeIdentity: any;
  endpointTypesList: any = [];
  endpointsList: any = [];
  rowData: any = [];
  dataToSave: any;
  saveRef?: string;
  destroyRef = inject(DestroyRef);
  geographies: Catalog[] = [];
  triggers: TriggerEcotox[] = [];
  selectedGeographie: any;
  speciesCatalog: Catalog[] = [];
  taxonList: TaxonMatching[] = [];
  mainGeography?: string = '';
  showAlert: boolean = false;
  projectPk!: number;
  metaboliteList: Catalog[] = [];
  activeIngredientList: Catalog[] = [];

  @ViewChild('grid') grid!: GridComponent;
  customDatafields: CustomDatafieldGroup[] = [];
  mainDiscipline: any;

  constructor(public dataSetService: EndpointDatasetService,
    private usersService: UsersLogicService,
    private dataSetApiService: DataSetApiService,
    private expoToxApiService: ExpoToxApiService,
    private customDatafieldGroupApiService: CustomDatafieldGroupApiService,
    private disciplineApiService: DisciplineApiService) {
    this.columnDefs = [];
  }


  ngOnDestroy(): void {
    if (this.grid?.transactionList) {
      if (this.grid?.transactionList.length > 0) {
        this.emitSavedDatasetpk.emit(this.dataSet.dataSetPk);
      }
      this.dataToSave = this.grid.transactionList;
      this.setDataToSave();
      this.save(this.dataToSave);
      this.detailTransactionToSave.emit(this.dataToSave);
    }
  }

  deleteRow(event: any) {
    this.save(event);
  }

  ngOnInit(): void {
    this.projectPk = this.selectedProject?.projectPk ?? -1;
    this.disciplineApiService.getDisciplineInformation().subscribe((disciplines: any) => {
      this.mainDiscipline = disciplines.filter((x: any) => x.name === Constants.DISCIPLINE_IDENTITIES.EXPOTOX)[0]?.disciplinePk;
    });
    this.getToxExpoData();
  }

  initSubscribes() {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.menuService.saveRef;
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.saveRef = this.subTabMenuService.saveRef;
    });
  }

  getData(): void {
    this.getEndpointData();
  }

  getEndpointData() {
    forkJoin([
      this.dataSetApiService.getEndpointsByDataSetsExpoTox([this.dataSet]),
      this.customDatafieldGroupApiService.GetCustomDatafieldGroupByDisciplineName(Constants.DISCIPLINE_IDENTITIES.EXPOTOX),
    ]).subscribe(([data, endpoints]) => {
      this.customDatafields = endpoints;
      this.filterAllRowData(data);
      this.isLoading = false;
    });
  }



  getToxExpoData() {
    this.isLoading = true;
    this.configureGridExpoToxAssessment();
    this.getData();
    this.initSubscribes();
  }

  filterAllRowData(data: any): void {
    this.rowData = data[0].endpoints;
    this.filterRowDataByEndpointTypePreselectedValue();
  }

  filterRowDataByEndpointTypePreselectedValue(): void {
    this.rowData = this.processGroupedEndpoints();
  }

  processGroupedEndpoints(): any[] {

    this.rowData.forEach((row: any) => {
      if (row.groupedEndpointFieldValues?.length > 0)
        Constants.HHRA_EXPO_TOX_ENDPOINTS.forEach((endpoint) => {
          if (
            row.groupedEndpointFieldValues.find(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )
          )
            row[endpoint.field] = row.groupedEndpointFieldValues.filter(
              (x: any) => x.key.toLowerCase() == endpoint.name
            )[0].value;
        });
    });
    return this.rowData;
  }

  saveData(data: any) {
    if (data === undefined) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    if (data.length <= 0) {
      this.menuService.setSuccess(true);
      this.subTabMenuService.setSuccess(true);
      return;
    }

    this.dataToSave = data;
    this.setDataToSave();
    this.save(this.dataToSave);
  }

  save(data: any) {
    this.expoToxApiService.saveData(data).subscribe({
      next: () => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(true) : this.subTabMenuService.setSuccess(true);
      },
      error: () => {
        this.saveRef === Constants.WIZARD_MENU_REF.Master ? this.menuService.setSuccess(false) : this.subTabMenuService.setSuccess(false);
      }
    })
  }

  onSelectionChanged(event: any) {
    this.validatedDataSetName(this.newRowDataSetObject(this.validateDdlSelections(event)));
    this.grid.gridApi.redrawRows();
  }

  validatedDataSetName(row: any) {
    if (row?.description != undefined && row?.newRow === true && this.showAlert === false)
      this.dataSetService.existDataSetNameByProjectMolecule(row?.description,
        this.projectPk,
        row?.moleculePk).pipe(take(1)).subscribe((exist: any) => {
          if (exist) {
            this.showAlert = true;
            Swal.fire({
              title: `${row.description}, This Dataset name already exists on selected active ingredient.`,
              text: 'Please use a different name',
              confirmButtonColor: '#0069be',
              confirmButtonText: 'Ok',
              icon: 'error',
            });
          } else {
            this.showAlert = false;
          }
        });
  }


  validateDdlSelections(pData: any) {
    if (pData.dataSetPk == undefined) {
      pData.dataSetPk = -1;
    }
    if (pData.moleculePk == -1) {
      pData.moleculePk == this.dataSet.moleculePk;

    }
    return pData;
  }

  public newRowDataSetObject(data: any) {
    let newRow: DataSet = {
      dataSetPk: data?.dataSetPk,
      description: data.row?.description,
      dataSetsToImport: [],
      hasEndpoints: false,
      moleculePk: data.row?.moleculePk,
      source: this.source,
      projectPk: this.projectPk,
      activeIngredient: '',
      substanceType: 'Active',
    };
    data = newRow;
    return data;
  }

  addEndpointsToFieldValueList(): void {
    this.customDatafields.forEach((item) => {
      item.endpoint.forEach((endpoint) => {
        if (
          endpoint != null &&
          this.endpointsList
            .map((x: any) => x.endpointValue)
            .indexOf(endpoint) === -1
        ) {
          this.endpointsList.push({ endpointValue: endpoint });
        }
      });
      this.endpointsList.sort((a: any, b: any) =>
        a.endpointValue.localeCompare(b.endpointValue)
      );
    });
  }






  configureGridExpoToxAssessment(): void {
    this.columnDefs = [
      {
        headerName: 'Exposure Duration',
        field: 'exposureDuration',
        hide: false,
        width: 200,
        suppressSizeToFit: true,
        type: 'select',
        enableCellChangeFlash: true,
        filter: 'agSetColumnFilter',
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          instance: this,
          options: Constants.HHRA_EXPOSURE_DURATION,
          columnThatModifies: 'exposureDuration',
          fieldToFilter: 'exposureDuration',
          optionLabel: 'label',
          optionValue: 'label',
          sort: 'asc',
          filterable: false,
          disabled: !this.isProjectOwnershipValid,
          isRequired: true,
        },
        instance: this,
        editable: false,
      },
      {
        headerName: 'Exposure Type',
        field: 'exposureType',
        hide: false,
        width: 160,
        suppressSizeToFit: true,
        editable: false,
        type: 'select',
        filter: DropdownFilterRenderer,
        filterParams: {
          labelId: 'exposureType',
        },
        cellRenderer: DropdownListRenderer,
        cellRendererParams: {
          instance: this,
          options: Constants.HHRA_EXPOSURE_TYPE,
          columnThatModifies: 'exposureType',
          filterDataValue: Constants.HHRA_EXPOSURE_TYPE,
          optionLabel: 'label',
          optionValue: 'label',
          filterable: false,
          disabled: !this.isProjectOwnershipValid,
        },
        instance: this,
      },
      {
        headerName: 'Non-cancer',
        children: [
          {
            editable: false,
            headerName: 'POD (mg/kg/day)',
            field: 'podNonCancer',
            hide: false,
            width: 130,
            suppressSizeToFit: true,
            type: 'default',
            enableCellChangeFlash: true,
            filter: 'agSetColumnFilter',
            cellRenderer: InputNumberRenderer,
            cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
          },
          {
            headerName: 'POD source/study',
            field: 'podSourceStudy',
            hide: false,
            width: 180,
            editable: false,
            suppressSizeToFit: true,
            type: 'select',
            filter: DropdownFilterRenderer,
            filterParams: {
              labelId: 'exposureType',
            },
            cellRenderer: DropdownListRenderer,
            cellRendererParams: {
              instance: this,
              options: Constants.HHRA_EXPO_TOX_NON_CANCER_POD,
              columnThatModifies: 'exposureType',
              filterDataValue: Constants.HHRA_EXPO_TOX_NON_CANCER_POD,
              optionLabel: 'label',
              optionValue: 'label',
              filterable: false,
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
          },
          {
            headerName: 'LOC',
            field: 'locNonCancer',
            hide: false,
            editable: false,
            width: 90,
            suppressSizeToFit: true,
            type: 'default',
            enableCellChangeFlash: true,
            filter: 'agSetColumnFilter',
            cellRenderer: InputNumberRenderer,
            cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
          },
          {
            headerName: 'Body Weight (kg)',
            field: 'podNonCancerBodyWeight',
            hide: false,
            width: 110,
            editable: false,
            suppressSizeToFit: true,
            type: 'select',
            filter: DropdownFilterRenderer,
            filterParams: {
              labelId: 'exposureType',
            },
            cellRenderer: DropdownListRenderer,
            cellRendererParams: {
              instance: this,
              options: Constants.HHRA_EXPO_TOX_NON_CANCER_BODY_WEIGHT,
              columnThatModifies: 'podNonCancerBodyWeight',
              filterDataValue: Constants.HHRA_EXPO_TOX_NON_CANCER_BODY_WEIGHT,
              optionLabel: 'label',
              optionValue: 'label',
              filterable: false,
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
            validationType: 'string',
          },
        ]
      },
      {
        headerName: 'Absorption',
        children: [
          {
            headerName: 'Fraction (0-1)',
            field: 'fractionAbsorption',
            hide: false,
            width: 100,
            suppressSizeToFit: true,
            type: 'default',
            editable: false,
            enableCellChangeFlash: true,
            filter: 'agSetColumnFilter',
            cellRenderer: InputNumberRenderer,
            cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
              min: 0,
              max: 1,
              parent: this
            },
            instance: this,
          },
          {
            headerName: 'Absorption source/study',
            field: 'absorptionSourceStudy',
            hide: false,
            width: 340,
            editable: false,
            suppressSizeToFit: true,
            type: 'select',
            filter: DropdownFilterRenderer,
            filterParams: {
              labelId: 'absorptionSourceStudy',
            },
            cellRenderer: DropdownListRenderer,
            cellRendererParams: {
              instance: this,
              options: Constants.HHRA_EXPO_TOX_ABSORPTION_SOURCE,
              columnThatModifies: 'absorptionSourceStudy',
              filterDataValue: Constants.HHRA_EXPO_TOX_ABSORPTION_SOURCE,
              optionLabel: 'label',
              optionValue: 'label',
              filterable: false,
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
          },

        ]
      },
      {
        headerName: 'Cancer',
        children: [
          {
            headerName: 'Q* (mg/kg-day)-1',
            field: 'qDayValue',
            hide: false,
            width: 150,
            suppressSizeToFit: true,
            editable: false,
            type: 'default',
            enableCellChangeFlash: true,
            filter: 'agSetColumnFilter',
            cellRenderer: InputNumberRenderer,
            cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
          },
          {
            headerName: 'Q* source/study',
            field: 'qSourceStudy',
            hide: false,
            width: 250,
            suppressSizeToFit: true,
            type: 'default',
            enableCellChangeFlash: true,
            filter: 'agSetColumnFilter',
            cellRenderer: InputTextRenderer,
            cellRendererParams: {
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
            editable: false,
          },
          {
            headerName: 'Body Weight (kg)',
            field: 'cancerBodyWeight',
            hide: false,
            width: 110,
            suppressSizeToFit: true,
            type: 'select',
            filter: DropdownFilterRenderer,
            filterParams: {
              labelId: 'absorptionSourceStudy',
            },
            cellRenderer: DropdownListRenderer,
            cellRendererParams: {
              instance: this,
              options: Constants.HHRA_EXPO_TOX_CANCER_BODY_WEIGHT,
              columnThatModifies: 'cancerBodyWeight',
              filterDataValue: Constants.HHRA_EXPO_TOX_CANCER_BODY_WEIGHT,
              optionLabel: 'label',
              optionValue: 'label',
              filterable: false,
              disabled: !this.isProjectOwnershipValid,
            },
            instance: this,
            editable: false,
          },

        ]
      },
    ];
  }

  public setChangeValues(newData: any): void {
    let oldValue = newData.oldValue;
    this.grid.CreateTransaction(
      newData.row.id,
      newData.id,
      oldValue,
      newData.row);
  }

  getEndpointFieldToGetSelectedValue(): any {
    return (this.endpointTypeIdentity = ['endpointTypePk']);
  }

  getEndpointFieldToFilter(): any {
    return (this.endpointTypeIdentity = ['endpoint_type']);
  }

  setDataToSave(): void {
    this.setDataSetPkToTransactionRow();
    this.addAuthor();
    this.addLastModifiedDate();
  }

  addAuthor(): void {
    this.dataToSave.forEach((transaction: any) => {
      if (transaction['row'].status === Constants.CONST_APPROVED) {
        transaction['row'].reviewedBy = this.usersService.getActiveUserID();
      } else {
        transaction['row'].modifiedBy = this.usersService.getActiveUserID();
      }
      transaction['row'].dataSource =
        Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE;
      transaction['row'].substanceType = "Active";
      transaction['row'].moleculePk = this.dataSet.moleculePk;
      transaction['row'].activeIngredient = this.dataSet.activeIngredient;


    });
  }

  setDataSetPkToTransactionRow(): void {
    if (this.dataSet) {
      this.dataToSave.forEach((transaction: any) => {
        transaction['row'].dataSetPk = this.dataSet.dataSetPk;
      });
    }
  }

  addLastModifiedDate(): void {
    this.dataToSave.forEach((transaction: any) => {
      transaction['row'].lastModifiedDate = Utils.getDateFormatToSaveData();
    });
  }



}
