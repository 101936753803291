import { Component, Input, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { filter } from 'rxjs/operators';
import { GridLogicService } from '../../components/grid/grid.logic.service';
import { GridComponent } from '../../components/grid/grid.component';

@Component({
  selector: 'app-button-expand-grid',
  templateUrl: './button-expand-grid.component.html',
  styleUrls: ['./button-expand-grid.component.css']
})
export class ButtonExpandGridRenderer implements ICellRendererAngularComp {

  public iconClass: string;
  public disabled: boolean = false;

  private showIcon: string = 'pi pi-chevron-right';
  private hideIcon: string = 'pi pi-chevron-down';
  private parent: any;
  private params: any;
  
  constructor(private gridService: GridLogicService) {
    this.iconClass = this.showIcon;
  }

  refresh(): boolean {
    return false;
  }

  agInit(params: any,): void {
    this.params = params;
    this.parent = params.context;
    this.iconClass = this.params.data.isExpanded ? this.hideIcon : this.showIcon;
    this.disabled = params.disabled ? params.disabled : false;
  }

  onClick(): void {
    this.params.data.isExpanded = !this.params.data.isExpanded;
    this.iconClass = this.params.data.isExpanded ? this.hideIcon : this.showIcon;
    this.parent.onButtonExpandClick(this.params);
  }
}