import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';

@Component({
  selector: 'app-eea-output',
  templateUrl: './eea-output.component.html',
  styleUrls: ['./eea-output.component.css']
})
export class EEAOutputComponent implements OnInit {

  @Input() selectedProject?:Project;
  @Input() menuService!: TabMenuLogicService;
  @Input() selectedProjectStatus?: number;
  
  destroyRef = inject(DestroyRef)

  constructor(){}

  ngOnInit(){
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.menuService.setSuccess(true));
  }
}
