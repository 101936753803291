import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EEAOutputProjectTier1ukDrainflowAndStreamColdef {
  constructor() { }

  getColumnsDefinition(): any {
    return [
      {
        headerName: '',
        headerClass: '',
        children: [
          {
            headerName: 'Run ID',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'runId',
            width: 150,
            filter: 'agSetColumnFilter',
            pinned: 'left'
          },
          {
            headerName: 'Application Scheme',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'applicationScheme',
            width: 300,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Crop',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'crop',
            width: 200,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Endpoints Data Set',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'endpointDataSet',
            width: 300,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Compound',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'compound',
            width: 140,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Drainage Period',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'drainagePeriod',
            width: 200,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Molecular \n Weight (g/mol)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'molecularWeight',
            width: 150,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Application rate \n (ga.s./ha)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'applicationRate',
            width: 150,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Number Of \n Applications',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'numberOfApplication',
            width: 120,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'SoilDegT50 \n (d)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'soilDegT50',
            width: 110,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Kfoc \n (L/kg)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'Kfoc',
            width: 100,
            filter: 'agSetColumnFilter',
          },
        ]
      },
      {
        headerName: 'Maximum Occurrence (%)',
        headerClass: '',
        children: [
          {
            headerName: 'Soil',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'soil',
            width: 100,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Sediment',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'sediment',
            width: 130,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Water',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'water',
            width: 100,
            filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: '',
        headerClass: '',
        children: [
          {
            headerName: 'PECsw in \n Drainflow (µg/L)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'PECswInDrainflow',
            width: 140,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'PECsw in \n Stream (µg/L)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'PECswInStream',
            width: 140,
            filter: 'agSetColumnFilter',
          },
          {
            headerName: 'PECsed in \n Stream (µg/kg)',
            editable: false,
            wrapText: false,
            resizable: true,
            sortable: true,
            field: 'PECsedInStream',
            width: 140,
            filter: 'agSetColumnFilter',
          }]
      },
    ];
  }
}
