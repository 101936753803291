import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class InverseModelingEEALogicService extends TabMenuLogicService {
  
  override items = Constants.INVERSE_MODELING_EXPOSURE_ASSESSMENT_STEPS;

  override activeItemSubject = new BehaviorSubject({} as CustomTabMenuItem);
  override activeItem = this.activeItemSubject.asObservable();

  override activeIndexChangedSubject = new BehaviorSubject(false);
  override activeIndexChanged = this.activeIndexChangedSubject.asObservable();
  
  override successSubject = new BehaviorSubject(true);
  override success = this.successSubject.asObservable();

  public usedModelsSubject = new BehaviorSubject([] as string[]);
  private readonly usedModels = this.usedModelsSubject.asObservable();

  SetUsedModels(usedModels: string[]) {
    this.usedModelsSubject.next(usedModels);
  }

}
