import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ColDef, ColGroupDef, RowGroupingDisplayType } from 'ag-grid-community';
import { GridComponent } from 'src/app/shared/components/grid/grid.component';
import { Project } from 'src/app/shared/models/project';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { HhraRunRiskAssessmentOpexResultsColDef } from './hhra-run-risk-assessment-occupational-handler-exposure-coldef';
import { OpexInputs } from 'src/app/shared/models/opex-Inputs';
import { RunRiskApiService } from 'src/app/shared/services/run-risk.api.service';
import { Subscription, switchMap, take, timer } from 'rxjs';
import { ProjectApiService } from 'src/app/shared/services/project.api.service';
import { Constants } from '../../../shared/utils/constants';
import { SelectedProjectApiService } from 'src/app/shared/components/selected-project/selected-project.api.service';

@Component({
  selector: 'app-hhra-run-risk-assessment-occupational-handler-exposure',
  templateUrl: './hhra-run-risk-assessment-occupational-handler-exposure.component.html',
  styleUrls: ['./hhra-run-risk-assessment-occupational-handler-exposure.component.css']
})
export class HhraRunRiskAssessmentOccupationalHandlerExposureComponent {

  @Input() selectedProject?: Project;
  @Input() subTabMenuService!: TabMenuLogicService;
  @Input() isProjectOwnershipValid: boolean = false;
  @Input() opexInputsCombinations: OpexInputs[] = [];
  @Output() status: EventEmitter<any>;

  style: string = 'width: auto; height: 350px; text-align: left; z-index:-1;font-size:10px; color: #656565;';
  columnDefs: (ColDef | ColGroupDef)[] | null = [];
  detailColumnDefs: (ColDef | ColGroupDef)[] | null = [];
  detailGroupDisplayType: RowGroupingDisplayType = 'custom';
  rowData: any[] = [];
  isLoading!: boolean;
  subscription!: Subscription;
  projectStatusStyle = {
    statusId: this.selectedProject?.status,
    statusName: "",
    badgeStyle: ""
  }

  @ViewChild('grid') grid!: GridComponent;

  constructor(private readonly hhraRunRiskAssessmentOpexResultsColDef: HhraRunRiskAssessmentOpexResultsColDef,
    private readonly runRiskApiService: RunRiskApiService,
    private projectApiService: ProjectApiService,
    private selectedProjectApiService: SelectedProjectApiService) {
    this.status = new EventEmitter<boolean>();   
  }

  ngOnInit() {
    this.rowData = [];
    this.isLoading = true;
    this.monitorProjectStatus();
  }

  monitorProjectStatus(): void {
    this.subscription = timer(0, 10000).pipe(
      switchMap(() => this.projectApiService.getProjectStatus(this.selectedProject!.projectPk).pipe(take(1)))
    ).subscribe({
      next: (status) => {
        this.selectedProject!.status = status;
        if (this.selectedProject!.status == Constants.PROJECT_STATUS_ID.COMPLETED) {
          this.selectedProjectApiService.updateSelectedProjectStatus(this.selectedProject!.status);
          this.getData();
          this.subscription.unsubscribe();
        }
        if(this.selectedProject!.status == Constants.PROJECT_STATUS_ID.FAILED){
          this.isLoading = false;
          this.subscription.unsubscribe();
        }
      },
      error: (error) => {
        console.warn(error)
      }
    });
  }

  getData() {
    this.columnDefs = this.hhraRunRiskAssessmentOpexResultsColDef.getColumnsDefinition();
    // TODO: This section is still pending to be completed due to another ticket's dependency.
    // this.runRiskApiService.GetOPEXRunsByProject(this.selectedProject?.projectPk!).subscribe((data: any[]) => {
    //   this.rowData = data;
    //   this.isLoading = false;
    // });
    this.isLoading = false;
  }
}