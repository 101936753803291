import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomTabMenuItem } from 'src/app/shared/models/custom-tab-menu-item';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { Constants } from 'src/app/shared/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class HHRAEndpointsLogicService extends TabMenuLogicService {
  override items = Constants.HUMAN_HEALTH_RISK_ASSESSMENT_ENDPOINTS;

  override saveRef = Constants.WIZARD_MENU_REF.Detail;

  override activeItemSubject = new BehaviorSubject(this.items[0] as CustomTabMenuItem);
  override activeItem = this.activeItemSubject.asObservable();

  override activeIndexChangedSubject = new BehaviorSubject(false);
  override activeIndexChanged = this.activeIndexChangedSubject.asObservable();

  override successSubject = new BehaviorSubject(true);
  override success = this.successSubject.asObservable();
}
