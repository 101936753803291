import { Injectable } from '@angular/core';
import { Utils } from 'src/app/shared/utils/utils';

@Injectable({
  providedIn: 'root'
})

export class EEAOutputProjectTier1ukInputsColdef {

  constructor() { }

  private columnDefs = [
    {
      headerName: 'General', 
      children: [
        {
          headerName: 'Run ID', 
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,      
          field: 'runId',
          minWidth: 120,
          width: 120,
          type: 'default',
          filter: 'agSetColumnFilter',
          pinned: 'left',
        },
        {
          headerName: 'Model',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,         
          field: 'model',
          minWidth: 110,
          width: 110,
          type: 'default',
          filter: 'agSetColumnFilter',
          pinned: 'left',
        }
      ]
    },
    {
      headerName: 'Application Pattern (GAP)',
      
      children: [
        {
          headerName: 'Application Scheme',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,         
          field: 'applicationScheme',
          minWidth: 300,
          width: 300,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Crop (Drift)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'cropDrift',
          minWidth: 170,
          width: 170,
          type: 'default',
          filter: 'agSetColumnFilter'
        },  
        {
          headerName: 'Crop (spray)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'cropSpray',
          minWidth: 250,
          width: 250,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Number Of \n Applications',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'applicationNumber',
          minWidth: 120,
          width: 120,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Application \n Interval (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'applicationIntervalDays',
          minWidth: 120,
          width: 120,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Active Ingredient', 
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'activeIngredient',
          minWidth: 200,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Rate \n (g/ha)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'rate',
          minWidth: 90,
          width: 90,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName:`BBCH \n (earliest)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'bbchEarliest',
          minWidth: 100,
          width: 100,
          type: 'default',
          filter: 'agSetColumnFilter'
        },

        {
          headerName: `Crop Coverage` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'cropCoverage',
          minWidth: 140,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
        {
          headerName: `Interception \n (%)` ,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'cropInterception',
          minWidth: 110,
          type: 'default',
          filter: 'agSetColumnFilter',
        },
      ]
    },
    {
      headerName: 'Endpoints',
      
      children: [
        {
          headerName: 'Endpoint Data Set',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'endpointsDataSetName',
          minWidth: 300,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Substance Name',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          cellRenderer: 'agGroupCellRenderer',
          field: 'substanceName',
          minWidth: 200,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Molecular \n Weight (g/mol)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          cellRenderer: 'agGroupCellRenderer',
          field: 'molecularWeight',
          minWidth: 140,
          type: 'default',
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Kfoc \n (L/Kg)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'kfocLKg',
          minWidth: 90,
          type: 'default',
          valueFormatter: (params:any) =>Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter'
        },
        {
          headerName: `Half-life \n in soil (d)`,
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,
          field: 'halflifeinsoild',
          minWidth: 100,
          type: 'default',
          valueFormatter: (params:any) =>Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Half-life in \n sediment (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,         
          field: 'halflifeinsediment',
          minWidth: 120,
          type: 'default',
          valueFormatter: (params:any) => Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Half-life in \n water (d)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'halflifeinwater',
          minWidth: 110,
          type: 'default',
          valueFormatter: (params:any) => Utils.convertNumberToExponential(params.value),
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Maxium occurrence \n in sediment (%)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'maxiumoccurrenceinsediment',
          minWidth: 153,
          type: 'default',
          valueFormatter: (params:any) =>Utils.convertNumberToExponential(params.value) ,
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Maxium occurrence \n in soil (%)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,        
          field: 'maximumOccurrenceInSoil',
          minWidth: 153,
          type: 'default',
          valueFormatter: (params:any) =>Utils.convertNumberToExponential(params.value) ,
          filter: 'agSetColumnFilter'
        },
        {
          headerName: 'Maxium occurrence \n in water (%)',
          editable: false,
          wrapText: false,
          resizable: true,
          sortable: true,         
          cellRenderer: 'agGroupCellRenderer',
          field: 'maxiumoccurrenceinwater',
          minWidth: 153,
          type: 'default',
          filter: 'agSetColumnFilter'
        }
      ]
    }
  ]    

  getColumnsDefinition(): any {
    return this.columnDefs;
  }
}