import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';

@Injectable({
    providedIn: 'root'
})
export class EEARunProjectQcColdef {

    constructor() { }

    public getQcColumnsDefinition(): ColDef[] {
        return [
            {
                headerName: 'Model',
                field: 'run.model.name',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Compartment',
                field: 'run.compartment',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Substance Name',
                field: 'run.substanceName',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Data Set',
                field: 'run.dataSet.description',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                width: 160,
                maxWidth:160,
                tooltipValueGetter: (params) => params.value
            },
            {
                headerName: 'Application Scheme',
                field: 'run.applicationSchemeData.name',
                editable: false,
                type: 'default',
                filter: 'agSetColumnFilter',
                pinned:'left',
                wrapText: true,
                wrapHeaderText:true,
                minWidth: 300,
                width: 300,
                tooltipValueGetter: (params) => params.value
            },
        ]
    }
}
