import { Component, DestroyRef, effect, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ChildLevel, EndpointsParameterPwc, Koc_Kd, KocKd, RelationLevel } from './eea-endpoints-parameters-pwc.inteface';
import { DataSet } from 'src/app/shared/models/echo/data-set';
import { EeaEndpointsParametersPwcLogicService } from './eea-endpoints-parameters-pwc.logic.service';
import { Constants } from 'src/app/shared/utils/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TabMenuLogicService } from 'src/app/shared/services/tab-menu.logic.service';
import { EndpointGet, EndpointSaveFormat } from 'src/app/shared/models/endpoint';
import { EndpointsConfigurationPwc } from './eea-endpoints-parameters-pwc.config';
import { EndpointDatasetLogicService } from 'src/app/shared/components/endpoint-dataset/endpoint-dataset.logic.service';

@Component({
  selector: 'app-eea-endpoints-parameters-pwc',
  templateUrl: './eea-endpoints-parameters-pwc.component.html',
  styleUrls: ['./eea-endpoints-parameters-pwc.component.css']
})
export class EeaEndpointsParametersPwcComponent implements OnInit, OnDestroy {

  @Input({required: true}) compartment!: string;
  @Input({required: true}) dataset!: DataSet;
  @Input({required: true}) isProjectOwnershipValid: boolean = false;
  @Input({required: true}) menuService!: TabMenuLogicService;
  @Input({required: true}) subTabMenuService!: TabMenuLogicService;

  public configuration:  EndpointsParameterPwc[] = [];
  public parent?:        ChildLevel;
  public daugther?:      ChildLevel;
  public grandDaugther?: ChildLevel;
  public koc_kd:         KocKd = {value: Koc_Kd.Koc, modified: true};
  public relationLevelParent = RelationLevel.Parent;
  public relationLevelDaugther = RelationLevel.Daugther;
  public relationLevelGrandDaugther = RelationLevel.GrandDaugther;
  public blockUi: boolean = false;

  private destroyRef = inject(DestroyRef);
  private logicService = inject(EeaEndpointsParametersPwcLogicService);
  private endpointLogicService = inject(EndpointDatasetLogicService);
  private isFirstLoad: boolean = true;

  isExpandedDatasetEffect = effect(() => {
    const isDatasetExpanded = this.endpointLogicService.isDatasetExpanded();

    if( !isDatasetExpanded )
      this.setSaveData();

  });

  public async ngOnInit(): Promise<void> {
    this.blockUi = true;
    EndpointsConfigurationPwc.CONFIGURATION.forEach(config => {
      this.configuration.push({
        ...config,
        parentModified: false,
        daughterModified: false,
        grandDaughterModified: false,
        moleDaughterModified: false,
        moleGranDautherModified: false
      });
    });

    this.initSubscribes();
    await this.setChildLevelDb();
    await this.GetEndpoints();
    this.isFirstLoad = false;
    this.blockUi = false;
  }

  private initSubscribes(): void {
    this.menuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if( !this.isFirstLoad )
        this.setSaveData();
    });
    this.subTabMenuService.activeIndexChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if( !this.isFirstLoad )
        this.setSaveData();
    });
  }

  private async setChildLevelDb(): Promise<void> {
    if( !this.dataset.dataSetPk ) return;

    this.parent = {
      childLevel: Constants.CHILD_LEVEL.PARENT,
      metabolitePk: this.dataset.compoundPk!,
      metaboliteName: this.dataset.activeIngredient!,
      isActive: true
    }

    const childrenLevel = await this.logicService.getChildLevelByDataset(this.dataset.dataSetPk);
    this.daugther = childrenLevel.find( child => child.childLevel === Constants.CHILD_LEVEL.DAUGHTER );
    this.grandDaugther = childrenLevel.find( child => child.childLevel === Constants.CHILD_LEVEL.GRANDDAUGHTER );
  }

  private async GetEndpoints(): Promise<void> {

    if( !this.dataset.dataSetPk ) return;

    const endpointsDb = await this.logicService.getEndpoints(this.dataset.dataSetPk);

    endpointsDb.forEach(endpointDb => {

      if( endpointDb.endpointFieldValue === Constants.DATA_VALUES_NAMES.isKoc ){
        this.koc_kd.modified = false;
        this.koc_kd.value = endpointDb.value === 1 ? Koc_Kd.Koc : Koc_Kd.Kd;
        this.koc_kd.endpointPk = endpointDb.endpointPk;
        this.configuration = this.configuration.filter(c => c.endpointFieldValue !== Constants.DATA_VALUES_NAMES.isKoc);
      }

      this.configuration.forEach( config => {

          if( endpointDb.moleculePk === this.parent?.metabolitePk && this.parent.isActive && !endpointDb.metabolitePk && config.endpointFieldValue.toLowerCase() === endpointDb.endpointFieldValue.toLowerCase() ){
            this.setCommonFieldsFromDb(config, endpointDb);
            config.parentEndpointPk = endpointDb.endpointPk;
            config.parentValue = endpointDb.value;
            config.parentModified = false;
          }

          if( endpointDb.metabolitePk === this.daugther?.metabolitePk && endpointDb.endpointFieldValue.includes('Moles') && endpointDb.endpointFieldValue.toLowerCase().includes(config.endpointFieldValue.toLowerCase()) ){
            this.setCommonFieldsFromDb(config, endpointDb);
            config.moleDaugtherEndpointPk = endpointDb.endpointPk;
            config.daughterMetabolitePk = endpointDb.metabolitePk;
            config.moleDaugtherValue = endpointDb.value;
            config.moleDaughterModified = false;
          }

          if( endpointDb.metabolitePk === this.daugther?.metabolitePk && config.endpointFieldValue.toLowerCase() === endpointDb.endpointFieldValue.toLowerCase() ){
            this.setCommonFieldsFromDb(config, endpointDb);
            config.daugtherEndpointPk = endpointDb.endpointPk;
            config.daughterMetabolitePk = endpointDb.metabolitePk;
            config.daugtherValue = endpointDb.value;
            config.daughterModified = false;
          }

          if( endpointDb.metabolitePk === this.grandDaugther?.metabolitePk && endpointDb.endpointFieldValue.includes('Moles') && endpointDb.endpointFieldValue.toLowerCase().includes(config.endpointFieldValue.toLowerCase()) ){
            this.setCommonFieldsFromDb(config, endpointDb);
            config.moleGranddaughterEndpointPk = endpointDb.endpointPk;
            config.grandDaughterMetabolitePk = endpointDb.metabolitePk;
            config.moleGrandDaughterValue = endpointDb.value;
            config.moleGranDautherModified = false;
          }

          if( endpointDb.metabolitePk === this.grandDaugther?.metabolitePk && config.endpointFieldValue.toLowerCase() === endpointDb.endpointFieldValue.toLocaleLowerCase() ){
            this.setCommonFieldsFromDb(config, endpointDb);
            config.granddaughterEndpointPk = endpointDb.endpointPk;
            config.grandDaughterMetabolitePk = endpointDb.metabolitePk;
            config.granDaugtherValue = endpointDb.value;
            config.grandDaughterModified = false;
          }
      });

    });
  }

  private setCommonFieldsFromDb( config: EndpointsParameterPwc, endpointDb: EndpointGet): void {
    config.datasetPk = endpointDb.datasetPk;
    config.moleculePk = endpointDb.moleculePk;
  }

  public onKocKdClick(): void{
    this.koc_kd.modified = true;
  }

  public setAsModified( relationLevel: RelationLevel, field: EndpointsParameterPwc): void {

    field.moleculePk = this.dataset.compoundPk;
    field.datasetPk = this.dataset.dataSetPk;

    switch( relationLevel ){
      case RelationLevel.Parent:
        field.parentModified = true;
        return;
      case RelationLevel.Daugther:
        field.daughterModified = true;
        field.daughterMetabolitePk = this.daugther?.metabolitePk;
        return;
      case RelationLevel.GrandDaugther:
        field.grandDaughterModified = true;
        field.grandDaughterMetabolitePk = this.grandDaugther?.metabolitePk;
      return;
    }
  }

  public setAsModifiedMole( relationLevel: RelationLevel, field: EndpointsParameterPwc ): void {
    switch( relationLevel ){
      case RelationLevel.Daugther:
        field.moleDaughterModified = true;
        return;
      case RelationLevel.GrandDaugther:
        field.moleGranDautherModified = true;
        return;
    }
  }

  private async setSaveData(): Promise<void> {
    this.blockUi = true;
    const moleculePk = this.parent?.metabolitePk!;
    const saveData: EndpointSaveFormat[] = [];

    if( this.koc_kd.modified )
      saveData.push(this.setKocKdRow( moleculePk ));

    this.configuration.forEach(config =>{

      config.moleculePk = moleculePk;

      if( config.daughterMetabolitePk && config.daughterMetabolitePk != this.daugther?.metabolitePk && (config.daugtherValue || config.moleDaugtherValue)){
        config.daughterMetabolitePk = this.daugther?.metabolitePk;

        if( config.daugtherValue )
          config.daughterModified = true;

        if( config.moleDaugtherValue )
          config.moleDaughterModified = true;
      }

      if( config.grandDaughterMetabolitePk && config.grandDaughterMetabolitePk != this.grandDaugther?.metabolitePk && (config.granDaugtherValue || config.moleGrandDaughterValue)){
        config.grandDaughterMetabolitePk = this.grandDaugther?.metabolitePk;

        if( config.granDaugtherValue )
          config.grandDaughterModified = true;

        if( config.moleGrandDaughterValue )
          config.moleGranDautherModified = true;
      }

      if( config.parentModified ){
        saveData.push(this.setRowToSave(config, config.parentValue, undefined, config.parentEndpointPk));
      }

      if( config.moleDaughterModified ){
        const endpointFieldValue = `Moles ${config.endpointFieldValue} Daughter`;
        saveData.push(this.setMolesRow( endpointFieldValue, config.moleDaugtherValue, this.daugther?.metabolitePk, config.moleculePk! ));
      }

      if( config.daughterModified )
        saveData.push(this.setRowToSave(config, config.daugtherValue, this.daugther?.metabolitePk, config.daugtherEndpointPk));

      if( config.moleGranDautherModified ){
        const endpointFieldValue = `Moles ${config.endpointFieldValue} Granddaughter`;
        saveData.push(this.setMolesRow( endpointFieldValue, config.moleGrandDaughterValue, this.grandDaugther?.metabolitePk, config.moleculePk! ));
      }

      if( config.grandDaughterModified )
        saveData.push(this.setRowToSave(config, config.granDaugtherValue, this.grandDaugther?.metabolitePk, config.granddaughterEndpointPk));

    });

    if( saveData.length == 0 ){
      this.blockUi = false;
    }

    await this.logicService.saveAndUpdateEndpoints(saveData);
    this.blockUi = false;

  }

  private setKocKdRow( moleculePk: number): EndpointSaveFormat {
    const row: EndpointSaveFormat ={
      endpointPk:         this.koc_kd.endpointPk,
      compartment:        this.compartment,
      datasetPk:          this.dataset.dataSetPk!,
      dataSource:         Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE,
      endpointFieldValue: Constants.DATA_VALUES_NAMES.isKoc,
      moleculePk:         moleculePk,
      value:              this.koc_kd.value === Koc_Kd.Koc ? 1 : 0
    }
    return row;
  }

  private setMolesRow( endpointFieldValue: string, value: number | undefined, metabolitePk: number | undefined, moleculePk: number ): EndpointSaveFormat {
    const row: EndpointSaveFormat ={
      compartment:        this.compartment,
      datasetPk:          this.dataset.dataSetPk!,
      dataSource:         Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE,
      endpointFieldValue: endpointFieldValue,
      metabolitePk:       metabolitePk,
      moleculePk:         moleculePk,
      value:              value
    }
    return row;
  }

  private setRowToSave( config: EndpointsParameterPwc,  value: number | undefined, metabolitePk: number | undefined, endpointPk: number | undefined ): EndpointSaveFormat {
    const row: EndpointSaveFormat ={
      compartment:        this.compartment,
      datasetPk:          config.datasetPk!,
      dataSource:         Constants.THIS_APPLICATION_ENDPOINT_DATA_SOURCE,
      endpointFieldValue: config.endpointFieldValue,
      endpointPk:         endpointPk,
      metabolitePk:       metabolitePk,
      moleculePk:         config.moleculePk!,
      value:              value
    }
    return row;
  }

  public ngOnDestroy(): void {
    this.endpointLogicService.setIsDatasetExpanded(false);
    this.isExpandedDatasetEffect.destroy();
  }

}
