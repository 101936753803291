import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Project } from 'src/app/shared/models/project';
import { HttpApiService } from 'src/app/shared/services/http.api.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class HHRARunLogicService extends HttpApiService {
    override apiUrl: string = environment.BAMS_Api_URL;

    DownloadOpexOutputFilesByProject(pProject: Project): Observable<string> {
        const body = JSON.stringify(pProject);
        const headers = new HttpHeaders({ 'Content-type': 'application/json' });
        const query = `api/downloadOpexOutputFilesByProject`;
        return this.put(query, body, headers)
            .pipe(map((data: any) => data.Value));
    }

}
